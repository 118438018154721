import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_currencies,
  get_currencies_error,
  get_currencies_success,
  create_currency,
  create_currency_error,
  create_currency_success
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getCurrenciesSagas() {
  try {
    const requestRes = yield call(api.get, '/currency');
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_currencies_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_currencies_error.type
    });
  }
}

function* createCurrencySagas({ payload }) {
  try {
    const requestRes = yield call(api.post, '/currency', payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: create_currency_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: create_currency_error.type
    });
  }
}

function* currenciesSagas() {
  yield takeEvery(get_currencies.type, getCurrenciesSagas);
  yield takeEvery(create_currency.type, createCurrencySagas);
}

export default currenciesSagas;
