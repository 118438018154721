import React from 'react';
import media from '@app/globalstyles/utils/media';
import styled from 'styled-components';

export const CustomSelect = styled.select`
  width: 100%;
  height: 42px;
  background: #ffffff;
  outline: none;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  color: #797b80;
  ${media.mobile`
  width: 100%;
  `}

  option {
    color: #797b80;
    padding-left: 3%;
  }

  &:focus {
    outline: none;
    border: 1px solid #549a9a;
  }
`;

export const SelectUnit = styled.select`
  width: 60px;
  height: auto;
  background: #ffffff;
  outline: none;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  color: #797b80;
  margin-left: 5px;
  ${media.mobile`
width: 50px;
`}

  option {
    color: #797b80;
    padding-left: 3%;
  }

  &:focus {
    outline: none;
    border: 1px solid #549a9a;
  }
`;

function SelectInputP({ name, label, children, ...rest }) {
  return (
    <CustomSelect name={name} {...rest}>
      <option value="">{label}</option>
      {children}
    </CustomSelect>
  );
}

export default SelectInputP;
