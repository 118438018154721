import React from 'react';
import { Link } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';

const Header = ({ name }) => {
  return (
    <div className="header">
      <Link className="link" to="/meals">
        Meals
      </Link>

      <MdChevronLeft color="#605D66" />

      <p>{name}</p>
    </div>
  );
};

export default Header;
