import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Title = styled.h1`
  text-align: center;
  color: ${(props) => (props.color ? props.color : '#549a9a')};
  font-weight: ${(props) => (props.weight ? props.weight : '600')};
  text-align: ${(props) => (props.align ? props.align : 'center')};

  @media screen and (max-width: 992px) {
    font-size: 1.7em;
  }

  @media screen and (min-width: 1024px) and (max-width: 1400px) {
    font-size: 3em;
  }
`;

const Body = styled.p`
  color: ${(props) => (props.color ? props.color : '#003232')};
  text-align: ${(props) => (props.align ? props.align : 'center')};
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
  width: ${(props) => (props.width ? props.width : '100%')};
  opacity: 80%;

  @media screen and (max-width: 992px) {
    font-size: 0.8em;
  }

  @media screen and (min-width: 1024px) and (max-width: 1400px) {
    font-size: 1.5em;
  }
`;

export const Text = ({ children, size, ...props }) => {
  switch (size) {
    case 'large':
      return <Title {...props}>{children}</Title>;
    case 'small':
      return <Body {...props}>{children}</Body>;
    default:
      return <Body {...props}>{children}</Body>;
  }
};

Text.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node
};

Text.defaultProps = {
  size: 'small'
};

export default Text;
