import styled from 'styled-components/macro';

export const TableFooter = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border: none;
  background: #ffffff;
`;

export const PagesNav = styled.div`
  display: flex;
  align-items: center;
`;
