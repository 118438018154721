import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { add_partner } from '../redux/reducer';
import { getCreating } from '../redux/selector';
import { ModalBody } from '../style';
import Business from './Business';
import PersonalInfo from './PersonalInfo';
import Steps from './Steps';

export const AddPartner = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getCreating);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    country: '',
    type: '',
    business_name: '',
    business_number: '',
    street_address: '',
    city: '',
    state: '',
    zip_code: '',
    currency: '',
    short_bio: ''
  });

  const next = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (final) {
      makeRequest(newData);
      return;
    }
    setStep((prev) => prev + 1);
  };

  const makeRequest = (doc) => {
    let data = {
      ...doc
    };
    delete data.code;
    delete data.business_number;
    dispatch(add_partner(data));
  };

  const prev = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setStep((prev) => prev - 1);
  };

  const steps = [
    <PersonalInfo next={next} data={data} />,
    <Business data={data} next={next} prev={prev} loading={loading} />
  ];
  return (
    <ModalBody>
      <div className="header">Add Partner</div>
      <Steps step={step} />
      {steps[step]}
    </ModalBody>
  );
};
