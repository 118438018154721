import * as Yup from 'yup';
import { ActionBtnDiv } from '@app/components/forms/addproduct/style';
import {
  AsgardP,
  Button,
  FormCloseBtnContainer,
  Img
} from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';

import { Form } from '@design-system/Form';
import React, { useEffect, useState } from 'react';
import { ProductUpdateFormDiv } from '../style';
import { FieldArray, Formik } from 'formik';
import Divider from '@app/globalstyles/dividerLine';
import {
  AddMealInputDiv,
  AddOrRemoveBtn,
  AddProdInput,
  ProductItemsDiv,
  ProductOption,
  RelativeInputDiv
} from '@app/pages/products/addmealpack/style';
import { SelectUnit } from '@app/pages/products/addproduct/select';
import { MEASUREMENTS } from '@app/pages/products/filterData';
import addFieldIcon from '@app/assets/icons/add_dynamic_field.svg';
import removeFieldIcon from '@app/assets/icons/trash.svg';
import useDebounce from '@app/Hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { getMealProducts } from '@app/pages/products/redux/selectors';
import { get_meal_products } from '@app/pages/products/redux/reducer';
import { update_product } from '../redux/reducer';
import { CloseFormButton } from '@app/components/forms/CloseFormButton';

function EditSubProducts({ closeform, product }) {
  const suggestions = useSelector(getMealProducts);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const debouncedTerm = useDebounce(search, 500);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(null);

  let initialValues = {
    sub_products:
      product?.sub_products.length > 0
        ? product?.sub_products
        : [{ product_id: '', name: '', measurement: 0, unit: '' }]
  };

  const validationSchema = Yup.object().shape({
    sub_products: Yup.array().min(1).label('Sub Products')
  });

  const handleSearhChange = (idx, text) => {
    setCurrentIndex(idx);
    setSearch(text);
    setShowSuggestions(true);
  };
  const handleUpdateSubProducts = (data) => {
    dispatch(update_product({ id: product._id, data }));
    closeform();
  };
  useEffect(
    () => {
      if (debouncedTerm) {
        dispatch(get_meal_products({ search: debouncedTerm }));
      }
    },
    [debouncedTerm, dispatch] // Only call effect if debounced search term changes
  );
  return (
    <>
      <ProductUpdateFormDiv>
        <div>
          <AsgardP fw="bold" fs="20px" color={Colors.blackish} tAlign="center">
            Edit Product
          </AsgardP>
          <FormCloseBtnContainer>
            <CloseFormButton onClick={closeform} />
          </FormCloseBtnContainer>
        </div>
        <Divider bg={Colors.primaryMain} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values) => handleUpdateSubProducts(values)}
        >
          {({
            handleChange,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            values: formikValues
          }) => (
            <Form onSubmit={handleSubmit}>
              <FieldArray name="sub_products">
                {({ push, remove }) => (
                  <div
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      position: 'relative'
                    }}
                  >
                    {formikValues.sub_products &&
                      formikValues.sub_products.length > 0 &&
                      formikValues.sub_products.map((item, idx) => (
                        <AddMealInputDiv key={`${idx}`} w="100%">
                          <div style={{ width: '100%' }}>
                            <AddProdInput
                              name={`item.${idx}.${item}`}
                              value={item.name}
                              onChange={(e) => {
                                let products = formikValues.sub_products;
                                let product = products[idx];
                                const data = {
                                  ...product,
                                  name: e.target.value
                                };
                                products[idx] = data;
                                setFieldValue('sub_products', products);
                                handleSearhChange(idx, e.target.value);
                              }}
                              placeholder="Search Product..."
                              width="100%"
                            />
                          </div>
                          <RelativeInputDiv w="30%">
                            <AddProdInput
                              type="number"
                              placeholder="Qty"
                              value={item.measurement}
                              onChange={(e) => {
                                let products = formikValues.sub_products;
                                let product = products[idx];
                                const data = {
                                  ...product,
                                  measurement: e.target.value
                                };
                                products[idx] = data;
                                setFieldValue('sub_products', products);
                                setShowSuggestions(false);
                              }}
                              width="100%"
                            />
                            <SelectUnit
                              name="type"
                              onChange={(e) => {
                                let products = formikValues.sub_products;
                                let product = products[idx];
                                const data = {
                                  ...product,
                                  unit: e.target.value
                                };
                                products[idx] = data;
                                setFieldValue('sub_products', products);
                                setShowSuggestions(false);
                              }}
                              label="Unit"
                            >
                              {formikValues.sub_products?.map((product, index) => (
                                <option
                                  key={product.name + index}
                                  value={product.unit}
                                >
                                  {product.unit}
                                </option>
                              ))}
                              {MEASUREMENTS.map((it, idx) => (
                                <option key={idx + it} value={it}>
                                  {it}
                                </option>
                              ))}
                            </SelectUnit>
                          </RelativeInputDiv>
                          {idx === 0 && (
                            <AddOrRemoveBtn
                              type="button"
                              onClick={() => {
                                push({
                                  product_id: '',
                                  measurement: 0,
                                  name: '',
                                  unit: 'kg'
                                });
                              }}
                            >
                              <Img
                                width="20px"
                                height="20px"
                                src={addFieldIcon}
                                alt="Add Field"
                              />
                            </AddOrRemoveBtn>
                          )}
                          {idx !== 0 && (
                            <AddOrRemoveBtn
                              type="button"
                              onClick={() => remove(idx)}
                            >
                              <Img
                                width="20px"
                                height="20px"
                                src={removeFieldIcon}
                                alt="Add Field"
                              />
                            </AddOrRemoveBtn>
                          )}
                        </AddMealInputDiv>
                      ))}
                    {suggestions?.length > 0 && showSuggestions && (
                      <ProductItemsDiv>
                        {suggestions.map((item, id) => (
                          <ProductOption
                            key={id}
                            onClick={() => {
                              let products = formikValues.sub_products;
                              const data = {
                                product_id: item._id,
                                name: item.name,
                                measurement: 0,
                                unit: 'kg'
                              };
                              products[currentIndex] = data;
                              setFieldValue('sub_products', products);
                              setSearch(item.name);
                              setShowSuggestions(false);
                            }}
                            className="list is-hoverable"
                          >
                            {item?.name}
                          </ProductOption>
                        ))}
                      </ProductItemsDiv>
                    )}
                  </div>
                )}
              </FieldArray>
              {/* </ProductFormBody> */}
              <ActionBtnDiv>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    type="submit"
                    p="10px 40px"
                    bc={Colors.primaryMain}
                    br="5px"
                    mr="15px"
                    color={Colors.white}
                    hbc={Colors.blackish}
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </ActionBtnDiv>
              {/* </ProductFormMain> */}
              <br />
            </Form>
          )}
        </Formik>
      </ProductUpdateFormDiv>
    </>
  );
}

export default EditSubProducts;
