import React from 'react';
import { Formik, Form } from 'formik';
import styles from './styles.module.css';

const CommentsBox = ({ handleChange, id }) => {
  let data = {
    comment: ''
  };

  const handleSubmit = (docs) => {
    handleChange('declined', id, docs);
  };
  return (
    <div style={{ background: '#fff' }}>
      <p style={{ marginTop: '-5px', fontSize: '16px' }}>
        Why are you declining {id.name}?
      </p>
      <Formik initialValues={data} onSubmit={handleSubmit}>
        {({ values, handleChange }) => (
          <Form>
            <textarea
              rows="5"
              name="comment"
              value={values.comment}
              onChange={handleChange}
              placeholder="Please leave a comment..."
              style={{
                width: '100%',
                boxSizing: 'border-box',
                borderRadius: '5px',
                border: '0.5px solid #d9d8da',
                padding: '5px',
                fontSize: '16px'
              }}
            />
            <button
              type="submit"
              className={styles.comment_button}
              disabled={values?.comment === '' ? true : false}
            >
              Comment
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CommentsBox;
