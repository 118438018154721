import React, { useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import ErrorMessage from '@app/components/forms/errorMessage';
import { CustomInput } from '@app/components/forms/InputGen';
import { create_product_group } from '../redux/reducers';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { getCreating } from '../redux/selectors';
import { FileUploadToCloud } from '@app/services/fileUploadService';
import toast from 'react-hot-toast';
import { FormBox } from '../style';

const CreateProductGroup = ({ hadleShow, categories }) => {
  const [productImage, setProductImage] = useState(null);

  let initialValues = {
    name: '',
    description: ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required().label('Name'),
    description: Yup.string().min(3).required().label('Description')
  });
  const loading = useSelector(getCreating);
  const dispatch = useDispatch();

  const handleSubmit = async (doc) => {
    if (productImage.length <= 0) return toast.error('Please pick a product Image');
    const response = await FileUploadToCloud(productImage);
    let data = {
      ...doc,
      image: response.secure_url
    };
    dispatch(create_product_group(data));
    setTimeout(() => {
      hadleShow();
    }, 1000);
  };

  const imgRef = useRef();

  const handleOpenImage = () => {
    imgRef.current.click();
  };

  const handlePickImage = (e) => {
    setProductImage(e.target.files[0]);
  };

  return (
    <FormBox>
      <div className="header">Create Product Group</div>
      <Formik
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
      >
        {({
          handleChange,
          values: formikValues,
          errors,
          setFieldTouched,
          touched
        }) => (
          <Form>
            <label className="title">Name</label>
            <CustomInput
              type="text"
              error={touched.name && errors.name}
              name="name"
              placeholder="name"
              onBlur={() => setFieldTouched('name')}
              onChange={handleChange('name')}
            />
            <ErrorMessage error={errors.name} visbile={touched.name} />

            <label className="title">Description</label>
            <CustomInput
              type="text"
              error={touched.description && errors.description}
              name="description"
              placeholder="Description"
              onBlur={() => setFieldTouched('description')}
              onChange={handleChange('description')}
            />
            <ErrorMessage error={errors.description} visbile={touched.description} />

            <div className="image_container">
              {productImage?.name ? (
                <div className="image_preview">
                  <img src={URL.createObjectURL(productImage)} alt="selected" />
                </div>
              ) : (
                <div className="image_label" onClick={handleOpenImage}>
                  Upload Image
                </div>
              )}
            </div>

            <input
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              ref={imgRef}
              onChange={(e) => handlePickImage(e)}
            />

            <div style={{ display: 'grid', placeItems: 'center' }}>
              <button type="submit" disabled={loading}>
                {loading ? 'Loading' : 'Create'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </FormBox>
  );
};

export default CreateProductGroup;
