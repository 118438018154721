import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const BTableBody = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #dfe0eb;

  ${media.mobile`
  /* width: 150vw; */
  td {
      white-space: nowrap;
    }
    `}
  th {
    padding: 20px 10px;
    text-align: left;
    text-transform: capitalize;
    color: ${Colors.grey};
    font-weight: bold;
    font-size: 14px;
  }
  td {
    padding: 10px 10px;
    color: ${Colors.muted};
    font-size: 14px;
    /* white-space: nowrap; */
  }
  td {
    text-align: left;
    padding: 20px 10px;
    font-size: 14px;
    color: ${Colors.tableText};
    border-bottom: 1px solid #dfe0eb;
    flex: 0 0 400px;
  }
  tr:last-child(even) {
    border-bottom: 1px solid #dfe0eb;
  }
  tr:hover {
    background: rgba(55, 81, 255, 0.04);
    cursor: pointer;
  }
`;
export const PaymentsTableContent = styled.div`
  width: 100%;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.05),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  ${media.mobile`
    overflow-x: scroll;
    `}
`;
