import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FormBox, SelectionBox } from '../style';
import { CustomInput } from '@app/components/forms/InputGen';
import * as Yup from 'yup';
import { FileUploadToCloud } from '@app/services/fileUploadService';
import ErrorMessage from '@app/components/forms/errorMessage';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryLoading } from '@app/pages/product-categories/redux/selectors';
import { edit_product_category } from '@app/pages/product-categories/redux/reducer';

const EditCategory = ({ StopBubbling, category, close }) => {
  const dispatch = useDispatch();
  const [productImage, setProductImage] = useState(null);
  const loading = useSelector(getCategoryLoading);
  const [selected, setSelected] = useState(category?.on_marketplace);

  useEffect(() => {
    setSelected(category?.on_marketplace);
  }, [category]);

  const enums_marketplace = [
    {
      title: 'Yes',
      value: true
    },
    {
      title: 'No',
      value: false
    }
  ];

  let initialValues = {
    name: category?.name,
    description: category?.description,
    image: category?.image
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).label('Name'),
    description: Yup.string().min(3).label('Description'),
    image: Yup.string().min(3).label('image')
  });

  const handleSubmit = async (doc) => {
    const response = await FileUploadToCloud(productImage);
    let data = {
      ...doc,
      image: response.secure_url,
      on_marketplace: selected
    };
    dispatch(edit_product_category({ id: category?._id, data, close }));
  };

  const imgRef = useRef();
  const handleOpenImage = () => {
    imgRef.current.click();
  };

  const handlePickImage = (e) => {
    setProductImage(e.target.files[0]);
  };
  return (
    <div>
      <FormBox onClick={StopBubbling}>
        <div className="form_title">Edit Category</div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            handleChange,
            handleSubmit,
            errors,
            setFieldTouched,
            values: formikValues,
            touched
          }) => (
            <Form>
              <div className="form_content">
                <label className="title">Name</label>
                <CustomInput
                  type="text"
                  error={touched.name && errors.name}
                  name="name"
                  placeholder="name"
                  value={formikValues.name}
                  onBlur={() => setFieldTouched('name')}
                  onChange={handleChange('name')}
                />
                <ErrorMessage error={errors.name} visbile={touched.name} />

                <label className="title">Description</label>
                <CustomInput
                  type="text"
                  error={touched.description && errors.description}
                  name="description"
                  placeholder="Description"
                  value={formikValues.description}
                  onBlur={() => setFieldTouched('description')}
                  onChange={handleChange('description')}
                />
                <ErrorMessage
                  error={errors.description}
                  visbile={touched.description}
                />
                <label className="title">Show on markeplace</label>
                <SelectionBox>
                  {enums_marketplace.map((data, idx) => (
                    <div
                      onClick={() => setSelected(data.value)}
                      className={
                        selected === data.value ? 'selection_active' : 'selection'
                      }
                      key={idx}
                    >
                      {data.title}
                    </div>
                  ))}
                </SelectionBox>
                <div className="image_container">
                  {productImage?.name ? (
                    <div className="image_preview">
                      <img src={URL.createObjectURL(productImage)} alt="selected" />
                    </div>
                  ) : (
                    formikValues.image && (
                      <div className="image_preview">
                        <img src={formikValues.image} alt="selected" />
                      </div>
                    )
                  )}
                  <div className="image_label" onClick={handleOpenImage}>
                    Update Image
                  </div>
                </div>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  accept="image/*"
                  ref={imgRef}
                  onChange={(e) => handlePickImage(e)}
                />
                <div>
                  <button type="submit">
                    {loading ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </FormBox>
    </div>
  );
};

export default EditCategory;
