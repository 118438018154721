import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FormContainer } from '../style';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { update_step, upload_recipe_data } from '../redux/reducer';
import { getMode, getStep, getUploadingRecipes } from '../redux/selectors';
import * as Yup from 'yup';
import { getCountries } from 'country-state-picker';

export const Details = ({ initialValues, prev }) => {
  const dispatch = useDispatch();
  const uploading = useSelector(getUploadingRecipes);
  const [link, setLink] = useState(uploading?.video);
  const mode = useSelector(getMode);
  const mainstep = useSelector(getStep);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (initialValues) {
      setLink(initialValues.video);
    }
  }, [initialValues]);

  useEffect(() => {
    const countries = getCountries();
    const result = countries.map((data) => {
      return {
        value: data.name,
        label: data.name
      };
    });
    setCountries(result);
  }, []);

  const options = [
    { value: 'low_effort', label: 'Low Effort (10 mins - 45 mins)' },
    { value: 'medium_effort', label: 'Medium Effort (45 mins - 2 hours)' },
    { value: 'high_effort', label: 'High Effort (> 2 hours)' }
  ];

  const recipe_type = [
    { value: 'Breakfast', label: 'Breakfast' },
    { value: 'Lunch', label: 'Lunch' },
    { value: 'Dinner', label: 'Dinner' },
    { value: 'Snack', label: 'Snack' },
    { value: 'Dessert', label: 'Dessert' },
    { value: 'Universal', label: 'Universal' }
  ];

  const ValidationSchema = Yup.object({
    name: Yup.string().required('Field is required!'),
    description: Yup.string().required('Field is required!'),
    prep_time: Yup.object().required('Choose one'),
    type: Yup.array().required('Choose one'),
    countries: Yup.array().required('Choose one')
  });

  let data = {
    name: uploading.name || '',
    description: uploading.description || '',
    prep_time: uploading.prep_time || '',
    type: uploading.type || '',
    video: link || '',
    countries: uploading.countries || ''
  };

  const handleSubmit = (docs) => {
    const data = {
      ...docs,
      video: link
    };
    dispatch(upload_recipe_data(data));
    dispatch(update_step(2));
  };

  return (
    <FormContainer>
      <div className="videoSection">
        <video width="650" height="240" controls src={link}>
          <source type="video/.mp4"></source>
        </video>
      </div>

      <div className="details_section">
        <label>Details</label>
        <Formik
          initialValues={data}
          validationSchema={ValidationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, setFieldValue, handleBlur }) => (
            <Form className="form_body">
              <input
                type="text"
                name="name"
                placeholder="Title"
                value={values.name}
                onChange={(value) => handleChange('name')(value)}
                onBlur={(e) => {
                  if (mode) {
                    dispatch(upload_recipe_data({ name: e?.target?.value }));
                  }
                }}
                required
              />
              <div style={{ fontSize: '14px', color: 'red', marginTop: '8px' }}>
                {errors.name}
              </div>
              <textarea
                type="text"
                placeholder="Description"
                name="description"
                value={values.description}
                onChange={(value) => handleChange('description')(value)}
                onBlur={(e) => {
                  if (mode) {
                    dispatch(upload_recipe_data({ description: e?.target?.value }));
                  }
                }}
                required
              />
              <div style={{ fontSize: '14px', color: 'red', marginTop: '8px' }}>
                {errors.description}
              </div>

              <label>Recipe Type</label>
              <Select
                name="type"
                closeMenuOnSelect={false}
                isMulti
                options={recipe_type}
                value={values.type}
                onChange={(value) => {
                  setFieldValue('type', value);
                }}
                onBlur={() => {
                  if (mode) {
                    dispatch(upload_recipe_data({ type: values.type }));
                  }
                }}
              />
              <div style={{ fontSize: '14px', color: 'red', marginTop: '8px' }}>
                {errors.type}
              </div>
              <label>Cook Time</label>
              <Select
                name="prep_time"
                value={values.prep_time}
                options={options}
                isClearable={true}
                onChange={(value) => {
                  setFieldValue('prep_time', value);
                }}
                onBlur={() => {
                  if (mode) {
                    dispatch(upload_recipe_data({ prep_time: values.prep_time }));
                  }
                }}
              />
              <div style={{ fontSize: '14px', color: 'red', marginTop: '8px' }}>
                {errors.prep_time}
              </div>

              {!mode && (
                <>
                  <label>Countries</label>
                  <Select
                    name="countries"
                    closeMenuOnSelect={false}
                    isMulti
                    options={countries}
                    value={values.countries}
                    onChange={(value) => {
                      setFieldValue('countries', value);
                    }}
                    onBlur={() => {
                      if (mode) {
                        dispatch(
                          upload_recipe_data({ countries: values.countries })
                        );
                      }
                    }}
                  />
                  <div style={{ fontSize: '14px', color: 'red', marginTop: '8px' }}>
                    {errors.countries}
                  </div>
                </>
              )}

              <div className="button_container">
                {mode || mainstep >= 4 ? null : (
                  <div className="inner_container">
                    <button className="back_button" onClick={prev}>
                      Back
                    </button>
                    <button type="submit">Next</button>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </FormContainer>
  );
};

export default Details;
