import { useEffect } from 'react';
import { AsgardP, Button, Div, Img } from '@app/globalstyles/asgard';
import { BTableBody, FBContent, TableRow } from './style';
import elipse from '@assets/icons/vert_elipses.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getLoading } from '../redux/selectors';
import StatusBadge from '@design-system/StatusBadge';
import { get_coupons } from '../redux/reducer';
import Link from '@design-system/Link';
import { COUPONS } from '@app/pages/ROUTECONSTS';
import LoadingDataUi from '@app/components/loading';
import EmptyDataUi from '@app/components/emptyData';
import moment from 'moment';

const CouponTable = ({ coupons, pageSize, currentPage }) => {
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();

  const today = new Date();
  const iso = today.toISOString();

  useEffect(() => {
    dispatch(get_coupons());
  }, [dispatch]);

  return (
    <div>
      <FBContent>
        {loading ? (
          <LoadingDataUi />
        ) : (
          <BTableBody>
            <tr
              style={{
                background: '#F0F0F0',
                color: '#4C515A',
                fontWeight: '500',
                fontSize: '16px'
              }}
            >
              <th>Coupon Code</th>
              <th>Description</th>
              <th>Value</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th>Redeemed</th>
              <th>Redeemable</th>
              <th></th>
            </tr>
            {coupons?.map((coupon, i) => (
              <TableRow key={coupon._id}>
                <td colSpan="1">
                  <Div display="flex">
                    <Link to={`${COUPONS}`}>
                      <AsgardP ml="10px">
                        {coupon.coupon_name || coupon.coupon_code}
                      </AsgardP>
                    </Link>
                  </Div>
                </td>
                <td>{coupon.description}</td>
                <td style={{ textAlign: 'center' }}>
                  {coupon.percentage || coupon.fixed_value}
                </td>
                <td>{moment(coupon.start_date).format('MMM D, YYYY')}</td>
                <td>{moment(coupon.end_date).format('MMM D, YYYY')}</td>
                <td>
                  {coupon.end_date <= iso ? (
                    <StatusBadge>Expired</StatusBadge>
                  ) : (
                    <StatusBadge bg="success">Active</StatusBadge>
                  )}
                </td>
                <td style={{ textAlign: 'center' }}>{coupon.times_redeemed}</td>
                <td style={{ textAlign: 'center' }}>{coupon.max_redemption}</td>
                <td>
                  <Div display="flex">
                    <Link to={`${COUPONS}/${coupon._id}`}>
                      <Button
                        h="20px"
                        ml="10px"
                        p="2px 5px"
                        bc="transparent"
                        br="3px"
                      >
                        <Img src={elipse} alt="indicator" />
                      </Button>
                    </Link>
                  </Div>
                </td>
              </TableRow>
            ))}
          </BTableBody>
        )}
      </FBContent>
      {!loading && coupons.length <= 0 && <EmptyDataUi />}
    </div>
  );
};

export default CouponTable;
