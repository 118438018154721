import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    creating: false,
    stats: {
      pageCount: 1
    }
  },

  health: []
};

export const healthConditionReducerSlice = createSlice({
  name: 'health',
  initialState,
  reducers: {
    get_health_conditions: (state) => {
      state.ui.loading = true;
    },
    get_health_conditions_success: (state, { payload }) => {
      state.ui.loading = false;
      state.health = payload.conditions;
      state.ui.stats = {
        limit: payload.limt,
        docs: payload.docs,
        page: payload.page
      };
    },
    get_health_conditions_error: (state) => {
      state.ui.loading = false;
    },
    create_health_conditions: (state) => {
      state.ui.creating = true;
    },
    create_health_conditions_success: (state, { payload }) => {
      state.ui.creating = false;
      state.health.unshift(payload);
    },
    create_health_conditions_error: (state) => {
      state.ui.creating = false;
    },
    delete_health_conditions: (state) => {
      state.ui.creating = true;
    },
    delete_health_conditions_success: (state, { payload }) => {
      state.ui.creating = false;
      const res = state.health.filter((data) => data._id !== payload._id);
      state.health = res;
    },
    delete_health_conditions_error: (state) => {
      state.ui.creating = false;
    },
    update_health_conditions: (state) => {
      state.ui.creating = true;
    },
    update_health_conditions_success: (state) => {
      state.ui.creating = false;
    },
    update_health_conditions_error: (state) => {
      state.ui.creating = false;
    }
  }
});

export const {
  get_health_conditions,
  get_health_conditions_success,
  get_health_conditions_error,
  create_health_conditions,
  create_health_conditions_error,
  create_health_conditions_success,
  delete_health_conditions,
  delete_health_conditions_error,
  delete_health_conditions_success,
  update_health_conditions,
  update_health_conditions_error,
  update_health_conditions_success
} = healthConditionReducerSlice.actions;

export default healthConditionReducerSlice.reducer;
