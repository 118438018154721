import React, { useEffect, useState } from 'react';
import { ImageContainer } from '../style';
import { update_step, upload_recipe_data } from '../redux/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getMode, getStep, getUploadingRecipes } from '../redux/selectors';

const SelectImages = ({ initialValues, next, prev, setData }) => {
  const uploading = useSelector(getUploadingRecipes);
  const [images, setImages] = useState(
    uploading.images.length ? uploading?.images : []
  );
  const mode = useSelector(getMode);
  const dispatch = useDispatch();
  const [imageEdit, setImageEdit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(upload_recipe_data({ images }));
    dispatch(update_step(4));
  };
  const mainstep = useSelector(getStep);

  useEffect(() => {
    if (mode) {
      dispatch(upload_recipe_data({ images }));
    }
  }, [images, imageEdit, dispatch, mode]);

  const startUpload = async (index) => {
    try {
      let cloudinaryOptions = {
        cloud_name: process.env.REACT_APP_CLOUDINARY_NAME,
        upload_preset: process.env.REACT_APP_CLOUDINARY_FOLDER,
        showAdvancedOptions: true,
        cropping: false,
        multiple: true, // Allow multiple images to be uploaded
        maxFiles: 4, // Maximum number of files to upload
        minFiles: 1, // Minimum number of files to upload
        resourceType: 'image',
        clientAllowedFormats: ['jpg', 'png', 'gif'],
        sources: ['local', 'url', 'camera', 'facebook', 'dropbox', 'instagram']
      };

      return await window.cloudinary.openUploadWidget(
        cloudinaryOptions,
        async (error, result) => {
          if (error || !result) {
            console.log('upload not successful', error);
            return;
          }
          if (!error && result && result.event === 'queues-end') {
            const tempImages = result.info.files.map(
              (file) => file.uploadInfo.secure_url
            );
            if (mode) {
              setImageEdit(!imageEdit);
              setImages((prev) => {
                let newArray = [...prev];
                newArray.splice(index, 1, tempImages?.[0]);
                return newArray;
              });
            } else {
              setImages(tempImages);
            }
          }
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleImagesUpload = () => {
    startUpload();
  };

  const handleImagesEdit = (index) => {
    startUpload(index);
  };

  return (
    <div style={{ padding: '1rem 0' }}>
      <p>Add Images</p>
      <ImageContainer>
        <div className="image_container">
          {images.map((image, index) => (
            <label
              key={index}
              className="image_preview"
              onClick={() => handleImagesEdit(index)}
            >
              <img src={image} alt="prev" />
            </label>
          ))}
          {images.length < 4 && (
            <div
              onClick={() => {
                mode ? handleImagesEdit(images?.length) : handleImagesUpload();
              }}
            >
              <label className="image_label">Choose up to 4 images</label>
            </div>
          )}
        </div>

        <div className="button_container">
          {mode || mainstep >= 4 ? null : (
            <div className="inner_container">
              <button className="back_button" onClick={prev}>
                Back
              </button>
              <button onClick={handleSubmit}>Review</button>
            </div>
          )}
        </div>
      </ImageContainer>
    </div>
  );
};

export default SelectImages;
