import { createSelector } from '@reduxjs/toolkit';

const unitsData = (state = {}) => state.units;

export const getUnits = createSelector(unitsData, (unitsData) => unitsData.data);

export const getLoading = createSelector(
  unitsData,
  (unitsData) => unitsData.ui.loading
);

export const getCreating = createSelector(unitsData, (data) => data.ui.creating);

export const getSingleUnit = createSelector(unitsData, (data) => data.unit_group);

export const getPageStats = createSelector(
  unitsData,
  (unitsData) => unitsData.ui.stats
);
