export const BasketFilterData = [
  {
    id: 1,
    title: 'Status',
    value: 'paid=true'
  },

  {
    id: 2,
    title: 'Paid',
    value: 'paid=true'
  }
];

export const filterData = [
  {
    id: 1,
    title: 'All',
    value: ''
  },
  {
    id: 2,
    title: 'Payment Date',
    value: 'payment_date=true'
  }
];

export const price_data = [
  {
    id: 0,
    title: 'All',
    low_price: '',
    high_price: ''
  },
  {
    id: 1,
    title: '₦0- ₦10,000',
    low_price: 0,
    high_price: 10000
  },
  {
    id: 2,
    title: '₦10,000- ₦20,000',
    low_price: 10000,
    high_price: 20000
  },
  {
    id: 3,
    title: '₦20,000- ₦30,000',
    low_price: 20000,
    high_price: 300000
  },
  {
    id: 4,
    title: '₦30,000- ₦40,000',
    low_price: 30000,
    high_price: 40000
  },
  {
    id: 5,
    title: '₦40,000- ₦50,000',
    low_price: 40000,
    high_price: 50000
  },
  {
    id: 6,
    title: 'Above ₦50,000',
    low_price: 50000
  }
];

export const tracking_data = [
  {
    id: 0,
    title: 'All',
    value: ''
  },
  {
    id: 1,
    title: 'Paid',
    value: 'paid=true'
  }
];

export const shipment_cycle = [
  {
    id: 1,
    title: 'All',
    value: ''
  },
  {
    id: 2,
    title: 'Order Recieved',
    value: 'order recieved'
  },
  {
    id: 3,
    title: 'Out for Delivery',
    value: 'out for delivery'
  },
  {
    id: 4,
    title: 'Delivered',
    value: 'delivered'
  }
];
