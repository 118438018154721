import React from 'react';
import { Box } from '../style';

export const Header = ({ setOpen }) => {
  return (
    <div>
      <Box>
        <div className="header_text">Recipes</div>

        <button style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
          Create
        </button>
      </Box>
    </div>
  );
};
