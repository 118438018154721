import { createSelector } from '@reduxjs/toolkit';

const deliveryData = (state = {}) => state.delivery;

export const getDeliveryPartners = createSelector(
  deliveryData,
  (data) => data.delivery
);

export const getLoading = createSelector(deliveryData, (data) => data.ui.loading);

export const getPageStats = createSelector(deliveryData, (data) => data.ui.stats);

export const getCreating = createSelector(deliveryData, (data) => data.ui.creating);
