import LoadingDataUi from '@app/components/loading';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_basket } from '../redux/reducer';
import { getBasket, getIsGettingBasket } from '../redux/selectors';
import {
  BasketDetailsHeader,
  HeaderText,
  HeaderTitle,
  ProductColumn,
  ProductDetailColumn,
  ProductDetailRow,
  ProductDetailsContainer,
  ProductDetailText,
  ProductPriceText,
  ProductQuantityText
} from '../style';

const BasketDetails = ({ basket }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getIsGettingBasket);
  const basket_detail = useSelector(getBasket);

  useEffect(() => {
    dispatch(get_basket(basket?.id));
  }, [dispatch, basket?.id]);

  return (
    <div>
      <BasketDetailsHeader>
        <HeaderTitle>Partner: </HeaderTitle>
        <HeaderText>{basket?.partner?.business_name}</HeaderText>
      </BasketDetailsHeader>

      <BasketDetailsHeader>
        <HeaderTitle>Customer:</HeaderTitle>
        <HeaderText>
          {basket?.creator?.first_name} {basket?.creator?.last_name}
        </HeaderText>
      </BasketDetailsHeader>

      <BasketDetailsHeader>
        <HeaderTitle>Price: </HeaderTitle>
        <HeaderText>
          {basket?.currency?.symbol}
          {basket?.total_price}
        </HeaderText>
      </BasketDetailsHeader>

      <div>
        <h3 style={{ marginTop: '70px' }}>Product Details</h3>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <LoadingDataUi />
          </div>
        ) : (
          <ProductDetailsContainer>
            <ProductDetailRow>
              <ProductColumn>Product</ProductColumn>
              <ProductDetailColumn>Quantity</ProductDetailColumn>
              <ProductDetailColumn>Price</ProductDetailColumn>
            </ProductDetailRow>
            {basket_detail?.products?.map((product, idx) => (
              <ProductDetailRow key={idx}>
                <ProductDetailText>{product?.product?.name}</ProductDetailText>
                <ProductQuantityText>{product?.quantity}</ProductQuantityText>
                <ProductPriceText>
                  {product?.price?.currency?.symbol}
                  {product?.price?.value}
                </ProductPriceText>
              </ProductDetailRow>
            ))}
          </ProductDetailsContainer>
        )}
      </div>
    </div>
  );
};

export default BasketDetails;
