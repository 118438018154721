import { createSelector } from '@reduxjs/toolkit';

const groupsData = (state = {}) => state?.basket_orders;

export const getGroups = createSelector(groupsData, (groupsData) => groupsData.data);

export const getLoading = createSelector(
  groupsData,
  (groupsData) => groupsData.ui.loading
);

export const getOrder = createSelector(groupsData, (groupsData) => groupsData.order);

export const getPageStats = createSelector(
  groupsData,
  (groupsData) => groupsData.ui.stats
);
