import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTags } from '../../redux/selectors';
import { create_tags, update_tag } from '../../redux/reducers';

const AddTag = ({ onClose, isEdit, content }) => {
  const loading = useSelector(createTags);
  const dispatch = useDispatch();
  let initialValues = {
    name: content?.name || '',
    category: content?.category || '',
    description: content?.description || ''
  };

  const handleSubmit = (doc) => {
    if (isEdit) {
      dispatch(update_tag({ id: content?._id, data: doc, onClose }));
    } else {
      dispatch(create_tags({ data: doc, onClose }));
    }
  };
  return (
    <div className="bg-white w-[400px] lg:w-[500px] p-6">
      {isEdit ? (
        <h3 className="text-[#222222] text-center text-[20px] font-[500] mb-4">
          Edit Meal Tag
        </h3>
      ) : (
        <h3 className="text-center text-[#222222] text-[20px] font-[500] mb-4">
          Add Meal Tag
        </h3>
      )}

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, handleChange }) => (
          <Form className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <label className="text-[16px] font-[500] text-[#828282]">Name</label>
              <input
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                placeholder="Name"
                className="focus:border-[#549a9a] border border-1 border-[#eeeeee] p-4 rounded-lg focus:outline-[#549a9a]"
                required
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-[16px] font-[500] text-[#828282]">
                Category
              </label>
              <input
                type="text"
                name="category"
                value={values.category}
                onChange={handleChange}
                placeholder="Category"
                className="focus:border-[#549a9a] border border-1 border-[#eeeeee] p-4 rounded-lg focus:outline-[#549a9a]"
                required
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-[16px] font-[500] text-[#828282]">
                Description
              </label>
              <textarea
                type="text"
                name="description"
                value={values.description}
                onChange={handleChange}
                placeholder="Category"
                rows={5}
                className="focus:border-[#549a9a] border border-1 border-[#eeeeee] p-4 rounded-lg focus:outline-[#549a9a]"
              />
            </div>

            {isEdit ? (
              <button
                className="w-full mt-[24px] bg-[#549a9a] border-0 text-[18px] font-[600] rounded p-3 text-white"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Saving'}
              </button>
            ) : (
              <button
                className="w-full mt-[24px] bg-[#549a9a] border-0 text-[18px] font-[600] rounded p-3 text-white"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Creating...' : 'Create'}
              </button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddTag;
