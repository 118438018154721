import React, { useEffect, useState } from 'react';
import { CreateRecipeModal } from '../style';
import Details from './Details';
import SelectFile from './selectFile';
import Stepper from './Stepper';
import Ingredients from './ingredients';
import SelectImages from './SelectImages';
import ReviewRecipe from './ReviewRecipe';
import { useDispatch, useSelector } from 'react-redux';
import { getProductLoading, getStep } from '../redux/selectors';
import { create_recipe, update_step } from '../redux/reducer';

const CreateRecipe = () => {
  const step = useSelector(getStep);
  const [stepd, setStep] = useState(step);
  const [data, setData] = useState({
    video: '',
    name: '',
    description: '',
    type: '',
    prep_time: '',
    video_url: '',
    ingredients: [
      {
        food_item: '',
        product_group: '',
        measurement: ''
      }
    ],
    other_ingredients: [
      {
        food_item: '',
        product_group: '',
        measurement: ''
      }
    ],
    instructions: [
      {
        step: '',
        time: ''
      }
    ],
    images: []
  });
  const loading = useSelector(getProductLoading);
  const dispatch = useDispatch();
  const next = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));

    if (final) {
      makeRequest(newData);
      return;
    }
    setStep((prev) => prev + 1);
  };

  const prev = (newData) => {
    dispatch(update_step(step - 1));
  };

  const makeRequest = async (doc) => {
    dispatch(create_recipe(doc));
  };

  useEffect(() => {
    setStep(step);
  }, [step]);

  const steps = [
    <SelectFile
      initialValues={data}
      step={step}
      changeStep={setStep}
      next={next}
      setData={setData}
    />,
    <Details
      initialValues={data}
      setData={setData}
      step={step}
      changeStep={setStep}
      next={next}
      prev={prev}
    />,
    <Ingredients
      initialValues={data}
      step={step}
      changeStep={setStep}
      setData={setData}
      next={next}
      prev={prev}
    />,
    <SelectImages
      initialValues={data}
      step={step}
      changeStep={setStep}
      setData={setData}
      next={next}
      prev={prev}
    />,
    <ReviewRecipe
      initialValues={data}
      step={step}
      changeStep={setStep}
      setData={setData}
      next={next}
      prev={prev}
      loading={loading}
    />
  ];

  return (
    <div>
      <CreateRecipeModal>
        <div className="modal_body">
          {step && step >= 1 && step < 5 ? (
            <div className="stepper_container">
              <Stepper step={step} changeStep={setStep} />
            </div>
          ) : null}
          {steps[stepd]}
        </div>
      </CreateRecipeModal>
    </div>
  );
};

export default CreateRecipe;
