import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    stats: {
      pageCount: 1
    }
  },
  data: [],
  basket: {},
  export: [],
  isGettingBasket: false
};

export const groupsReducerSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    get_groups_success: (state, { payload }) => {
      state.ui.loading = false;
      const { baskets, stats } = payload;

      state.data = baskets;
      state.ui.stats = {
        ...stats
      };
    },
    get_groups_error: (state) => {
      state.ui.loading = false;
    },
    get_groups: (state) => {
      state.ui.loading = true;
    },
    get_basket_success: (state, { payload }) => {
      state.isGettingBasket = false;
      state.basket = payload;
    },
    get_basket_error: (state) => {
      state.isGettingBasket = false;
    },
    get_basket: (state) => {
      state.isGettingBasket = true;
    },
    get_export: (state) => {
      state.ui.loading = true;
    },
    get_export_success: (state, { payload }) => {
      const { baskets } = payload;
      state.ui.loading = false;
      state.export = baskets;
    },
    get_export_error: (state) => {
      state.ui.loading = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_groups,
  get_groups_error,
  get_groups_success,
  get_basket,
  get_basket_error,
  get_basket_success,
  get_export,
  get_export_success,
  get_export_error
} = groupsReducerSlice.actions;

export default groupsReducerSlice.reducer;
