import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const BTableBody = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #dfe0eb;
  width: 100%;
  td {
    text-align: left;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: normal;
    color: ${Colors.tableText};
    white-space: nowrap;
  }
  th {
    padding: 20px 20px;
    text-align: left;
    text-transform: capitalize;
    color: ${Colors.grey};
    font-weight: bold;
    font-size: 14px;
  }
  td {
    color: ${Colors.muted};
    font-size: 14px;
  }
  tr:last-child(even) {
    border-bottom: 1px solid #dfe0eb;
  }
  tr:hover() {
    background: #3751ff;
    opacity: 0.04;
    cursor: pointer;
  }
`;

export const BTextHeader = styled.th`
  text-align: left;
  padding: 8px;
  border-bottom: 2px solid red;
  td {
    text-align: left;
    padding: 8px;
    /* border-bottom:2px solid red;  */
    color: ${Colors.grey};
  }
`;

export const TableRow = styled.tr`
  &:hover {
    background: rgba(55, 81, 255, 0.04);
    cursor: pointer;
  }
`;

export const GroupsTableContent = styled.div`
  width: 100%;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.05),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 13px;
  ${media.mobile`
  overflow-x: scroll;
  `}
`;
