import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';
import styled, { css } from 'styled-components/macro';

export const RevStatsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: colum;

  ${media.smallDesktopMinimum`
         height: 100%;
         flex-wrap: wrap;
         flex-direction: column;
    `}
  ${media.mobile`
         height: 100%;
         flex-wrap: wrap;
         flex-direction: column;
        
    `}
`;

export const Statstab = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const StatstabContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.mobile`
         flex-wrap: wrap;
         flex-direction: column-reverse;
    `}
`;

export const CurrencyButton = styled.button`
  width: 60px;
  height: 32px;
  outline: none;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(10, 2, 13, 0.08);
  ${({ active }) =>
    active
      ? css`
          background: ${Colors.white};
          color: rgba(55, 81, 255, 1);
          border: 1px solid rgba(55, 81, 255, 1);
        `
      : css`
          background: ${Colors.white};
          color: #9fa2b4;
        `}

  border-radius: 4px;
  cursor: pointer;
`;
export const CurrencyButtonDiv = styled.div`
  display: flex;
  width: 130px;
  height: 32px;
  gap: 6px;
`;
