import React, { useState } from 'react';
import {
  CardContainer,
  Background,
  Card,
  TextInput,
  Text,
  Form,
  Button
} from '@design-system/';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getLoading } from './redux/selectors';
import { reset_password } from './redux/reducer';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ChangePassword = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const query = useQuery();
  const [state, setState] = useState({
    email: query.get('email'),
    new_password: '',
    confirm_password: '',
    token: ''
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...state
    };
    dispatch(reset_password(data));
  };

  return (
    <Background style={{ backgroundColor: 'grey' }}>
      <CardContainer>
        <Card style={{ paddingBottom: 120, paddingTop: 100 }}>
          <Text size="large" color="#043C10" weight="900">
            Change Password
          </Text>
          <Text size="small" color="#494A4D">
            Enter the token sent to your email and your new password.
          </Text>
          <Form onSubmit={handleSubmit}>
            <TextInput
              type="email"
              id="email"
              name="email"
              value={state.email}
              placeholder="Enter email"
            ></TextInput>
            <TextInput
              type="password"
              id="new_password"
              name="new_password"
              value={state.new_password}
              onChange={handleChange}
              placeholder="Enter new password"
            ></TextInput>
            <TextInput
              type="password"
              id="confirm_password"
              name="confirm_password"
              value={state.confirm_password}
              onChange={handleChange}
              placeholder="Confirm new password"
            ></TextInput>
            <TextInput
              type="token"
              id="token"
              name="token"
              value={state.token}
              onChange={handleChange}
              placeholder="Reset token"
            ></TextInput>

            <Button
              style={{ marginTop: 30 }}
              text={loading ? 'Loading...' : 'Reset'}
              type="submit"
              disabled={loading}
            />
          </Form>
        </Card>
      </CardContainer>
    </Background>
  );
};

export default ChangePassword;
