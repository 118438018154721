import { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  ActionBtnDiv,
  FormItemContainer,
  OpenImgPickbtn,
  ProductFormBody,
  ProductFormLeft,
  ProductFormMain,
  ProductFormRight
} from '@app/pages/products/addproduct/style';

import {
  AsgardP,
  Button,
  FormCloseBtnContainer,
  Img
} from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';
import Divider from '@app/globalstyles/dividerLine';
import {
  CustomInput,
  NumberInput,
  PickImageWhiteButton,
  SelectWeightInput
} from '@app/components/forms/InputGen';
import SelectInputP from '@app/pages/products/addproduct/select';
import ErrorMessage from '@app/components/forms/errorMessage';
import { FileUploadToCloud } from '@app/services/fileUploadService';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_product_categories } from '@app/pages/product-categories/redux/reducer';
import { getProductCatgories } from '@app/pages/product-categories/redux/selectors';
import { getProduct } from '../redux/selectors';
import { update_product } from '../redux/reducer';
import { EditDescDiv } from '@design-system/ProductGrid';
import { EditSizeFormContainer, ProductUpdateFormDiv } from '../style';
import { CloseFormButton } from '@app/components/forms/CloseFormButton';
import { getCurrencies } from '@app/pages/currrencies/redux/selectors';
import sizeSelect from '@app/assets/icons/sizeSelect.svg';
import { get_currencies } from '@app/pages/currrencies/redux/reducer';
import { MEASUREMENTS } from '@app/pages/products/filterData';
import ImagePreviewcard, {
  ImagesScrollDiv
} from '@design-system/Card/UploadImagePreviewCard';
import addFieldIcon from '@app/assets/icons/add_dynamic_field.svg';

const EditProduct = ({ closeform }) => {
  const [productImage, setProductImage] = useState([]);
  const dispatch = useDispatch();
  const currencies = useSelector(getCurrencies);
  const categories = useSelector(getProductCatgories);
  const product = useSelector(getProduct);

  let initialValues = {
    name: product.name || '',
    description: product.description || '',
    product_category: product.product_category._id || '',
    quantity: product.quantity || '',
    container_type: product.container_type[0] || '',
    country: product.country || ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Name is required').label('Name'),
    container_type: Yup.string().required().label('Weight'),
    product_category: Yup.string().required().label('Product Category'),
    country: Yup.string().required().label('Country'),
    description: Yup.string().required().label('Description')
  });

  const imgRef = useRef();

  const handleOpenImage = () => {
    imgRef.current.click();
  };

  const handlePickImage = (e) => {
    setProductImage(e.target.files[0]);
  };
  const handleCreateProduct = async (doc) => {
    let response;
    if (productImage.name) {
      response = await FileUploadToCloud(productImage);
    }

    const data = {
      ...doc,
      image: response ? response.secure_url : product.image
    };
    delete data['currency'];
    dispatch(update_product({ id: product._id, data }));
    closeform();
  };

  useEffect(() => {
    dispatch(get_product_categories());
    dispatch(get_currencies());
  }, [dispatch]);
  return (
    <ProductUpdateFormDiv>
      {/* <ProductFormContainer> */}
      <div style={{ marginTop: '15px' }}>
        <AsgardP fw="bold" fs="20px" color={Colors.black} tAlign="center">
          Edit Product
        </AsgardP>
        <FormCloseBtnContainer mt="-50px">
          <CloseFormButton onClick={closeform} />
        </FormCloseBtnContainer>
      </div>
      <Divider bg={Colors.primaryMain} />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values) => handleCreateProduct(values)}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values
        }) => (
          <Form onSubmit={handleSubmit}>
            <ProductFormMain>
              <ProductFormBody>
                <ProductFormLeft>
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                      Name
                    </AsgardP>
                    <CustomInput
                      error={touched.name && errors.name}
                      type="text"
                      name="name"
                      value={values.name}
                      placeholder="Name"
                      onBlur={() => setFieldTouched('name')}
                      onChange={handleChange('name')}
                    />
                  </FormItemContainer>
                  <ErrorMessage error={errors.name} visible={touched.name} />
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                      Quantity in Stock
                    </AsgardP>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        gap: '2%',
                        height: '50px'
                      }}
                    >
                      <NumberInput
                        width="100%"
                        value={product?.size?.price?.value}
                        thousandSeparator={true}
                      />
                    </div>
                  </FormItemContainer>
                  <ErrorMessage error={errors.country} visible={touched.country} />
                  <EditSizeFormContainer>
                    <AsgardP mb="3px" fw="bold" color="#4F4F4F">
                      Price
                    </AsgardP>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        gap: '2%',
                        height: '50px'
                      }}
                    >
                      <SelectWeightInput>
                        <select>
                          <option value={product?.size?.price?.currency._id}>
                            {product?.size?.price?.currency?.short_code}
                          </option>
                          {currencies?.map((currency) => (
                            <option key={currency._id} value={currency.id}>
                              {currency?.short_code}
                            </option>
                          ))}
                        </select>
                        <div>
                          <Img src={sizeSelect} alt="Select" />
                        </div>
                      </SelectWeightInput>
                      <NumberInput
                        value={product?.size?.price?.value}
                        thousandSeparator={true}
                      />
                    </div>
                  </EditSizeFormContainer>
                </ProductFormLeft>
                <ProductFormRight>
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                      Product Category
                    </AsgardP>
                    <SelectInputP
                      name="type"
                      value={values.product_category}
                      onBlur={() => setFieldTouched('product_category')}
                      onChange={handleChange('product_category')}
                      label="Select Category"
                    >
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </SelectInputP>
                  </FormItemContainer>
                  <ErrorMessage
                    error={errors.product_category}
                    visible={touched.product_category}
                  />
                  <EditSizeFormContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                      Weight (kg)
                    </AsgardP>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        gap: '2%',
                        height: '50px'
                      }}
                    >
                      <SelectWeightInput>
                        <select>
                          {MEASUREMENTS?.map((measurement, idx) => (
                            <option key={idx} value={measurement}>
                              {measurement}
                            </option>
                          ))}
                        </select>
                        <div>
                          <Img src={sizeSelect} alt="Select" />
                        </div>
                      </SelectWeightInput>
                      <NumberInput thousandSeparator={true} />
                    </div>
                  </EditSizeFormContainer>
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                      Image
                    </AsgardP>
                    <ImagesScrollDiv>
                      {productImage?.name && (
                        <ImagePreviewcard
                          imgUrl={URL.createObjectURL(productImage)}
                          alt="seletced image"
                        />
                      )}
                      {productImage?.name && (
                        <ImagePreviewcard
                          imgUrl={URL.createObjectURL(productImage)}
                          alt="seletced image"
                        />
                      )}
                      {productImage?.name && (
                        <ImagePreviewcard
                          imgUrl={URL.createObjectURL(productImage)}
                          alt="seletced image"
                        />
                      )}
                      {productImage?.name && (
                        <ImagePreviewcard
                          imgUrl={URL.createObjectURL(productImage)}
                          alt="seletced image"
                        />
                      )}
                      <PickImageWhiteButton type="button" onClick={handleOpenImage}>
                        <Img
                          width="20px"
                          height="20px"
                          src={addFieldIcon}
                          alt="Add Field"
                        />
                      </PickImageWhiteButton>
                    </ImagesScrollDiv>
                  </FormItemContainer>
                  <EditDescDiv>
                    <OpenImgPickbtn type="button" onClick={handleOpenImage}>
                      {productImage?.name
                        ? productImage?.name
                        : 'Upload JPEG or PNG'}
                    </OpenImgPickbtn>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      ref={imgRef}
                      onChange={(e) => handlePickImage(e)}
                    />
                  </EditDescDiv>
                </ProductFormRight>
              </ProductFormBody>
              <ActionBtnDiv>
                <Button
                  type="submit"
                  p="10px 60px"
                  bc={Colors.primaryMain}
                  br="5px"
                  mr="15px"
                  color={Colors.white}
                  hbc={Colors.blackish}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </ActionBtnDiv>
            </ProductFormMain>
          </Form>
        )}
      </Formik>
      {/* </ProductFormContainer> */}
    </ProductUpdateFormDiv>
  );
};

export default EditProduct;
