import React, { useState } from 'react';
import { ProductDetailsTopSection, SectionContainer } from '../style';
import CommaNumber from 'comma-number';
import { AsgardP, Div, FormsModal, Img } from '@app/globalstyles/asgard';
import { useDispatch } from 'react-redux';
import { update_product } from '../redux/reducer';

import {
  InstockBtn,
  MainDetailsContainer,
  OtherImageCardDiv,
  ProductDetailsContainer,
  ProductImageCard,
  ProductImageCardContainer,
  RightContentLeft,
  RightSection,
  RightSectionTopDiv,
  SubProductImageCard
} from '@design-system/ProductGrid';
import { DetailsEditButton } from '@design-system/ProductGrid/DetailsEditButton';
import forwardArrow from '@app/assets/icons/prodnamearrow.svg';
import Link from '@design-system/Link';
import Divider from '@app/globalstyles/dividerLine';
import EditProduct from './EditProduct';

export const ProductDetails = ({ product }) => {
  const [showForm, setShowForm] = useState(false);
  const handleShowProductForm = () => {
    setShowForm(!showForm);
  };

  const dispatch = useDispatch();

  const toggleApprovalStatus = (e) => {
    const payload = {
      id: product._id,
      data: { approved: !product.approved }
    };
    e.target.value = !product.approved;
    dispatch(update_product(payload));
  };

  const toggleInstockStatus = () => {
    const payload = {
      id: product._id,
      data: { in_stock: !product.in_stock }
    };
    dispatch(update_product(payload));
  };
  // console.log(product);
  return (
    <SectionContainer>
      <ProductDetailsTopSection>
        <Link
          style={{ display: 'flex', alignItems: 'center', marginTop: '0px' }}
          to="/products"
        >
          <p>Products</p>
          <Img src={forwardArrow} mt="5px" alt="Arrow" />
        </Link>
        <h3>{product.name}</h3>
      </ProductDetailsTopSection>

      <ProductDetailsContainer>
        <MainDetailsContainer>
          <ProductImageCardContainer>
            <ProductImageCard>
              <Img
                width="70%"
                height="100%"
                src={product?.image}
                alt="Active product image"
              />
            </ProductImageCard>

            <OtherImageCardDiv>
              {[...new Array(4)].map((img, idx) => (
                <SubProductImageCard key={idx}>
                  <Img
                    br="10px"
                    width="100%"
                    height="100%"
                    src={product?.image}
                    alt="Active product image"
                  />
                </SubProductImageCard>
              ))}
            </OtherImageCardDiv>
          </ProductImageCardContainer>
          <RightSection>
            <RightSectionTopDiv>
              <RightContentLeft>
                <h2>{product.name}</h2>
                <InstockBtn onClick={toggleInstockStatus}>
                  {product.in_stock ? 'In stock' : 'Not In stock'}{' '}
                </InstockBtn>
              </RightContentLeft>
              <DetailsEditButton onClick={handleShowProductForm} />
            </RightSectionTopDiv>
            <Div display="flex" bg="red" mt="-30px">
              <AsgardP color="#AFAFAF">Product Category:</AsgardP>
              <AsgardP ml="3px" fw="bold" color="#4C515A">
                {product.product_category?.name}
              </AsgardP>
            </Div>
            <Div display="flex" mt="-20px">
              <AsgardP color="#AFAFAF">Quantity in stock:</AsgardP>
              <AsgardP ml="3px" fw="bold" color="#4C515A">
                {product?.quantity}
              </AsgardP>
            </Div>
            <Div display="flex" mt="-20px">
              <AsgardP color="#AFAFAF">QWeight({product?.unit}):</AsgardP>
              <AsgardP ml="3px" fw="bold" color="#4C515A">
                {product?.measurement}
              </AsgardP>
            </Div>
            <Divider mt="-5px" mb="-10px" />
            <h3>
              {product?.price?.currency?.short_code}{' '}
              {CommaNumber(product?.price?.value)}
            </h3>
            <Divider mt="-10px" />
            <Div display="flex" mt="35px">
              <InstockBtn
                h="40px"
                bg={product.approved ? '#549A9A' : '#F12B2C'}
                color="#FFFFFF"
                onClick={toggleApprovalStatus}
              >
                {' '}
                {product.approved ? 'Approved' : 'Not Aprroved'}{' '}
              </InstockBtn>
            </Div>
          </RightSection>
        </MainDetailsContainer>
        {showForm && (
          <FormsModal show={showForm}>
            <EditProduct product={product} closeform={handleShowProductForm} />
          </FormsModal>
        )}
      </ProductDetailsContainer>
    </SectionContainer>
  );
};

export default ProductDetails;
