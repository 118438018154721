import React, { useState, useRef } from 'react';
import { Modal } from '../style';
import { Formik, Form } from 'formik';
import { FileUploadToCloud } from '@app/services/fileUploadService';
import toast from 'react-hot-toast';
import { getCreateLoading } from '../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { create_image } from '../redux/reducers';

const AddImage = ({ close }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const loading = useSelector(getCreateLoading);
  const dispatch = useDispatch();

  let initialValues = {
    name: ''
  };

  const imgRef = useRef();

  const handleOpenImage = () => {
    imgRef.current.click();
  };

  const handlePickImage = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleSubmit = async (doc) => {
    if (selectedImage.length <= 0) return toast.error('Please pick a product Image');
    const response = await FileUploadToCloud(selectedImage);

    const data = {
      images: [
        {
          ...doc,
          url: response.secure_url
        }
      ]
    };

    dispatch(create_image(data));
    setTimeout(() => {
      close();
    }, 1000);
  };
  return (
    <Modal>
      <p className="title">Add Image</p>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, handleChange }) => (
          <Form>
            <p>Name</p>
            <input type="text" onChange={handleChange} name="name" />

            <div>
              {selectedImage?.name ? (
                <div className="image_preview" onClick={handleOpenImage}>
                  <img src={URL.createObjectURL(selectedImage)} alt="selected" />
                </div>
              ) : (
                <div className="image_label" onClick={handleOpenImage}>
                  Upload Image
                </div>
              )}
            </div>
            <input
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              ref={imgRef}
              onChange={(e) => handlePickImage(e)}
            />

            <button disabled={loading} type="submit">
              {' '}
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddImage;
