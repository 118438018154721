import React from 'react';
import { SearchBox } from '@app/pages/recipes/style';
import ExpandIcon from '@design-system/Assets/ExpandIcon';

const SearchContent = ({
  handleChange,
  active,
  onClose,
  drop,
  handleFilter,
  showState,
  states,
  filterStateChange,
  showApproval
}) => {
  const data = [
    {
      title: 'All',
      value: ''
    },
    {
      title: 'Verified',
      value: 'account_verified=true'
    },
    {
      title: 'Not Verified',
      value: 'account_verified=false'
    }
  ];

  const approval = [
    {
      title: 'All',
      value: ''
    },
    {
      title: 'Approved',
      value: 'approved=true'
    },
    {
      title: 'Not Approved',
      value: 'approved=false'
    }
  ];
  return (
    <SearchBox>
      <div className="search_bar">
        <input
          type="text"
          placeholder="Search by business name..."
          onChange={handleChange}
        />
      </div>
      <div className="filter">
        <div className="filter_text">Status:</div>
        <div className="filter_content" onClick={onClose}>
          {active}
          {drop ? (
            <div style={{ transform: 'rotate(180deg)', transition: '0.5s' }}>
              <ExpandIcon />
            </div>
          ) : (
            <ExpandIcon />
          )}
        </div>
        <div className={drop ? 'filter_box' : 'hide'}>
          {showApproval ? (
            <>
              {approval.map((data, id) => (
                <div
                  className={active !== data.title ? 'filter_box_content' : 'active'}
                  onClick={() => handleFilter(data)}
                  key={id}
                >
                  {data.title}
                </div>
              ))}
            </>
          ) : (
            <>
              {data.map((data, id) => (
                <div
                  className={active !== data.title ? 'filter_box_content' : 'active'}
                  onClick={() => handleFilter(data)}
                  key={id}
                >
                  {data.title}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      {showState && (
        <div className="states_container">
          <div className="filter_text">States:</div>
          <select onChange={filterStateChange}>
            <option value="">All</option>
            {states.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
          </select>
        </div>
      )}
    </SearchBox>
  );
};

export default SearchContent;
