import React from 'react';
import { FormBody, ModalBody } from '../style';
import { Formik, Form } from 'formik';
import SelectInputP from '@app/pages/products/addproduct/select';

const EditPersonal = ({ personal, updatePartner, loading }) => {
  let data = {
    phone: personal?.phone,
    type: personal?.type,
    business_name: personal?.business_name,
    street_address: personal?.street_address,
    city: personal?.city
  };
  const handleSubmit = (doc) => {
    updatePartner(doc);
  };

  onsubmit = (values) => {
    handleSubmit(values);
  };

  const type = [
    {
      id: 1,
      name: 'Shopper',
      value: 'shopper'
    },
    {
      id: 3,
      name: 'Shop',
      value: 'shop'
    },
    {
      id: 4,
      name: 'Cook',
      value: 'cook'
    }
  ];
  return (
    <ModalBody>
      <div className="header">Edit Profile</div>
      <FormBody>
        <div style={{ marginTop: '20px' }}>
          <Formik initialValues={data} onSubmit={onsubmit}>
            {({ values, handleChange }) => (
              <Form>
                <div className="grid">
                  <div className="form_content">
                    <label>First Name</label>
                    <input
                      type="text"
                      value={personal?.user?.first_name}
                      name="first_name"
                      disabled
                    />
                  </div>
                  <div className="form_content">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={personal?.user?.last_name}
                      name="last_name"
                      disabled
                    />
                  </div>
                </div>

                <div className="grid">
                  <div className="form_content">
                    <label>Email Address</label>
                    <input
                      type="text"
                      value={personal?.user?.email}
                      name="email"
                      disabled
                    />
                  </div>
                  <div className="form_content">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      value={values.phone}
                      name="phone"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="grid">
                  <div className="form_content">
                    <label>Type</label>
                    <SelectInputP
                      onChange={handleChange}
                      name="type"
                      value={values.type}
                    >
                      <option selected disabled>
                        Select Type
                      </option>
                      {type.map((type) => (
                        <option key={type.id} value={type.value}>
                          {type.name}
                        </option>
                      ))}
                    </SelectInputP>
                  </div>

                  <div className="form_content">
                    <label>Business Name</label>
                    <input
                      type="text"
                      value={values.business_name}
                      name="business_name"
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                </div>

                <div className="grid">
                  <div className="form_content">
                    <label>Business Number</label>
                    <input
                      type="text"
                      value={values.phone}
                      name="phone"
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                  <div className="form_content">
                    <label>Street Address </label>
                    <input
                      placeholder="JohnDoe"
                      name="street_address"
                      value={values.street_address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="grid">
                  <div className="form_content">
                    <label>City </label>
                    <input
                      placeholder="JohnDoe"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button type="submit" disabled={loading}>
                    {loading ? 'Saving...' : 'Save Changes'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </FormBody>
    </ModalBody>
  );
};

export default EditPersonal;
