import styled from 'styled-components';

export const Header = styled.div`
  box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.07);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #003232;
  padding-top: 29.5px;
  padding-bottom: 29.5px;
  padding-left: 14.65px;
`;

export const Crumb = styled.div`
  padding-left: 14.65px;
  padding-top: 29.5px;
  .title {
    font-weight: 500;
    font-size: 15px;
    color: #549a9a;
    text-decoration: none;

    > img {
      margin-right: 9px;
    }
  }
`;

export const MealBody = styled.div`
  padding-bottom: 29.5px;
  padding-left: 14.65px;
  height: 100vh;
  overflow-y: scroll;
  .title {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    margin-top: 20.3px;
    margin-bottom: 7.5px;
  }

  .ingredients_list {
    margin-top: 31.5px
    border: 1px solid red;
  }

  ul li {
    font-size: 15px;
    font-weight: 400;
    color: #4f4f4f;
    margin-bottom: 10px;
  }
`;

export const ShowButton = styled.button`
  position: fixed;
  top: 90%;
  left: 20%;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  background: #549a9a;
  border-radius: 4px;
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const Container = styled.div`
  padding-top: 31.5px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-bottom: 100px;

  .form_container {
    width: 40vw;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  label {
    font-weight: 500;
    font-size: 15px;
    color: #4f4f4f;
    margin-top: 10px;
  }

  input {
    width: auto;
    border: 1px solid #e0e0e0;
    height: 45px;
    border-radius: 5px;
    padding-left: 15px;
    margin-top: 5px;
    font-weight: 400;
    font-size: 15px;
    color: #4f4f4f;
    margin-bottom: 10px;
  }

  select {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
    width: auto;

    &:disabled {
      background: #f2f2f2;
    }
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    color: #4f4f4f;
    margin-top: 20px;
  }

  .form_input_box {
    display: flex;
    align-content: center;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: auto;
    height: 44px;
    margin-top: 5px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    > select {
      margin-top: 0px;
      border: none;
      border-right: 1px solid #e0e0e0;
      border-radius: 0px;
      background: none;
      padding-left: 12px;
      width: auto;

      option {
        font-weight: 500;
        font-size: 15px;
        color: #4f4f4f;
      }
    }

    input {
      border: none;
      background: none;
      height: 41px;
      margin-top: 0;
      font-weight: 500;
      font-size: 15px;

      &:placeholder {
        font-weight: 500;
        font-size: 15px;
        color: #e0e0e0;
      }
    }
  }

  .add {
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    color: #549a9a;
    display: flex;
    justfiy-content: space-around;
    align-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    width: 100px;
  }

  .image_container {
    margin-top: 50px;
    display: flex;
  }

  .file {
    display: none;
  }

  img {
    width: 128.29px;
    height: 91.35px;
    object-fit: contain;
    border-radius: 5px;
    margin-right: 3px;
  }
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  .form_content {
    display: flex;
    flex-direction: column;

    .custom_select {
      height: 41px;
      border: 1px solid red;
    }

    > input {
      width: auto;
      height: 41px;
    }
  }

  select {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;

    &:disabled {
      background: #f2f2f2;
    }
  }
`;

export const Grid = styled.div`
  
  input[type='text'] {
    width: auto;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 44px;

    &:disabled {
      background-color: #efefef;
    }
  }

  .form_container {
    display: flex;
    flex-direction: column;

    > input[type='text'] {
      width: auto;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      height: 44px;
    }
  }

  select {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
    width: auto;

    &:disabled {
      background: #f2f2f2;
    }
  }

  .form_box {
    display: flex;
    align-items: center;
    input[type='number'] {
      width: auto;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      height: 44px;
    }

    select {
      border: 1px solid #e0e0e0;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      border-right: none;
      height: 44px;
      padding-left: 15px;
      padding-right: 15px;
     
      width: auto;
  
      &:disabled {
        background: #f2f2f2;
      }
  }
`;

export const CreateButton = styled.button`
  width: 100%;
  margin-top: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  background: #549a9a;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background: #acd1d1;
  }
`;

export const ServingBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  align-items: center;

  .serving_box {
    box-sizing: border-box;
    display: flex;
    width: 100px;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
    border-radius: 4px;
    height: 44px;
    font-size: 13px;
    cursor: pointer;
  }

  .active {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    border-radius: 4px;
    height: 44px;
    font-size: 13px;
    cursor: pointer;
  }
`;

export const SuggestionBox = styled.div`
  height: 200px;
  position: absolute;
  width: 40%;
  background: #ffffff;
  box-shadow: 0px 0px 11px rgba(111, 93, 93, 0.25);
  border-radius: 10px;
  top: 155%;
  padding: 10px;
  overflow-y: scroll;
`;

export const ProductButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  padding-top: 20px;

  > button {
    border: 1px solid #549a9a;
    color: #549a9a;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    padding-top: 12px;
    padding-bottom: 12px;

    &:hover {
      background: #549a9a;
      color: #fff;
    }
  }
`;
