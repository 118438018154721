import { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  ActionBtnDiv,
  FormItemContainer,
  OpenImgPickbtn,
  PImageUpload,
  PreviewImgDiv,
  ProductFormBody,
  ProductFormContainer,
  ProductFormLeft,
  ProductFormMain,
  ProductFormRight
} from './style';
import { AsgardP, Button, Img } from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';
import { CustomInput, CustomTextArea } from '@app/components/forms/InputGen';
import SelectInputP, { SelectUnit } from './select';
import ErrorMessage from '@app/components/forms/errorMessage';
import { FileUploadToCloud } from '@app/services/fileUploadService';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_product_categories } from '@app/pages/product-categories/redux/reducer';
import { get_currencies } from '@app/pages/currrencies/redux/reducer';
import { getCurrencies } from '@app/pages/currrencies/redux/selectors';
import { getProductCatgories } from '@app/pages/product-categories/redux/selectors';
import { create_product } from '../redux/reducer';
import { getCountries } from 'country-state-picker';
import { FloaTingLabel, RelativeInputDiv } from '../addmealpack/style';
import { CONTAINER_TYPES, MEASUREMENTS } from '../filterData';
import { getLoadingCreateMeal } from '../redux/selectors';
import { getProductGroups } from '@app/pages/product-groups/redux/selectors';
import { get_product_groups } from '@app/pages/product-groups/redux/reducers';

const AddProductForm = ({ closeform }) => {
  const [productImage, setProductImage] = useState([]);
  const dispatch = useDispatch();
  const currencies = useSelector(getCurrencies);
  const categories = useSelector(getProductCatgories);
  const countries = getCountries();
  const loading = useSelector(getLoadingCreateMeal);
  const group = useSelector(getProductGroups);

  let initialValues = {
    name: '',
    description: '',
    currency: '',
    price: '',
    product_category: '',
    product_group: '',
    measurement: 0,
    unit: '',
    container_type: '',
    country: ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Name is required').label('Name'),
    currency: Yup.string().min(3).required().label('Currency'),
    container_type: Yup.string()
      .required('Container is required')
      .label('Container Type'),
    product_category: Yup.string().label('Product Category'),
    product_group: Yup.string().required().label('Product Category'),
    price: Yup.number().required('Price is required').label('Base Price'),
    measurement: Yup.number().required().label('Weight'),
    unit: Yup.string().required('Unit is required'),
    country: Yup.string().required('Country is required').label('Country'),
    description: Yup.string()
      .required('Description is required')
      .label('Description')
  });

  const imgRef = useRef();

  const handleOpenImage = () => {
    imgRef.current.click();
  };

  const handlePickImage = (e) => {
    setProductImage(e.target.files[0]);
  };
  const handleCreateProduct = async (doc) => {
    if (productImage.length <= 0) return toast.error('Please pick a product Image');
    const response = await FileUploadToCloud(productImage);
    const data = {
      ...doc,
      price: {
        value: doc.price,
        currency: doc.currency
      },
      image: response.secure_url
    };
    if (!response.secure_url) return toast.error('Product image is required');
    delete data['currency'];
    dispatch(create_product(data));
    closeform();
  };

  useEffect(() => {
    dispatch(get_product_categories());
    dispatch(get_product_groups());
    dispatch(get_currencies());
  }, [dispatch]);

  return (
    <ProductFormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values) => handleCreateProduct(values)}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values: formikValues
        }) => (
          <Form onSubmit={handleSubmit}>
            <ProductFormMain>
              <ProductFormBody>
                <ProductFormLeft>
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Product Name
                    </AsgardP>
                    <CustomInput
                      error={touched.name && errors.name}
                      type="text"
                      name="name"
                      placeholder="Name"
                      onBlur={() => setFieldTouched('name')}
                      onChange={handleChange('name')}
                    />
                  </FormItemContainer>

                  <ErrorMessage error={errors.name} visible={touched.name} />
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Product Description
                    </AsgardP>
                    <CustomTextArea
                      error={errors.description && touched.description}
                      type="text"
                      rows={2}
                      name="description"
                      placeholder="Description"
                      onBlur={() => setFieldTouched('description')}
                      onChange={handleChange('description')}
                    />
                  </FormItemContainer>
                  <ErrorMessage
                    error={errors.description}
                    visible={touched.description}
                  />

                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Measurement
                    </AsgardP>
                    <RelativeInputDiv>
                      <CustomInput
                        className="remove"
                        error={touched.measurement && errors.measurement}
                        type="number"
                        name="measurement"
                        placeholder="Measurement"
                        onBlur={() => setFieldTouched('measurement')}
                        onChange={handleChange('measurement')}
                      />
                      <SelectUnit
                        name="type"
                        onBlur={() => setFieldTouched('unit')}
                        onChange={handleChange('unit')}
                        label="Unit"
                      >
                        {MEASUREMENTS.map((it, idx) => (
                          <option key={idx + it} value={it}>
                            {it}
                          </option>
                        ))}
                      </SelectUnit>
                    </RelativeInputDiv>
                  </FormItemContainer>
                  <ErrorMessage
                    error={errors.measurement}
                    visible={touched.measurement}
                  />
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Price
                    </AsgardP>
                    <RelativeInputDiv w="100%">
                      <CustomInput
                        error={errors.price && touched.price}
                        type="number"
                        name="price"
                        placeholder="Price"
                        onBlur={() => setFieldTouched('price')}
                        onChange={handleChange('price')}
                      />
                      <FloaTingLabel left="87%">
                        {
                          currencies?.filter(
                            (currency) => currency._id === formikValues.currency
                          )[0]?.short_code
                        }
                      </FloaTingLabel>
                    </RelativeInputDiv>
                  </FormItemContainer>
                  <ErrorMessage error={errors.price} visible={touched.price} />

                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Upload Image
                    </AsgardP>
                    <OpenImgPickbtn type="button" onClick={handleOpenImage}>
                      {productImage?.name
                        ? productImage?.name
                        : 'Upload JPEG or PNG'}
                    </OpenImgPickbtn>
                  </FormItemContainer>
                </ProductFormLeft>
                <ProductFormRight>
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Product Category
                    </AsgardP>
                    <SelectInputP
                      name="type"
                      onBlur={() => setFieldTouched('product_category')}
                      onChange={handleChange('product_category')}
                      label="Select Category"
                    >
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </SelectInputP>
                  </FormItemContainer>
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Product Group
                    </AsgardP>
                    <SelectInputP
                      name="type"
                      onBlur={() => setFieldTouched('product_group')}
                      onChange={handleChange('product_group')}
                      label="Select Group"
                    >
                      {group.map((data) => (
                        <option key={data._id} value={data._id}>
                          {data.name}
                        </option>
                      ))}
                    </SelectInputP>
                  </FormItemContainer>
                  <ErrorMessage
                    error={errors.product_group}
                    visible={touched.product_group}
                  />
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Country
                    </AsgardP>
                    <SelectInputP
                      name="country"
                      onBlur={() => setFieldTouched('country')}
                      onChange={handleChange('country')}
                      label="Select Country"
                    >
                      {countries.map((country) => (
                        <option key={country.code} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </SelectInputP>
                  </FormItemContainer>
                  <ErrorMessage error={errors.country} visible={touched.country} />
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Currency Type
                    </AsgardP>
                    <SelectInputP
                      name="currency"
                      onBlur={() => setFieldTouched('currency')}
                      onChange={handleChange('currency')}
                      label="Select Curreny"
                    >
                      {currencies.map((currency) => (
                        <option key={currency._id} value={currency._id}>
                          {currency.short_code} - {currency.symbol}{' '}
                        </option>
                      ))}
                    </SelectInputP>
                  </FormItemContainer>
                  <ErrorMessage error={errors.currency} visible={touched.currency} />

                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Packaging Type
                    </AsgardP>
                    <SelectInputP
                      name="container_type"
                      onBlur={() => setFieldTouched('container_type')}
                      onChange={handleChange('container_type')}
                      label="Select packaging"
                    >
                      {CONTAINER_TYPES.map((container, idx) => (
                        <option key={`${idx}-${container}`}>{container}</option>
                      ))}
                    </SelectInputP>
                  </FormItemContainer>
                  <ErrorMessage
                    error={errors.container_type}
                    visible={touched.container_type}
                  />
                  {productImage?.name && (
                    <PreviewImgDiv>
                      <Img
                        width="100%"
                        height="100%"
                        br="50%"
                        src={URL.createObjectURL(productImage)}
                        alt="selected image"
                      />
                    </PreviewImgDiv>
                  )}
                </ProductFormRight>
              </ProductFormBody>
              <PImageUpload>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  accept="image/*"
                  ref={imgRef}
                  onChange={(e) => handlePickImage(e)}
                />
              </PImageUpload>
              <ActionBtnDiv>
                <Button
                  type="submit"
                  p="10px 50px"
                  bc={Colors.primaryMain}
                  br="5px"
                  mr="15px"
                  color={Colors.white}
                  hbc={Colors.black}
                  onClick={handleSubmit}
                >
                  {loading ? 'Loading...' : 'Create'}
                </Button>
              </ActionBtnDiv>
            </ProductFormMain>
          </Form>
        )}
      </Formik>
    </ProductFormContainer>
  );
};

export default AddProductForm;
