import React from 'react';
import { useHistory } from 'react-router-dom';
import { AsgardNavLink, Div } from '@app/globalstyles/asgard';
import { MenuIcon, MenuIconContainer, MenuItem, MenuText } from './style';

export default function BigMenu({
  icon,
  text,
  isActive,
  link,
  active,
  textColor = ''
}) {
  const history = useHistory();

  return (
    <>
      <AsgardNavLink to={link}>
        <MenuItem
          active={history?.location?.pathname.startsWith(link) ? true : false}
        >
          <MenuIconContainer>
            {typeof icon !== 'string' ? icon : <MenuIcon src={icon} />}
          </MenuIconContainer>
          <Div display="flex" justify="space-between">
            {!textColor && <MenuText>{text}</MenuText>}{' '}
          </Div>
        </MenuItem>
      </AsgardNavLink>
    </>
  );
}
