import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { update_step } from '../redux/reducer';
import { getUploadingRecipes } from '../redux/selectors';
import styles from './styles.module.css';

const Stepper = ({ step, changeStep }) => {
  const data = useSelector(getUploadingRecipes);
  const dispatch = useDispatch();

  return (
    <div>
      <div>
        <div className={styles.steplabel}>
          <p className={step === 1 ? styles.active : ''}>Details</p>
          <p className={step === 2 ? styles.active : ''}>Ingredients</p>
          <p className={step === 3 ? styles.active : ''}>Add Images</p>
        </div>

        <div className={styles.stepper}>
          {/* Step 1 */}
          <div className={styles.stepcover}>
            <div
              id="step1"
              onClick={() => {
                dispatch(update_step(1));
              }}
              // onClick={() => changeStep(1)}
              className={styles.activecircle}
            ></div>
          </div>

          {/* Step 2 */}
          <div className={styles.stepper}>
            <div
              className={step && step >= 2 ? styles.activeline : styles.line}
            ></div>
            <div className={styles.stepcover}>
              <div
                id="step2"
                onClick={() => {
                  data?.name && dispatch(update_step(2));
                }}
                className={step && step >= 2 ? styles.activecircle : styles.circle}
              ></div>
            </div>
          </div>

          {/* Step 3 */}
          <div className={styles.stepper}>
            <div
              className={step && step >= 3 ? styles.activeline : styles.line}
            ></div>
            <div className={styles.stepcover}>
              <div
                id="step3"
                onClick={() => {
                  data?.instructions?.[0]?.step && dispatch(update_step(3));
                }}
                className={step && step >= 3 ? styles.activecircle : styles.circle}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
