import React from 'react';
import { Track, TrackHead, TrackBody, StepWrapper } from './style';
import TrackingCard from './TrackingCard';
import moment from 'moment';

const Tracking = ({ group }) => {
  return (
    <div>
      <Track>
        <TrackHead>
          {' '}
          <p className="head">Tracking</p>
        </TrackHead>
        <TrackBody>
          <StepWrapper>
            {group.tracking_steps?.map((step) => (
              <div
                className={
                  step.status === 'Complete' ? 'stepper-item-no' : 'stepper-item'
                }
                key={step?._id}
              >
                <TrackingCard
                  id={group._id}
                  sn={step?.sn}
                  key={step._id}
                  trackName={step?.name}
                  trackStatus={step?.status}
                  time={step.completed_at}
                  completedText={
                    step.completed_at
                      ? `${moment(step.completed_at).format('MMM DD, YYYY')}`
                      : ''
                  }
                />
              </div>
            ))}
          </StepWrapper>
        </TrackBody>
      </Track>
    </div>
  );
};

export default Tracking;
