import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { AsgardP, Button, Img } from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';
import {
  ActionBtnDiv,
  CouponFormMain
} from '../../../components/forms/addcoupon/style';
import {
  AutoCompDiv,
  IngredInputDiv,
  CouponsFormContainer,
  FormHead,
  PercentBlock
} from './style';
import { addIcon } from '../../../components/re-usedicons';
import Divider from '@app/globalstyles/dividerLine';
import { CustomInput } from '../../../components/forms/InputGen';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getLoading } from '../redux/selectors';
import { create_coupon } from '../redux/reducer';
import SelectInputP from './select';
import Cancel from '@app/assets/icons/cancel.svg';

function AddCoupon({ closeform }) {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);

  const validationSchema = Yup.object().shape({
    coupon_code: Yup.string().required('First Name is required'),
    description: Yup.string().required('Last name is required'),
    max_redemption: Yup.string().required('Email is required'),
    start_date: Yup.string(),
    end_date: Yup.string()
  });

  let initialValuesDb = {
    coupon_code: '',
    description: '',
    max_redemption: '',
    start_date: '',
    end_date: '',
    value: ''
  };

  const handleUpdateUser = async (doc) => {
    let payload = {};
    if (doc.coupon_type === 'PCT') {
      payload = {
        coupon_code: doc.coupon_code,
        description: doc.description,
        end_date: doc.end_date,
        start_date: doc.start_date,
        max_redemption: doc.max_redemption,
        percentage: doc.value
      };
    } else {
      payload = {
        coupon_code: doc.coupon_code,
        description: doc.description,
        end_date: doc.end_date,
        start_date: doc.start_date,
        max_redemption: doc.max_redemption,
        fixed_value: doc.value
      };
    }

    dispatch(create_coupon(payload));
    closeform();
  };

  return (
    <CouponsFormContainer>
      <FormHead>
        <AsgardP
          fw="600"
          color={Colors.blackish}
          tAlign="left"
          style={{ marginLeft: '10px' }}
        >
          Create Coupon
        </AsgardP>
        <img
          src={Cancel}
          onClick={closeform}
          alt="Close form"
          style={{ marginRight: '10px' }}
        />
      </FormHead>
      <Divider bg={Colors.primaryMain} />
      <Formik
        initialValues={initialValuesDb}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize={true}
        onSubmit={(values) => handleUpdateUser(values)}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values
        }) => (
          <Form onSubmit={handleSubmit}>
            <CouponFormMain>
              <AutoCompDiv>
                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Coupon Code</AsgardP>
                  <CustomInput
                    type="text"
                    name="coupon_code"
                    placeholder="welcome20"
                    onBlur={() => setFieldTouched('coupon_code')}
                    onChange={handleChange('coupon_code')}
                    value={values.coupon_name}
                  />
                </IngredInputDiv>
                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Description</AsgardP>
                  <CustomInput
                    type="text"
                    name="description"
                    placeholder="welcome bonus for new customers"
                    onBlur={() => setFieldTouched('description')}
                    onChange={handleChange('description')}
                    value={values.description}
                  />
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Start Date</AsgardP>
                  <CustomInput
                    type="date"
                    name="start_date"
                    placeholder="Jun 10, 2022"
                    onBlur={() => setFieldTouched('start_date')}
                    onChange={handleChange('start_date')}
                    value={values.start_date}
                  />
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>End Date</AsgardP>
                  <CustomInput
                    type="date"
                    name="end_date"
                    placeholder="Jun 10, 2022"
                    onBlur={() => setFieldTouched('end_date')}
                    onChange={handleChange('end_date')}
                    value={values.end_date}
                  />
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Value</AsgardP>
                  <PercentBlock>
                    <SelectInputP
                      name="percentage"
                      onBlur={() => setFieldTouched('coupon_type')}
                      onChange={handleChange('coupon_type')}
                      label="NGN"
                    >
                      <option value="NGN">NGN</option>
                      <option value="PCT">PCT %</option>
                    </SelectInputP>

                    <CustomInput
                      type="text"
                      name="percentage"
                      placeholder="10"
                      onBlur={() => setFieldTouched('value')}
                      onChange={handleChange('value')}
                      value={values.value}
                    />
                  </PercentBlock>
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Redeemable Coupons</AsgardP>
                  <CustomInput
                    type="text"
                    name="max_redemption"
                    placeholder="1200"
                    onBlur={() => setFieldTouched('max_redemption')}
                    onChange={handleChange('max_redemption')}
                    value={values.max_redemption}
                  />
                </IngredInputDiv>
              </AutoCompDiv>
              <ActionBtnDiv>
                <Button
                  type="submit"
                  p="10px 30px"
                  bc={Colors.primaryMain}
                  br="5px"
                  mr="15px"
                  color={Colors.white}
                  onClick={handleSubmit}
                  hbc={Colors.blackish}
                >
                  <Img src={addIcon} alt="product-btn" />{' '}
                  {loading ? 'Creating' : 'Create'}
                </Button>
              </ActionBtnDiv>
            </CouponFormMain>
          </Form>
        )}
      </Formik>
    </CouponsFormContainer>
  );
}

export default AddCoupon;
