import React from 'react';
import { Base } from './style';
import PropTypes from 'prop-types';

const Blur = ({ toggle, isOpen, ...props }) => {
  return <Base onClick={toggle} isOpen={isOpen} {...props} />;
};

export default Blur;

Blur.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool.isRequired
};

Blur.defaultProps = {
  toggle: () => {}
};
