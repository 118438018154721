import { createSlice } from '@reduxjs/toolkit';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = {
  ui: {
    loading: false
  },
  user: user || {}
};

export const authReducerSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login_success: (state, { payload }) => {
      state.ui.loading = false;
      state.user = payload;
    },
    login_error: (state) => {
      state.ui.loading = false;
    },
    login: (state) => {
      state.ui.loading = true;
    },
    request_token: (state) => {
      state.ui.loading = true;
    },
    request_token_success: (state, { payload }) => {
      state.ui.loading = false;
      state.user = payload;
    },
    request_token_error: (state) => {
      state.ui.loading = false;
    },
    reset_password: (state) => {
      state.ui.loading = true;
    },
    reset_password_success: (state, { payload }) => {
      state.ui.loading = false;
      state.user = payload;
    },
    reset_password_error: (state) => {
      state.ui.loading = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  login_success,
  login,
  login_error,
  request_token,
  request_token_error,
  request_token_success,
  reset_password,
  reset_password_error,
  reset_password_success
} = authReducerSlice.actions;

export default authReducerSlice.reducer;
