import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border: 1px solid #f0f0f0;
  padding-top: 30px;
  padding-bottom: 31px;

  .header_text {
    margin-left: 27px;
    font-weight: 600;
    font-size: 25px;
    color: #284242;
  }

  button {
    border: 1px solid #549a9a;
    outline: none;
    background: #fff;
    color: #549a9a;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 39px;
  }
`;

export const ModalBox = styled.div`
  width: 450px;
  height: 204px;
  background: white;

  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 10px;
  padding: 20px;
  justify-content: space-between;
  align-content: center;
  z-index: 40;
  .content__head {
    width: 490px;
    height: 68px;
    font-size: 20px;
    border-bottom: 1px solid black;
    text-align: center;
    width: min-width;
  }
  .akaani__drive {
    width: 490px;
    width: min-width;
    height: 68px;
    border-bottom: 1px solid black;
    text-align: center;
    cursor: pointer;
  }

  .mobile__drive {
    cursor: pointer;
    text-align: center;
  }
  .images {
    display: flex;
    flex-direction: row;
  }
  .container {
    display: flex;
  }
`;

export const ImageModal = styled.div`
  width: 500px;
  height: 250px;
  background: white;

  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .images {
    display: flex;
    flex-direction: row;
  }
  .header {
    height: 50px;
    text-align: center;
    padding-top: 5px;
    border-bottom: 1px solid black;
  }
  .image__text {
    text-align: center;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 31px;
  padding-left: 27px;
  padding-right: 39px;

  .search_bar {
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    /*// padding-top: 12px;*/
    /* // padding-bottom: 12px; */
    padding-left: 23px;
    width: 587px;
  }

  input {
    width: 578px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: none;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 18px;
  }

  input::placeholder {
    font-weight: 500;
    font-size: 18px;
    color: #bdbdbd;
  }

  .filter {
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
  }

  .filter_text {
    font-weight: 500;
    font-size: 15px;
    color: #4f4f4f;
    margin-right: 11px;
  }

  .filter_content {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #549a9a;
    font-size: 12px;
    font-weight: 500;
    background: #edfafa;
    border: 1px solid #549a9a;
    width: 185px;
    padding-left: 19px;
    padding-right: 22px;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
  }

  .filter_box {
    position: absolute;
    width: 228px;
    background: #ffffff;
    top: 100%;
    left: 21%;
    right: 0;
    margin: 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-top: 2px;
    ${'' /* margin-left: 71px; */}
    display: block;
  }

  .hide {
    display: none;
  }

  .filter_box_content {
    font-weight: 500;
    font-size: 12px;
    color: #828282;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;

    &:hover {
      background: #edfafa;
      color: #549a9a;
    }
  }

  .active {
    font-weight: 500;
    font-size: 12px;
    background: #edfafa;
    color: #549a9a;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;
  }

  .states_container {
    display: flex;
    align-items: center;
    gap: 5px;

    select {
      margin: 0;
      color: #828282;
      font-size: 15px;
      font-weight: 500;
      border: 1px solid #549a9a;
      width: 185px;
      padding-left: 19px;
      padding-right: 22px;
      padding-top: 6.5px;
      padding-bottom: 6.5px;
      border-radius: 0px;
    }
  }
`;

export const TableBox = styled.div`
  margin-top: 5px;
  margin-left: 27px;
  margin-right: 39px;

  table {
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
  }

  .table_head {
    background: #f2f2f2;
  }

  .table_head th {
    font-weight: 600;
    font-size: 16px;
    color: #4c515a;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 19px;
    text-align: left;
  }

  .table_body {
    border-bottom: 1px solid #f2f2f2;
  }

  .table_body td {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 19px;
  }

  .recipe_name {
    font-weight: 500;
    font-size: 15px;
    color: #549a9a;
    text-decoration: underline;
    cursor: pointer;
  }

  .creator_name {
    font-weight: 500;
    font-size: 15px;
    color: #4f4f4f;
  }

  .date_created {
    font-weight: 400;
    font-size: 15px;
    color: #4f4f4f;
  }

  .meal_pack {
    background: #f2f2f2;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #333333;
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;

export const Content = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  height: 100vh;
  overflow-y: scroll;

  .icon {
    cursor: pointer;
  }

  img {
    margin-top: 26px;
    width: 100%;
  }

  .recipe_info {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 11px;
  }

  .recipe_info_text {
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    color: #333333;
    margin-left: 9px;
  }

  .content_header {
    font-weight: 500;
    font-size: 15px;
    color: #333333;
    margin-top: 20px;
  }

  .content_sub {
    font-weight: 400;
    font-size: 15px;
    color: #828282;
    margin-top: 2px;
  }

  .filled_button {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 10px;
    border: none;
    outline: none;
    border-radius: 4px;
    background: #549a9a;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;

    &:disabled {
      background: #acd1d1;
    }
  }

  .outline_button {
    width: 100%;
    height: 50px;
    border: 1px solid #549a9a;
    outline: none;
    border-radius: 4px;
    background: none;
    font-weight: 600;
    font-size: 18px;
    color: #549a9a;
    cursor: pointer;
  }

  .meal_button {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 196px;
    border: none;
    outline: none;
    border-radius: 4px;
    background: #549a9a;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;

    &:disabled {
      background: #acd1d1;
    }
  }

  .bottom {
    margin-bottom: 196px;
  }

  .line {
    border-bottom: 1px solid #f2f2f2;
    margin-top: 3px;
  }
`;

export const CreateContent = styled.div`
  background: #fff;
  width: 605px;
  border-radius: 10px;

  .header {
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .header_text {
    font-weight: 500;
    font-size: 18px;
    color: #333333;
  }

  .icon {
    cursor: pointer;
  }
`;

export const CreateBody = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 25px;
  overflow-y: scroll;
  height: 400px;

  label {
    font-weight: 500;
    font-size: 15px;
    color: #4f4f4f;
    margin-top: 10px;
  }

  input {
    width: 100%;
    border: 1px solid #e0e0e0;
    height: 45px;
    border-radius: 5px;
    padding-left: 15px;
    margin-top: 5px;
    font-weight: 400;
    font-size: 15px;
    color: #4f4f4f;
    margin-bottom: 10px;
  }

  .form_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  .form_content {
    display: flex;
    flex-direction: column;

    > input {
      width: auto;
      height: 41px;
    }
  }

  .form_fill {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  select {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;

    &:disabled {
      background: #f2f2f2;
    }
  }

  .form_input_box {
    display: flex;
    align-content: center;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 44px;
    margin-top: 5px;

    > select {
      margin-top: 0px;
      border: none;
      border-right: 1px solid #e0e0e0;
      border-radius: 0px;
      background: none;
      padding-left: 12px;
      width: auto;

      option {
        font-weight: 500;
        font-size: 15px;
        color: #4f4f4f;
      }
    }

    input {
      border: none;
      background: none;
      height: 41px;
      margin-top: 0;
      font-weight: 500;
      font-size: 15px;

      &:placeholder {
        font-weight: 500;
        font-size: 15px;
        color: #e0e0e0;
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    color: #4f4f4f;
    margin-top: 20px;
  }

  .add {
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    color: #549a9a;
    display: flex;
    justfiy-content: space-around;
    align-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    width: 100px;
  }

  .image_container {
    margin-top: 50px;
    display: flex;
  }

  .file {
    display: none;
  }

  img {
    width: 128.29px;
    height: 91.35px;
    object-fit: contain;
    border-radius: 5px;
    margin-right: 3px;
  }

  .image_upload {
    border: 1px dashed #bdbdbd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    width: 128.29px;
    height: 91.35px;
    font-weight: 500;
    font-size: 15px;
    color: #bdbdbd;
    text-align: center;
    cursor: pointer;
  }

  .meal_button {
    width: 100%;
    margin-top: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    border: none;
    outline: none;
    background: #549a9a;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      background: #acd1d1;
    }
  }
`;

export const ConvertMeal = styled.div`
  padding-left: 14.65px;
  padding-right: 14.65px;
  padding-top: 31.5px;
  overflow-x: hidden;
  padding-bottom: 100px;
`;

export const CreateRecipeModal = styled.div`
  background: #fff;
  border-radius: 10px;

  .modal_header {
    display: flex;
    justify-content: space-between;
    padding-left: 22px;
    padding-right: 22px;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    height: 70px;
    border-bottom: 1px solid #f2f2f2;

    .close {
      color: #bdbdbd;
      cursor: pointer;
    }
  }

  .modal_body {
    padding: 22px;
    height: 400px;
    overflow-y: scroll;
    padding-bottom: 50px;

    .stepper_container {
      margin: 1.5rem 0;
    }
  }
`;

export const FormContainer = styled.div`
  .upload_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
    box-sizing: border-box;
  }
  .videoSection {
    border-radius: 10px;
    margin-top: 1.5rem;
    position: relative;
    width: fit-content;
  }

  .videoOverlay {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 98%;
    border-radius: 10px 10px 0 0;
    background: rgba(7, 7, 7, 0.21);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .videoOverlay h4 {
    color: white;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0.5rem;
  }

  .videoOverlay label {
    color: white;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 700;
    margin-top: 0;
    background: #549a9a;
  }

  .videoOverlay input {
    display: none;
  }
  h4 {
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 0.9375rem;
    font-weight: 600;
  }

  p {
    margin-top: 0;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #4f4f4f;
    margin-bottom: 0;
  }

  .select_file_button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    background-color: #549a9a;
    color: white;
    width: 10rem;
    height: 2.5rem;
    font-size: 15px;
    color: white;
    font-weight: 600;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .details_section {
    margin-top: 35px;
  }

  label {
    padding-bottom: 9px;
    padding-top: 9px;
    font-size: 0.9375rem;
    color: #4f4f4f;
    font-weight: 500;
  }

  .form_body {
    display: flex;
    flex-direction: column;
  }

  input {
    border: 1px solid #e0e0e0;
    border-radius: 0.3125rem;
    padding-left: 1rem;
    height: 2.5rem;
    margin-bottom: 1rem;
    box-sizing: border-box;

    ::placeholder {
      font-size: 15px;
      color: #bdbdbd;
      font-weight: 500;
      font-family: poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
  }

  textarea {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 0.3125rem;
    outline: none;
    box-sizing: border-box;
    padding: 1rem;
    height: 8rem;
    font-size: 18px;
    font-weight: 500;
    font-family: poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    cursor: pointer;

    ::placeholder {
      font-size: 15px;
      color: #bdbdbd;
      font-weight: 500;
      font-family: poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
  }

  .details_form {
    input {
      border: 1px solid #e0e0e0;
      border-radius: 0.3125rem;
      padding-left: 1rem;
      height: 2.5rem;
      width: 100%;
      margin-bottom: 1rem;
      box-sizing: border-box;

      ::placeholder {
        font-size: 15px;
        color: #bdbdbd;
        font-weight: 500;
        font-family: poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
          'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          'Helvetica Neue', sans-serif;
      }
    }
  }

  .button_container {
    display: flex;
    justify-content: flex-end !important;
    margin-left: auto;

    .inner_container {
      display: flex;
      align-items: center;
      align-content: center;
    }

    .back_button {
      width: 133px;
      margin-right: 4px;
      margin-top: 24px;
      height: 50px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #549a9a;
      border: 1px solid #549a9a;
      outline: none;
      background: #fff;
      border-radius: 4px;
      cursor: pointer;
    }

    button {
      width: 133px;
      margin-top: 24px;
      height: 50px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
      border: none;
      outline: none;
      background: #549a9a;
      border-radius: 4px;
      cursor: pointer;
    }
  }
`;

export const InContainer = styled.div`
  margin-top: 15px;

  .info {
    padding-bottom: 10px;
    font-size: 15px;
    color: #bdbdbd;
    font-weight: 500;
  }

  .item_row {
    display: grid;
    grid-template-columns: 5fr 1fr;

    p {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-size: 0.9375rem;
    }
  }

  .ingredients {
    display: grid;
    grid-template-columns: 4fr 4fr 0.5fr;
    gap: 0.5rem;
    /* // border: 1px solid red; */
    /* align-items: flex-end; */

    input {
      border: 1px solid #e0e0e0;
      border-radius: 0.3125rem;
      padding-left: 1rem;
      height: 2.5rem;
      margin-bottom: 1rem;
      box-sizing: border-box;

      ::placeholder {
        font-size: 15px;
        color: #bdbdbd;
        font-weight: 500;
      }
    }
  }

  .instructions {
    display: grid;
    grid-template-columns: 5fr 1fr 0.2fr;
    gap: 0.5rem;
    /* align-items: flex-end; */

    input {
      border: 1px solid #e0e0e0;
      border-radius: 0.3125rem;
      padding-left: 1rem;
      height: 2.5rem;
      margin-bottom: 1rem;
      box-sizing: border-box;

      ::placeholder {
        font-size: 15px;
        color: #bdbdbd;
        font-weight: 500;
      }
    }
  }

  .add {
    width: 130px !important;
    border: 1px solid #f2f2f2;
    color: #549a9a;
    font-size: 0.9375rem;
    padding: 0.5rem;
    font-weight: 500;
    border-radius: 5px;
    margin-top: 1.5rem;
    cursor: pointer;
  }

  .map_div {
    margin-bottom: 2rem;
  }

  .delete_icon {
    margin: 0;
    &:hover {
      filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg)
        saturate(600%) contrast(0.8);
      cursor: pointer;
    }
  }

  .button_container {
    display: flex;
    justify-content: flex-end !important;
    margin-left: auto;

    .inner_container {
      display: flex;
      align-items: center;
      align-content: center;
    }

    .back_button {
      width: 133px;
      margin-right: 4px;
      margin-top: 24px;
      height: 50px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #549a9a;
      border: 1px solid #549a9a;
      outline: none;
      background: #fff;
      border-radius: 4px;
      cursor: pointer;
    }

    button {
      width: 133px;
      margin-top: 24px;
      height: 50px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
      border: none;
      outline: none;
      background: #549a9a;
      border-radius: 4px;
      cursor: pointer;

      &:disabled {
        opacity: 0.05;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  .image_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
  }

  .image_label {
    border: 1px dashed #bdbdbd;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
  }

  .image_preview {
    composes: imageLabel;
    border: none;

    img {
      width: 100%;
      height: 5rem;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .button_container {
    display: flex;
    justify-content: flex-end !important;
    margin-left: auto;

    .inner_container {
      display: flex;
      align-items: center;
      align-content: center;
    }

    .back_button {
      width: 133px;
      margin-right: 4px;
      margin-top: 24px;
      height: 50px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #549a9a;
      border: 1px solid #549a9a;
      outline: none;
      background: #fff;
      border-radius: 4px;
      cursor: pointer;
    }

    button {
      width: 133px;
      margin-top: 24px;
      height: 50px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
      border: none;
      outline: none;
      background: #549a9a;
      border-radius: 4px;
      cursor: pointer;
    }
  }
`;

export const ProductGroup = styled.div`
  background: #fff;
  width: 300px;
  hieght: 200px;
  overflow: hidden;
  color: #000;
  position: absolute;
  overflow-y: scroll;
  margin-top: 10px;
  box-shadow: 0px 0px 11px rgba(111, 93, 93, 0.25);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
`;

export const FormBox = styled.div`
  background: #fff;
  padding: 10px;

  .form_content: {
    display: flex;
    flex-direction: column;
  }

  .form_title {
    text-align: center;
    margin-bottom: 24px;
    font-sixe: 16px;
    font-weight: 700;
  }

  .title {
    margin-top: 8px;
  }

  input {
    width: 100%;
    height: 42px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  select {
    width: 100%;
    height: 42px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  button {
    border: none;
    outline: none;
    background: #549a9a;
    color: #fff;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    width: 50%;
    margin-top: 24px;
    /* // margin-right: 27px; */
  }

  .image_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
  }

  .image_label {
    border: 1px dashed #bdbdbd;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
  }

  .image_preview {
    composes: imageLabel;
    border: none;

    img {
      width: 100%;
      height: 5rem;
      object-fit: cover;
      border-radius: 10px;
    }
  }
`;
