import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    createLoading: false,
    stats: {
      pageCount: 1
    }
  },
  data: []
};

export const driveReducerSlice = createSlice({
  name: 'drive',
  initialState,
  reducers: {
    get_images_success: (state, { payload }) => {
      state.ui.loading = false;
      const { images, stats } = payload;
      state.data = images;
      state.ui.stats = {
        ...stats
      };
    },
    get_images_error: (state) => {
      state.ui.loading = false;
    },
    get_images: (state) => {
      state.ui.loading = true;
    },
    create_image: (state) => {
      state.ui.createLoading = true;
    },
    create_image_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data.unshift(payload);
    },
    create_image_error: (state) => {
      state.ui.loading = false;
    },
    delete_image: (state) => {
      state.ui.createLoading = true;
    },
    delete_image_success: (state) => {
      state.ui.createLoading = false;
    },
    delete_image_error: (state) => {
      state.ui.createLoading = false;
    }
  }
});

export const {
  get_images_success,
  get_images_error,
  get_images,
  create_image,
  create_image_success,
  create_image_error,
  delete_image,
  delete_image_success,
  delete_image_error
} = driveReducerSlice.actions;

export default driveReducerSlice.reducer;
