import Pagination from '@app/components/pagination';
import TableFeatures from '@app/components/tablefeatures';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrders, getPageStats, getLoading } from './redux/selectors';
import { get_orders } from './redux/reducer';
import { SubscriptionContainer } from './style';
import SubscriptionTable from './table';

function SubscriptionPage() {
  const ordersFromreducer = useSelector(getOrders);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orders, setOrders] = useState([]);
  const pageStats = useSelector(getPageStats);
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const searchString = e.target.value.toLowerCase();
    if (!searchString) {
      setOrders(ordersFromreducer);
    } else {
      const result = ordersFromreducer.filter(
        (order) =>
          order.group.name.toLowerCase().includes(searchString) ||
          order.delivery_address.toLowerCase().includes(searchString) ||
          order.created_at.includes(searchString)
      );
      setOrders(result);
      setCurrentPage(1);
    }
  };

  const getNewOrders = (page, limit) => {
    dispatch(get_orders({ page, limit }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewOrders(page, pageSize);
  };
  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(size));
    setCurrentPage(1);
    getNewOrders(1, pageSize);
  };

  useEffect(() => {
    setOrders(ordersFromreducer);
  }, [ordersFromreducer]);
  return (
    <div>
      <SubscriptionContainer>
        <TableFeatures
          title="All Subscriptions"
          onSearch={handleChange}
          addbtn={false}
        />
        <SubscriptionTable
          orders={orders}
          currentPage={currentPage}
          pageSize={pageSize}
        />
        {!loading && (
          <Pagination
            onChangePageSize={handlePageSizeChange}
            itemsCount={pageStats.docs}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </SubscriptionContainer>
    </div>
  );
}

export default SubscriptionPage;
