import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLoading, getMeal } from '../meals/redux/selector';
import { delete_meal, get_single_meal } from '../meals/redux/reducer';
import LoadingDataUi from '@app/components/loading';
import { Container, Content } from './style';
import Header from './components/header';
import Details from './components/details';
import { Link } from 'react-router-dom';

const MealDetails = () => {
  const params = useParams();
  const meal = useSelector(getMeal);
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);

  useEffect(() => {
    dispatch(get_single_meal(params.id));
  }, [dispatch, params]);

  const handleDelete = () => {
    dispatch(delete_meal(params.id));
  };
  return (
    <div>
      {loading ? (
        <LoadingDataUi />
      ) : (
        <Container>
          <Header name={meal?.name} />

          <Content>
            <div className="left-flex">
              <Details meal={meal} />
            </div>

            <div className="right-flex">
              <Link
                to={`/meals/edit/${meal?._id}`}
                style={{ textDecoration: 'none', width: '100%' }}
              >
                <button className="edit">Edit</button>
              </Link>
              <button className="delete" onClick={handleDelete}>
                Delete
              </button>
            </div>
          </Content>
        </Container>
      )}
    </div>
  );
};

export default MealDetails;
