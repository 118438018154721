import React from 'react';
import { SearchStyles } from '../meal.styles';
import { IoSearch } from 'react-icons/io5';

const SearchContent = ({
  types,
  handleSelected,
  handleSearch,
  exportMeal,
  field,
  sortField,
  selectedField,
  sort,
  handleSort,
  sorted,
  selectedFilter
}) => {
  return (
    <SearchStyles>
      <div className="search">
        <IoSearch size={20} color="#64748B" />
        <input type="text" placeholder="Search" onChange={handleSearch} />
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <select className="select" onChange={(e) => sortField(e.target.value)}>
          <option disabled selected>
            Filter by Field
          </option>
          {field.map((field, idx) => (
            <option
              value={field.value}
              key={idx}
              selected={selectedField === field.value}
            >
              {field.name}
            </option>
          ))}
        </select>

        <select className="select" onChange={(e) => handleSort(e.target.value)}>
          <option disabled selected>
            Sort By
          </option>
          {sort.map((field, idx) => (
            <option value={field.value} key={idx} selected={sorted === field.value}>
              {field.name}
            </option>
          ))}
        </select>

        <select
          className="select"
          value={selectedFilter}
          onChange={(e) => handleSelected(e.target.value)}
        >
          <option disabled selected>
            Filter by Type
          </option>
          {types.map((type, idx) => (
            <option value={type} key={idx}>
              {type}
            </option>
          ))}
        </select>

        <button onClick={exportMeal}>Export</button>
      </div>
    </SearchStyles>
  );
};

export default SearchContent;
