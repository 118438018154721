import React, { useEffect, useState } from 'react';
import TableFeatures from '@app/components/tablefeatures';
import { StaffContainer } from './style';
import CustomerTable from './table';
import { FormsModal } from '@app/globalstyles/asgard';
import AddStaff from './addstaff';
import Pagination from '@app/components/pagination';
import { useSelector } from 'react-redux';
import { getLoading, getStaff } from './redux/selectors';
import { paginate } from '@app/components/pagination/paginate';

function PartnersPage() {
  const staffsFromReducer = useSelector(getStaff);
  const loading = useSelector(getLoading);
  const [showForm, setShowForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [staffs, setStaffs] = useState([]);

  const handleShowProductForm = () => {
    setShowForm(!showForm);
  };

  const handleChange = (e) => {
    const searchString = e.target.value.toLowerCase();
    if (!searchString) {
      setStaffs(staffsFromReducer);
    } else {
      const result = staffsFromReducer.filter(
        (staff) =>
          staff.city.toLowerCase().includes(searchString) ||
          staff.state.toLowerCase().includes(searchString) ||
          staff.country.toLowerCase().includes(searchString) ||
          staff.user.email.toLowerCase().includes(searchString) ||
          staff.user.first_name.toLowerCase().includes(searchString) ||
          staff.user.last_name.toLowerCase().includes(searchString) ||
          staff.phone.includes(searchString)
      );
      setStaffs(result);
      setCurrentPage(1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(size));
    setCurrentPage(1);
  };

  const getPagedData = () => {
    const staffData = paginate(staffs, currentPage, pageSize);

    return { totalCount: staffs.length, data: staffData };
  };

  const { totalCount, data: allStaff } = getPagedData();

  useEffect(() => {
    setStaffs(staffsFromReducer);
  }, [staffsFromReducer]);

  return (
    <div>
      <StaffContainer>
        <TableFeatures
          onSearch={handleChange}
          title="Staff"
          createBtnLabel={'Add Staff'}
          addbtn={true}
          onCreateBtn={handleShowProductForm}
        />
        {showForm && (
          <FormsModal show={showForm}>
            <AddStaff closeform={handleShowProductForm} />
          </FormsModal>
        )}
        <CustomerTable staff={allStaff} />
        {!loading && (
          <Pagination
            onChangePageSize={handlePageSizeChange}
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </StaffContainer>
    </div>
  );
}

export default PartnersPage;
