import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    stats: {
      pageCount: 1
    }
  },
  data: [],
  export: []
};

export const customersReducerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    get_customers_success: (state, { payload }) => {
      state.ui.loading = false;
      const { users, stats } = payload;
      state.data = users;
      state.ui.stats = {
        ...stats
      };
    },
    get_customers_error: (state) => {
      state.ui.loading = false;
    },
    get_customers: (state) => {
      state.ui.loading = true;
    },

    create_customer_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data.unshift(payload);
    },
    create_customer_error: (state) => {
      state.ui.loading = false;
    },
    create_customer: (state) => {
      state.ui.loading = true;
    },
    get_export: (state) => {
      state.ui.loading = true;
    },
    get_export_success: (state, { payload }) => {
      state.ui.loading = false;
      const { users } = payload;
      state.export = users;
    },
    get_export_error: (state) => {
      state.ui.loading = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_customers,
  get_customers_error,
  get_customers_success,
  create_customer,
  create_customer_error,
  create_customer_success,
  get_export,
  get_export_success,
  get_export_error
} = customersReducerSlice.actions;

export default customersReducerSlice.reducer;
