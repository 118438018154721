import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { approve_product, edit_partner_products } from '../redux/reducer';
import { Formik, Form } from 'formik';
import { getProduct } from '@app/pages/product/redux/selectors';
import { get_product } from '@app/pages/product/redux/reducer';
import { getCreating, getEditSuccess } from '../redux/selector';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { getProductCatgories } from '@app/pages/product-categories/redux/selectors';
import { get_product_categories } from '@app/pages/product-categories/redux/reducer';
import { getLoading } from '@app/pages/product/redux/selectors';
import LoadingDataUi from '@app/components/loading';
import commaNumber from 'comma-number';
import CommentsBox from './commentsBox';
import Modal from '@app/components/modal';
import { getProductGroups } from '@app/pages/product-groups/redux/selectors';
import { get_product_groups } from '@app/pages/product-groups/redux/reducers';
import Select from 'react-select';
import { getRecipes } from '@app/pages/recipes/redux/selectors';
import { get_recipe } from '@app/pages/recipes/redux/reducer';
import { update_recipe } from '@app/pages/recipe/redux/reducer';
import toast from 'react-hot-toast';
import { getUpdating } from '@app/pages/recipe/redux/selector';

const EditProduct = () => {
  const dispatch = useDispatch();
  const currentId = useParams();
  const product = useSelector(getProduct);
  const product_groups = useSelector(getProductGroups);
  const load = useSelector(getEditSuccess);
  const product_categories = useSelector(getProductCatgories);
  const loading = useSelector(getLoading);
  const [show, setShow] = useState(false);
  const loaded = useSelector(getCreating);
  const pageSize = 1000;
  const [categoryValues, setCategoryValues] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(product?.on_marketplace);
  const [showRecipe, setShowRecipe] = useState(false);
  const [recipe, setRecipe] = useState('');
  const recipes = useSelector(getRecipes);
  const updating = useSelector(getUpdating);

  let data = {
    name: product?.name,
    description: product?.description,
    product_group: product?.product_group?._id,
    product_category: categoryValues
  };

  const { id } = currentId;

  useEffect(() => {
    dispatch(
      get_recipe({
        page: 1,
        limit: 1000
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const str = product?.name;
    const target = 'bundle';
    if (str?.toLowerCase()?.includes(target)) {
      setShowRecipe(true);
    }
  }, [product]);

  useEffect(() => {
    setSelected(product?.on_marketplace);
  }, [product]);

  const enums_marketplace = [
    {
      title: 'Yes',
      value: true
    },
    {
      title: 'No',
      value: false
    }
  ];

  useEffect(() => {
    dispatch(get_product(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      get_product_groups({
        page: 1,
        limit: pageSize
      })
    );
  }, [dispatch, pageSize]);

  useEffect(() => {
    dispatch(
      get_product_categories({
        page: 1,
        limit: pageSize
      })
    );
  }, [dispatch, pageSize]);

  useEffect(() => {
    const result = product?.product_category?.map((data) => {
      return {
        _id: data?._id,
        value: data?._id,
        label: data?.name
      };
    });
    setCategoryValues(result);
  }, [product]);

  useEffect(() => {
    const result = product_categories?.map((data) => {
      return {
        _id: data?._id,
        value: data?._id,
        label: data?.name
      };
    });
    setCategories(result);
  }, [product_categories]);

  const handleApproval = (doc, id, comment) => {
    let data = {
      status: doc,
      comment: comment?.comment
    };
    dispatch(approve_product({ data, id }));
    setTimeout(() => {
      setShow(false);
    }, 1500);
  };

  //this submits the form data when your done
  const handleSubmit = (doc) => {
    const response = doc.product_category.map((data) => data.value);

    let data = {
      ...doc,
      product_category: response,
      on_marketplace: selected
    };
    dispatch(edit_partner_products({ data, id }));
  };

  const handleRecipe = (e) => {
    setRecipe(e.target.value);
  };

  const attachRecipe = () => {
    if (!recipe) return toast.error('Please select a recipe');
    let data = {
      product_bundles: [product?._id]
    };
    dispatch(update_recipe({ data, id: recipe }));
  };

  return (
    <div className={styles.container}>
      {show && (
        <Modal width={'352px'} onClose={() => setShow(false)} title={'Comment'}>
          <CommentsBox handleChange={handleApproval} id={id} />
        </Modal>
      )}
      {loading ? (
        <LoadingDataUi />
      ) : (
        <>
          <div className={styles.header}>
            Edit Products
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <button
                className={styles.approve}
                onClick={() => handleApproval('approved', id)}
              >
                {loaded ? 'Loading...' : ' Approve'}
              </button>
              <button className={styles.decline} onClick={() => setShow(true)}>
                {loaded ? 'Loading...' : ' Decline'}
              </button>
            </div>
          </div>
          <Formik
            initialValues={data}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize={true}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <div className={styles.body}>
                  <div>
                    <div className={styles.label}>Product Name</div>
                    <input
                      type="text"
                      name="name"
                      value={values?.name}
                      onChange={handleChange}
                      disabled
                    />

                    <div className={styles.label}>Product Description</div>
                    <textarea
                      rows="5"
                      value={values?.description}
                      onChange={handleChange}
                      name="description"
                      disabled
                    />

                    <div>
                      <div className={styles.label}>Product Group</div>
                      <select
                        onChange={handleChange}
                        name="product_group"
                        style={{ width: '100%' }}
                      >
                        {product_groups?.map((group) => (
                          <option
                            value={group._id}
                            key={group._id}
                            selected={values?.product_group === group?._id}
                          >
                            {group?.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <div className={styles.label}>Product Category</div>
                      <Select
                        isClearable={true}
                        className={styles.select_box}
                        options={categories}
                        value={values.product_category}
                        isMulti
                        closeMenuOnSelect={false}
                        name="product_category"
                        onChange={(value) =>
                          setFieldValue('product_category', value)
                        }
                      />
                    </div>

                    <div className={styles.form_body}>
                      <div>
                        <div className={styles.label}>Price</div>
                        <input
                          type="text"
                          name="name"
                          value={commaNumber(product?.price?.value)}
                          disabled
                        />
                      </div>

                      <div>
                        <div className={styles.label}>Quantity in stock</div>
                        <input
                          type="text"
                          name="name"
                          value={`${product?.quantity}`}
                          disabled
                        />
                      </div>
                    </div>

                    {product?.sizes && <div className={styles.label}>Sizes:</div>}

                    <div className={styles.form_body}>
                      {product?.sizes?.map((size, idx) => (
                        <div key={idx}>
                          <div className={styles.label}>
                            Price for {`${size?.weight}`}
                            {product?.unit}{' '}
                          </div>
                          <input
                            type="text"
                            name="name"
                            value={`${
                              product?.price?.currency
                                ? product?.price?.currency?.symbol
                                : ''
                            }${commaNumber(size.price?.value)}`}
                            disabled
                          />
                        </div>
                      ))}
                    </div>

                    {showRecipe && (
                      <div style={{ marginTop: '10px' }}>
                        <div className={styles.label}>Recipe</div>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <select onChange={handleRecipe} style={{ width: '100%' }}>
                            {recipes?.map((group) => (
                              <option value={group._id} key={group._id}>
                                {group?.name}
                              </option>
                            ))}
                          </select>

                          <button
                            className={styles.save}
                            onClick={attachRecipe}
                            disabled={updating}
                          >
                            {updating ? 'Attaching...' : 'Attach Recipe'}
                          </button>
                        </div>
                      </div>
                    )}

                    <div>
                      <div className={styles.label}>Show on marketplace</div>
                      <div className={styles.selection_box}>
                        {enums_marketplace.map((data, idx) => (
                          <div
                            onClick={() => setSelected(data.value)}
                            className={
                              selected === data.value
                                ? styles.selection_active
                                : styles.selection
                            }
                            key={idx}
                          >
                            {data.title}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* second grid */}
                  <div>
                    <div className={styles.label}>Product Images</div>
                    <img
                      src={product?.image}
                      style={{
                        width: '100%',
                        height: '20rem',
                        objectFit: 'contain',
                        borderRadius: '10px'
                      }}
                      alt="product"
                    />
                    <div className={styles.gallery}>
                      {product?.other_images?.map((data, i) => (
                        <label className={styles.image_preview} key={i}>
                          <img src={data} alt="prev" />
                        </label>
                      ))}
                    </div>
                    <div className={styles.button_container}>
                      <button type="submit" className={styles.save} disabled={load}>
                        {load ? 'Saving...' : 'Save Product'}
                      </button>
                      <Link to={`/partners/products/${product?.owner?._id}`}>
                        <button className={styles.back} disabled={load}>
                          Back
                        </button>
                      </Link>
                    </div>
                  </div>
                  {/* end of second grid */}

                  {/* third grid */}
                  <div>
                    <div className={styles.label}>Product Video</div>
                    <video width="450" height="150" controls src={product?.video} />
                  </div>
                  {/* end of third grid */}
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default EditProduct;
