import styled from 'styled-components';

export const TextInput = styled.input`
  border-radius: 20px;
  height: 2.4em;
  border: 1px solid #aaadb2;
  padding-left: 3%;
  font-size: 18px;
  margin-top: 5%;

  ::placeholder {
    color: #797b80;
    padding-left: 3%;
  }

  &:focus {
    outline: none;
    border: 1px solid #549a9a;
  }

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1400px) {
    font-size: 20px;
  }
`;
