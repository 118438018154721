import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_coupons,
  get_coupons_error,
  get_coupons_success,
  create_coupon,
  create_coupon_success,
  create_coupon_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getCouponsSagas() {
  try {
    // const { page = 1, limit = 10 } = payload;

    const requestRes = yield call(api.get, `/coupons`);
    const responseData = requestRes?.data;
    const response = responseData?.data;
    yield put({
      type: get_coupons_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_coupons_error.type
    });
  }
}

function* createCouponSagas({ payload }) {
  try {
    const requestRes = yield call(api.post, '/coupons', payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success('Coupons created successfully');
    yield put({
      type: create_coupon_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: create_coupon_error.type
    });
  }
}

function* couponsSagas() {
  yield takeEvery(get_coupons.type, getCouponsSagas);
  yield takeEvery(create_coupon.type, createCouponSagas);
}

export default couponsSagas;
