import React, { useEffect, useState } from 'react';
import { Container, Grid, Header, SearchBox } from './style';
import { useSelector, useDispatch } from 'react-redux';
import { delete_image, get_images } from './redux/reducers';
import { getImages, getLoading, getPageStats } from './redux/selectors';
import Pagination from '@app/components/pagination';
import { FormsModal } from '@app/globalstyles/asgard';
import { FormContainerProducts } from '../products/style';
import AddImage from './addImage';
import EmptyDataUi from '@app/components/emptyData';

const AkaaniDrive = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const imagesFromReducer = useSelector(getImages);
  const pageStats = useSelector(getPageStats);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState('');
  const [images, setImages] = useState([]);
  const [show, setShow] = useState(false);

  const getNewRecipes = (page, limit, searchString) => {
    dispatch(get_images({ page, limit, searchString }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewRecipes(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewRecipes(1, pageSize);
  };

  useEffect(() => {
    dispatch(
      get_images({
        page: currentPage,
        limit: pageSize,
        searchString
      })
    );
  }, [dispatch, pageSize, searchString, currentPage]);

  useEffect(() => {
    setImages(imagesFromReducer);
  }, [imagesFromReducer]);

  const handleChange = (e) => {
    setSearchString(e.target.value);
  };

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const handleDelete = (data) => {
    dispatch(delete_image(data));
  };

  return (
    <div>
      {show && (
        <FormsModal show={show} onClick={() => setShow(false)}>
          <FormContainerProducts onClick={StopBubbling}>
            <AddImage close={() => setShow(false)} />
          </FormContainerProducts>
        </FormsModal>
      )}
      <Header>
        <p className="header_text">Akaani Drive</p>

        <button onClick={() => setShow(true)}>Add Image</button>
      </Header>
      <Container>
        <SearchBox>
          <div className="search_bar">
            <input type="text" placeholder="Search" onChange={handleChange} />
          </div>
        </SearchBox>

        {!loading && images?.length <= 0 ? (
          <EmptyDataUi />
        ) : (
          <Grid>
            {images.map((data) => (
              <div key={data._id} className="card">
                <div className="img_overlay" onClick={() => handleDelete(data._id)}>
                  {' '}
                  Delete{' '}
                </div>
                <img src={data.url} alt="product_image" />
                {data.name}
              </div>
            ))}
          </Grid>
        )}

        {!loading && (
          <Pagination
            onChangePageSize={handlePageSizeChange}
            itemsCount={pageStats.docs}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </Container>
    </div>
  );
};

export default AkaaniDrive;
