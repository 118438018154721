import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    creating: false,
    edit: false,
    stats: {
      pageCount: 1
    }
  },
  partner: [],
  single_partner: {},
  products: [],

  partner_location: {},

  term: {},
  config: {},
  bank: [],
  locations: [],
  export: []
};

export const partnerReducerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    get_partners: (state) => {
      state.ui.loading = true;
    },
    get_partners_success: (state, { payload }) => {
      state.ui.loading = false;
      state.partner = payload.partners;
      state.ui.stats = payload.stats;
    },
    get_partners_error: (state) => {
      state.ui.loading = false;
    },
    get_partner: (state) => {
      state.ui.loading = true;
    },
    get_partner_success: (state, { payload }) => {
      state.ui.loading = false;
      state.single_partner = payload;
    },
    get_partner_error: (state) => {
      state.ui.loading = false;
    },
    get_partner_location: (state) => {
      state.ui.loading = true;
    },

    get_partner_location_success: (state, { payload }) => {
      state.ui.loading = false;
      state.partner_location = payload;
    },

    get_partner_location_error: (state) => {
      state.ui.loading = false;
    },

    add_partner: (state) => {
      state.ui.creating = true;
    },
    add_partner_success: (state) => {
      state.ui.creating = false;
    },
    add_partner_error: (state) => {
      state.ui.creating = false;
    },
    delete_partner: (state) => {
      state.ui.creating = true;
    },
    delete_partner_success: (state) => {
      state.ui.creating = false;
    },
    delete_partner_error: (state) => {
      state.ui.creating = false;
    },
    update_partner: (state) => {
      state.ui.creating = true;
    },
    update_partner_success: (state) => {
      state.ui.creating = false;
    },
    update_partner_error: (state) => {
      state.ui.creating = false;
    },
    add_partner_product: (state) => {
      state.ui.creating = true;
    },
    add_partner_product_success: (state) => {
      state.ui.creating = false;
    },
    add_partner_product_error: (state) => {
      state.ui.creating = false;
    },
    get_partner_products: (state) => {
      state.ui.loading = true;
    },

    get_partner_products_success: (state, { payload }) => {
      state.ui.loading = false;
      const { products, stats } = payload;
      state.products = products;
      state.ui.stats = {
        ...stats
      };
    },
    get_partner_products_error: (state) => {
      state.ui.loading = false;
    },
    verify_partner: (state) => {
      state.ui.creating = true;
    },
    verify_partner_success: (state) => {
      state.ui.creating = false;
    },
    verify_partner_error: (state) => {
      state.ui.creating = false;
    },
    approve_product: (state) => {
      state.ui.creating = true;
    },
    approve_product_success: (state, { payload }) => {
      state.ui.creating = false;
      state.products = state.products.map((data) => {
        return data._id === payload._id ? payload : data;
      });
    },
    approve_product_error: (state) => {
      state.ui.creating = false;
    },
    edit_partner_products: (state) => {
      state.ui.edit = true;
    },

    edit_partner_products_success: (state, { payload }) => {
      state.ui.edit = false;
      state.products = state.products.map((data) => {
        return data._id === payload._id ? payload : data;
      });
    },

    edit_partner_products_error: (state) => {
      state.ui.edit = false;
    },
    add_payment_term: (state) => {
      state.ui.creating = true;
    },
    add_payment_term_success: (state, { payload }) => {
      state.ui.creating = false;
      state.term = payload.payment_terms;
      state.config = payload;
    },
    add_payment_term_error: (state) => {
      state.ui.creating = false;
    },
    get_payment_term: (state) => {
      state.ui.loading = true;
    },
    get_payment_term_success: (state, { payload }) => {
      state.ui.loading = false;
      state.term = payload.payment_terms;
      state.config = payload;
    },
    get_payment_term_error: (state) => {
      state.ui.loading = false;
    },
    update_payment_term: (state) => {
      state.ui.creating = true;
    },
    update_payment_term_success: (state, { payload }) => {
      state.ui.creating = false;
      state.term = payload.payment_terms;
      state.config = payload;
    },
    update_payment_term_error: (state) => {
      state.ui.creating = false;
    },
    get_bank_detail: (state) => {
      state.ui.loading = true;
    },
    get_bank_detail_success: (state, { payload }) => {
      state.ui.loading = false;
      state.bank = payload;
    },
    get_bank_detail_error: (state) => {
      state.ui.loading = false;
    },
    get_locations: (state) => {
      state.ui.loading = true;
    },
    get_locations_success: (state, { payload }) => {
      state.ui.loading = false;
      const { locations } = payload;
      state.locations = locations;
    },
    get_locations_error: (state) => {
      state.ui.loading = false;
    },
    get_export: (state) => {
      state.ui.loading = true;
    },
    get_export_success: (state, { payload }) => {
      state.ui.loading = false;
      const { partners } = payload;
      state.export = partners;
    },
    get_export_error: (state) => {
      state.ui.loading = false;
    }
  }
});

export const {
  get_partners,
  get_partners_success,
  get_partners_error,
  get_partner,
  get_partner_success,
  get_partner_error,
  add_partner,
  add_partner_success,
  add_partner_error,
  delete_partner,
  delete_partner_success,
  delete_partner_error,
  update_partner,
  update_partner_success,
  update_partner_error,
  add_partner_product,
  add_partner_product_success,
  add_partner_product_error,
  get_partner_products,
  get_partner_products_success,
  get_partner_products_error,
  verify_partner,
  verify_partner_success,
  verify_partner_error,
  approve_product,
  approve_product_success,
  approve_product_error,
  edit_partner_products,
  edit_partner_products_success,
  edit_partner_products_error,
  get_partner_location,
  get_partner_location_success,
  get_partner_location_error,
  add_payment_term,
  add_payment_term_success,
  add_payment_term_error,
  get_payment_term,
  get_payment_term_success,
  get_payment_term_error,
  update_payment_term,
  update_payment_term_success,
  update_payment_term_error,
  get_bank_detail,
  get_bank_detail_success,
  get_bank_detail_error,
  get_locations,
  get_locations_success,
  get_locations_error,
  get_export,
  get_export_success,
  get_export_error
} = partnerReducerSlice.actions;

export default partnerReducerSlice.reducer;
