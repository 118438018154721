import styled from 'styled-components';

export const PageContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

export const SectionContainer = styled.div`
  .detailvalue {
    margin-top: -5px;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 10px;
  flex-direction: column;
`;

export const ProductUpdateFormDiv = styled.div`
  width: 50%;
  background-color: #fff;
  border-radius: 5px;
`;

export const ProductDetailsTopSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  img {
    margin-left: 5px;
    margin-right: 5px;
  }
  p {
    letter-spacing: 0.02em;
    color: rgba(184, 184, 184, 0.74);
  }
  h3 {
    letter-spacing: 0.02em;
    color: #284242;
  }
`;

export const EditSizeFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
