import { put, takeEvery, takeLatest, call } from '@redux-saga/core/effects';
import {
  login,
  login_error,
  login_success,
  request_token,
  request_token_error,
  reset_password,
  reset_password_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* loginSaga({ payload }) {
  try {
    const requestRes = yield call(api.post, '/auth/login', payload);
    const responseData = requestRes.data;
    localStorage.setItem('x-access-token', responseData.data.token);
    const user = responseData.data.user;
    let data = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      username: user.username
    };
    const userJSON = JSON.stringify(data);
    localStorage.setItem('user', userJSON);
    yield put({
      type: login_success.type,
      payload: user
    });
    if (user.email_verified || user.account_verified) {
      window.location.href = '/dashboard';
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.message || 'An error occurred during login.');
    yield put({
      type: login_error.type
    });
  }
}

function* requestNewPasswordTokenSaga({ payload }) {
  try {
    const requestRes = yield call(api.patch, '/auth/forgot-password', payload);
    const responseData = requestRes.data;
    if (responseData.success && responseData.status_code === 200) {
      toast.success('Password reset token was sent to your email');
      window.location.href = `/change-password?email=${responseData.data.email}`;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: request_token_error.type
    });
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    const requestRes = yield call(api.patch, '/auth/reset-password', payload);
    const responseData = requestRes.data;
    if (responseData.success && responseData.status_code === 200) {
      toast.success('Password reset successful, redirecting to login');
      window.location.href = `/login`;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: reset_password_error.type
    });
  }
}

function* authSagas() {
  yield takeEvery(login.type, loginSaga);
  yield takeLatest(request_token.type, requestNewPasswordTokenSaga);
  yield takeLatest(reset_password.type, resetPasswordSaga);
}

export default authSagas;
