import { put, takeEvery, call, select } from '@redux-saga/core/effects';
import {
  get_partners,
  get_partners_success,
  get_partners_error,
  get_partner,
  get_partner_success,
  get_partner_error,
  add_partner,
  add_partner_success,
  add_partner_error,
  delete_partner,
  delete_partner_success,
  delete_partner_error,
  update_partner,
  update_partner_success,
  update_partner_error,
  add_partner_product,
  add_partner_product_success,
  add_partner_product_error,
  get_partner_products,
  get_partner_products_success,
  get_partner_products_error,
  verify_partner,
  verify_partner_success,
  verify_partner_error,
  approve_product,
  approve_product_success,
  approve_product_error,
  edit_partner_products,
  edit_partner_products_success,
  edit_partner_products_error,
  get_partner_location,
  get_partner_location_success,
  get_partner_location_error,
  add_payment_term,
  add_payment_term_success,
  add_payment_term_error,
  get_payment_term,
  get_payment_term_success,
  get_payment_term_error,
  update_payment_term,
  update_payment_term_success,
  update_payment_term_error,
  get_bank_detail,
  get_bank_detail_success,
  get_bank_detail_error,
  get_locations,
  get_locations_success,
  get_locations_error,
  get_export,
  get_export_success,
  get_export_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';
import { MutipleFileUploadToCloud } from '@app/services/fileUploadService';
import { getPartnerConfig } from './selector';

function* getPartnerSaga({
  payload: { page = 1, limit = 10, searchString, verify_stat, states }
}) {
  try {
    const verification = verify_stat ? `&${verify_stat}` : ``;
    const search = searchString ? `&search=${searchString}` : ``;
    const state = states ? `&state=${states}` : ``;
    const { data } = yield call(
      api.get,
      `/partners?page=${page}&limit=${limit}${search}${verification}${state}`
    );
    yield put({
      type: get_partners_success.type,
      payload: data.data
    });
  } catch (error) {
    yield put({
      type: get_partners_error.type
    });
    toast.error(
      !error?.response?.data?.status_code === 400
        ? error.toString()
        : error?.response?.data?.message
    );
  }
}

function* getPartnerLocationSaga({ payload }) {
  try {
    const request = yield call(api.get, `/delivery_locations/bypartner/${payload}`);
    const responseData = request.data;
    const response = responseData.data;

    yield put({
      type: get_partner_location_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_partner_location_error.type
    });
  }
}

function* getPartnerSingleSaga({ payload }) {
  try {
    const request = yield call(api.get, `/partners/${payload}`);
    const responseData = request.data;
    const response = responseData.data;

    yield put({
      type: get_partner_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_partner_error.type
    });
  }
}

function* addPartnerSaga({ payload }) {
  try {
    const request = yield call(api.post, `/partners/invite`, payload);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: add_partner_success.type,
      payload: response
    });
    if (responseData.success) {
      toast.success(responseData.message);
      setTimeout(() => {
        window.location.href = `/partners`;
      }, 1500);
    }
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: add_partner_error.type
    });
  }
}

function* deletePartnerSaga({ payload }) {
  try {
    const request = yield call(api.delete, `/partners/${payload}`);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: delete_partner_success.type,
      payload: response
    });
    if (responseData.success) {
      toast.success(responseData.message);
      setTimeout(() => {
        window.location.href = '/partners';
      }, 1500);
    }
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: delete_partner_error.type
    });
  }
}

function* addPartnerProductSaga({ payload }) {
  try {
    const responseImage = yield call(MutipleFileUploadToCloud, payload.image);
    const image = responseImage.filter((image) => image !== '');
    const data = {
      ...payload,
      image: image[0],
      other_images: image
    };
    delete data['currency'];
    const request = yield call(api.post, `/products`, data);
    const responseData = request.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: add_partner_product_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: add_partner_product_error.type
    });
  }
}

function* editPartnerProducts({ payload }) {
  try {
    const requestRes = yield call(api.put, `/products/${payload?.id}`, payload.data);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: edit_partner_products_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: edit_partner_products_error.type,
      payload: error
    });
  }
}

function* updatePartnerSaga({ payload }) {
  try {
    const { id, data } = payload;
    const request = yield call(api.patch, `/partners/${id}`, data);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: update_partner_success.type,
      payload: response
    });
    if (responseData.success) {
      toast.success(responseData.message);
      setTimeout(() => {
        window.location.href = `/partners/${id}`;
      }, 1500);
    }
  } catch (error) {
    toast.error(error ? error.toString() : error?.message);
    yield put({
      type: update_partner_error.type
    });
  }
}

function* getPartnerPaymentTerm({ payload }) {
  try {
    const { id } = payload;
    let partner = ``;
    if (id) {
      partner = `${id}`;
    } else {
      partner = ``;
    }
    const request = yield call(api.get, `/partner_configs/partner/${partner}`);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: get_payment_term_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_payment_term_error.type
    });
  }
}

function* getPartnerProductSaga({ payload }) {
  try {
    const { status, product_group, id, page = 1, limit = 20, searchTerm } = payload;
    let stat = ``;
    if (status) {
      stat = `&${status}`;
    } else {
      stat = ``;
    }
    let group = ``;
    if (product_group) {
      group = `&product_group=${product_group}`;
    } else {
      group = ``;
    }

    let search = ``;
    if (searchTerm) {
      search = `&search=${searchTerm}`;
    } else {
      search = ``;
    }
    const request = yield call(
      api.get,
      `/products/bypartner/${id}?&page=${page}&limit=${limit}${stat}${group}${search}`
    );
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_partner_products_success.type,
      payload: response
    });
  } catch (error) {
    console.log(error);
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_partner_products_error.type
    });
  }
}

function* verifyPartnerSaga({ payload }) {
  try {
    const request = yield call(api.patch, `/users/verify-account/${payload}`);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: verify_partner_success.type,
      payload: response
    });
    if (responseData.success) {
      toast.success(responseData.message);
      setTimeout(() => {
        window.location.href = `/partners/${response._id}`;
      }, 1500);
    }
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: verify_partner_error.type
    });
  }
}

function* approveProductSaga({ payload }) {
  try {
    const { data, id } = payload;
    const request = yield call(api.patch, `/products/approval/${id}`, data);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: approve_product_success.type,
      payload: response
    });
    toast.success(requestData.message);
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: approve_product_error.type
    });
  }
}

function* getBankDetail({ payload }) {
  try {
    const request = yield call(api.get, `/bank_accounts/bypartner/${payload}`);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: get_bank_detail_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_bank_detail_error.type
    });
  }
}

function* getDeliveryLocation({ payload }) {
  try {
    const request = yield call(api.get, `/delivery_locations/bypartner/${payload}`);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: get_locations_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_locations_error.type
    });
  }
}

function* addPaymentTerm({ payload }) {
  try {
    const request = yield call(api.post, `/partner_configs`, payload.data);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: add_payment_term_success.type,
      payload: response
    });
    toast.success(response.message);
    setTimeout(() => {
      payload.close();
    }, 1500);
  } catch (error) {
    const { response } = error;
    toast.error(error ? error.toString() : response?.data?.message);
    payload.close();
    yield put({
      type: add_payment_term_error.type
    });
  }
}

function* updatePaymentTerm({ payload }) {
  const config = yield select(getPartnerConfig);
  const data = {
    ...payload.data,
    config: 'payment'
  };
  try {
    const request = yield call(api.patch, `/partner_configs/${config._id}`, data);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: update_payment_term_success.type,
      payload: response
    });
    toast.success(response.message);
    setTimeout(() => {
      payload.close();
    }, 1500);
  } catch (error) {
    const { response } = error;
    toast.error(error ? error.toString() : response?.data?.message);
    payload.close();
    yield put({
      type: update_payment_term_error.type
    });
  }
}

function* getExportData({ payload }) {
  try {
    const { page = 1, limit = 1000 } = payload;
    const { data } = yield call(api.get, `/partners?page=${page}&limit=${limit}`);
    yield put({
      type: get_export_success.type,
      payload: data.data
    });
  } catch (error) {
    yield put({
      type: get_export_error.type
    });
    toast.error(
      !error?.response?.data?.status_code === 400
        ? error.toString()
        : error?.response?.data?.message
    );
  }
}

function* partnerSagas() {
  yield takeEvery(get_partners.type, getPartnerSaga);
  yield takeEvery(get_partner.type, getPartnerSingleSaga);
  yield takeEvery(add_partner.type, addPartnerSaga);
  yield takeEvery(add_partner_product.type, addPartnerProductSaga);
  yield takeEvery(delete_partner.type, deletePartnerSaga);
  yield takeEvery(update_partner.type, updatePartnerSaga);
  yield takeEvery(get_partner_products.type, getPartnerProductSaga);
  yield takeEvery(verify_partner.type, verifyPartnerSaga);
  yield takeEvery(approve_product.type, approveProductSaga);
  yield takeEvery(edit_partner_products.type, editPartnerProducts);
  yield takeEvery(get_partner_location.type, getPartnerLocationSaga);
  yield takeEvery(add_payment_term.type, addPaymentTerm);
  yield takeEvery(get_payment_term.type, getPartnerPaymentTerm);
  yield takeEvery(update_payment_term.type, updatePaymentTerm);
  yield takeEvery(get_bank_detail.type, getBankDetail);
  yield takeEvery(get_locations.type, getDeliveryLocation);
  yield takeEvery(get_export.type, getExportData);
}

export default partnerSagas;
