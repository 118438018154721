import { createSelector } from '@reduxjs/toolkit';

const customersData = (state = {}) => state.customers;
const custData = (state = {}) => state;

export const getCustomers = createSelector(
  customersData,
  (customersData) => customersData.data
);

export const getLoading = createSelector(
  customersData,
  (customersData) => customersData.ui.loading
);

export const getPageStats = createSelector(
  customersData,
  (customersData) => customersData.ui.stats
);

export const getCustStats = createSelector(custData, (custData) => custData);

export const getExportData = createSelector(
  customersData,
  (customersData) => customersData.export
);
