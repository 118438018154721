import React from 'react';
import styles from '../addDelivery/styles.module.css';

const Switch = ({ handleChange, values }) => {
  return (
    <div>
      <label className={styles.switch}>
        <input
          type="checkbox"
          name="interstate"
          value={values.interstate}
          onChange={handleChange}
          defaultChecked={values.interstate}
          checked={values.interstate}
        />
        <span className={styles.slider} />
      </label>
    </div>
  );
};

export default Switch;
