import React from 'react';
import { AsgardP, Button, Div, Img } from '@app/globalstyles/asgard';
import add from '@assets/icons/add.svg';
// import filterIcon from '@assets/icons/filter-icons.svg';
// import sortIcon from '@assets/icons/sort.svg';
import { PageTitleHeading, TableFeaturesContainer } from './style';
import Colors from '@app/globalstyles/utils/colors';
import MainSearch from '../mainsearch';

function TableFeatures({
  title,
  createBtnLabel,
  addbtn = false,
  onCreateBtn,
  onSearch,
  searchFiled = true,
  exportBtn = false,
  download
}) {
  return (
    <TableFeaturesContainer>
      <PageTitleHeading>{title}</PageTitleHeading>

      {searchFiled && <MainSearch onSearch={onSearch} />}
      <div>
        {addbtn && (
          <Button
            h="30px"
            onClick={onCreateBtn}
            cursor="pointer"
            border={Colors.primaryMain}
            bc={Colors.white}
            br="5px"
            hbc="#bcc5c2"
            mr="10px"
          >
            {!createBtnLabel && (
              <Div display="flex" mt="-7px">
                <Img src={add} alt="Add" />
                <AsgardP ml="5px" color={Colors.primaryMain}>
                  Create
                </AsgardP>
              </Div>
            )}
            {createBtnLabel && (
              <Div display="flex" mt="-7px">
                <AsgardP ml="5px" color={Colors.primaryMain}>
                  {createBtnLabel}
                </AsgardP>
              </Div>
            )}
          </Button>
        )}

        {!exportBtn && (
          <Button
            h="30px"
            color={Colors.white}
            bc={Colors.primaryMain}
            br="5px"
            hbc={Colors.primaryDark}
            cursor="pointer"
            onClick={download}
          >
            Export
          </Button>
        )}

        {/* <Button h="30px" br="5px" mr="10px" bc="transparent">
          <Div display="flex" mt="-7px">
            <Img src={sortIcon} alt="sort" />
            <AsgardP ml="5px" color={Colors.muted}>
              Sort
            </AsgardP>
          </Div>
        </Button>
        <Button h="30px" br="5px" bc="transparent">
          <Div display="flex" mt="-7px">
            <Img src={filterIcon} alt="filter" />
            <AsgardP ml="5px" color={Colors.muted}>
              Filter
            </AsgardP>
          </Div>
        </Button> */}
      </div>
    </TableFeaturesContainer>
  );
}

export default TableFeatures;
