import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

const StatusBadge = styled(Badge)`
  padding: 0.5em;
  background-color: ${(props) =>
    props.bg === 'warning' ? 'orange' : props.bg === 'success' ? '#549A9A' : 'red'};
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
  font-size: 1em;
`;

export default StatusBadge;
