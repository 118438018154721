import styled from 'styled-components';
import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';

export const PageContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

export const SectionContainer = styled.div``;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Tabs = styled.ul`
  display: flex;
  border: 2px 2px 0px solid grey;
  padding: 10px;
  cursor: pointer;
`;

export const Tab = styled.li`
  margin-left: 2em;
  list-style: none;
  color: ${(props) => (props.active ? Colors.primaryMain : Colors.blackish)};
  font-weight: ${(props) => (props.active ? '800' : '400')};
`;

export const VerifyFormContainer = styled.div`
  width: 25%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-shadow: 0px 0px 13px 1px rgba(63, 63, 68, 0.24),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 8px;
  ${media.tablet`
  width: 50%;
  height: 150px;
  
  `}
  ${media.mobile`
  width: 80%;
  height: 150px;
  
  `}
`;

export const BtnContainer = styled.div`
  width: 100%;
  height: 40px;
  bottom: 0px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;
