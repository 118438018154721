import React from 'react';
import { Sidemodal } from './style';
import Blur from './Blur';

export const SideModal = ({ isOpen, toggle, children, ...props }) => {
  return (
    <>
      <Blur isOpen={isOpen} toggle={toggle} />
      <Sidemodal isOpen={isOpen} {...props}>
        {children}
      </Sidemodal>
    </>
  );
};
