import styled from 'styled-components';

export const ProductDetailsContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  background: #ffffff;
  border: 2px solid #f0f0f0;
  margin-top: 10px;
  border-radius: 10px;
`;
export const MainDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
`;

export const ProductImageCardContainer = styled.div`
  width: 40%;
  border-radius: 10px;
`;
export const ProductImageCard = styled.div`
  /* box-sizing: border-box; */
  width: 100%;
  display: flex;
  justify-content: center;
  height: 197.2px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
`;
export const SubProductImageCard = styled.div`
  width: 136px;
  height: 80.31px;
  flex: 0 0 136px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
`;
export const OtherImageCardDiv = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  margin-top: 10px;
  border-radius: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const RightSection = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  h2,
  h3 {
    color: #4c515a;
  }
`;
export const RightSectionTopDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const RightContentLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  p {
    letter-spacing: 0.02em;
  }
`;

export const InstockBtn = styled.button`
  width: 110px;
  height: ${({ h }) => (h ? h : '30px')};
  outline: none;
  border: none;
  text-transform: capitalize;
  color: ${({ color }) => (color ? color : 'rgba(4, 120, 84, 1)')};
  background: ${({ bg }) => (bg ? bg : '#d5eddc')};
  border-radius: 5px;
`;

export const GridHeaderSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  h3 {
    color: #284242;
  }
`;
export const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr) 0.7fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  margin-bottom: 10px;
  gap: 15px;
`;
export const ProductGridItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const DetailHeading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #a8a8a8;
  text-transform: uppercase;
`;

export const EditDescDiv = styled.div`
  width: calc(100% - 40px);
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
`;
