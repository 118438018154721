import React from 'react';
import { useHistory } from 'react-router-dom';
import { AsgardNavLink } from '@app/globalstyles/asgard';
import {
  MenuIcon,
  MenuIconContainerLG,
  MenuItemLogout,
  MenuTextLogout
} from './style';
import { useSelector } from 'react-redux';
import { getUser } from '@app/auth/redux/selectors';

export default function LogoutMenu({
  icon,
  text,
  isActive,
  link,
  active,
  textColor = ''
}) {
  const user = useSelector(getUser);
  const history = useHistory();
  return (
    <AsgardNavLink to={link}>
      <MenuItemLogout
        active={history?.location?.pathname.startsWith(link) ? true : false}
      >
        <MenuIconContainerLG>
          <MenuIcon src={icon} />
        </MenuIconContainerLG>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h5 style={{ color: '#4D4D4D', marginTop: '-10px' }}>
            {user?.first_name} {user?.last_name}
          </h5>
          <MenuTextLogout color={textColor}>{text}</MenuTextLogout> {/* </Div> */}
        </div>
      </MenuItemLogout>
    </AsgardNavLink>
  );
}
