import { TableContainer } from '@app/pages/facts/styles';
import moment from 'moment';
import React from 'react';
import { CiEdit } from 'react-icons/ci';
import { RiDeleteBinLine } from 'react-icons/ri';

const MealCategoryTable = ({ category, handleDelete, handleEdit }) => {
  const head = ['Name', 'Description', 'Date', ''];
  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            {head.map((data, idx) => (
              <th key={idx}>{data}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {category.map((data) => (
            <tr key={data._id}>
              <td style={{ display: 'flex', alignItems: 'center', gap: '26px' }}>
                {data.image && <img src={data.image} alt={data.title} />}
                <h4>{data.name}</h4>
              </td>
              <td>{data.description}</td>
              <td>{moment(data.created_at).format('LL')}</td>

              <td>
                <div style={{ display: 'flex', alignItems: 'center', gap: '26px' }}>
                  <CiEdit size={18} onClick={() => handleEdit(data)} />
                  <RiDeleteBinLine
                    size={18}
                    onClick={() => handleDelete(data._id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default MealCategoryTable;
