import { AsgardP, Button, Div, Img } from '@app/globalstyles/asgard';
import { BTableBody, ProductsTableContent, TableRow } from './style';
import { useSelector } from 'react-redux';
import { getLoading } from '../redux/selectors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get_products } from '../redux/reducer';
// import commaNumber from 'comma-number';
import verticalElipses from '@app/assets/icons/vert_elipses.svg';
// import StatusBadge from '@design-system/StatusBadge';
import Link from '@design-system/Link';
// import StatusLabel from '@design-system/StatusLabel';
import LoadingDataUi from '@app/components/loading';
import EmptyDataUi from '@app/components/emptyData';
import Colors from '@app/globalstyles/utils/colors';
// import approvedIcon from '@app/assets/icons/approved_icon.svg';
// import unapprovedIcon from '@app/assets/icons/unaproved_icon.svg';

const AsgardTable = ({
  products,
  selectedfilter,
  pageSize,
  currentPage,
  searchString
}) => {
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      get_products({
        limit: pageSize,
        page: currentPage,
        selectedfilter,
        searchString
      })
    );
  }, [dispatch, selectedfilter, currentPage, pageSize, searchString]);

  return (
    <div>
      <ProductsTableContent>
        {loading ? (
          <LoadingDataUi />
        ) : (
          <BTableBody>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Product Group</th>
                <th>Country</th>
                {/* <th>Price</th>
                <th>In Stock? </th>
                <th>Approval Status</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products?.map((product) => (
                <TableRow key={product._id}>
                  <td>
                    <Link color={Colors.black} to={`/products/${product._id}`}>
                      <Div display="flex">
                        <AsgardP color={Colors.black}>{product.name}</AsgardP>
                      </Div>
                    </Link>
                  </td>
                  {/* <td>{product.quantity}</td> */}

                  <td>{product?.product_group?.name}</td>
                  <td>{product.country}</td>
                  {/* <td>
                    <Div display="flex" alignI="center">
                      {product?.price?.currency?.symbol}
                      <AsgardP fw="bold">
                        {commaNumber(product?.price?.value)}
                      </AsgardP>
                    </Div>
                  </td>
                  <td>
                    <Div width="100%" display="flex">
                      {product.in_stock ? (
                        <Img src={approvedIcon} alt="Aprroved" />
                      ) : (
                        <Img src={unapprovedIcon} alt="Unprroved" />
                      )}
                    </Div>
                  </td>
                  <td>
                    <Div width="100%" display="flex">
                      {product.approved ? (
                        <StatusLabel bg={'success'}>APPROVED</StatusLabel>
                      ) : (
                        <StatusLabel bg={'danger'}>PENDING</StatusLabel>
                      )}
                    </Div>
                  </td> */}
                  <td colSpan="2">
                    <Link to={`/products/${product._id}`}>
                      <Div display="flex">
                        <Button
                          h="20px"
                          ml="10px"
                          p="2px 5px"
                          bc="transparent"
                          br="3px"
                        >
                          <Img src={verticalElipses} alt="update" />
                        </Button>
                      </Div>
                    </Link>
                  </td>
                  <td></td>
                </TableRow>
              ))}
            </tbody>
          </BTableBody>
        )}
      </ProductsTableContent>
      {!loading && products.length <= 0 && <EmptyDataUi />}
    </div>
  );
};

export default AsgardTable;
