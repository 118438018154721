import React from 'react';
import { SearchDiv, SearchInput } from './style';
import SearchIcon from '@assets/icons/search.svg';
import { Img } from '@app/globalstyles/asgard';

export default function Search() {
  return (
    <SearchDiv>
      <Img width="25px" height="25px" ml="10px" src={SearchIcon} alt="search" />
      <SearchInput placeholder="Search for raw ingredients, meals and how to prepare dishes" />
    </SearchDiv>
  );
}
