import React, { useState } from 'react';
import { SearchDiv, SearchInput } from './style';
import SearchIcon from '@assets/icons/search.svg';
import { Img } from '@app/globalstyles/asgard';

export default function MainSearch({ onSearch }) {
  const [input, setInput] = useState('');

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handlePress = (e) => {
    if (e.key === 'Enter') {
      onSearch(input);
    }
  };

  return (
    <SearchDiv>
      <SearchInput
        value={input}
        onChange={handleChange}
        onKeyPress={handlePress}
        placeholder="Search for raw ingredients, meals and how to prepare dishes"
      />
      <Img
        onClick={() => onSearch(input)}
        width="20px"
        height="20px"
        ml="10px"
        src={SearchIcon}
        alt="search"
      />
    </SearchDiv>
  );
}
