import React from 'react';
import { Container, ContainerBg, LogoArea, Menu } from './style';
import { Img } from '@app/globalstyles/asgard';
import Logo from '@assets/icons/logo.svg';
import CaancelIcon from '@assets/icons/cancel.svg';
const Sidebar = ({ open, setOpen }) => {
  return (
    <ContainerBg open={open} onClick={() => setOpen(false)}>
      <Container open={open}>
        <LogoArea>
          <Img ml="-22px" onClick={() => setOpen(false)} src={Logo} alt="close" />
          <Img onClick={() => setOpen(false)} src={CaancelIcon} alt="close" />
        </LogoArea>

        <Menu></Menu>
      </Container>
    </ContainerBg>
  );
};

export default Sidebar;
