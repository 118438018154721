import { createSelector } from '@reduxjs/toolkit';

const authData = (state = {}) => state.auth;

export const getUser = createSelector(authData, (authData) => authData.user);

export const getLoading = createSelector(
  authData,
  (authData) => authData.ui.loading
);

export const isLoginSuccessful = createSelector(
  authData,
  (authData) => authData.ui.loginsuccessful
);
