import axios from 'axios';

export let getServerUrl = () => {
  // IS_PRODUCTION, IS_STAGING are set on heroku apps
  // This is because heroku treats all apps as production
  if (process.env.REACT_APP_IS_PRODUCTION) {
    return process.env.REACT_APP_API_PROD_URL;
  } else if (process.env.REACT_APP_IS_STAGING) {
    return process.env.REACT_APP_API_STAGING_URL;
  } else {
    return process.env.REACT_APP_API_DEV_URL;
  }
};

const signUserOut = () => {
  localStorage.clear();
  window.location.href = '/login';
};

const api = axios.create({
  baseURL: getServerUrl(),
  headers: {
    Accept: 'application/json'
  }
});

api.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('x-access-token');
    if (token) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        config.headers = { Authorization: `Bearer ${token}` };
      }
    }
    return config;
  },
  (error) => {
    throw error;
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      signUserOut();
    }
    throw error;
  }
);

export default api;
