import { createSelector } from '@reduxjs/toolkit';

const mealFacts = (state = {}) => state.facts;

export const getMealFacts = createSelector(
  mealFacts,
  (mealFacts) => mealFacts.facts
);

export const getMealFactsLoading = createSelector(
  mealFacts,
  (mealFacts) => mealFacts.ui.loading
);

export const getMealFactsPageStats = createSelector(
  mealFacts,
  (mealFacts) => mealFacts.ui.stats
);

export const CreatingMealFacts = createSelector(
  mealFacts,
  (mealFacts) => mealFacts.ui.creating
);
