import React from 'react';
import LoadingDataUi from '@app/components/loading';
import { Link } from 'react-router-dom';
import { Badge } from '@design-system/Badge/Badge';
import { BTableBody } from '@app/pages/products/table/style';

const Producttable = ({ loading, partners }) => {
  return (
    <div>
      {' '}
      {loading ? (
        <LoadingDataUi />
      ) : (
        <BTableBody>
          <table style={{ width: '100%' }}>
            <thead className="table_head">
              <tr>
                <th>Business Name</th>
                <th>Type</th>
                <th>Group</th>
                <th>Approval Status</th>
                <th>Product name</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody className="table_body">
              {partners.map((data) => (
                <tr key={data?._id}>
                  <td>
                    <Link
                      to={`/partners/products/${data?.owner?._id}`}
                      style={{
                        textDecoration: 'none',
                        color: '#549a9a',
                        fontWeight: '500'
                      }}
                    >
                      {data?.owner?.business_name}
                    </Link>
                  </td>

                  <td style={{ textTransform: 'capitalize' }}>
                    {data?.owner?.type}
                  </td>
                  <td>{data?.product_group?.name}</td>
                  <td>
                    {data?.approved ? (
                      <Badge bg="success"> approved</Badge>
                    ) : (
                      <Badge bg="danger"> not approved</Badge>
                    )}
                  </td>
                  <td>
                    <Link
                      to={`/partners/products/edit/${data?._id}`}
                      style={{
                        textDecoration: 'none',
                        color: '#549a9a',
                        fontWeight: '500'
                      }}
                    >
                      {data?.name}
                    </Link>
                  </td>
                  <td>
                    {data?.weight}
                    {data?.unit}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </BTableBody>
      )}
    </div>
  );
};

export default Producttable;
