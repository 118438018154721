import { createSelector } from '@reduxjs/toolkit';

const dashboardData = (state = {}) => state.dashboard;

const NAIRA = 'NGN';
const DOLLAR = 'USD';

export const getKPIs = createSelector(
  dashboardData,
  (dashboardData) => dashboardData.kpis
);

const findRevenue = (payments, currency) => {
  const matched = payments.filter((payment) => payment._id?.short_code === currency);
  const payment = matched[0];
  let response = {
    price: 0,
    total: 0,
    symbol: currency
  };

  if (payment) {
    response = {
      symbol: payment._id.symbol,
      price: payment.price,
      total: payment.total
    };
  }

  return response;
};

export const getMonthlyNGN = createSelector(getKPIs, (kpis) => {
  return findRevenue(kpis.monthly_value, NAIRA);
});

export const getMonthlyUSD = createSelector(getKPIs, (kpis) => {
  return findRevenue(kpis.monthly_value, DOLLAR);
});

export const getTotalUSD = createSelector(getKPIs, (kpis) => {
  return findRevenue(kpis.all_value, DOLLAR);
});

export const getTotalNGN = createSelector(getKPIs, (kpis) => {
  return findRevenue(kpis.all_value, NAIRA);
});

export const getLoading = createSelector(
  dashboardData,
  (dashboardData) => dashboardData.ui.loading
);

export const getUserStatistics = createSelector(
  dashboardData,
  (dashboardData) => dashboardData.user_statistics
);

export const orderStats = createSelector(
  dashboardData,
  (dashboardData) => dashboardData.order_stats
);

export const getUserStats = createSelector(
  dashboardData,
  (dashboardData) => dashboardData.user_stats
);

export const getAllSellers = createSelector(
  dashboardData,
  (dashboardData) => dashboardData.all_sellers
);
