import { AsgardP, Button, Div, Img } from '@app/globalstyles/asgard';
import React, { useRef } from 'react';
import Colors from '@app/globalstyles/utils/colors';
import closebtn from '@app/assets/icons/cancel.svg';
import { BtnContainer, VerifyFormContainer } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { mark_customer_as_verified } from '../redux/reducer';
import { getVerifyAccountLoading } from '../redux/selectors';

function ConfirmVerify({ onCancel, onContinue, label, closeform, id }) {
  const dispatch = useDispatch();
  const verifyingAccount = useSelector(getVerifyAccountLoading);
  const closeref = useRef();

  const handleVerifyAccount = () => {
    dispatch(mark_customer_as_verified(id));
    setTimeout(() => {
      closeform();
    }, 5000);
  };

  return (
    <VerifyFormContainer>
      <Div width="100%" display="flex" justify="flex-end">
        <Button
          w="40px"
          h="40px"
          br="50%"
          ref={closeref}
          bc="transparent"
          onClick={!verifyingAccount && closeform}
          disabled={verifyingAccount}
        >
          <Img src={closebtn} alt="Close Verification" />
        </Button>
      </Div>

      <Div width="100%" mt="10px">
        <AsgardP tAlign="center">
          {label ? label : 'Are you sure, you want to verify this Account?'}
        </AsgardP>
      </Div>
      <BtnContainer>
        <Button
          w="45%"
          p="10px 0px"
          br="3px"
          bc={Colors.red}
          border={Colors.red}
          color={Colors.white}
          disabled={verifyingAccount}
          onClick={!verifyingAccount && closeform}
        >
          Cancel
        </Button>
        <Button
          w="45%"
          br="3px"
          p="10px 0px"
          border={Colors.primaryMain}
          bc={Colors.primaryMain}
          color={Colors.white}
          disabled={verifyingAccount}
          onClick={handleVerifyAccount}
        >
          {verifyingAccount ? 'Verifying...' : 'Continue'}
        </Button>
      </BtnContainer>
    </VerifyFormContainer>
  );
}

export default ConfirmVerify;
