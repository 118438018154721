import React from 'react';
import * as Yup from 'yup';
import { EditSizeFormContainer, ProductUpdateFormDiv } from '../style';
import Divider from '@app/globalstyles/dividerLine';
import {
  AsgardP,
  Button,
  Div,
  FormCloseBtnContainer,
  Img
} from '@app/globalstyles/asgard';
import {
  ProductFormLeft,
  ProductFormRight
} from '@app/components/forms/addproduct/style';

import Colors from '@app/globalstyles/utils/colors';
import {
  ProductFormBody,
  ProductFormMain
} from '@app/pages/products/addproduct/style';
import { NumberInput, SelectWeightInput } from '@app/components/forms/InputGen';
import sizeSelect from '@app/assets/icons/sizeSelect.svg';
import { Form, Formik } from 'formik';
import { MEASUREMENTS } from '@app/pages/products/filterData';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencies } from '@app/pages/currrencies/redux/selectors';
import { update_product } from '../redux/reducer';
import { CloseFormButton } from '@app/components/forms/CloseFormButton';

export const EditSizes = ({ closeform, product }) => {
  const currencies = useSelector(getCurrencies);
  const dispatch = useDispatch();

  const { sizes } = product;
  console.log(sizes);
  let initialValues = {
    sizes: product.sizes || []
  };

  const validationSchema = Yup.object().shape({
    sizes: Yup.array().min(1)
  });

  const handleCreateProduct = async (doc) => {
    const size = {
      price: {
        currency: doc.currency,
        value: doc.price
      },
      value: doc.value
    };
    let { sizes } = product;
    const newSizes = [...sizes, size];
    const data = {
      sizes: newSizes
    };
    dispatch(update_product({ id: product._id, data }));
    closeform();
  };
  return (
    <ProductUpdateFormDiv>
      <div style={{ width: '100%' }}>
        <AsgardP tAlign="center" fw="bold" fs="20px" color={Colors.blackish}>
          Edit Sizes
        </AsgardP>
        <FormCloseBtnContainer>
          <CloseFormButton onClick={closeform} />
        </FormCloseBtnContainer>
      </div>
      <Divider bg={Colors.primaryMain} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values) => handleCreateProduct(values)}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values
        }) => (
          <Form onSubmit={handleSubmit}>
            <ProductFormMain>
              {sizes?.map((size) => (
                <ProductFormBody>
                  <ProductFormLeft>
                    <EditSizeFormContainer>
                      <AsgardP mb="3px" fw="bold" color="#4F4F4F">
                        Weight
                      </AsgardP>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          gap: '2%',
                          height: '50px'
                        }}
                      >
                        <SelectWeightInput>
                          <select>
                            {MEASUREMENTS?.map((measurement, idx) => (
                              <option key={idx} value={measurement}>
                                {measurement}
                              </option>
                            ))}
                          </select>
                          <div>
                            <Img src={sizeSelect} alt="Select" />
                          </div>
                        </SelectWeightInput>
                        <NumberInput value={size?.value} thousandSeparator={true} />
                      </div>
                    </EditSizeFormContainer>
                  </ProductFormLeft>
                  <ProductFormRight>
                    <EditSizeFormContainer>
                      <AsgardP mb="3px" fw="bold" color="#4F4F4F">
                        Price
                      </AsgardP>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          gap: '2%',
                          height: '50px'
                        }}
                      >
                        <SelectWeightInput>
                          <select>
                            <option value={size?.price?.currency._id}>
                              {size?.price?.currency?.short_code}
                            </option>
                            {currencies?.map((currency) => (
                              <option key={currency._id} value={currency.id}>
                                {currency?.short_code}
                              </option>
                            ))}
                          </select>
                          <div>
                            <Img src={sizeSelect} alt="Select" />
                          </div>
                        </SelectWeightInput>
                        <NumberInput
                          value={size?.price?.value}
                          thousandSeparator={true}
                        />
                      </div>
                    </EditSizeFormContainer>
                  </ProductFormRight>
                </ProductFormBody>
              ))}
              <Div display="flex" justify="center" width="100%" mt="1.5rem">
                <Button
                  type="submit"
                  p="10px 50px"
                  bc={Colors.primaryMain}
                  br="5px"
                  mr="15px"
                  color={Colors.white}
                  hbc={Colors.blackish}
                  // onClick={handleSubmit}
                >
                  Update
                </Button>
              </Div>
            </ProductFormMain>
          </Form>
        )}
      </Formik>
    </ProductUpdateFormDiv>
  );
};

export default EditSizes;
