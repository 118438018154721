import { createSelector } from '@reduxjs/toolkit';

const groupsData = (state = {}) => state.groups;

export const getGroups = createSelector(groupsData, (groupsData) => groupsData.data);

export const getLoading = createSelector(
  groupsData,
  (groupsData) => groupsData.ui.loading
);

export const getPageStats = createSelector(
  groupsData,
  (groupsData) => groupsData.ui.stats
);

export const getIsGettingBasket = createSelector(
  groupsData,
  (groupsData) => groupsData.isGettingBasket
);

export const getBasket = createSelector(
  groupsData,
  (groupsData) => groupsData.basket
);

export const getExportData = createSelector(
  groupsData,
  (groupsData) => groupsData.export
);
