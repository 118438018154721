import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_images_success,
  get_images_error,
  get_images,
  create_image,
  create_image_success,
  create_image_error,
  delete_image,
  delete_image_success,
  delete_image_error
} from './reducers';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getImages({ payload }) {
  try {
    const { page = 1, limit = 10, searchString } = payload;
    let search = `search`;
    if (searchString) {
      search = `search=${searchString}`;
    } else {
      search = ``;
    }
    const request = yield call(
      api.get,
      `/product_images?page=${page}&limit=${limit}&${search}`
    );
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_images_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: get_images_error.type
    });
  }
}

function* createImages({ payload }) {
  try {
    const request = yield call(api.post, `/product_images`, payload);
    const responseData = request.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: create_image_success.type,
      payload: response
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: create_image_error.type
    });
  }
}

function* deleteImages({ payload }) {
  try {
    const request = yield call(api.delete, `/product_images/${payload}`);
    const responseData = request.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: delete_image_success.type,
      payload: response
    });
    window.location.href = '/akaani-drive';
  } catch (error) {
    yield put({
      type: delete_image_error.type
    });
  }
}

function* DriveSagas() {
  yield takeEvery(get_images.type, getImages);
  yield takeEvery(create_image.type, createImages);
  yield takeEvery(delete_image.type, deleteImages);
}

export default DriveSagas;
