import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_customers,
  get_customers_error,
  get_customers_success,
  create_customer,
  create_customer_error,
  create_customer_success,
  get_export,
  get_export_success,
  get_export_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getCustomersSagas({ payload }) {
  try {
    const { page = 1, limit = 10, searchString } = payload;
    let search = ``;
    if (searchString) {
      search = `search=${searchString}`;
    }
    const requestRes = yield call(
      api.get,
      `/users?user_type=customer&page=${page}&limit=${limit}&${search}`
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_customers_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_customers_error.type
    });
  }
}

function* createCustomerSagas({ payload }) {
  try {
    const requestRes = yield call(api.post, '/users/create', payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: create_customer_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: create_customer_error.type
    });
  }
}

function* getExportData({ payload }) {
  try {
    const { page = 1, limit = 1000 } = payload;
    const requestRes = yield call(
      api.get,
      `/users?user_type=customer&page=${page}&limit=${limit}&`
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_export_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_export_error.type
    });
  }
}

function* customersSagas() {
  yield takeEvery(get_customers.type, getCustomersSagas);
  yield takeEvery(create_customer.type, createCustomerSagas);
  yield takeEvery(get_export.type, getExportData);
}

export default customersSagas;
