import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    creating: false
  },
  data: {},
  product_bundle: []
};

export const recipesReducerSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    get_recipes_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    get_recipes_error: (state) => {
      state.ui.loading = false;
    },
    get_recipes: (state) => {
      state.ui.loading = true;
    },
    update_recipes_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    update_recipes_error: (state) => {
      state.ui.loading = false;
    },
    update_recipes: (state) => {
      state.ui.loading = true;
    },
    update_recipe_success: (state, { payload }) => {
      state.ui.creating = false;
      state.data = payload;
    },
    update_recipe_error: (state) => {
      state.ui.creating = false;
    },
    update_recipe: (state) => {
      state.ui.creating = true;
    },
    get_product_group: (state) => {
      state.ui.loading = true;
    },
    get_product_groups_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    get_product_groups_error: (state) => {
      state.ui.loading = false;
    },
    get_product_bundle: (state) => {
      state.ui.creating = true;
    },
    get_product_bundle_success: (state, { payload }) => {
      state.ui.creating = false;
      const { products } = payload;
      state.product_bundle = products;
    },
    get_product_bundle_error: (state) => {
      state.ui.creating = false;
    }
  }
});

export const {
  get_recipes_success,
  get_recipes_error,
  get_recipes,
  update_recipes_success,
  update_recipes_error,
  update_recipes,
  update_recipe_success,
  update_recipe_error,
  update_recipe,
  get_product_group,
  get_product_groups_error,
  get_product_groups_success,
  get_product_bundle,
  get_product_bundle_success,
  get_product_bundle_error
} = recipesReducerSlice.actions;

export default recipesReducerSlice.reducer;
