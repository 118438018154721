import { AsgardP, Div } from '@app/globalstyles/asgard';
import { BTableBody, SubscriptionTableContent, TableRow } from './style';
import { useEffect } from 'react';
import Colors from '@app/globalstyles/utils/colors';
import { get_orders } from '../redux/reducer';
import { getLoading } from '../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Link from '@design-system/Link';
import LoadingDataUi from '@app/components/loading';
import EmptyDataUi from '@app/components/emptyData';
import commaNumber from 'comma-number';

const SubscriptionTable = ({ orders, pageSize, currentPage }) => {
  // const orders = useSelector(getOrders);
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_orders({ limit: pageSize, page: currentPage }));
  }, [dispatch, currentPage, pageSize]);

  return (
    <div>
      <SubscriptionTableContent>
        {loading ? (
          <LoadingDataUi />
        ) : (
          <BTableBody>
            <tr>
              <th>Group</th>
              <th>Address</th>
              <th>Reference</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
            {orders.map((order, i) => (
              <TableRow key={(order, i)}>
                <td style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                  <Div>
                    <Link to={`groups/${order?.group?._id}`}>
                      <AsgardP ml="10px">{order?.group?.name}</AsgardP>
                    </Link>
                    <AsgardP color={Colors.grey} ml="10px" mt="-10px">
                      Updated {moment(order.updated_at).format('D')} day ago
                    </AsgardP>
                  </Div>
                </td>
                <td>{order?.delivery_address}</td>
                <td>
                  <Link to={`payments/${order?._id}`}>
                    {order?.payment_reference}
                  </Link>
                </td>
                <td>
                  {moment(order.created_at).format('LL')}
                  <AsgardP color={Colors.grey}>
                    {' '}
                    {moment(order.created_at).format('e:ee')} PM
                  </AsgardP>
                </td>
                <td>
                  {' '}
                  {order?.amount_paid?.currency?.symbol}
                  {commaNumber(order?.amount_paid?.value)}
                </td>
                <td>
                  <Link to={`order/${order?._id}`}>
                    <AsgardP ml="10px">View</AsgardP>
                  </Link>
                </td>
              </TableRow>
            ))}
          </BTableBody>
        )}
      </SubscriptionTableContent>
      {!loading && orders.length <= 0 && <EmptyDataUi />}
    </div>
  );
};

export default SubscriptionTable;
