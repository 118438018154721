import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoading, getPayment, getPageStats } from './redux/selectors';
import Pagination from '@app/components/pagination';
import TableFeatures from '@app/components/tablefeatures';
import { PaymentsContainer } from './style';
import PaymentTable from './table';
import { get_payment } from './redux/reducer';

function PaymentsPage() {
  const paymentsFromReducer = useSelector(getPayment);
  const loading = useSelector(getLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const pageStats = useSelector(getPageStats);
  const [payments, setPayments] = useState([]);

  const dispatch = useDispatch();

  const handleChange = (value) => {
    setSearchString(value);
  };

  const getNewPayments = (page, limit) => {
    dispatch(get_payment({ page, limit }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewPayments(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewPayments(1, pageSize);
  };

  useEffect(() => {
    setPayments(paymentsFromReducer);
  }, [paymentsFromReducer]);

  return (
    <div>
      <PaymentsContainer>
        <TableFeatures onSearch={handleChange} title="All Payments" addbtn={false} />

        <PaymentTable
          payments={payments}
          currentPage={currentPage}
          pageSize={pageSize}
          searchString={searchString}
        />
        {!loading && (
          <Pagination
            onChangePageSize={handlePageSizeChange}
            itemsCount={pageStats.docs}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </PaymentsContainer>
    </div>
  );
}

export default PaymentsPage;
