export const FilterData = [
  {
    id: 1,
    title: 'Approved',
    value: 'approved=true'
  },

  {
    id: 2,
    title: 'Unpproved',
    value: 'approved=false'
  },
  {
    id: 3,
    title: 'In Stock',
    value: 'in_stock=true'
  },
  {
    id: 4,
    title: 'Not In Stock',
    value: 'in_stock=false'
  }
];

export const MEASUREMENTS = ['g', 'kg', 'ml', 'l', 'pieces'];

export const CONTAINER_TYPES = [
  '1 kg bag',
  '2kg bag',
  'Ziplock medium',
  'Ziplock large',
  'Produce bag',
  'Net produce bag',
  'Ziplock small',
  'Maggi Nylon [Sealing]',
  'Packaging bag L',
  'Packaging bag M',
  '1 litre bowl',
  '500ml bowl',
  '300ml bowl',
  '60ml bowl',
  'Pepper Mix Nylon'
];
