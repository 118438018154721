import React from 'react';
import { FormContainer } from '../style';
import { getId, getMode, getUploadingRecipes } from '../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Details from './Details';
import Ingredients from './ingredients';
import SelectImages from './SelectImages';
import { edit_recipe } from '../redux/reducer';

export const ReviewRecipe = ({
  next = () => {},
  initialValues,
  prev,
  loading = false
}) => {
  const uploading = useSelector(getUploadingRecipes);
  const mode = useSelector(getMode);
  const id = useSelector(getId);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    let ingredients = Object.values(uploading?.ingredients);
    let instructions = Object.values(uploading?.instructions);
    let other_ingredients = Object.values(uploading?.other_ingredients);
    const firstIngredient = other_ingredients[0];
    if (firstIngredient?.food_item === '') {
      other_ingredients = [];
    }

    if (!mode) {
      next(
        {
          ...uploading,
          ingredients,
          instructions,
          other_ingredients
        },
        true
      );
    }
  };

  const handleEdit = async () => {
    let ingredients = Object.values(uploading?.ingredients);
    let instructions = Object.values(uploading?.instructions);
    let other_ingredients = Object.values(uploading?.other_ingredients);
    const firstIngredient = other_ingredients[0];
    if (firstIngredient.food_item === '') {
      other_ingredients = [];
    }
    let data = {
      ...uploading,
      ingredients,
      instructions,
      other_ingredients
    };
    dispatch(edit_recipe({ data, mode, id }));
  };

  return (
    <div id="closed">
      <FormContainer>
        <Details />
        <Ingredients />
        <SelectImages />
        <div className="button_container">
          {mode ? (
            <div className="inner_container">
              <button type="submit" onClick={handleEdit}>
                {loading ? 'Editing...' : 'Submit'}
              </button>
            </div>
          ) : (
            <div className="inner_container">
              <button className="back_button" onClick={prev}>
                Back
              </button>
              <button onClick={handleSubmit}>
                {loading ? 'Creating...' : 'Submit'}
              </button>
            </div>
          )}
        </div>
      </FormContainer>
    </div>
  );
};

export default ReviewRecipe;
