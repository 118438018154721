import React, { useEffect, useState } from 'react';
import { FormsModal, Img } from '@app/globalstyles/asgard';
import AsgardTable from '@app/pages/products/table';
import {
  FilterDiv,
  FilterOption,
  FilterSelectDiv,
  FormContainerProducts,
  ProductsContainer,
  ProductsMainDiv,
  SortFilterSelect,
  SwitchProductFormTab,
  ToggleFormBtnProduct
} from './style';
import TableFeatures from '@app/components/tablefeatures';
import ArrowTriangleDown from '@assets/icons/filter_downarrow.svg';
// import { useQuery } from 'react-query';
// import { getAllProducts } from '@app/services/productService';
import AddProductForm from './addproduct';
import { useDispatch, useSelector } from 'react-redux';
import EmptyDataUi from '@app/components/emptyData';
import {
  getLoading,
  getPageStats,
  getProducts,
  getAllProducts
} from './redux/selectors';
import Pagination from '@app/components/pagination';
import { FilterData } from './filterData';
// import FilterAndSort from '@app/components/filter';
// import { FilterData } from './filterData';
import { get_all_products, get_products } from './redux/reducer';
// import AddMealPack from './addmealpack';

const { Parser } = require('json2csv');
const fileDownload = require('js-file-download');
const fields = ['Name', 'Price', 'Product_Category', 'Sub_Category'];
const json2csvParser = new Parser({ fields });

function ProductPage() {
  const productsFromReducer = useSelector(getProducts);
  const allProducts = useSelector(getAllProducts);
  const loading = useSelector(getLoading);
  const [showForm, setShowForm] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [formtab, setFormTab] = useState('product');
  const pageStats = useSelector(getPageStats);
  const [selectedfilter, setSelectedFilter] = useState('');
  const [searchString, setSearchString] = useState('');
  const dispatch = useDispatch();

  const handleShowProductForm = () => {
    setShowForm(!showForm);
  };

  useEffect(() => {
    dispatch(get_all_products());
  }, [dispatch]);

  const Download = () => {
    let tempProducts = [];
    let productsString = ``;

    allProducts.forEach((product) => {
      product?.sub_products?.forEach(
        (product) => (productsString += `${product.name},`)
      );
      let obj = {
        Name: product.name,
        Price: product?.price?.currency?.short_code + product.price.value,
        Product_Group: product.product_group.name,
        Sub_Category: productsString
      };
      tempProducts.push(obj);
    });
    const csv = json2csvParser.parse(tempProducts);
    fileDownload(csv, 'Products.csv');
  };

  const handleChange = (value) => {
    setSearchString(value);
  };

  const getNewProducts = (page, limit) => {
    dispatch(get_products({ page, limit, selectedfilter }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewProducts(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewProducts(1, pageSize);
  };

  const handleSelectFilter = (e) => {
    setSelectedFilter(e.target.value);
    setCurrentPage(1);
  };
  function StopBubbling(e) {
    e.stopPropagation();
  }
  useEffect(() => {
    setProducts(productsFromReducer);
  }, [productsFromReducer, selectedfilter]);
  return (
    <div>
      <ProductsContainer>
        <ProductsMainDiv>
          <TableFeatures
            title="Products"
            createBtnLabel="Create Product"
            onSearch={handleChange}
            addbtn={true}
            onCreateBtn={handleShowProductForm}
          />
          <FilterDiv>
            <button className="save_btn" onClick={Download}>
              Save
            </button>
            <FilterSelectDiv>
              <SortFilterSelect>
                <select>
                  <FilterOption value="">Country</FilterOption>
                  <FilterOption value="?country=NG">Nigeria</FilterOption>
                  <FilterOption
                    active={selectedfilter === '?approved=false'}
                    value="?country=US"
                  >
                    Untied States
                  </FilterOption>
                </select>
                <div>
                  <Img src={ArrowTriangleDown} alt="select" />
                </div>
              </SortFilterSelect>
              <SortFilterSelect>
                <select onChange={handleSelectFilter}>
                  <FilterOption active={selectedfilter === ''} value="">
                    All Products
                  </FilterOption>
                  {FilterData.map((filter) => (
                    <FilterOption
                      key={filter.id}
                      active={selectedfilter === filter.value}
                      value={filter.value}
                    >
                      {filter.title}
                    </FilterOption>
                  ))}
                </select>
                <div>
                  <Img src={ArrowTriangleDown} alt="select" />
                </div>
              </SortFilterSelect>
            </FilterSelectDiv>
          </FilterDiv>
          {showForm && (
            <FormsModal show={showForm} onClick={handleShowProductForm}>
              <FormContainerProducts onClick={StopBubbling}>
                <SwitchProductFormTab>
                  <ToggleFormBtnProduct
                    active={formtab === 'product'}
                    onClick={() => setFormTab('product')}
                  >
                    Create Product
                  </ToggleFormBtnProduct>
                  {/* <ToggleFormBtnProduct
                    active={formtab === 'meal'}
                    onClick={() => setFormTab('meal')}
                  >
                    Create Pack
                  </ToggleFormBtnProduct> */}
                </SwitchProductFormTab>
                {/* {formtab === 'meal' && <AddMealPack />} */}
                {formtab === 'product' && (
                  <AddProductForm closeform={handleShowProductForm} />
                )}
              </FormContainerProducts>
            </FormsModal>
          )}
          {!loading && products?.length <= 0 ? (
            <EmptyDataUi />
          ) : (
            <AsgardTable
              products={products}
              selectedfilter={selectedfilter}
              currentPage={currentPage}
              pageSize={pageSize}
              searchString={searchString}
            />
          )}
        </ProductsMainDiv>
        {!loading && (
          <Pagination
            onChangePageSize={handlePageSizeChange}
            itemsCount={pageStats.docs}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </ProductsContainer>
    </div>
  );
}

export default ProductPage;
