import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  margin-top: 24px;

  .link {
    text-decoration: none;
    color: #605d66;
  }

  .order_title {
    font-size: 18px;
    font-weight: 700;
  }

  .header {
    border-bottom: 1px solid #d9d8da;
    padding-bottom: 16px;
  }

  .header_content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-content: baseline;
  }

  .header_text {
    font-size: 20px;
    font-weight: 700;
  }

  .tracking {
    color: #fff;
    background: #549a9a;
    font-weight: 700;
    font-size: 16px;
    border-radius: 5px;
    padding: 4px;
    width: auto;
    box-sizing: border-box;
    text-align: center;
  }

  .order_date {
    margin-top: 10px;
    color: #605d66;
  }

  .amount_paid {
    margin-top: 10px;
    color: #000;
    font-weight: 700;
    text-align: right;
  }

  .body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  .line {
    border-bottom: 1px solid #d9d8da;
  }

  .label {
    font-size: 18px;
    margin-top: 24px;
    font-weight: 700;
  }

  .fee {
    font-size: 16px;
    font-weight: 700;
    color: #605d66;
    margin-top: 8px;
  }

  .title {
    margin-top: 8px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
  }

  .sub_title {
    text-transform: capitalize;
  }
`;

export const Card = styled.div`
  margin-bottom: 16px;
  .order_box {
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    gap: 8px;
    align-items: center;

    .order_box_container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
    }

    .image {
      width: auto;
      height: 5rem;
      object-fit: cover;
      border: 1px solid #d9d8da;
      border-radius: 5px;
    }

    .name {
      font-size: 14px;
    }

    .price {
      color: #000;
      font-weight: 700;
      font-size: 18px;
    }

    .quantity {
      color: #605d66;
      font-size: 12px;
      text-align: right;
      margin-top: 8px;
    }
  }
`;
