import { createSelector } from '@reduxjs/toolkit';

const currenciesData = (state = {}) => state.currencies;

export const getCurrencies = createSelector(
  currenciesData,
  (currenciesData) => currenciesData.data
);

export const getLoading = createSelector(
  currenciesData,
  (currenciesData) => currenciesData.ui.loading
);
