import TableFeatures from '@app/components/tablefeatures';
import { FormsModal } from '@app/globalstyles/asgard';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AddProductForm from '../products/addproduct';
import {
  FormContainerProducts,
  SwitchProductFormTab,
  ToggleFormBtnProduct
} from '../products/style';
import OtherDetails from './containers/OtherDetails';
import ProductDetails from './containers/ProductDetails';
import Sizes from './containers/Sizes';
// import SizingDetails from './containers/Sizing';
import SubProducts from './containers/SubProducts';
import { get_product } from './redux/reducer';
import { getLoading, getProduct } from './redux/selectors';

const Product = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [formtab, setFormTab] = useState('product');
  const loading = useSelector(getLoading);
  const product = useSelector(getProduct);
  const params = useParams();

  const handleShowProductForm = () => {
    setShowForm(!showForm);
  };

  function StopBubbling(e) {
    e.stopPropagation();
  }
  useEffect(() => {
    dispatch(get_product(params.id));
  }, [dispatch, params]);
  return (
    <>
      {showForm && (
        <FormsModal show={showForm} onClick={handleShowProductForm}>
          <FormContainerProducts onClick={StopBubbling}>
            <SwitchProductFormTab>
              <ToggleFormBtnProduct
                active={formtab === 'product'}
                onClick={() => setFormTab('product')}
              >
                Create Product
              </ToggleFormBtnProduct>
            </SwitchProductFormTab>

            {formtab === 'product' && (
              <AddProductForm closeform={handleShowProductForm} />
            )}
          </FormContainerProducts>
        </FormsModal>
      )}
      <TableFeatures
        title="Products"
        createBtnLabel="Create Product"
        searchFiled={false}
        addbtn={true}
        onCreateBtn={handleShowProductForm}
      />
      <div style={{ paddingLeft: 20, marginRight: 30 }}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <ProductDetails product={product} />
            {/* <SizingDetails product={product} /> */}
            <Sizes product={product} />
            <SubProducts product={product} />
            <OtherDetails product={product} />
          </>
        )}
      </div>
      <br />
    </>
  );
};

export default Product;
