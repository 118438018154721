import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_users_stats_error,
  get_users_stats_success,
  get_groups_stats_error,
  get_groups_stats_success,
  get_stats,
  get_payment_stats_error,
  get_payment_stats_success,
  get_user_statistics,
  get_user_statistics_success,
  get_user_statistics_error,
  get_order_stat,
  get_order_stat_success,
  get_order_stat_error,
  get_all_sellers_success,
  get_all_sellers_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getUsersStatsSaga({ payload }) {
  try {
    const requestRes = yield call(api.get, '/users/stats', payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_users_stats_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: get_users_stats_error.type
    });
  }
}

function* getGroupsStatsSaga() {
  try {
    const requestRes = yield call(api.get, '/baskets/stats');
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_groups_stats_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: get_groups_stats_error.type
    });
  }
}

function* getPaymentStatsSaga() {
  try {
    const requestRes = yield call(api.get, '/payments/stats');
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_payment_stats_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: get_payment_stats_error.type
    });
  }
}

function* getUserStatisticsSaga() {
  try {
    const requestRes = yield call(api.get, '/users/statistics');
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_user_statistics_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: get_user_statistics_error.type
    });
  }
}

function* getOrderStatSaga() {
  try {
    const requestRes = yield call(api.get, '/orders/stats');
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_order_stat_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: get_order_stat_error.type
    });
  }
}

function* getAllSellers() {
  try {
    const request = yield call(api.get, `/partners?limit=1000`);
    const requestRes = request.data;
    const response = requestRes.data;
    yield put({
      type: get_all_sellers_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: get_all_sellers_error.type
    });
  }
}

function* dashboardSagas() {
  yield takeEvery(get_stats.type, getUsersStatsSaga);
  yield takeEvery(get_stats.type, getGroupsStatsSaga);
  yield takeEvery(get_stats.type, getPaymentStatsSaga);
  yield takeEvery(get_user_statistics.type, getUserStatisticsSaga);
  yield takeEvery(get_order_stat.type, getOrderStatSaga);
  yield takeEvery(get_stats.type, getAllSellers);
}

export default dashboardSagas;
