import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_basket,
  get_basket_error,
  get_basket_success,
  get_groups,
  get_groups_error,
  get_groups_success,
  get_export,
  get_export_success,
  get_export_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getGroupsSagas({ payload }) {
  try {
    const { page = 1, limit = 10, selectedfilter, searchString } = payload;
    let search = `search`;
    if (searchString) {
      search = `search=${searchString}`;
    } else {
      search = ``;
    }

    const requestRes = yield call(
      api.get,
      `/baskets?${selectedfilter}&page=${page}&limit=${limit}&${search}`
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_groups_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_groups_error.type
    });
  }
}

function* getBasketDetails({ payload }) {
  try {
    const requestRes = yield call(api.get, `basket_products/basket/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_basket_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_basket_error.type
    });
  }
}

function* getExportSagas({ payload }) {
  try {
    const { page = 1, limit = 1000 } = payload;

    const requestRes = yield call(api.get, `/baskets?page=${page}&limit=${limit}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_export_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_export_error.type
    });
  }
}

function* groupsSagas() {
  yield takeEvery(get_groups.type, getGroupsSagas);
  yield takeEvery(get_export.type, getExportSagas);
  yield takeEvery(get_basket.type, getBasketDetails);
}

export default groupsSagas;
