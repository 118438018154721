import { createSelector } from '@reduxjs/toolkit';

const orderData = (state = {}) => state.orders;

export const getOrders = createSelector(
  orderData,

  (orderData) => orderData.data
);

export const getSingleOrder = createSelector(orderData, (data) => data.details);

export const getLoading = createSelector(
  orderData,
  (orderData) => orderData.ui.loading
);

export const getPageStats = createSelector(
  orderData,
  (orderData) => orderData.ui.stats
);
