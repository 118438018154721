import React, { useEffect, useState } from 'react';
import { GroupContainer } from './style';
import GroupsTable from './table';
import TableFeatures from '@app/components/tablefeatures';
import {
  getGroups,
  getLoading,
  getPageStats,
  getExportData
} from './redux/selectors';
import Pagination from '@app/components/pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  FilterDiv,
  FilterOption,
  FilterSelectDiv,
  SortFilterSelect
} from '../products/style';
import { Img } from '@app/globalstyles/asgard';
import { BasketFilterData } from './basketFilterData';
import ArrowTriangleDown from '@assets/icons/filter_downarrow.svg';
import { get_export } from './redux/reducer';
import moment from 'moment';

const { Parser } = require('json2csv');
const fileDownload = require('js-file-download');
const fields = [
  'Customer',
  'Partner',
  'Price',
  'Total Products',
  'Date Created',
  'Weight'
];
const json2csvParser = new Parser({ fields });

function GroupPage() {
  const groupsFromReducer = useSelector(getGroups);
  const loading = useSelector(getLoading);
  const [selectedfilter, setSelectedFilter] = useState('');
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageStats = useSelector(getPageStats);
  const [searchString, setSearchString] = useState('');
  const [groups, setGroups] = useState([]);
  const exportData = useSelector(getExportData);
  const dispatch = useDispatch();

  const handleShowMealForm = () => {
    setShow(!show);
  };

  const handleChange = (value) => {
    setSearchString(value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
  };

  const handleSelectFilter = (e) => {
    setSelectedFilter(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    setGroups(groupsFromReducer);
  }, [groupsFromReducer, selectedfilter]);

  useEffect(() => {
    dispatch(
      get_export({
        page: 1,
        limit: 1000
      })
    );
  }, [dispatch]);

  const download = () => {
    let temp = [];
    exportData.forEach((basket) => {
      let obj = {
        Customer: basket?.creator?.first_name + ' ' + basket?.creator?.last_name,
        Partner: basket?.partner?.business_name,
        Price: basket?.currency?.short_code + basket?.total_price || 0,
        'Total Products': basket?.all_products,
        'Date Created': moment(basket?.created_at).format('LL'),
        Weight: basket?.total_weight
      };
      temp.push(obj);
    });
    const csv = json2csvParser.parse(temp);
    fileDownload(csv, 'Baskets.csv');
  };

  return (
    <div>
      <GroupContainer>
        <TableFeatures
          onSearch={handleChange}
          title="Baskets"
          addbtn={false}
          onCreateBtn={handleShowMealForm}
          exportBtn={true}
        />
        <FilterDiv>
          <FilterSelectDiv>
            <SortFilterSelect>
              <select onChange={handleSelectFilter}>
                {BasketFilterData.map((filter) => (
                  <FilterOption
                    key={filter.id}
                    active={selectedfilter === filter.value}
                    value={filter.value}
                  >
                    {filter.title}
                  </FilterOption>
                ))}
              </select>
              <div>
                <Img src={ArrowTriangleDown} alt="select" />
              </div>
            </SortFilterSelect>
          </FilterSelectDiv>
          <button className="save_btn" onClick={download}>
            Export{' '}
          </button>
        </FilterDiv>
        <GroupsTable
          groups={groups}
          currentPage={currentPage}
          pageSize={pageSize}
          selectedfilter={selectedfilter}
          searchString={searchString}
        />
        {!loading && (
          <Pagination
            onChangePageSize={handlePageSizeChange}
            itemsCount={pageStats.docs}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </GroupContainer>
    </div>
  );
}

export default GroupPage;
