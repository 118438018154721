import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { AsgardP, Button, Div, Img } from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';
import {
  ActionBtnDiv,
  ProductFormMain
} from '../../../components/forms/addproduct/style';
import { AutoCompDiv, IngredInputDiv, CustomerFormContainer } from './style';
import { addIcon } from '../../../components/re-usedicons';
import Divider from '@app/globalstyles/dividerLine';
import { CustomInput } from '../../../components/forms/InputGen';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getLoading } from '../redux/selectors';
import { create_customer } from '../redux/reducer';
import { getCountries } from 'country-state-picker';
import SelectInputP from './select';

function AddCustomer({ closeform }) {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required'),
    password: Yup.string()
  });
  const countries = getCountries();

  let initialValuesDb = {
    first_name: '',
    email: '',
    last_name: '',
    password: 'TemporaryPassword'
  };

  const handleUpdateUser = async (doc) => {
    dispatch(create_customer(doc));
    closeform();
  };

  return (
    <CustomerFormContainer>
      <Div width="100%" display="flex" justify="center">
        <AsgardP fw="600" color={Colors.blackish} tAlign="center">
          Add Customer
        </AsgardP>
      </Div>
      <Divider bg={Colors.primaryMain} />
      <Formik
        initialValues={initialValuesDb}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values) => handleUpdateUser(values)}
      >
        {({ handleChange, handleSubmit, errors, setFieldTouched, touched }) => (
          <Form onSubmit={handleSubmit}>
            <ProductFormMain>
              <AutoCompDiv>
                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>First Name</AsgardP>
                  <CustomInput
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    onBlur={() => setFieldTouched('first_name')}
                    onChange={handleChange('first_name')}
                  />
                </IngredInputDiv>
                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Country</AsgardP>
                  <SelectInputP
                    name="country"
                    onBlur={() => setFieldTouched('country')}
                    onChange={handleChange('country')}
                    label="Select Country"
                  >
                    {countries.map((country) => (
                      <option key={country.code} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </SelectInputP>
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Last Name</AsgardP>
                  <CustomInput
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    onBlur={() => setFieldTouched('last_name')}
                    onChange={handleChange('last_name')}
                  />
                </IngredInputDiv>
                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>City</AsgardP>
                  <CustomInput
                    type="text"
                    name="city"
                    placeholder="City"
                    onBlur={() => setFieldTouched('city')}
                    onChange={handleChange('city')}
                  />
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Email</AsgardP>
                  <CustomInput
                    type="email"
                    name="email"
                    placeholder="email"
                    onBlur={() => setFieldTouched('email')}
                    onChange={handleChange('email')}
                  />
                </IngredInputDiv>
                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Phone</AsgardP>
                  <CustomInput
                    type="phone"
                    name="phone"
                    placeholder="Enter Phone"
                    onBlur={() => setFieldTouched('phone')}
                    onChange={handleChange('phone')}
                  />
                </IngredInputDiv>
              </AutoCompDiv>
              <ActionBtnDiv>
                <Button
                  type="submit"
                  p="10px 30px"
                  bc={Colors.primaryMain}
                  br="5px"
                  mr="15px"
                  color={Colors.white}
                  onClick={handleSubmit}
                  hbc={Colors.blackish}
                >
                  <Img src={addIcon} alt="product-btn" /> Create
                </Button>
                <Button
                  type="button"
                  disabled={loading}
                  onClick={closeform}
                  p="10px 30px"
                  bc={Colors.red}
                  br="5px"
                  color={Colors.white}
                  hbc={Colors.blackish}
                >
                  Cancel
                </Button>
              </ActionBtnDiv>
            </ProductFormMain>
          </Form>
        )}
      </Formik>
    </CustomerFormContainer>
  );
}

export default AddCustomer;
