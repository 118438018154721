import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    loadingMealProducts: false,
    loadingCreateMeal: false,
    stats: {
      pageCount: 1
    }
  },
  data: [],
  mealPackProducts: [],
  allProducts: []
};

export const productsReducerSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    get_products_success: (state, { payload }) => {
      state.ui.loading = false;
      const { products, stats } = payload;
      state.data = products;
      state.ui.stats = {
        ...stats
      };
    },
    get_products_error: (state) => {
      state.ui.loading = false;
    },
    get_products: (state) => {
      state.ui.loading = true;
    },
    get_all_products_success: (state, { payload }) => {
      state.ui.loading = false;
      const { products } = payload;
      state.allProducts = products;
    },
    get_all_products_error: (state) => {
      state.ui.loading = false;
    },
    get_all_products: (state) => {
      state.ui.loading = true;
    },
    get_meal_products_success: (state, { payload }) => {
      state.ui.loadingMealProducts = false;
      const { products } = payload;
      state.mealPackProducts = products;
    },
    get_meal_products_error: (state) => {
      state.ui.loadingMealProducts = false;
    },
    get_meal_products: (state) => {
      state.ui.loadingMealProducts = true;
    },
    create_product_success: (state, { payload }) => {
      state.ui.loadingCreateMeal = false;
      state.data.unshift(payload);
    },
    create_product_error: (state) => {
      state.ui.loadingCreateMeal = false;
    },
    create_product: (state) => {
      state.ui.loadingCreateMeal = true;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_products,
  get_products_error,
  get_products_success,
  get_all_products_success,
  get_all_products_error,
  get_all_products,
  get_meal_products,
  get_meal_products_error,
  get_meal_products_success,
  create_product,
  create_product_error,
  create_product_success
} = productsReducerSlice.actions;

export default productsReducerSlice.reducer;
