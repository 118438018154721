import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    stats: {
      pageCount: 1
    }
  },
  data: [],
  order: []
};

export const paidBasketsReducerSlice = createSlice({
  name: 'baskets',
  initialState,
  reducers: {
    get_baskets_success: (state, { payload }) => {
      state.ui.loading = false;
      const { orders, stats } = payload;

      state.data = orders;
      state.ui.stats = {
        ...stats
      };
    },
    get_baskets_error: (state) => {
      state.ui.loading = false;
    },
    get_baskets: (state) => {
      state.ui.loading = true;
    },
    get_order_success: (state, { payload }) => {
      state.ui.loading = false;
      state.order = payload;
    },
    get_order_error: (state) => {
      state.ui.loading = false;
    },
    get_order: (state) => {
      state.ui.loading = true;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_baskets,
  get_baskets_error,
  get_baskets_success,
  get_order_success,
  get_order_error,
  get_order,
  get_partner_location,
  get_partner_location_success,
  get_partner_location_error
} = paidBasketsReducerSlice.actions;

export default paidBasketsReducerSlice.reducer;
