import { activeNextInd, inActiveNextInd } from '@app/components/re-usedicons';
import { AsgardH, AsgardP, Button, Div, Img } from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';
import React, { useState, useEffect } from 'react';
import RevenueCharts from './chart';
// import RevenueCharts from "./chart"
import {
  RevStatsContainer,
  Statstab,
  StatstabContent,
  CurrencyButton,
  CurrencyButtonDiv
} from './style';
import { useDispatch, useSelector } from 'react-redux';
import { get_user_statistics, get_order_stat } from '../../redux/reducer';
import { getUserStatistics, orderStats } from '../../redux/selectors';
import moment from 'moment';

function RevenueStats({ currency, onNGN, onUSD }) {
  const [dashboard, setDashboard] = useState('monthlyUser');
  const dispatch = useDispatch();
  const user_statistics = useSelector(getUserStatistics);
  const order = useSelector(orderStats);
  const [userStat, setUserStat] = useState([]);
  const [orderStat, setOrderStat] = useState([]);

  const currentDate = new Date();

  useEffect(() => {
    dispatch(get_user_statistics());
    dispatch(get_order_stat());
  }, [dispatch]);

  useEffect(() => {
    const months = [
      '',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    let arr = [];
    months.forEach((item, i) => {
      const match = user_statistics?.monthlyUsers?.find((item2) => {
        return i === item2.month;
      });
      let data = {
        name: item,
        amt: user_statistics?.totalUsers,
        pv: match?.total || 0,
        uv: match?.total || 0
      };
      arr.push(data);
    });
    const filter = arr.filter((data) => {
      return data.name !== '';
    });
    setUserStat(filter);
  }, [user_statistics]);

  useEffect(() => {
    const months = [
      '',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    let arr = [];
    months.forEach((item, i) => {
      const match = order?.orderStats?.find((item2) => {
        return i === item2._id;
      });
      let data = {
        name: item,
        amt: order?.totalOrders,
        pv: match?.total || 0,
        uv: match?.total || 0
      };
      arr.push(data);
    });
    setOrderStat(arr);
  }, [order]);

  return (
    <RevStatsContainer>
      <Statstab>
        <Div width="100%" display="flex" justify="space-between" alignI="center">
          <AsgardH>Revenue Stats</AsgardH>
          <CurrencyButtonDiv>
            <CurrencyButton
              active={dashboard === 'monthlyUser' ? true : false}
              onClick={() => setDashboard('monthlyUser')}
            >
              User
            </CurrencyButton>
            <CurrencyButton
              active={dashboard === 'monthlyRevenue' ? true : false}
              onClick={() => setDashboard('monthlyRevenue')}
            >
              Rev.
            </CurrencyButton>
          </CurrencyButtonDiv>
          <CurrencyButtonDiv>
            <CurrencyButton
              active={currency === 'NGN' ? true : false}
              onClick={onNGN}
            >
              NGN
            </CurrencyButton>
            <CurrencyButton
              active={currency === 'USD' ? true : false}
              onClick={onUSD}
            >
              USD
            </CurrencyButton>
          </CurrencyButtonDiv>
        </Div>
        <StatstabContent>
          <AsgardP color={Colors.grey}>
            as of {moment(currentDate).format('D MMMM YYYY, HH:mm A')}{' '}
          </AsgardP>
          <Div display="flex">
            <Button bc="transparent" color={Colors.grey} cursor="pointer" mr="10px">
              {' '}
              <Div display="flex" justify="center" alignI="center">
                <Img mr="8px" src={activeNextInd} alt="active" />
                Today
              </Div>
            </Button>
            <Button bc="transparent" color={Colors.grey}>
              <Div display="flex" justify="center" alignI="center">
                <Img mr="8px" src={inActiveNextInd} alt="inactive" /> Previously
              </Div>
            </Button>
          </Div>
        </StatstabContent>
        <RevenueCharts dashboard={dashboard} stat={userStat} order={orderStat} />
      </Statstab>
    </RevStatsContainer>
  );
}

export default RevenueStats;
