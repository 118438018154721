import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import GroupDetails from './containers/GroupDetails';
import Members from './containers/Members';
import { get_group } from './redux/reducer';
import { getLoading, getGroup } from './redux/selectors';
import {
  GroupHeader,
  GroupContainer,
  TabGroup,
  GroupDetailsContainer,
  GroupBody
} from './style';
import Basket from './containers/Basket';
import Transactions from './containers/Transactions';
import Tracking from './Tracking/Tracking';

const Group = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const group = useSelector(getGroup);
  const params = useParams();
  const [key, setKey] = useState('basket');

  const Details = () => {
    return (
      <>
        <GroupDetails group={group} />
      </>
    );
  };

  const getActiveView = () => {
    switch (key) {
      case 'members':
        return <Members group={group} />;
      case 'basket':
        return <Basket />;
      case 'transactions':
        return <Transactions />;
      case 'overview':
        return <Details />;
      default:
        return <Basket />;
    }
  };

  useEffect(() => {
    dispatch(get_group(params.id));
  }, [dispatch, params.id]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <GroupBody>
          <GroupContainer>Baskets</GroupContainer>
          <GroupHeader>
            <div className="group_title">Baskets</div>
            <div className="group_name"> {group.name}</div>
          </GroupHeader>

          <GroupDetailsContainer>
            <TabGroup>
              <div
                className={`${key === 'members' ? 'active' : ''} tab`}
                onClick={() => setKey('members')}
              >
                Members
              </div>
              <div
                className={`${key === 'basket' ? 'active' : ''} tab`}
                onClick={() => setKey('basket')}
              >
                Basket
              </div>
              <div
                className={`${key === 'transactions' ? 'active' : ''} tab`}
                onClick={() => setKey('transactions')}
              >
                Transaction
              </div>
              <div
                className={`${key === 'overview' ? 'active' : ''} tab`}
                onClick={() => setKey('overview')}
              >
                Overview
              </div>
              <div className="content">{getActiveView()}</div>
            </TabGroup>
            <aside>
              <Tracking group={group} />
            </aside>
            {/* <Tracking /> */}
          </GroupDetailsContainer>
        </GroupBody>
      )}
    </div>
  );
};

export default Group;
