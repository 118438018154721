import { createSelector } from '@reduxjs/toolkit';

const groupsData = (state = {}) => state.product_groups;

export const getProductGroups = createSelector(groupsData, (data) => data.data);

export const getLoading = createSelector(groupsData, (data) => data.ui.loading);

export const getCreating = createSelector(groupsData, (data) => data.ui.creating);

export const getSingleProductGroup = createSelector(
  groupsData,
  (data) => data.product_group
);

export const getPageStats = createSelector(
  groupsData,
  (groupsData) => groupsData.ui.stats
);
