import styled from 'styled-components/macro';
import commaNumber from 'comma-number';
import { AsgardH, AsgardP } from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';
import Divider from '@app/globalstyles/dividerLine';
import { useSelector } from 'react-redux';
import {
  getMonthlyNGN,
  getMonthlyUSD,
  getTotalNGN,
  getTotalUSD
} from '../../redux/selectors';

const AnalyticsContainer = styled.div`
  display: grid;
  /* place-items: center; */
  grid-template-columns: repeat(1, 100%);
  grid-gap: 20px;
  ${media.smallDesktopMinimum`
    grid-template-columns: repeat(2, 49%);
    `}
  ${media.tablet`
    grid-template-columns: repeat(2, 48.5%);
    `}
	${media.mobile`
    grid-template-columns: repeat(1, 100%);
    `}
`;
const CardAnalytics = styled.div`
  height: 70px;
  width: 100%;
  background: ${Colors.white};
`;

function RevenueAnalytics({ currency }) {
  const monthlyUSD = useSelector(getMonthlyUSD);
  const monthlyNGN = useSelector(getMonthlyNGN);
  const totalNGN = useSelector(getTotalNGN);
  const totalUSD = useSelector(getTotalUSD);

  return (
    <AnalyticsContainer>
      {currency === 'USD' && (
        <CardAnalytics>
          <AsgardP tAlign="center" color={Colors.grey}>
            This Month, USD
          </AsgardP>
          <AsgardH fs="18px" tAlign="center" mt="-10px" color={Colors.blackish}>
            {monthlyUSD.symbol}
            {commaNumber(monthlyUSD.price)} - {monthlyUSD.total}
          </AsgardH>
          <Divider />
        </CardAnalytics>
      )}
      {currency === 'NGN' && (
        <CardAnalytics>
          <AsgardP tAlign="center" color={Colors.grey}>
            This Month, NGN
          </AsgardP>
          <AsgardH fs="18px" tAlign="center" mt="-10px" color={Colors.blackish}>
            {monthlyNGN.symbol}
            {commaNumber(monthlyNGN.price)} - {monthlyNGN.total}
          </AsgardH>
          <Divider />
        </CardAnalytics>
      )}
      {currency === 'USD' && (
        <CardAnalytics>
          <AsgardP tAlign="center" color={Colors.grey}>
            All Time, USD
          </AsgardP>
          <AsgardH fs="18px" tAlign="center" mt="-10px" color={Colors.blackish}>
            {totalUSD.symbol}
            {commaNumber(totalUSD.price)} - {totalUSD.total}
          </AsgardH>
          <Divider />
        </CardAnalytics>
      )}
      {currency === 'NGN' && (
        <CardAnalytics>
          <AsgardP tAlign="center" color={Colors.grey}>
            All Time, NGN
          </AsgardP>
          <AsgardH fs="18px" tAlign="center" mt="-10px" color={Colors.blackish}>
            {totalNGN.symbol}
            {commaNumber(totalNGN.price)} - {totalNGN.total}
          </AsgardH>
          <Divider />
        </CardAnalytics>
      )}
    </AnalyticsContainer>
  );
}

export default RevenueAnalytics;
