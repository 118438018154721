import Search from './searchIcon';
import { HeaderContainer } from './style';

function DashboardHeader() {
  return (
    <HeaderContainer style={{ marginTop: '15px', marginLeft: '10px' }}>
      <Search />
    </HeaderContainer>
  );
}

export default DashboardHeader;
