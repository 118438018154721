import { createSelector } from '@reduxjs/toolkit';

const categoriessData = (state = {}) => state.product_categories;

export const getProductCatgories = createSelector(
  categoriessData,
  (categoriessData) => categoriessData.data
);

export const getLoading = createSelector(
  categoriessData,
  (categoriessData) => categoriessData.ui.loading
);

export const getCategoryLoading = createSelector(
  categoriessData,
  (data) => data.ui.category_loading
);

export const getPageStats = createSelector(
  categoriessData,
  (categoriessData) => categoriessData.ui.stats
);
