import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  ActionBtnDiv,
  FormItemContainer,
  ProductFormBody,
  ProductFormContainer,
  ProductFormLeft,
  ProductFormMain,
  ProductFormRight
} from '@app/pages/products/addproduct/style';
import { CustomInput } from '@app/components/forms/InputGen';
import { AsgardP, Button, Div, Img } from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';
import Divider from '@app/globalstyles/dividerLine';
import { addIcon } from '@app/components/re-usedicons';
import SelectInputP from '@app/pages/products/addproduct/select';
import ErrorMessage from '@app/components/forms/errorMessage';
import { getGroup } from '../redux/selectors';
import { update_group } from '../redux/reducer';
import { useDispatch, useSelector } from 'react-redux';

const GroupApprovalModal = ({ closeform }) => {
  const dispatch = useDispatch();
  const group = useSelector(getGroup);
  let initialValues = {
    shipment_circle: group.shipment_circle || '',
    shipping_type: group.shipping_type || '',
    country: group.country || '',
    city: group.city || ''
  };

  const validationSchema = Yup.object().shape({
    shipment_circle: Yup.string()
      .required('Delivery Circle is required')
      .label('Delivery Circle'),
    shipping_type: Yup.string()
      .required('Delivery Type is required')
      .label('Delivery Type'),
    country: Yup.string().required('Country is required').label('Country'),
    city: Yup.string().required('City is required')
  });

  const handUpdateShippingDetails = async (doc) => {
    const data = {
      ...doc
    };
    dispatch(update_group({ id: group._id, data }));
    closeform();
  };

  return (
    <ProductFormContainer>
      <Div width="100%" display="flex" justify="center">
        <AsgardP fw="600" color={Colors.blackish} tAlign="center">
          Update Shipping Details
        </AsgardP>
      </Div>
      <Divider bg={Colors.primaryMain} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values) => handUpdateShippingDetails(values)}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values
        }) => (
          <Form onSubmit={handleSubmit}>
            <ProductFormMain>
              <ProductFormBody>
                <ProductFormLeft>
                  <FormItemContainer>
                    <AsgardP color={Colors.blackish}>Country</AsgardP>
                    <SelectInputP
                      name="country"
                      value={values.country}
                      onBlur={() => setFieldTouched('country')}
                      onChange={handleChange('country')}
                      label="Select country"
                    >
                      <option value={'Nigeria'}>Nigeria</option>
                      <option value={'United States'}>United States</option>
                      <option value={'Canada'}>Canada</option>
                      <option value={'United Kingdom'}>United Kingdom</option>
                    </SelectInputP>
                  </FormItemContainer>
                  <ErrorMessage error={errors.country} visible={touched.country} />

                  <FormItemContainer>
                    <AsgardP color={Colors.blackish}>Delivery Circle</AsgardP>
                    <SelectInputP
                      name="shipment_circle"
                      value={values.shipment_circle}
                      onBlur={() => setFieldTouched('shipment_circle')}
                      onChange={handleChange('shipment_circle')}
                      label="Select"
                    >
                      <option value={'Once'}>Once</option>
                      <option value={'Weekly'}>Weekly</option>
                      <option value={'Monthly'}>Monthly</option>
                      <option value={'Quarterly'}>Quarterly</option>
                    </SelectInputP>
                  </FormItemContainer>
                  <ErrorMessage
                    error={errors.shipment_circle}
                    visible={touched.shipment_circle}
                  />
                </ProductFormLeft>
                <ProductFormRight>
                  <FormItemContainer>
                    <AsgardP color={Colors.blackish}>City</AsgardP>
                    <CustomInput
                      error={errors.city && touched.city}
                      type="text"
                      name="city"
                      value={values.city}
                      placeholder="Enter city"
                      onBlur={() => setFieldTouched('city')}
                      onChange={handleChange('city')}
                    />
                  </FormItemContainer>
                  <ErrorMessage error={errors.city} visible={touched.city} />

                  <FormItemContainer>
                    <AsgardP color={Colors.blackish}>Delivery Type</AsgardP>
                    <SelectInputP
                      name="shipping_type"
                      value={values.shipping_type}
                      onBlur={() => setFieldTouched('shipping_type')}
                      onChange={handleChange('shipping_type')}
                      label="Select"
                    >
                      <option value={'Standard'}>Standard</option>
                      <option value={'Expedited'}>Expedited</option>
                    </SelectInputP>
                  </FormItemContainer>
                  <ErrorMessage
                    error={errors.shipping_type}
                    visible={touched.shipping_type}
                  />
                </ProductFormRight>
              </ProductFormBody>

              <ActionBtnDiv>
                <Button
                  type="submit"
                  p="10px 30px"
                  bc={Colors.primaryMain}
                  br="5px"
                  mr="15px"
                  color={Colors.white}
                  hbc={Colors.blackish}
                  onClick={handleSubmit}
                >
                  <Img src={addIcon} alt="product-btn" /> Save
                </Button>
                <Button
                  // disabled={isDisabled}
                  // ref={saveRef}
                  onClick={closeform}
                  p="10px 30px"
                  bc={Colors.red}
                  br="5px"
                  color={Colors.white}
                  hbc={Colors.blackish}
                >
                  Cancel
                </Button>
              </ActionBtnDiv>
            </ProductFormMain>
          </Form>
        )}
      </Formik>
    </ProductFormContainer>
  );
};

export default GroupApprovalModal;
