import React, { useEffect, useState } from 'react';
import { InContainer } from '../style';
import { FieldArray, Formik, Form, ErrorMessage } from 'formik';
import DeleteIcon from '@app/assets/icons/DeleteIcon';
import {
  getMode,
  getProductGroup,
  getStep,
  getUploadingRecipes
} from '../redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  get_product_group,
  update_step,
  upload_recipe_data
} from '../redux/reducer';

const Ingredients = ({ prev }) => {
  const product_groups = useSelector(getProductGroup);
  const dispatch = useDispatch();
  const mode = useSelector(getMode);
  const uploading = useSelector(getUploadingRecipes);
  const [ingredients, setIngredient] = useState(uploading.ingredients);
  const [delIngredients, setDelIngregient] = useState(false);
  const [delOtherIngredients, setDelOtherIngredients] = useState(false);
  const [delCookingInstructions, setDelCookingInstruction] = useState(false);
  const [other_ingredients, setOtherIngredient] = useState(
    uploading.other_ingredients
  );
  const [instructions, setInstructions] = useState(uploading.instructions);
  const mainstep = useSelector(getStep);
  const [formComplete, setFormComplete] = useState(false);
  const page = 1;
  const limit = 1000;

  useEffect(() => {
    checkFormCompleteness();
  }, [ingredients, other_ingredients, instructions]);

  const checkFormCompleteness = () => {
    const requiredFields = document.querySelectorAll('[required]');
    const hasEmptyRequiredField = Array.from(requiredFields).some(
      (field) => !field.value
    );
    setFormComplete(!hasEmptyRequiredField);
  };

  const reorderItems = (item) => {
    const element = Object.values(item);
    let newItem = {};

    for (let i = 0; i < element.length; i++) {
      newItem[String(i)] = element[i];
    }
    return newItem;
  };

  const onSubmit = () => {
    let final_data = { ...uploading, ingredients, other_ingredients, instructions };
    dispatch(upload_recipe_data(final_data));
    dispatch(update_step(3));
  };
  useEffect(() => {
    dispatch(get_product_group({ page, limit }));
  }, [dispatch, page, limit]);

  if (mode) {
    if (delIngredients) {
      dispatch(upload_recipe_data({ ingredients }));
      setDelIngregient(false);
    }
    if (delOtherIngredients) {
      dispatch(upload_recipe_data({ other_ingredients }));
      setDelOtherIngredients(false);
    }
    if (delCookingInstructions) {
      dispatch(upload_recipe_data({ instructions }));
      setDelCookingInstruction(false);
    }
  }

  return (
    <div>
      <InContainer>
        <div className="info">
          Please ensure that all required inputs are filled before the button becomes
          active. <strong style={{ color: 'red' }}>*</strong>
        </div>
        <Formik
          initialValues={{
            ingredients: ingredients || '',
            other_ingredients: other_ingredients || '',
            instructions: instructions || ''
          }}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ values: formikValues }) => (
            <Form>
              <label style={{ fontSize: 16 }}>Ingredients</label>
              <div className="item_row" style={{ marginTop: '10px' }}>
                <p>
                  Food Item <strong style={{ color: 'red' }}>*</strong>{' '}
                </p>
                <p>
                  {' '}
                  Quantity <strong style={{ color: 'red' }}>*</strong>
                </p>
              </div>

              <div className="map_div">
                <FieldArray name="ingredients">
                  {({ push, remove }) => (
                    <>
                      {formikValues.ingredients &&
                        Object.keys(formikValues?.ingredients).map((item, idx) => (
                          <div className="instructions" key={idx}>
                            <input
                              placeholder="E.g Rice"
                              required
                              name="ingredients"
                              value={ingredients[item]?.food_item}
                              onChange={(e) => {
                                setIngredient((prevState) => {
                                  return {
                                    ...prevState,
                                    [idx]: {
                                      ...prevState[idx],
                                      food_item: e.target.value
                                    }
                                  };
                                });
                              }}
                              onBlur={() => {
                                if (mode) {
                                  dispatch(upload_recipe_data({ ingredients }));
                                }
                              }}
                            />
                            <select
                              value={ingredients[item]?.product_group}
                              required
                              onChange={(e) => {
                                setIngredient((prevState) => {
                                  return {
                                    ...prevState,
                                    [idx]: {
                                      ...prevState[idx],
                                      product_group: e.target.value
                                    }
                                  };
                                });
                              }}
                              onBlur={(e) => {
                                if (mode) {
                                  dispatch(upload_recipe_data({ ingredients }));
                                }
                              }}
                            >
                              <option value={''}>Select Group</option>
                              {product_groups.map((group) => (
                                <option value={group._id}>{group.name}</option>
                              ))}
                            </select>
                            <div>
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: ' 4fr 3fr ',
                                  gap: '0.5rem'
                                }}
                              >
                                <input
                                  placeholder="1kg"
                                  value={ingredients[item]?.measurement}
                                  required
                                  onChange={(e) => {
                                    setIngredient((prevState) => {
                                      let init = {
                                        ...prevState,
                                        [idx]: {
                                          ...prevState[idx],
                                          measurement: e.target.value
                                        }
                                      };

                                      return init;
                                    });
                                  }}
                                  onBlur={(e) => {
                                    if (mode) {
                                      dispatch(upload_recipe_data({ ingredients }));
                                    }
                                  }}
                                />
                                <ErrorMessage name="measurement" />
                                <p
                                  onClick={() => {
                                    setIngredient((prevState) => {
                                      let ingredients = { ...prevState };
                                      delete ingredients[idx];
                                      return reorderItems(ingredients);
                                    });
                                    remove(idx);
                                    if (mode) {
                                      setDelIngregient(true);
                                    }
                                  }}
                                  className="delete_icon"
                                >
                                  {Object.values(ingredients).length > 1 ? (
                                    <DeleteIcon />
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      <div
                        className="add"
                        onClick={(e) => {
                          e.preventDefault();
                          setIngredient((prevState) => {
                            let initialState = { ...prevState };
                            initialState[Object.keys(initialState).length] = {
                              food_item: '',
                              product_group: '',
                              measurement: ''
                            };
                            return initialState;
                          });

                          push({
                            food_item: '',
                            product_group: '',
                            measurement: ''
                          });
                        }}
                      >
                        + Add Ingredient
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>

              <label>Other Ingredients</label>
              <div className="item_row">
                <p>Food Item </p>
                <p> Quantity </p>
              </div>

              <div className="map_div">
                <FieldArray name="other_ingredients">
                  {({ push, remove }) => (
                    <>
                      {formikValues.other_ingredients &&
                        Object.keys(formikValues?.other_ingredients).map(
                          (item, idx) => (
                            <div className="instructions">
                              <input
                                placeholder="E.g Rice"
                                value={other_ingredients[item]?.food_item}
                                onChange={(e) => {
                                  setOtherIngredient((prevState) => {
                                    return {
                                      ...prevState,
                                      [idx]: {
                                        ...prevState[idx],
                                        food_item: e.target.value
                                      }
                                    };
                                  });
                                }}
                                onBlur={(e) => {
                                  if (mode) {
                                    dispatch(
                                      upload_recipe_data({ other_ingredients })
                                    );
                                  }
                                }}
                              />
                              <select
                                value={other_ingredients[item]?.product_group}
                                onChange={(e) => {
                                  setOtherIngredient((prevState) => {
                                    return {
                                      ...prevState,
                                      [idx]: {
                                        ...prevState[idx],
                                        product_group: e.target.value
                                      }
                                    };
                                  });
                                }}
                                onBlur={(e) => {
                                  if (mode) {
                                    dispatch(
                                      upload_recipe_data({ other_ingredients })
                                    );
                                  }
                                }}
                              >
                                <option value={''}>Select Group</option>
                                {product_groups.map((group) => (
                                  <option value={group._id}>{group.name}</option>
                                ))}
                              </select>
                              <div>
                                <div
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns: ' 4fr 3fr ',
                                    gap: '0.5rem'
                                  }}
                                >
                                  <input
                                    placeholder="1kg"
                                    value={other_ingredients[item]?.measurement}
                                    onChange={(e) => {
                                      setOtherIngredient((prevState) => {
                                        return {
                                          ...prevState,
                                          [idx]: {
                                            ...prevState[idx],
                                            measurement: e.target.value
                                          }
                                        };
                                      });
                                    }}
                                    onBlur={(e) => {
                                      if (mode) {
                                        dispatch(
                                          upload_recipe_data({ other_ingredients })
                                        );
                                      }
                                    }}
                                  />

                                  <p
                                    onClick={() => {
                                      setOtherIngredient((prevState) => {
                                        let ingredients = { ...prevState };
                                        delete ingredients[idx];
                                        return reorderItems(ingredients);
                                      });
                                      remove(idx);
                                      if (mode) {
                                        setDelOtherIngredients(true);
                                      }
                                    }}
                                    className="delete_icon"
                                  >
                                    <DeleteIcon />
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      <div
                        className="add"
                        onClick={(e) => {
                          e.preventDefault();
                          setOtherIngredient((prevState) => {
                            let initialState = { ...prevState };
                            initialState[Object.keys(initialState).length] = {
                              food_item: '',
                              product_group: '',
                              measurement: ''
                            };
                            return initialState;
                          });
                          push({
                            food_item: '',
                            product_group: '',
                            measurement: ''
                          });
                        }}
                      >
                        + Add Ingredient
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>

              <label>Cooking Steps</label>
              <div className="item_row" style={{ marginTop: '10px' }}>
                <p>
                  Instructions <strong style={{ color: 'red' }}>*</strong>
                </p>
                <p>
                  Time <strong style={{ color: 'red' }}>*</strong>
                </p>
              </div>
              <div>
                <FieldArray name="instructions">
                  {({ push, remove }) => (
                    <>
                      {formikValues.instructions &&
                        Object.keys(formikValues.instructions).map((item, idx) => (
                          <div className="instructions" key={idx}>
                            <input
                              placeholder="E.g Boil the Chicken"
                              value={instructions[item]?.step}
                              required
                              onChange={(e) => {
                                setInstructions((prevState) => {
                                  return {
                                    ...prevState,
                                    [idx]: {
                                      ...prevState[idx],
                                      step: e.target.value
                                    }
                                  };
                                });
                              }}
                              onBlur={(e) => {
                                if (mode) {
                                  dispatch(upload_recipe_data({ instructions }));
                                }
                              }}
                            />
                            <input
                              placeholder="30mins"
                              required
                              value={instructions[item].time}
                              onChange={(e) => {
                                setInstructions((prevState) => {
                                  return {
                                    ...prevState,
                                    [idx]: {
                                      ...prevState[idx],
                                      time: e.target.value
                                    }
                                  };
                                });
                              }}
                              onBlur={(e) => {
                                if (mode) {
                                  dispatch(upload_recipe_data({ instructions }));
                                }
                              }}
                            />
                            <p
                              onClick={() => {
                                setInstructions((prevState) => {
                                  let instructions = { ...prevState };
                                  delete instructions[idx];
                                  return reorderItems(instructions);
                                });
                                remove(idx);
                                if (mode) {
                                  setDelCookingInstruction(true);
                                }
                              }}
                              className="delete_icon"
                            >
                              <DeleteIcon />
                            </p>
                          </div>
                        ))}
                      <div
                        className="add"
                        onClick={() => {
                          setInstructions((prevState) => {
                            let initialState = { ...prevState };
                            initialState[Object.keys(initialState).length] = {
                              step: '',
                              time: ''
                            };

                            return initialState;
                          });
                          push({
                            step: '',
                            time: ''
                          });
                        }}
                      >
                        + Add Instruction
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>
              <div className="button_container">
                {mode || mainstep >= 4 ? null : (
                  <div className="inner_container">
                    <button className="back_button" onClick={prev}>
                      Back
                    </button>
                    <button onClick={onSubmit} disabled={!formComplete}>
                      Next
                    </button>
                    <br />
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </InContainer>
    </div>
  );
};

export default Ingredients;
