import React from 'react';
import styled from 'styled-components';
import { MenuText } from '@app/main/menu/style';
import { Div } from '@app/globalstyles/asgard';
import { useHistory } from 'react-router-dom';
import Colors from '@app/globalstyles/utils/colors';
import ExpandIcon from '@design-system/Assets/ExpandIcon';

const AccordionContainer = styled.div`
  overflow: hidden;
  width: auto;
  border-radius: 0px;
`;

const Inner = styled.div`
  padding: 1rem;
`;

const Header = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  background: ${(props) => (props.active ? `${Colors.activemenubg}` : 'none')};
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
`;

const HeaderIcon = styled.span`
transform: rotate(${(props) => (props.isActive ? 180 : 180)}deg)
transition: all 0.2s;
`;

const Content = styled.div`
  position: relative;
  overflow: hidden;
  height: ${(props) => {
    const inner = document.getElementById(props.itemName);
    return `${props.isActive && inner ? inner.clientHeight : 0}px`;
  }};
  transition: height 0.35s;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: ${(props) => (props.active ? 900 : 500)};
  color: ${(props) => (props.active ? `${Colors.navTextActive}` : `${Colors.grey}`)};
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 6px;
`;

const AccordionContent = ({
  content,
  onClick,
  itemName,
  itemContent,
  isActive,
  props
}) => {
  const history = useHistory();

  const sev = history?.location?.pathname.split('/')[1] === itemName?.toLowerCase();

  return (
    <div>
      <Header
        onClick={onClick}
        active={
          history?.location?.pathname?.split('/')[1] === itemName?.toLowerCase()
            ? true
            : false
        }
        {...props}
      >
        <Menu
          active={
            history?.location?.pathname?.split('/')[1] === itemName?.toLowerCase()
              ? true
              : false
          }
          {...props}
        >
          <Icon src={sev ? content.activeIcon : content.icon} />

          <Div display="flex" justify="space-between">
            <MenuText>{itemName}</MenuText>
          </Div>
        </Menu>

        <HeaderIcon isActive={isActive} {...props}>
          <ExpandIcon
            color={
              history?.location?.pathname?.split('/')[1] === itemName?.toLowerCase()
                ? `#fff`
                : `${Colors.grey}`
            }
          />
        </HeaderIcon>
      </Header>
      <Content itemName={itemName} isActive={isActive} {...props}>
        <Inner id={itemName} {...props}>
          {itemContent}
        </Inner>
      </Content>
    </div>
  );
};

export { AccordionContainer, AccordionContent };
