import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const groupsAdapter = createEntityAdapter({
  selectId: (group) => group._id
});

const initialState = {
  ui: {
    loading: false,
    creating: false,
    stats: {
      pageCount: 1
    }
  },
  data: [],
  product_group: {},
  deleted_product: groupsAdapter.getInitialState()
};

export const productGroupsReducerSlice = createSlice({
  name: 'product_groups',
  initialState,
  reducers: {
    get_product_groups: (state) => {
      state.ui.loading = true;
    },
    get_product_groups_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload.groups;
      state.ui.stats = payload.stats;
    },
    get_product_groups_error: (state) => {
      state.ui.loading = false;
    },
    create_product_group: (state) => {
      state.ui.creating = true;
    },
    create_product_group_succes: (state, { payload }) => {
      state.ui.creating = false;
      state.data.unshift(payload);
    },
    create_product_group_error: (state) => {
      state.ui.creating = false;
    },
    delete_product_group: (state) => {
      state.ui.creating = true;
    },
    delete_product_group_success: (state, { payload }) => {
      state.ui.creating = false;
      state.data = state.data.filter((data) => data._id !== payload._id);
    },
    delete_product_group_error: (state) => {
      state.ui.loading = false;
    },
    update_product_group: (state) => {
      state.ui.creating = true;
    },
    update_product_group_success: (state, { payload }) => {
      state.ui.creating = false;
      const previousData = state.data;
      const index = previousData.findIndex((data) => data._id === payload._id);
      previousData[index] = payload;
      state.data = previousData;
    },
    update_product_group_error: (state) => {
      state.ui.loading = false;
    },
    get_one_product_group: (state) => {
      state.ui.loading = true;
    },
    get_one_product_group_success: (state, { payload }) => {
      state.ui.loading = false;
      state.product_group = payload;
    },
    get_one_product_group_error: (state) => {
      state.ui.loading = false;
    }
  }
});

export const {
  get_product_groups,
  get_product_groups_success,
  get_product_groups_error,
  create_product_group,
  create_product_group_succes,
  create_product_group_error,
  delete_product_group,
  delete_product_group_success,
  delete_product_group_error,
  update_product_group,
  update_product_group_success,
  update_product_group_error,
  get_one_product_group,
  get_one_product_group_success,
  get_one_product_group_error
} = productGroupsReducerSlice.actions;

export default productGroupsReducerSlice.reducer;
