import styled from 'styled-components';
import img55 from './icons/55-01.png';
import img66 from './icons/66-01.png';
import img33 from './icons/33-01.png';
import img56 from './icons/56-01.png';
import img88 from './icons/88-01.png';
import img77 from './icons/77-01.png';
import img11 from './icons/11-01.png';

export const Background = styled.div`
  padding: 0;
  margin: 0;
  border: 0;
  background: url(${img55}) top right no-repeat, url(${img88}) left 20% no-repeat,
    url(${img66}) right bottom no-repeat, url(${img33}) right center no-repeat,
    url(${img56}) left 70% no-repeat, url(${img77}) 9% bottom no-repeat;
  background-color: #549a9a;
  display: grid;
  align-content: center;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;

  @media screen and (max-width: 992px) {
    background: url(${img55}) top right no-repeat,
      url(${img66}) right bottom no-repeat, url(${img56}) left center no-repeat,
      url(${img88}) left top no-repeat;
    background-color: #549a9a;
  }
`;

export const SignupBackground = styled(Background)`
  display: block;
  background: url(${img11}) top right no-repeat, url(${img88}) left 8% no-repeat,
    url(${img33}) right 70% no-repeat, url(${img56}) left 65% no-repeat,
    url(${img77}) bottom center no-repeat;
  background-color: #549a9a;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const LoginBackground = styled(Background)`
  background: url(${img11}) 135% -10% no-repeat, url(${img33}) bottom right no-repeat;
  background-color: #549a9a;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;
