import { TableContainer } from '@app/pages/facts/styles';
import React from 'react';
import { CiEdit } from 'react-icons/ci';
import { RiDeleteBinLine } from 'react-icons/ri';

const TagTable = ({ tags, handleDelete, handleEdit }) => {
  const head = ['Name', 'Category', 'Description', ''];
  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            {head.map((data, idx) => (
              <th key={idx}>{data}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tags.map((data, index) =>
            data.tags.map((tag) => (
              <tr key={index}>
                <td>{tag.name}</td>
                <td>{tag.category}</td>
                <td>{tag.description}</td>
                <td>
                  <div className="flex flex-row gap-6">
                    <CiEdit size={15} onClick={() => handleEdit(tag)} />
                    <RiDeleteBinLine
                      size={15}
                      className="text-red-500"
                      onClick={() => handleDelete(tag._id)}
                    />
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default TagTable;
