import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_baskets,
  get_baskets_error,
  get_baskets_success,
  get_order_success,
  get_order_error,
  get_order
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getPaidBasketSagas({ payload }) {
  try {
    const {
      page = 1,
      limit = 10,
      cycle,
      startDate,
      endDate,
      filterDate,
      low_price,
      high_price,
      getSearch
    } = payload;
    let type = ``;
    if (filterDate && startDate && endDate) {
      type = `${filterDate}`;
    } else {
      type = ``;
    }
    let search = ``;
    if (getSearch) {
      search = `orders?search=${getSearch}`;
    }
    const requestRes = yield call(
      api.get,
      `/orders/?page=${page}&limit=${limit}&low_price=${low_price}&high_price=${high_price}&tracking=${cycle}&${type}&start_date=${startDate}&end_date=${endDate}&${search}`
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_baskets_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_baskets_error.type
    });
  }
}

function* getOrderSagas({ payload }) {
  try {
    const request = yield call(api.get, `/orders/${payload}`);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: get_order_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_order_error.type
    });
  }
}

function* paidBasketSagas() {
  yield takeEvery(get_baskets.type, getPaidBasketSagas);
  yield takeEvery(get_order.type, getOrderSagas);
}

export default paidBasketSagas;
