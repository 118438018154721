import { Switch } from 'react-router-dom';
import ProtectedRoute from '@app/utilities/ProtectedRoute';
import { DashboardBody, DashboardContainer } from './style';
import Menu from '../main/menu';
import { dashboardRoutes } from './routes';
import Sidebar from '../main/Sidebar';
import { useState } from 'react';
import MobileMenu from '../main/menu/mobileMenu';

function Dashboard() {
  const [open, setOpen] = useState(false);
  return (
    <DashboardContainer>
      <MobileMenu open={open} setOpen={setOpen} />
      <Menu />
      <Sidebar open={open} setOpen={setOpen} />
      <DashboardBody>
        <Switch>
          {dashboardRoutes.map((route, i) => (
            <ProtectedRoute
              key={i}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </DashboardBody>
    </DashboardContainer>
  );
}

export default Dashboard;
