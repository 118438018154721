import React from 'react';
import { NutrientStyle } from '../meal.styles';
import { FieldArray } from 'formik';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';

const Nutrients = ({ value, setFieldValue }) => {
  return (
    <NutrientStyle>
      <FieldArray name="nutrients">
        {({ push, remove }) => (
          <>
            {value.nutrients &&
              value.nutrients.map((item, idx) => (
                <div
                  key={idx}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '20px'
                  }}
                >
                  <input
                    type="text"
                    placeholder="Fat"
                    name="name"
                    value={item.name}
                    onChange={(e) => {
                      let infos = value.nutrients;
                      let info = infos[idx];
                      const data = {
                        ...info,
                        name: e.target.value
                      };
                      infos[idx] = data;
                      setFieldValue('nutrients', infos);
                    }}
                  />
                  <div className="inner">
                    <input
                      type="text"
                      placeholder="10"
                      name="value"
                      value={item.value}
                      onChange={(e) => {
                        let infos = value.nutrients;
                        let info = infos[idx];
                        const data = {
                          ...info,
                          value: e.target.value
                        };
                        infos[idx] = data;
                        setFieldValue('nutrients', infos);
                      }}
                    />
                    <div className="aside">
                      {value.nutrients.length >= 2 && (
                        <div onClick={() => remove(idx)} className="del">
                          <MdDeleteOutline size={20} />
                        </div>
                      )}

                      <div
                        onClick={() => {
                          push({
                            name: '',
                            value: ''
                          });
                        }}
                        className="add"
                      >
                        <IoMdAddCircleOutline size={20} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </FieldArray>
    </NutrientStyle>
  );
};

export default Nutrients;
