import CloseIcon from '@design-system/Assets/CloseIcon';
import React, { useEffect, useState } from 'react';
import { CreateContent, CreateBody } from '../style';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { get_currencies } from '@app/pages/currrencies/redux/reducer';
import { getCurrencies } from '@app/pages/currrencies/redux/selectors';
import { useSelector } from 'react-redux';
import { getProductCatgories } from '@app/pages/product-categories/redux/selectors';
import { get_product_categories } from '@app/pages/product-categories/redux/reducer';
import { Formik, Form, FieldArray } from 'formik';
import {
  create_product,
  get_meal_products
} from '@app/pages/products/redux/reducer';
import { getCountries } from 'country-state-picker';
import useDebounce from '@app/Hooks/useDebounce';
import { getMealProducts } from '@app/pages/products/redux/selectors';
import { MEASUREMENTS } from '@app/pages/products/filterData';
import {
  ProductItemsDiv,
  ProductOption
} from '@app/pages/products/addmealpack/style';

const cook = ['Low Effort', 'Medium Effort', 'Cook time'];
const priceCat = ['Sapa Friendly', 'Meduim Class', 'Gourmet'];

const CreateProduct = ({ showForm, closeForm, creator }) => {
  const dispatch = useDispatch();
  const recipeImage = creator.images;
  const currencies = useSelector(getCurrencies);
  const categories = useSelector(getProductCatgories);
  const countries = getCountries();
  const [search, setSearch] = useState('');
  const debouncedTerm = useDebounce(search, 500);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(null);
  const suggestions = useSelector(getMealProducts);

  console.log(creator);

  let initialValues = {
    name: '',
    description: '',
    quantity: '',
    product_category: '',
    country: '',
    price: '',
    currency: '',
    price_category: '',
    cook_time: '',
    base_ingredients: [{ name: '', measurement: 0, unit: '' }]
  };

  const handleForm = async (doc) => {
    if (recipeImage.length <= 0) return toast.error('Please pick a recipe Image');
    const data = {
      ...doc,
      recipe: creator._id,
      recipe_creator: creator.created_by._id,
      image: creator.images[0],
      other_images: creator.images,
      price: {
        value: doc.price,
        currency: doc.currency
      }
    };
    delete data['currency'];
    dispatch(create_product(data));
    closeForm();
  };

  useEffect(() => {
    dispatch(get_product_categories());
    dispatch(get_currencies());
  }, [dispatch]);

  useEffect(
    () => {
      if (debouncedTerm) {
        dispatch(get_meal_products({ search: debouncedTerm }));
      }
    },
    [debouncedTerm, dispatch] // Only call effect if debounced search term changes
  );

  const handleSearhChange = (idx, text) => {
    setCurrentIndex(idx);
    setSearch(text);
    setShowSuggestions(true);
  };

  return (
    <div>
      <CreateContent>
        <div className="header">
          <div className="header-text">Create meal</div>
          <CloseIcon className="icon" onClick={closeForm} />
        </div>

        <CreateBody>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleForm(values)}
          >
            {({
              handleChange,
              handleSubmit,
              values: formikValues,
              setFieldValue
            }) => (
              <Form onSubmit={handleSubmit}>
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Village Rice"
                  name="name"
                  onChange={handleChange('name')}
                />

                <label>Description</label>
                <input
                  type="text"
                  placeholder="Village Rice"
                  name="description"
                  onChange={handleChange('description')}
                />

                <div className="form_grid">
                  <div className="form_content">
                    <label>Product Category</label>
                    <select name="type" onChange={handleChange('product_category')}>
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form_content">
                    <label>Quantity in stock</label>
                    <input
                      type="text"
                      placeholder="Quantity"
                      name="quantity"
                      onChange={handleChange('quantity')}
                    />
                  </div>
                </div>

                <div className="form_grid">
                  <div className="form_content">
                    <label>Price</label>
                    <div className="form_input_box">
                      <select name="currency" onChange={handleChange('currency')}>
                        {currencies.map((currency) => (
                          <option key={currency._id} value={currency._id}>
                            {currency.short_code} - {currency.symbol}
                          </option>
                        ))}
                      </select>
                      <input
                        type="text"
                        placeholder="0"
                        name="price"
                        onChange={handleChange('price')}
                      />
                    </div>
                  </div>
                  <div className="form_content">
                    <label>Price Cateory</label>
                    <select
                      name="price_category"
                      onChange={handleChange('price_category')}
                    >
                      <option selected disabled value="">
                        --Select-
                      </option>
                      {priceCat.map((data, index) => (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form_grid">
                  <div className="form_content">
                    <label>Cook Time</label>
                    <select name="cook_time" onChange={handleChange('cook_time')}>
                      <option selected disabled value="">
                        --Select--
                      </option>
                      {cook.map((data, index) => (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form_content">
                    <label>Country</label>
                    <select
                      name="country"
                      label="Select Country"
                      onChange={handleChange('country')}
                    >
                      {countries.map((country) => (
                        <option key={country.code} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="title">Ingredients</div>

                <div>
                  <div className="form_content">
                    <label>Food Item</label>
                    <FieldArray name="sub_products">
                      {({ push, remove }) => (
                        <div style={{ paddingLeft: '20px', position: 'relative' }}>
                          {formikValues.sub_products &&
                            formikValues.sub_products.length > 0 &&
                            formikValues.sub_products.map((item, idx) => (
                              <div className="form_grid" key={`${idx}`}>
                                <div className="form_content">
                                  <input
                                    type="text"
                                    name={`item.${idx}.${item}`}
                                    placeholder="E.g Chicken breast"
                                    value={item.name}
                                    onChange={(e) => {
                                      let products = formikValues.sub_products;
                                      let product = products[idx];
                                      const data = {
                                        ...product,
                                        name: e.target.value
                                      };
                                      products[idx] = data;
                                      setFieldValue('sub_products', products);
                                      handleSearhChange(idx, e.target.value);
                                    }}
                                  />
                                </div>
                                <div
                                  className="form_input_box"
                                  style={{ marginBottom: '10px' }}
                                >
                                  <select
                                    name="type"
                                    onChange={(e) => {
                                      let products = formikValues.sub_products;
                                      let product = products[idx];
                                      const data = {
                                        ...product,
                                        unit: e.target.value
                                      };
                                      products[idx] = data;
                                      setFieldValue('sub_products', products);
                                    }}
                                  >
                                    <option disabled>Unit</option>
                                    {MEASUREMENTS.map((weight, id) => (
                                      <option key={id} value={weight}>
                                        {weight}
                                      </option>
                                    ))}
                                  </select>
                                  <input
                                    type="number"
                                    placeholder="0"
                                    value={item.measurement}
                                    onChange={(e) => {
                                      let products = formikValues.sub_products;
                                      let product = products[idx];
                                      const data = {
                                        ...product,
                                        measurement: e.target.value
                                      };
                                      products[idx] = data;
                                      setFieldValue('sub_products', products);
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          <div
                            className="add"
                            onClick={() => {
                              push({
                                name: '',
                                measurement: 0,
                                unit: 'kg'
                              });
                            }}
                          >
                            Add Ingredient
                          </div>
                          {suggestions?.length > 0 && showSuggestions && (
                            <ProductItemsDiv>
                              {suggestions.map((item, id) => (
                                <ProductOption
                                  key={id}
                                  onClick={() => {
                                    let products = formikValues.sub_products;
                                    const data = {
                                      product_id: item._id,
                                      name: item.name,
                                      measurement: 0,
                                      unit: 'kg'
                                    };
                                    products[currentIndex] = data;
                                    setFieldValue('sub_products', products);
                                    setSearch(item.name);
                                    setShowSuggestions(false);
                                  }}
                                  className="list is-hoverable"
                                >
                                  {item?.name}
                                </ProductOption>
                              ))}
                            </ProductItemsDiv>
                          )}
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>

                <div className="image_container">
                  {recipeImage?.length >= 1 &&
                    recipeImage?.map((image, id) => (
                      <img src={image} alt="selected" key={id} />
                    ))}
                </div>

                <button className="meal_button" type="submit">
                  Create meal
                </button>
              </Form>
            )}
          </Formik>
        </CreateBody>
      </CreateContent>
    </div>
  );
};

export default CreateProduct;
