import React, { useState, useEffect } from 'react';
import { BundleContainer, RecipeIngriedents } from '../style';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { update_recipe } from '../redux/reducer';
import { getCreating } from '../redux/selector';
import { Formik, Form } from 'formik';

const ProductBundle = ({ bundles, id, pBundles }) => {
  const [options, setOptions] = useState([]);
  const loading = useSelector(getCreating);
  const dispatch = useDispatch();

  let data = {
    product_bundles: pBundles || ''
  };

  useEffect(() => {
    const response = bundles?.map((data) => {
      return {
        _id: data?._id,
        value: data?._id,
        label: data?.name
      };
    });
    setOptions(response);
  }, [bundles]);

  const handleSubmit = (doc) => {
    const bundles = doc.product_bundles.map((data) => data.value);
    let data = {
      product_bundles: bundles
    };
    dispatch(update_recipe({ data, id }));
  };

  return (
    <Formik initialValues={data} onSubmit={handleSubmit} enableReinitialize={true}>
      {({ values, setFieldValue }) => (
        <Form>
          <RecipeIngriedents>
            <p className="title">Product Bundle</p>
            <BundleContainer>
              <Select
                className="item"
                name="product_bundles"
                isClearable={true}
                options={options}
                isMulti
                value={values.product_bundles}
                closeMenuOnSelect={false}
                onChange={(value) => setFieldValue('product_bundles', value)}
              />

              <button className="item" type="submit" disabled={loading}>
                {loading ? 'Saving...' : 'Save'}
              </button>
            </BundleContainer>
          </RecipeIngriedents>
        </Form>
      )}
    </Formik>
  );
};

export default ProductBundle;
