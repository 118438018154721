import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  color: black;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border: 1px solid #f0f0f0;
  padding: 24px;

  .header_text {
    font-weight: 600;
    font-size: 25px;
    color: #284242;
  }

  button {
    border: 1px solid #549a9a;
    outline: none;
    background: #fff;
    color: #549a9a;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 39px;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 31px;

  .search_bar {
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    // padding-top: 12px;
    // padding-bottom: 12px;
    padding-left: 23px;
    width: 587px;
  }

  input {
    width: 578px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: none;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 18px;
  }

  input::placeholder {
    font-weight: 500;
    font-size: 18px;
    color: #bdbdbd;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  .card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 16px;
    text-transform: capitalize;

    &:hover .img_overlay {
      opacity: 1;
    }
  }

  .img_overlay {
    position: absolute;
    top: 5;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 50%;
    height: 177px;
    display: grid;
    place-items: center;
    text-transform: capitalize;
    max-width: 226px;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }

  img {
    width: 100%;
    height: 215px;
    object-fit: center;
  }
`;

export const Modal = styled.div`
  padding: 16px;

  .title {
    text-align: center;
    font-size: 20px;
    font-weight: 600px;
  }

  input {
    border: 1px solid #e0e0e0;
    outline: none;
    height: 44px;
    margin-bottom: 10px;
    padding-left: 14px;
    padding-right: 14px;
    width: 96%;
  }

  .image_label {
    width: 100%;
    border: 1px dashed #e0e0e0;
    border-radius: 10px;
    height: 250px;
    margin-top: 16px;
    display: grid;
    place-items: center;
    cursor: pointer;
    color: #e0e0e0;
  }

  img {
    width: 100%;
    height: 250px;
    object-fit: center;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 16px;
  }

  button {
    background: #549a9a;
    height: 44px;
    border: none;
    outline: none;
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;
    margin-top: 16px;
    border-radius: 10px;

    &:disabled {
      background: #d4e7e7;
    }
  }
`;
