import { AsgardP, Div } from '@app/globalstyles/asgard';
import React, { useEffect } from 'react';
import { BTableBody, PaymentsTableContent } from './style';
import Colors from '@app/globalstyles/utils/colors';
import { useDispatch, useSelector } from 'react-redux';
import { get_payment } from '../redux/reducer';
import { getLoading } from '../redux/selectors';
import moment from 'moment';
import Link from '@design-system/Link';
import LoadingDataUi from '@app/components/loading';
import EmptyDataUi from '@app/components/emptyData';
import commaNumber from 'comma-number';

const PaymentTable = ({
  payments,
  pageSize,
  currentPage,
  selectedfilter,
  searchString
}) => {
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      get_payment({
        limit: pageSize,
        page: currentPage,
        selectedfilter,
        searchString
      })
    );
  }, [dispatch, pageSize, currentPage, selectedfilter, searchString]);

  return (
    <div>
      <PaymentsTableContent>
        {loading ? (
          <LoadingDataUi />
        ) : (
          <BTableBody>
            <tr>
              <th>Group</th>
              <th>Customer</th>
              <th>Reference</th>
              <th>Amount</th>
            </tr>

            {payments?.map((pay, i) => (
              <tr key={(pay, i)}>
                <td style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                  <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                    <Div>
                      <Link to={`groups/${pay?.group?._id}`}>
                        <AsgardP ml="10px">{pay?.group?.name}</AsgardP>
                      </Link>
                      <AsgardP color={Colors.grey} ml="10px" mt="-10px">
                        Shipment Date:{' '}
                        {moment(pay?.group?.shipment_date).format('LL')}
                      </AsgardP>
                    </Div>
                  </div>
                </td>
                <td>
                  <Link to={`customers/${pay?.created_by?._id}`}>
                    {pay?.created_by?.first_name} {pay?.created_by?.first_name}
                  </Link>
                  <AsgardP color={Colors.grey}>
                    {' '}
                    {moment(pay.created_at).format('LL')} PM
                  </AsgardP>
                </td>
                <td>
                  <Link to={`payments/${pay._id}`}>{pay.reference}</Link>
                </td>
                <td>
                  {pay?.price?.currency?.symbol}
                  {commaNumber(pay?.price?.value)}
                </td>
                <td>
                  <Link to={`payments/${pay._id}`}>
                    <AsgardP ml="10px">View</AsgardP>
                  </Link>
                </td>
              </tr>
            ))}
          </BTableBody>
        )}
      </PaymentsTableContent>
      {!loading && payments.length <= 0 && <EmptyDataUi />}
    </div>
  );
};

export default PaymentTable;
