import { akaaniLogo } from '@app/components/re-usedicons';
import { AsgardP, Div, Img } from '@app/globalstyles/asgard';
import React, { useEffect } from 'react';
import { LogoutdropContainer } from './style';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '@app/routes';
function LogoutPage() {
  const history = useHistory();
  useEffect(() => {
    localStorage.removeItem('x-access-token');
    setTimeout(() => {
      history.push(LOGIN);
    }, 2000);
  }, [history]);

  return (
    <LogoutdropContainer show={true}>
      <Div display="flex" flexDirection="column" alignI="center" mt="-200px">
        <Img width="100px" height="100px" src={akaaniLogo} alt="Logout" />
        <AsgardP tAlign="center">Logging out...</AsgardP>
      </Div>
    </LogoutdropContainer>
  );
}

export default LogoutPage;
