import styled from 'styled-components';

export const Track = styled.div`
  width: 364px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px 0px 0px 0px;
  position: relative;
  margin-left: 7%;
`;

export const TrackHead = styled.div`
  width: auto;
  padding: 10px;
  background: #549a9a;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px 0px 0px 0px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
`;
export const TrackBody = styled.div`
  padding: 10px;
`;

export const StepWrapper = styled.div`
  padding: 0 1rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .stepper-item {
      position:relative;
      display: flex;
      flex-direction: row;
      flex: 1
      font-size: 11px;
      width: 100%;
     


      &:after {
        position: absolute;
  content: "";
  border-left: 3px dashed #ACD1D1;
  height: 100px;
  top: 20px;
  left: 5.7%;
  z-index: 2;  
      }
  }

  .stepper-item-no {
    position:relative;
    display: flex;
    flex-direction: row;
    flex: 1
    font-size: 11px;

    
  

    &:after {
      position: absolute;
content: "";
border-left: 3px solid #ACD1D1;
height: 100px;
top: 20px;
left: 5.7%;
z-index: 2;  
    }
}

  .step-counter-no {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42.38px;
    height: 42.38px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #ACD1D1;
    color: #549A9A;
    margin-bottom: 40px;
    left: 0;
  }



  .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42.38px;
    height: 42.38px;
    border-radius: 50%;
    background: #ACD1D1;
    color: #549A9A;
    margin-bottom: 40px;
    left: 0;
  }

  .step-name {
    margin-left: 10px;
    margin-top: 2px;    
  }

  .step-content {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 250px;
  }


  .step-content-text {
    font-weight: 500;
    font-size: 15px;   
    color: #333333;

    p {
      font-weight: 400;
font-size: 12px;
color: #828282;
line-height: 2px;

    }
  }

  .step-content-text-no {
    font-weight: 500;
font-size: 15px;
color: #ACD1D1;
margin-top: 10px;
  }

  .step-content-time {
    font-weight: 500;
font-size: 12px;
line-height: 140%;

color: #828282;

  }

  

  .stepper-item {
    &:last-child {
        &:before {
            content: none
        }

        &:after{
            content: none;
        }
    }
  }
  .stepper-item-no {
    &:last-child {
        &:before {
            content: none
        }

        &:after{
            content: none;
        }
    }
  }
 
`;

export const Card = styled.div`

  
.stepper-item {
    position:relative;
    display: flex;
    flex-direction: row;
    flex: 1
    font-size: 11px;
    width: 100%;
   
    &:after {
      position: absolute;
content: "";
border-left: 3px dashed #ACD1D1;
height: 100px;
top: 20px;
left: 5.7%;
z-index: 2;  
    }
}

.stepper-item-no {
  position:relative;
  display: flex;
  flex-direction: row;
  flex: 1
  font-size: 11px;

  


  &:after {
       position: absolute;
content: "";
border-left: 3px solid #ACD1D1;
height: 100px;
top: 20px;
left: 0%;
z-index: 2;  
  }
}

.step-counter-no {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42.38px;
  height: 42.38px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #ACD1D1;
  color: #549A9A;
  margin-bottom: 40px;
  left: 0;
}



.step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42.38px;
  height: 42.38px;
  border-radius: 50%;
  background: #ACD1D1;
  color: #549A9A;
  margin-bottom: 40px;
  left: 0;
}

.step-name {
  margin-left: 10px;
  margin-top: 2px;    
}

.step-content {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 250px;
}


.step-content-text {
  font-weight: 500;
  font-size: 15px;   
  color: #333333;

  p {
    font-weight: 400;
font-size: 12px;
color: #828282;
line-height: 2px;

  }
}

.step-content-text-no {
  font-weight: 500;
font-size: 15px;
color: #333333;
margin-top: -5px;

}

.step-content-time {
  font-weight: 500;
font-size: 12px;
line-height: 140%;

color: #828282;

}



.stepper-item {
  &:last-child {
      &:before {
          content: none
      }

      &:after{
          content: none;
      }
  }
}
.stepper-item-no {
  &:last-child {
      &:before {
          content: none
      }

      &:after{
          content: none;
      }
  }
}

.track_button{
    border: 1px solid #549A9A;
    color: #549A9A;
    border-radius: 5px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 9px;
    padding-right: 9px;
    font-szie: 12px;
    font-weight: 500;
    background: none;
    outline: none;
    cursor: pointer;
    margin-top: 5px;
}

`;
