import React from 'react';
import { Card } from './style';
import addRingactive from '@design-system/Assets/Add_ring.svg';
import addRing from '@design-system/Assets/Add_ringn.svg';
import shieldactive from '@design-system/Assets/Chield_check.png';
import shield from '@design-system/Assets/Chield_check-1.png';
import deskAltactive from '@design-system/Assets/Desk_alt.svg';
import deskAlt from '@design-system/Assets/Desk_altn.svg';
import mateactive from '@design-system/Assets/Materials.png';
import mate from '@design-system/Assets/Materials-1.png';
import transitactive from '@design-system/Assets/transit.png';
import transit from '@design-system/Assets/transit-1.png';
import checkactive from '@design-system/Assets/Check_ring.png';
import check from '@design-system/Assets/Check_ring-1.png';
import shipmentactive from '@design-system/Assets/shipment.png';
import shipment from '@design-system/Assets/shipment-1.png';
import arrivedactive from '@design-system/Assets/arrived.png';
import arrived from '@design-system/Assets/arrived-1.png';
import moment from 'moment';

const TrackingCard = ({ trackName, time, trackStatus, completedText, sn, id }) => {
  return (
    <div>
      <Card>
        <div
          // onClick={
          //   sn !== 1 && trackStatus !== 'Processing'
          //     ? () => router.push(`/baskets/basket/${id}`)
          //     : null
          // }
          className="stepper-item"
        >
          <div
            className={
              trackStatus === 'Complete' ? 'step-counter' : 'step-counter-no'
            }
          >
            {trackName === 'Add Products' ? (
              <>
                {trackStatus === 'Complete' ? (
                  <img src={addRingactive} alt="active-ring" />
                ) : (
                  <img src={addRing} alt="active-ring" />
                )}
              </>
            ) : null}
            {trackName === 'Booking Confirmed' ? (
              <>
                {trackStatus === 'Complete' ? (
                  <img src={deskAltactive} alt="active-ring" />
                ) : (
                  <img src={deskAlt} alt="active-ring" />
                )}
              </>
            ) : null}
            {trackName === 'Gathering Products' ? (
              <>
                {trackStatus === 'Complete' ? (
                  <img src={mateactive} alt="active-ring" />
                ) : (
                  <img src={mate} alt="active-ring" />
                )}
              </>
            ) : null}
            {trackName === 'In Transit' ? (
              <>
                {trackStatus === 'Complete' ? (
                  <img src={transitactive} alt="active-ring" />
                ) : (
                  <img src={transit} alt="active-ring" />
                )}
              </>
            ) : null}
            {trackName === 'Out for Delivery' ? (
              <>
                {trackStatus === 'Complete' ? (
                  <img src={transitactive} alt="active-ring" />
                ) : (
                  <img src={transit} alt="active-ring" />
                )}
              </>
            ) : null}

            {trackName === 'Quality Assurance' ? (
              <>
                {trackStatus === 'Complete' ? (
                  <img src={shieldactive} alt="active-ring" />
                ) : (
                  <img src={shield} alt="active-ring" />
                )}
              </>
            ) : null}
            {trackName === 'Shipment Booked' ? (
              <>
                {trackStatus === 'Complete' ? (
                  <img src={shipmentactive} alt="active-ring" />
                ) : (
                  <img src={shipment} alt="active-ring" />
                )}
              </>
            ) : null}
            {trackName === 'Arrived Destination' ? (
              <>
                {trackStatus === 'Complete' ? (
                  <img src={arrivedactive} alt="active-ring" />
                ) : (
                  <img src={arrived} alt="active-ring" />
                )}
              </>
            ) : null}
            {trackName === 'Delivered' ? (
              <>
                {trackStatus === 'Complete' ? (
                  <img src={checkactive} alt="active-ring" />
                ) : (
                  <img src={check} alt="active-ring" />
                )}
              </>
            ) : null}
          </div>

          <div className="step-name">
            <div className="step-content">
              <div
                className={
                  trackStatus === 'Complete'
                    ? 'step-content-text'
                    : 'step-content-text-no'
                }
              >
                {trackName} <br />{' '}
                {trackStatus === 'Processing' ? (
                  <button className="track_button">Mark as Completed</button>
                ) : (
                  <p> {completedText} </p>
                )}
              </div>

              <div className="step-content-time">
                <>{time ? `${moment(time).format('LT')}` : ''} </>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default TrackingCard;
