import React from 'react';
import { Modal } from '../../style';

const ViewDelivery = ({ content }) => {
  console.log(content);
  return (
    <Modal>
      <h2>Delivery Partner</h2>

      <div className="container">
        <label>Name</label>
        <h4>{content.name}</h4>

        <label>Description</label>
        <h4>{content.description}</h4>

        <div className="grid">
          <div>
            <label>Phone</label>
            <h4>{content.phone}</h4>
          </div>
          <div>
            <label>Location</label>
            <h4>{content.location}</h4>
          </div>
        </div>

        <div>
          <label>Interstate</label>
          <h4>{content.interstate ? 'Yes' : 'No'}</h4>
        </div>
      </div>
    </Modal>
  );
};

export default ViewDelivery;
