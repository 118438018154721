import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    product_loading: false,
    stats: {
      pageCount: 1
    }
  },
  data: [],
  update: {},
  meal: [],
  product_group: [],
  product_images: [],
  step: 0,
  editMode: false,
  edit_success: false,
  recipeId: '',
  create_recipe_data: {
    video: '',
    name: '',
    description: '',
    type: '',
    prep_time: '',
    countries: '',
    video_url: '',
    ingredients: {
      0: {
        food_item: '',
        product_group: '',
        measurement: ''
      }
    },
    other_ingredients: {
      0: {
        food_item: '',
        product_group: '',
        measurement: ''
      }
    },
    instructions: [
      {
        step: '',
        time: ''
      }
    ],
    images: []
  },
  imageIndex: 0
};

export const recipeReducerSlice = createSlice({
  name: 'recipe',
  initialState,
  reducers: {
    get_recipe_success: (state, { payload }) => {
      const { recipes, stats } = payload;
      state.ui.loading = false;
      state.data = recipes;
      state.ui.stats = {
        ...stats
      };
    },
    get_recipe_error: (state) => {
      state.ui.loading = false;
    },
    get_recipe: (state) => {
      state.ui.loading = true;
    },
    add_meal_success: (state, { payload }) => {
      state.ui.loading = false;
      state.meal = payload;
    },
    add_meal_error: (state) => {
      state.ui.loading = false;
    },
    add_meal: (state) => {
      state.ui.loading = true;
    },
    update_recipe_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = state.data.map((it) => {
        return it._id === payload._id ? payload : it;
      });
    },
    update_recipe_error: (state) => {
      state.ui.loading = false;
    },
    update_recipe: (state) => {
      state.ui.loading = true;
    },
    get_product_group: (state) => {
      state.ui.loading = true;
    },
    get_product_group_success: (state, { payload }) => {
      state.ui.loading = false;
      state.product_group = payload.groups;
    },
    get_product_group_error: (state) => {
      state.ui.loading = false;
    },
    get_product_images: (state) => {
      state.ui.loading = false;
    },
    get_product_images_error: (state) => {
      state.ui.loading = false;
    },
    get_product_images_success: (state, { payload }) => {
      state.ui.loading = false;
      const { images } = payload;
      state.product_images = images;
    },
    create_product_group: (state) => {
      state.ui.product_loading = true;
    },
    create_product_group_success: (state) => {
      state.ui.product_loading = false;
    },
    create_product_group_error: (state) => {
      state.ui.product_loading = false;
    },
    create_recipe: (state) => {
      state.ui.product_loading = true;
    },
    create_recipe_success: (state, { payload }) => {
      state.ui.product_loading = false;
      state.data.unshift(payload);
      state.create_recipe_data = initialState?.create_recipe_data;
      state.step = initialState?.step;
      state.editMode = initialState?.editMode;
      state.recipeId = initialState?.recipeId;
    },
    create_recipe_error: (state) => {
      state.ui.product_loading = false;
    },
    edit_recipe: (state) => {
      state.ui.product_loading = true;
      state.edit_success = false;
    },
    edit_recipe_success: (state, { payload }) => {
      state.ui.product_loading = false;
      state.create_recipe_data = initialState?.create_recipe_data;
      state.step = initialState?.step;
      state.editMode = initialState?.editMode;
      state.recipeId = initialState?.recipeId;
      state.edit_success = true;
    },
    edit_recipe_error: (state) => {
      state.ui.product_loading = false;
      state.edit_success = false;
    },
    reset_recipe: (state) => {
      state.create_recipe_data = initialState?.create_recipe_data;
      state.editMode = initialState?.editMode;
    },
    upload_recipe_data: (state, { payload }) => {
      state.create_recipe_data = {
        ...state.create_recipe_data,
        ...payload
      };
    },
    update_step: (state, { payload }) => {
      state.step = payload;
    },
    update_mode: (state, { payload }) => {
      state.editMode = payload;
    },
    update_id: (state, { payload }) => {
      state.recipeId = payload;
    },
    set_image_index: (state, { payload }) => {
      state.imageIndex = payload;
    }
  }
});

export const {
  get_recipe_success,
  get_recipe_error,
  get_recipe,
  add_meal_success,
  add_meal_error,
  add_meal,
  update_recipe_success,
  update_recipe_error,
  update_recipe,
  get_product_group,
  get_product_group_success,
  get_product_group_error,
  create_product_group,
  create_product_group_success,
  create_product_group_error,
  create_recipe,
  create_recipe_success,
  create_recipe_error,
  edit_recipe,
  edit_recipe_success,
  edit_recipe_error,
  upload_recipe_data,
  update_step,
  update_mode,
  update_id,
  get_product_images,
  get_product_images_success,
  get_product_images_error,
  reset_recipe,
  set_image_index
} = recipeReducerSlice.actions;

export default recipeReducerSlice.reducer;
