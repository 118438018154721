import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder, getLoading } from '../orders/redux/selectors';
import { get_order } from '../orders/redux/reducer';
import { useParams } from 'react-router-dom';
import { Container } from './style';
import moment from 'moment';
import OrderCard from './components/orderCard';
import commaNumber from 'comma-number';
import { Link } from 'react-router-dom';
import left from '@design-system/Assets/left.png';
import LoadingDataUi from '@app/components/loading';

const Order = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const order = useSelector(getOrder);

  const loading = useSelector(getLoading);

  useEffect(() => {
    dispatch(get_order(id));
  }, [dispatch, id]);
  return (
    <Container>
      {loading ? (
        <LoadingDataUi />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginBottom: '24px'
            }}
          >
            <Link to="/basket-orders" className="link">
              Orders
            </Link>
            <img src={left} alt="left_arrow" className="left_arrow" />
            <div className="order_title">{order.order_no} </div>
          </div>

          <div className="header">
            <div className="header_content">
              <div>
                <div className="header_text">Order ID: {order?.order_no}</div>
                <div className="order_date">
                  Order date: {moment(order?.date_paid).format('LL')}
                </div>
                <div className="order_date">
                  Order Delivered:{' '}
                  {moment(order?.tracking_steps?.updated_at).format('LL')}
                </div>
                {order?.delivery_fee && (
                  <>
                    <div className="order_date">
                      Delivery Fee: {order?.amount_paid?.currency?.symbol}
                      {order?.delivery_fee}
                    </div>
                  </>
                )}
              </div>
              <div>
                <div className="tracking">{order?.current_tracking}</div>
                <div className="amount_paid">
                  Amount Paid: {order?.amount_paid?.currency?.symbol}
                  {commaNumber(order?.amount_paid?.value)}
                </div>
              </div>
            </div>
          </div>

          <div className="body">
            {/* first grid item */}
            <div>
              <div className="label">Products</div>
              {order?.products?.map((data) => (
                <OrderCard
                  key={data._id}
                  order={data}
                  id={data._id}
                  currency={order?.amount_paid?.currency?.symbol}
                />
              ))}
              <div className="line" />

              <div className="label">Customer</div>
              <div className="body">
                <div>
                  <div className="title">Firstname</div>
                  <div className="sub_title">{order?.created_by?.first_name}</div>
                </div>

                <div>
                  <div className="title">Lastname</div>
                  <div className="sub_title">{order?.created_by?.last_name}</div>
                </div>

                <div>
                  <div className="title">Email</div>
                  <div className="sub_title_mail">{order?.created_by?.email}</div>
                </div>

                <div>
                  <div className="title">Address</div>
                  <div className="sub_title">{order?.address?.street_address}</div>
                </div>

                <div>
                  <div className="title">City</div>
                  <div className="sub_title_mail">{order?.address?.city}</div>
                </div>

                <div>
                  <div className="title">State</div>
                  <div className="sub_title">{order?.address?.state}</div>
                </div>
                <div>
                  <div className="title">Country</div>
                  <div className="sub_title">{order?.address?.country}</div>
                </div>
              </div>
            </div>
            {/* end of first grid item */}

            {/* second grid item */}
            <div style={{ borderLeft: '1px solid #d9d8da', paddingLeft: '16px' }}>
              <div className="label">Partner</div>

              <div className="title" style={{ marginTop: '24px' }}>
                Business name
              </div>
              <div className="sub_title" style={{ marginBottom: '24px' }}>
                {order?.partner?.business_name}
              </div>

              <div className="body">
                <div>
                  <div className="title">Address</div>
                  <div className="sub_title">{order?.partner?.street_address}</div>
                </div>

                <div>
                  <div className="title">City</div>
                  <div className="sub_title_mail">{order?.partner?.city}</div>
                </div>

                <div>
                  <div className="title">State</div>
                  <div className="sub_title">{order?.partner?.state}</div>
                </div>
                <div>
                  <div className="title">Country</div>
                  <div className="sub_title">{order?.partner?.country}</div>
                </div>
              </div>
            </div>
            {/* end of second grid item */}
          </div>
        </>
      )}
    </Container>
  );
};

export default Order;
