import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false
  },
  data: {}
};

export const productReducerSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    get_product_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    get_product_error: (state) => {
      state.ui.loading = false;
    },
    get_product: (state) => {
      state.ui.loading = true;
    },
    update_product_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    update_product_error: (state) => {
      state.ui.loading = false;
    },
    update_product: (state) => {
      state.ui.loading = true;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_product,
  get_product_error,
  get_product_success,
  update_product,
  update_product_error,
  update_product_success
} = productReducerSlice.actions;

export default productReducerSlice.reducer;
