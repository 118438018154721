import React, { useState, useEffect } from 'react';
import { SideModal } from '@design-system/SideModal';
import { Header } from './components/header';
import { SearchContent } from './components/searchContent';
import CreatorContent from './components/creatorContent';
import { Table } from './table';
import { CreateFormCloseBtn, FormsModal } from '@app/globalstyles/asgard';
import CreateProduct from './components/createProduct';
import { useDispatch, useSelector } from 'react-redux';
import { get_recipe } from './redux/reducer';
import { getRecipes, getLoading, getPageStats } from './redux/selectors';
import Pagination from '@app/components/pagination';
import { FilterData } from './filterData';
import CreateRecipe from './createRecipe';
import {
  FormContainerProducts,
  SwitchProductFormTab,
  ToggleFormBtnProduct
} from '../products/style';
import CreateProductGroup from './components/CreateProductGroup';
import CloseIcon from '@app/assets/icons/CloseIcon';
import EmptyDataUi from '@app/components/emptyData';

const Recipe = () => {
  const recipeFromReducer = useSelector(getRecipes);
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [creator, setCreator] = useState({});
  const [recipes, setRecipes] = useState([]);
  const pageStats = useSelector(getPageStats);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedfilter, setSelectedFilter] = useState('');
  const [active, setActive] = useState('All');
  const [drop, setDrop] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [showCreate, setShowCreate] = useState(false);
  const [formtab, setFormTab] = useState('recipe');

  const onClose = () => {
    setDrop(!drop);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value.toLowerCase());
  };

  const toggle = () => {
    setOpen(!open);
  };

  const showModal = (data) => {
    setOpen(!open);
    setCreator(data);
  };

  const showForm = (data) => {
    setShow(true);
    setCreator(data);
    setOpen(!open);
  };

  const closeForm = () => {
    setShow(false);
  };

  const getNewRecipes = (page, limit, searchString) => {
    dispatch(get_recipe({ page, limit, selectedfilter, searchString }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewRecipes(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewRecipes(1, pageSize);
  };

  const handleFilter = (data) => {
    setActive(data.title);
    setSelectedFilter(data.value);
    onClose();
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  useEffect(() => {
    setRecipes(recipeFromReducer);
  }, [recipeFromReducer]);

  useEffect(() => {
    dispatch(
      get_recipe({
        page: currentPage,
        limit: pageSize,
        selectedfilter,
        searchString
      })
    );
  }, [dispatch, currentPage, pageSize, selectedfilter, searchString]);

  function StopBubbling(e) {
    e.stopPropagation();
  }

  return (
    <div>
      <SideModal isOpen={open} toggle={toggle}>
        <CreatorContent toggle={toggle} creator={creator} showForm={showForm} />
      </SideModal>
      {show && (
        <FormsModal show={show}>
          <CreateProduct
            showForm={showForm}
            creator={creator}
            closeForm={closeForm}
          />
        </FormsModal>
      )}

      {showCreate && (
        <FormsModal show={showCreate}>
          <FormContainerProducts onClick={StopBubbling}>
            <CreateFormCloseBtn
              id="close"
              onClick={() => setShowCreate(!showCreate)}
            >
              <CloseIcon />
            </CreateFormCloseBtn>
            <SwitchProductFormTab>
              <ToggleFormBtnProduct
                active={formtab === 'recipe'}
                onClick={() => {
                  setFormTab('recipe');
                }}
              >
                Create Recipe
              </ToggleFormBtnProduct>
              <ToggleFormBtnProduct
                active={formtab === 'product'}
                onClick={() => setFormTab('product')}
              >
                Create Product Group
              </ToggleFormBtnProduct>
            </SwitchProductFormTab>
            {formtab === 'recipe' && (
              <CreateRecipe open={showCreate} setOpen={setShowCreate} />
            )}
            {formtab === 'product' && (
              <CreateProductGroup open={showCreate} setOpen={setShowCreate} />
            )}
          </FormContainerProducts>
        </FormsModal>
      )}

      <Header setOpen={setShowCreate} />
      <SearchContent
        handleSearch={handleSearch}
        FilterData={FilterData}
        active={active}
        handleFilter={handleFilter}
        drop={drop}
        onClose={onClose}
      />
      {!loading && recipes?.length <= 0 ? (
        <EmptyDataUi />
      ) : (
        <Table showModal={showModal} recipe={recipes} loading={loading} />
      )}

      {!loading && (
        <Pagination
          onChangePageSize={handlePageSizeChange}
          itemsCount={pageStats.docs}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default Recipe;
