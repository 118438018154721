import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHealthConditions,
  getHealthConditionsLoading,
  getHealthConditionsPageStats
} from './redux/selectors';
import { Container, Header } from '../facts/styles';
import { IoSearch } from 'react-icons/io5';
import useDebounce from '@app/Hooks/useDebounce';
import { delete_health_conditions, get_health_conditions } from './redux/reducer';
import LoadingDataUi from '@app/components/loading';
import Pagination from '@app/components/pagination';
import HealthConditionsTable from './components/table';
import { FormsModal } from '@app/globalstyles/asgard';
import AddHealthConditions from './components/add';

const Health = () => {
  const health = useSelector(getHealthConditions);
  const loading = useSelector(getHealthConditionsLoading);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const pageStats = useSelector(getHealthConditionsPageStats);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [content, setContent] = useState({});
  const [search, setSearch] = useState('');
  const debouncedTerm = useDebounce(search, 500);

  useEffect(() => {
    dispatch(get_health_conditions({ page, limit, search: debouncedTerm }));
  }, [dispatch, page, limit, debouncedTerm]);

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const getNewHealthCondition = (page, limit) => {
    dispatch(get_health_conditions({ page, limit, search: debouncedTerm }));
  };

  const handlePageChange = (page) => {
    setPage(page);
    getNewHealthCondition(page, limit);
  };
  const handlePageSizeChange = () => {
    setLimit(parseInt(limit));
    setPage(1);
    getNewHealthCondition(1, limit);
  };

  const handleDelete = (doc) => {
    dispatch(delete_health_conditions(doc));
  };

  const handleEdit = (doc) => {
    setContent(doc);
    setIsEdit(!isEdit);
    setTimeout(() => {
      setShow(!show);
    }, 400);
  };

  return (
    <Container>
      {show && (
        <FormsModal show={show} onClick={() => setShow(!show)}>
          <div onClick={StopBubbling}>
            <AddHealthConditions
              onClose={() => setShow(!show)}
              content={content}
              isEdit={isEdit}
            />
          </div>
        </FormsModal>
      )}
      <Header>
        <h4>Health Conditions</h4>

        <div className="search_box">
          <IoSearch size={20} color="#64748B" />
          <input
            placeholder="Search for a health condition..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <button
          onClick={() => {
            setContent({});
            setIsEdit(false);
            setTimeout(() => {
              setShow(!show);
            }, 400);
          }}
        >
          Add Health Condition
        </button>
      </Header>

      {loading ? (
        <LoadingDataUi />
      ) : (
        <div>
          <HealthConditionsTable
            health={health}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />

          <Pagination
            itemsCount={pageStats.docs}
            currentPage={page}
            pageSize={limit}
            onChangePageSize={handlePageSizeChange}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </Container>
  );
};

export default Health;
