import React, { useState, useRef, useEffect } from 'react';
import { FilterBox, FilterDateBox } from '../style';
import ExpandIcon from '@design-system/Assets/ExpandIcon';
import { filterData } from '../basketFilterData';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styles from './styles.module.css';

const FilterQuery = ({
  open,
  setOpen,
  range,
  setRange,
  onSearch,
  setFilterDate
}) => {
  const [active, setActive] = useState('All');
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState('');

  const handleSelectFilter = (e) => {
    setActive(e.title);
    setShow(!show);
    setFilterDate(e.value);
    setHide(e.value);
  };

  const refOne = useRef(null);

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const hideOnEscape = (e) => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', hideOnClickOutside, true);
    document.addEventListener('keydown', hideOnEscape, true);
  });

  return (
    <>
      <div ref={refOne}>
        {open && (
          <DateRangePicker
            className={styles.calendarElement}
            onChange={(item) => setRange([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={range}
            direction="horizontal"
            rangeColors={['#549A9A']}
          />
        )}
      </div>
      <FilterBox>
        <div className="filter_query">
          <div className="filter_query_text">Filter by date:</div>
          <div className="filter_query_content" onClick={() => setShow(!show)}>
            {active}
            <ExpandIcon />
          </div>
          <div className={show ? 'filter_query_box' : 'hide'}>
            {filterData.map((filter, id) => (
              <div
                className={active !== filter.title ? 'filter_box_content' : 'active'}
                onClick={() => handleSelectFilter(filter)}
                key={id}
              >
                {filter.title}
              </div>
            ))}
          </div>
        </div>
      </FilterBox>

      {hide && (
        <FilterDateBox>
          <div
            className="filter_start_date"
            onClick={() => setOpen((open) => !open)}
          >
            <p>Start date</p>
            {range[0].startDate ? (
              <h5> {moment(range[0].startDate).format('LL')} </h5>
            ) : (
              <h5> - </h5>
            )}
          </div>
          <div className="filter_start_date">
            <p>End date</p>
            {range[0].endDate ? (
              <h5> {moment(range[0].endDate).format('LL')} </h5>
            ) : (
              <h5> - </h5>
            )}
          </div>

          <button onClick={onSearch}>Search</button>
        </FilterDateBox>
      )}
    </>
  );
};

export default FilterQuery;
