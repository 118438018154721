import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const transactionsAdapter = createEntityAdapter({
  selectId: (transaction) => transaction._id
});

const initialState = {
  ui: {
    loading: false,
    makingPayment: false,
    fetchingBasket: false,
    loadingMembers: false,
    updatingTracking: false
  },
  data: {},
  basket: [],
  members: [],
  transactions: transactionsAdapter.getInitialState()
};

export const groupReducerSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    get_group_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    get_group_error: (state) => {
      state.ui.loading = false;
    },
    get_group: (state) => {
      state.ui.loading = true;
    },
    update_group_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    update_group_error: (state) => {
      state.ui.loading = false;
    },
    update_group: (state) => {
      state.ui.loading = true;
    },
    get_group_basket: (state) => {
      state.ui.fetchingBasket = true;
    },
    get_group_basket_success: (state, { payload }) => {
      state.ui.fetchingBasket = false;
      state.basket = payload.products;
    },
    get_group_basket_error: (state) => {
      state.ui.fetchingBasket = false;
    },
    mark_basket_product_as_paid: (state) => {
      state.ui.makingPayment = true;
    },
    mark_basket_product_as_paid_success: (state, { payload }) => {
      state.ui.makingPayment = false;
      state.basket = state.basket.map((it) => {
        return it._id === payload._id ? payload : it;
      });
    },
    mark_basket_product_as_paid_error: (state) => {
      state.ui.makingPayment = false;
    },
    get_group_members: (state) => {
      state.ui.loadingMembers = false;
    },
    get_group_members_success: (state, { payload }) => {
      state.ui.loadingMembers = false;
      state.members = payload;
    },
    get_group_member_error: (state) => {
      state.ui.loadingMembers = false;
    },
    get_group_transactions: (state) => {
      //state.ui.loading = true
    },
    get_group_transactions_success: (state, { payload }) => {
      transactionsAdapter.setAll(state.transactions, payload);
      state.ui.loading = false;
    },
    get_group_transactions_failure: (state) => {
      state.ui.loading = false;
    },
    update_tracking: (state) => {
      state.ui.updatingTracking = true;
    },
    update_tracking_success: (state, { payload }) => {
      state.data = payload;
      state.ui.updatingTracking = false;
    },
    update_tracking_error: (state) => {
      state.ui.updatingTracking = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_group,
  get_group_error,
  get_group_success,
  update_group,
  update_group_error,
  update_group_success,
  get_group_basket,
  get_group_basket_error,
  get_group_basket_success,
  mark_basket_product_as_paid,
  mark_basket_product_as_paid_error,
  mark_basket_product_as_paid_success,
  get_group_members,
  get_group_members_error,
  get_group_members_success,
  get_group_transactions,
  get_group_transactions_failure,
  get_group_transactions_success,
  update_tracking,
  update_tracking_error,
  update_tracking_success
} = groupReducerSlice.actions;

export const transactionsSelector = transactionsAdapter.getSelectors();

export default groupReducerSlice.reducer;
