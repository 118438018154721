import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';
import { SlideIn } from '@app/components/UI/Modal/const/style';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  box-shadow: 10px 0px 30px rgba(196, 196, 196, 0.5);
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px 40px;
  //   opacity: 0.4;
  z-index: 120;

  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};
  overflow: auto;
  animation-name: ${SlideIn};
  animation-duration: 0.5s;

  ${media.smallDesktopMinimum`
  width: 50%;
  `}
  ${media.tablet`
  width: 50%;
  `}
	${media.mobile`
  width: 100%;
  `}
`;
export const ContainerBg = styled.div`
  top: 0;
  transition: all 0.3s ease-in-out;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  position: absolute;
  height: 100vh;
  background: rgba(11, 11, 11, 0.5);
  z-index: 119;
  transition: all 0.2s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
`;

export const SideNotification = styled.div`
  display: flex;
  margin-top: 10px;
  margin-top: -5px;
`;

export const Paragraph = styled.p`
  color: white;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const Menu = styled.div`
  margin-top: 20px;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
`;

export const LogoArea = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  z-index: 350;
  /* position: absolute;
  bottom: 3%; */
  img {
    cursor: pointer;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 0px;
    color: #000000;
    text-transform: capitalize;
  }

  small {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #929aa7;
  }
`;

export const SideMenuDiv = styled.div`
  display: flex;
  width: 100%;
  margin-left: -10px;
  margin-top: 10px;
  height: 40px;
  width: 50%;
  padding: 0px 15px;
  border-radius: 8px;
  img {
    width: 25px;
    margin-right: 20px;
  }

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 5px;
    display: flex;
    align-items: center;
    color: #66788a;
  }

  &.activeLink {
    background-color: ${Colors.primaryMain};
  }
  &.activeLink p {
    color: ${Colors.navTextActive};
  }
`;

export const CorporateTitle = styled.div`
  margin-top: 100px;
`;

export const AddNewProductContainer = styled.div`
  width: 100%;
  padding: 15px;
  background: #274b89;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-top: 25px;
`;
