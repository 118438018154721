import React from 'react';
import styled from 'styled-components/macro';
import emptySvg from '@assets/icons/empty-data.svg';
import { AsgardP, Img } from '@app/globalstyles/asgard';

const EmptyDiv = styled.div`
  display: grid;
  justify-content: center;
  place-items: center;
  margin-top: ${({ mt }) => (mt ? mt : '60px')};
  margin-bottom: 140px;
`;
const EmptyDivContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
function EmptyDataUi({ text = 'No Data, Sorry no data was found', mt = '' }) {
  return (
    <EmptyDiv mt={mt}>
      <EmptyDivContent>
        <AsgardP>{text}</AsgardP>
        <Img width="130px" height="130px" src={emptySvg} alt="No Data" />
      </EmptyDivContent>
    </EmptyDiv>
  );
}

export default EmptyDataUi;
