import { createSelector } from '@reduxjs/toolkit';

const staffData = (state = {}) => state.staff;

export const getStaff = createSelector(staffData, (staffData) => staffData.data);

export const getLoading = createSelector(
  staffData,
  (staffData) => staffData.ui.loading
);
