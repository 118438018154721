import React from 'react';
import { Modal } from '../../style';
import { Formik, Form } from 'formik';
import Switch from '../switch';
import { useSelector, useDispatch } from 'react-redux';
import { getCreating } from '../../redux/selectors';
import {
  create_delivery_partner,
  update_delivery_partner
} from '../../redux/reducers';

const AddDeliveryPartner = ({ mode, content }) => {
  const loading = useSelector(getCreating);
  const dispatch = useDispatch();
  let data = {
    name: content?.name || '',
    phone: content?.phone || '',
    email: content?.email || '',
    location: content?.location || '',
    description: content?.description || '',
    interstate: content?.interstate || false
  };

  const submitData = (doc) => {
    if (mode === 'add') {
      let data = {
        ...doc
      };
      dispatch(create_delivery_partner(data));
    } else {
      let data = {
        ...doc
      };
      data.email === '' && delete data['email'];
      dispatch(update_delivery_partner({ id: content._id, data }));
    }
  };
  return (
    <Modal>
      <p>{mode === 'add' ? 'Add Delivery Partner' : 'Edit Delivery Partner'}</p>

      <div className="container">
        <Formik enableReinitialize={true} initialValues={data} onSubmit={submitData}>
          {({ values, handleChange }) => (
            <Form>
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={values.name}
                placeholder="ABC Transport"
                onChange={handleChange}
              />

              <div className="grid">
                <div>
                  <label>Phone</label>
                  <input
                    type="text"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Email Address</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="abctransport@mail.com"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <label>Location</label>
              <input
                type="text"
                placeholder="Lagos,Abuja"
                value={values.location}
                onChange={handleChange}
              />
              <div className="grid">
                <div>
                  <label>Description</label>
                  <textarea
                    type="text"
                    value={values.description}
                    name="description"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Interstate</label>
                  <Switch values={values} handleChange={handleChange} />
                </div>
              </div>

              <div className="btn">
                {mode === 'add' ? (
                  <button type="submit" disabled={loading}>
                    {' '}
                    {loading ? 'Submitting' : 'Submit'}
                  </button>
                ) : (
                  <button type="submit" disabled={loading}>
                    {' '}
                    {loading ? 'Saving' : 'Save'}
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddDeliveryPartner;
