import styled, { keyframes } from 'styled-components/macro';

export const SlideIn = keyframes`
from {
    opacity: 0
}
to{
    opacity: 1
}
`;
export const TbackdropContainer = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;

  background-color: rgba(0, 0, 0, 0.5);
  transform: ${({ show }) => (show ? `translateY(0)` : `translateY(-100%)`)};
  animation-name: ${SlideIn};
  animation-duration: 0.3s;
`;
