import styled from 'styled-components';
// import { mediaQuery }

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border: 1px solid #f0f0f0;
  padding-top: 30px;
  padding-bottom: 31px;

  .header_text {
    margin-left: 27px;
    font-weight: 600;
    font-size: 25px;
    color: #284242;
  }

  button {
    border: 1px solid #549a9a;
    outline: none;
    background: #fff;
    color: #549a9a;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 39px;
  }
`;

export const ModalBody = styled.div`
  padding: 10px;

  .header {
    color: #4f4f4f;
    font-size: 20px;
    font-weight: 700;
  }
`;

export const FormBody = styled.div`
  label {
    color: #4f4f4f;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .form_content {
      display: flex;
      flex-direction: column;

      > input {
        border: 1px solid #e0e0e0;
        outline: none;
        height: 44px;
        margin-bottom: 10px;
        padding-left: 14px;
        padding-right: 14px;
      }

      > select {
        border: 1px solid #e0e0e0;
        outline: none;
        height: 47px;
        margin-bottom: 10px;
        border-radius: 0px;
      }
    }
  }

  .form_content {
    display: flex;
    flex-direction: column;

    > input {
      border: 1px solid #e0e0e0;
      outline: none;
      height: 44px;
      margin-bottom: 10px;
      padding-left: 14px;
      padding-right: 14px;
    }

    > select {
      border: 1px solid #e0e0e0;
      outline: none;
      height: 47px;
      margin-bottom: 10px;
      border-radius: 0px;
    }
  }

  .psw_container {
    display: flex;
    align-items: center;
    align-content: center;
    padding-left: 14px;
    padding-right: 14px;
    height: 44px;
    border: 1px solid #e0e0e0;

    .psw {
      border: none;
      box-sizing: border-box;
      outline: none;
      height: 42px;
      width: 100%;
    }
  }

  textarea {
    border: 1px solid #e0e0e0;
    outline: none;
    padding-left: 14px;
    padding-right: 14px;
    font-weight: 400;
    font-size: 18px;
    color: #828282;
  }

  button {
    background: #549a9a;
    height: 44px;
    border: none;
    outline: none;
    width: 200px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;

    &:disabled {
      background: #d4e7e7;
    }
  }

  .cancel {
    background: #fff;
    height: 44px;
    border: 1px solid #549a9a;
    outline: none;
    width: 200px;
    color: #549a9a;
    font-weight: 700;
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;
    margin-right: 9px;

    &:disabled {
      background: #d4e7e7;
    }
  }

  .segment {
    display: flex;
    align-items: center;
    align-content: center;
    height: 44px;
    border: 1px solid #e0e0e0;

    > .select {
      border-right: 1px solid #e0e0e0;
      border-bottom: none;
      border-top: none;
      border-left: none;

      border-radius: 0px;

      outline: none;
      margin-bottom: 0px;
      height: 43px;
    }

    > input {
      border: none;
      outline: none;
      height: 42px;
      width: 100%;
    }
  }
`;

export const VIewPage = styled.div`
  /* border: 1px solid #f0f0f0;*/
  padding-top: 30px;
  padding-bottom: 31px;
  margin-left: 27px;
  margin-right: 27px;

  .tp_btn {
    border: 1px solid #ee1717;
    outline: none;
    background: #fff;
    color: #ee1717;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
  }

  .vp_btn {
    border: none;
    outline: none;
    background: #549a9a;
    color: #fff;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
  }

  .link {
    font-weight: 500;
    font-size: 14px;
    color: #605d66;
    cursor: pointer;
    text-decoration: none;
  }

  .left_arrow {
    margin-left: 6px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    margin-left: 6px;
    color: #2b292f;
  }

  .personal_info {
    border: 1px solid #f0f0f0;
    margin-top: 24px;
  }

  .header {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;

    .circle {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: #10aa69;
      color: #fff;
      margin-right: 8px;
      display: grid;
      place-items: center;
    }

    .text {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const PartnerBox = styled.div`
  border: 1px solid #f0f0f0;
  margin-top: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    font-size: 18px;
    font-weight: 700;

    .add {
      border: 1px solid #549a9a;
      outline: none;
      background: #fff;
      color: #549a9a;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-right: 31px;
      padding-left: 32px;
      border-radius: 6px;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
    }

    .pay {
      border: none;
      outline: none;
      background: #549a9a;
      color: #fff;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-right: 31px;
      padding-left: 32px;
      border-radius: 6px;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  h4 {
    font-size: 12px;
    font-weight: 700;
    line-height: 1px;
    color: #605d66;
  }

  h5 {
    font-size: 16px;
    font-weight: 700;
    /* line-height: 1px;*/
    color: #2b292f;
  }

  .body {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;

    h4 {
      font-size: 12px;
      font-weight: 700;
      line-height: 1px;
      color: #605d66;
    }

    h5 {
      font-size: 16px;
      font-weight: 700;
      /* line-height: 1px;*/
      color: #2b292f;
    }
  }
`;

export const ProductBox = styled.div`
  margin-top: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 10px;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;

    .add {
      border: 1px solid #549a9a;
      outline: none;
      background: #fff;
      color: #549a9a;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-right: 31px;
      padding-left: 32px;
      border-radius: 6px;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .body {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    h4 {
      font-size: 12px;
      font-weight: 700;
      line-height: 1px;
      color: #605d66;
    }

    h5 {
      font-size: 16px;
      font-weight: 700;
      /* line-height: 1px;*/
      color: #2b292f;
    }
  }
`;

export const Card = styled.div`
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 0 20em;
  padding: 16px;
  cursor: pointer;
  position: relative;

  &:hover .img_overlay {
    opacity: 1;
  }

  .img_overlay {
    position: absolute;
    top: 5;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 74%;
    height: 138px;
    display: grid;
    place-items: center;
    text-transform: capitalize;
    max-width: 300px;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
  }

  .bundle {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
  }

  img {
    width: 100%;
    object-fit: contain;
    height: 177px;
    border-radius: 5px;
  }

  .title_text {
    font-weight: 500;
    font-size: 15px;
    color: #333333;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .price {
    font-weight: 600;
    font-size: 18px;
    color: #003232;
  }

  button {
    border: none;
    outline: none;
    background: #549a9a;
    color: #fff;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
    align-self: flex-start;
    border-radius: 5px;

    &:hover {
      opacity: 0.7;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .decline {
    border: none;
    outline: none;
    background: red;
    color: #fff;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
    align-self: flex-start;
    border-radius: 5px;

    &:hover {
      opacity: 0.7;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .comment {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .btn_container {
    display: flex;
    gap: 10px;
    margin-top: 16px;
  }
`;

export const ImageContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  .image_label {
    width: 100%;
    height: 150px;
    border: 1px solid #e0e0e0;
    display: grid;
    place-items: center;
  }

  .image_preview {
    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
  }
`;

export const Tabs = styled.div`
  color: #e0e0e0;
  display: inline-block;
  padding: 7px;
  cursor: pointer;
  font-size: 18px;
  width: 92%;
  margin-top: 24px;
  font-weight: 500;
  position: relative;
  margin-left: 27px;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    height: 5px;
    border-radius: 10px;
    background: #e0e0e0;
    width: 100%;
    left: 1%;
    top: calc(100% - 1px);
  }

  .tab {
    margin-top: 10px;
    color: #18191a;
    display: inline;
    justify-content: space-between;
    padding: 7.3px;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: 900;
    color: #e0e0e0;
    vertical-align: bottom;
    margin-right: 33px;
  }

  .active {
    background: none;
    color: #549a9a;
    position: relative;
    font-size: 18px;
    font-weight: 500;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      height: 4px;
      background: #549a9a;
      width: 100%;
      border-radius: 10px;
      left: 2%;
      top: calc(104% - 2px);
      z-index: 2;
    }
  }

  .content {
  }
  a {
    text-decoration: none;
  }
`;

export const TabContent = styled.div``;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const ProductDets = styled.div`
  padding: 10px;
  height: 90vh;
  overflow-y: scroll;

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
  }

  .other_images_container {
    display: grid;
    place-items: center;
    margin-top: 16px;
  }

  .other_images {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
  }

  .others {
    width: 50px;
    height: 50px;
    object-fit: contain;
    cursor: pointer;
  }

  .others_active {
    width: 50px;
    height: 50px;
    object-fit: contain;
    cursor: pointer;
    border: 2px solid #549a9a;
    padding: 2px;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
    text-transform: capitalize;
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  .badge {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  .vp_btn {
    border: none;
    outline: none;
    background: #549a9a;
    color: #fff;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
  }
`;
