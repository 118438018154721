import React from 'react';
import ExpandIcon from '@design-system/Assets/ExpandIcon';
import { SearchBox } from '../style';

export const SearchContent = ({
  handleSearch,
  FilterData,
  active,
  handleFilter,
  drop,
  onClose
}) => {
  return (
    <div>
      <SearchBox>
        <div className="search_bar">
          <input type="text" placeholder="Search" onChange={handleSearch} />
        </div>
        <div className="filter">
          <div className="filter_text">Filter by:</div>
          <div className="filter_content" onClick={onClose}>
            {active}
            <ExpandIcon />
          </div>
          <div className={drop ? 'filter_box' : 'hide'}>
            {FilterData.map((data, id) => (
              <div
                className={active !== data.title ? 'filter_box_content' : 'active'}
                onClick={() => handleFilter(data)}
                key={id}
              >
                {data.title}
              </div>
            ))}
          </div>
        </div>
      </SearchBox>
    </div>
  );
};
