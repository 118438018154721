import { createSelector } from '@reduxjs/toolkit';
import { transactionsSelector } from './reducer';

const groupData = (state = {}) => state.group;

export const getGroup = createSelector(groupData, (groupData) => groupData.data);

export const getGroupTrackingSteps = createSelector(
  getGroup,
  (group) => group.tracking_steps
);

export const getGroupBasket = createSelector(
  groupData,
  (groupData) => groupData.basket
);

export const getPaymentLoading = createSelector(
  groupData,
  (groupData) => groupData.ui.loading
);

export const getLoading = createSelector(
  groupData,
  (groupData) => groupData.ui.loading
);

export const getLoadingMembers = createSelector(
  groupData,
  (groupData) => groupData.ui.loadingMembers
);

export const getGroupMembers = createSelector(
  groupData,
  (groupData) => groupData.members
);

export const getGroupTransactions = createSelector(groupData, (data) =>
  transactionsSelector.selectAll(data.transactions)
);
