import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Dashboard from '@app/pages';
import Login from '@auth/Login';
import { ChangePassword, ResetPassword } from '@app/auth';
import { CHANGE_PASSWORD, DASHBOARD, LOGIN, RESET_PASSWORD } from '@app/routes';
import { Toaster } from 'react-hot-toast';

function App() {
  const path = window.location.pathname;
  if (path === '/') window.location.href = '/dashboard';
  return (
    <div>
      <div>
        <Toaster />
      </div>
      <BrowserRouter>
        <Switch>
          <Route path={LOGIN} component={Login} />
          <Route path={RESET_PASSWORD} component={ResetPassword} />
          <Route path={CHANGE_PASSWORD} component={ChangePassword} />
          <Route path={DASHBOARD} component={Dashboard} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
