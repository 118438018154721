import { createSelector } from '@reduxjs/toolkit';

const tagsData = (state = {}) => state.tags;

export const getTags = createSelector(tagsData, (tagsData) => tagsData.tags);

export const loadingTags = createSelector(
  tagsData,
  (tagsData) => tagsData.ui.loading
);

export const getTagsPageStats = createSelector(
  tagsData,
  (tagsData) => tagsData.ui.stats
);

export const createTags = createSelector(
  tagsData,
  (tagsData) => tagsData.ui.creating
);
