import { put, takeEvery, takeLatest, call } from '@redux-saga/core/effects';
import {
  get_customer,
  get_customer_error,
  get_customer_success,
  mark_customer_as_verified,
  mark_customer_as_verified_error,
  mark_customer_as_verified_success,
  get_customer_address,
  get_customer_address_success,
  get_customer_address_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getCustomerSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/users/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_customer_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_customer_error.type
    });
  }
}

function* markCustomerAsVerifiedSagas({ payload }) {
  try {
    const requestRes = yield call(api.patch, `/users/verify/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: mark_customer_as_verified_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: mark_customer_as_verified_error.type
    });
  }
}

function* getCustomerAddress({ payload }) {
  try {
    const request = yield call(api.get, `/addresses/byuser/${payload}`);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_customer_address_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: get_customer_address_error.type
    });
  }
}

function* customerSagas() {
  yield takeEvery(get_customer.type, getCustomerSagas);
  yield takeLatest(mark_customer_as_verified.type, markCustomerAsVerifiedSagas);
  yield takeLatest(get_customer_address.type, getCustomerAddress);
}

export default customerSagas;
