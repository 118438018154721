import { createSelector } from '@reduxjs/toolkit';

const customerData = (state = {}) => state.customer;

export const getCustomer = createSelector(
  customerData,
  (customerData) => customerData.data
);

export const getVerifyAccountLoading = createSelector(
  customerData,
  (customerData) => customerData.ui.verifyingAccount
);

export const getLoading = createSelector(
  customerData,
  (customerData) => customerData.ui.loading
);

export const getAddress = createSelector(
  customerData,
  (customerData) => customerData.address
);
