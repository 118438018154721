import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_one_order,
  get_one_order_error,
  get_one_order_success,
  get_orders,
  get_orders_error,
  get_orders_success
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getOrdersSagas({ payload }) {
  try {
    const { page = 1, limit = 10 } = payload;

    const requestRes = yield call(
      api.get,
      `/order_history?page=${page}&limit=${limit}`
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_orders_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_orders_error.type
    });
  }
}

function* getOneOrdersSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/order_history/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_one_order_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_one_order_error.type
    });
  }
}

function* orderSagas() {
  yield takeEvery(get_orders.type, getOrdersSagas);
  yield takeEvery(get_one_order.type, getOneOrdersSagas);
}

export default orderSagas;
