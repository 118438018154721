const Colors = {
  primaryMain: '#549A9A',
  primaryDark: '#003333',
  white: '#FFFFFF',
  grey: '#9FA2B4',
  navText: '#797B80',
  navTextActive: '#FFF',
  green: '#219653',
  labelGreen: '#29CC97',
  blackish: '#252733',
  red: ' #F12B2C',
  yellow: '#FEC400',
  muted: '#4B506D',
  blue: '#3751FF',
  appBg: '#C4C4C4',
  searchInputBg: '#F2F2F2',
  tableText: '#252733',
  activemenubg: '#549A9A'
};
export default Colors;
