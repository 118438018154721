import React, { useState, useEffect } from 'react';
import { FormsModal } from '@app/globalstyles/asgard';
import { FormContainerProducts } from '../products/style';
import { AddPartner } from './addPartner';
import SearchContent from './components/searchContent';
import { Header } from './style';
import Table from './table';
import {
  getPartners,
  getLoading,
  getPageStats,
  getExportData
} from './redux/selector';
import { get_export, get_partners } from './redux/reducer';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@app/components/pagination';
import useDebounce from '@app/Hooks/useDebounce';
import EmptyDataUi from '@app/components/emptyData';
import { getStates } from 'country-state-picker';

const { Parser } = require('json2csv');
const fileDownload = require('js-file-download');
const fields = [
  'Sella Name',
  'Email',
  'Business Name',
  'Products',
  'Phone Number',
  'Country',
  'State',
  'Type',
  'Delivery Fee',
  'Currency',
  'Verification Status'
];

const json2csvParser = new Parser({ fields });

const Partner = () => {
  const [show, setShow] = useState(false);
  const partner = useSelector(getPartners);
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const [filter, setFilter] = useState('');
  const pageStats = useSelector(getPageStats);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState('');
  const debouncedTerm = useDebounce(searchString, 500);
  const [active, setActive] = useState('All');
  const [drop, setDrop] = useState(false);
  const [filterState, setFilterState] = useState('');
  const exportData = useSelector(getExportData);

  let states = getStates('ng');

  useEffect(() => {
    dispatch(
      get_export({
        page: 1,
        limit: 1000
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      get_partners({
        page: currentPage,
        limit: pageSize,
        searchString: debouncedTerm,
        verify_stat: filter,
        states: filterState
      })
    );
  }, [dispatch, currentPage, pageSize, debouncedTerm, filter, filterState]);

  const onClose = () => {
    setDrop(!drop);
  };

  const filterStateChange = (e) => {
    setFilterState(e.target.value);
  };

  const handleFilter = (data) => {
    setActive(data.title);
    setFilter(data.value);
    onClose();
  };

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const getNewPartner = (page, limit) => {
    dispatch(
      get_partners({
        page,
        limit,
        searchString: debouncedTerm,
        verify_stat: filter,
        states: filterState
      })
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewPartner(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewPartner(1, pageSize);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  const download = () => {
    let temp = [];
    exportData.forEach((sella) => {
      let obj = {
        'Sella Name': sella?.user?.first_name + ' ' + sella?.user?.last_name,
        Email: sella?.user?.email,
        'Business Name': sella?.business_name,
        Products: sella?.products,
        'Phone Number': sella?.phone,
        Country: sella?.country,
        State: sella?.state,
        Type: sella?.type,
        'Delivery Fee': sella?.delivery_fee?.value,
        Currency: sella?.currency?.short_code,
        'Verification Status':
          sella?.account_verified === true ? 'Verified' : 'Not Verified'
      };
      temp.push(obj);
    });
    const csv = json2csvParser.parse(temp);
    fileDownload(csv, 'Sellas.csv');
  };

  return (
    <div>
      {show && (
        <FormsModal show={show} onClick={() => setShow(false)}>
          <FormContainerProducts onClick={StopBubbling}>
            <AddPartner />
          </FormContainerProducts>
        </FormsModal>
      )}
      <Header>
        <p className="header_text">Partners Overview</p>

        <div>
          <button onClick={() => setShow(true)}>Add Partner</button>
          <button onClick={download}>Export</button>
        </div>
      </Header>

      <SearchContent
        handleChange={handleSearch}
        active={active}
        handleFilter={handleFilter}
        drop={drop}
        onClose={onClose}
        showState={true}
        states={states}
        filterStateChange={filterStateChange}
      />

      {!loading && partner.length <= 0 ? (
        <EmptyDataUi />
      ) : (
        <Table loading={loading} partners={partner} />
      )}

      <Pagination
        itemsCount={pageStats.docs}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangePageSize={handlePageSizeChange}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Partner;
