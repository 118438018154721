import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  ActionBtnDiv,
  FormItemContainer,
  ProductFormBody,
  ProductFormContainer,
  ProductFormLeft,
  ProductFormMain,
  ProductFormRight
} from '@app/pages/products/addproduct/style';

import { AsgardP, Button, FormCloseBtnContainer } from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';
import Divider from '@app/globalstyles/dividerLine';
import { CustomInput } from '@app/components/forms/InputGen';
import SelectInputP from '@app/pages/products/addproduct/select';
import ErrorMessage from '@app/components/forms/errorMessage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_currencies } from '@app/pages/currrencies/redux/reducer';
import { getProduct } from '../redux/selectors';
import { update_product } from '../redux/reducer';
import { getCurrencies } from '@app/pages/currrencies/redux/selectors';
import { ProductUpdateFormDiv } from '../style';
import { CloseFormButton } from '@app/components/forms/CloseFormButton';

const AddProductSize = ({ closeform }) => {
  const dispatch = useDispatch();
  const currencies = useSelector(getCurrencies);
  const product = useSelector(getProduct);
  let initialValues = {
    currency: product.price?.currency._id || '',
    price: product.price.value || 0,
    value: product.quantity || 0
  };

  const validationSchema = Yup.object().shape({
    currency: Yup.string().min(3).required().label('Currency'),
    price: Yup.number().required().label('Price'),
    value: Yup.number()
  });

  const handleCreateProduct = async (doc) => {
    const size = {
      price: {
        currency: doc.currency,
        value: doc.price
      },
      value: doc.value
    };
    let { sizes } = product;
    const newSizes = [...sizes, size];
    const data = {
      sizes: newSizes
    };
    dispatch(update_product({ id: product._id, data }));
    closeform();
  };

  useEffect(() => {
    dispatch(get_currencies());
  }, [dispatch]);

  return (
    <ProductUpdateFormDiv>
      <ProductFormContainer>
        <div>
          <AsgardP tAlign="center" fw="bold" fs="20px" color={Colors.black}>
            Add New Product Size
          </AsgardP>
          <FormCloseBtnContainer>
            <CloseFormButton onClick={closeform} />
          </FormCloseBtnContainer>
        </div>
        <Divider bg={Colors.primaryMain} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values) => handleCreateProduct(values)}
        >
          {({
            handleChange,
            handleSubmit,
            errors,
            setFieldTouched,
            touched,
            values
          }) => (
            <Form onSubmit={handleSubmit}>
              <ProductFormMain>
                <ProductFormBody>
                  <ProductFormLeft>
                    <FormItemContainer>
                      <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                        Currency
                      </AsgardP>
                      <SelectInputP
                        name="currency"
                        value={values.currency}
                        onBlur={() => setFieldTouched('currency')}
                        onChange={handleChange('currency')}
                        label="Select Currency"
                      >
                        {currencies.map((currency) => (
                          <option key={currency._id} value={currency._id}>
                            {currency.short_code} - {currency.symbol}{' '}
                          </option>
                        ))}
                      </SelectInputP>
                    </FormItemContainer>

                    <ErrorMessage
                      error={errors.currency}
                      visible={touched.currency}
                    />

                    <FormItemContainer>
                      <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                        Weight
                      </AsgardP>
                      <CustomInput
                        error={errors.value && touched.value}
                        type="value"
                        name="value"
                        value={values.value}
                        placeholder="Enter Individual MOQ"
                        onBlur={() => setFieldTouched('value')}
                        onChange={handleChange('value')}
                      />
                    </FormItemContainer>
                    <ErrorMessage error={errors.value} visible={touched.value} />
                  </ProductFormLeft>
                  <ProductFormRight>
                    <FormItemContainer>
                      <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                        Price
                      </AsgardP>
                      <CustomInput
                        error={errors.price && touched.price}
                        type="number"
                        name="price"
                        value={values.price}
                        placeholder="Price"
                        onBlur={() => setFieldTouched('price')}
                        onChange={handleChange('price')}
                      />
                    </FormItemContainer>
                    <ErrorMessage error={errors.price} visible={touched.price} />
                  </ProductFormRight>
                </ProductFormBody>
                <ActionBtnDiv>
                  <Button
                    type="submit"
                    p="10px 30px"
                    bc={Colors.primaryMain}
                    br="5px"
                    mr="15px"
                    color={Colors.white}
                    hbc={Colors.blackish}
                    onClick={handleSubmit}
                  >
                    Create Size
                  </Button>
                </ActionBtnDiv>
              </ProductFormMain>
            </Form>
          )}
        </Formik>
      </ProductFormContainer>
    </ProductUpdateFormDiv>
  );
};

export default AddProductSize;
