export const CouponsFilterData = [
  {
    id: 1,
    title: 'Active',
    value: 'paid=true'
  },

  {
    id: 2,
    title: 'Not Active',
    value: 'paid=true'
  }
];
