import React from 'react';
import { MealCardStyle } from '../meal.styles';

const MealCard = ({ title, type, countries }) => {
  return (
    <MealCardStyle>
      <p className="title">{title}</p>
      <div className="sub">Type: {type.join(', ')}</div>
      <div className="sub" style={{ marginTop: '5px' }}>
        Countries: {countries.join(', ')}
      </div>
    </MealCardStyle>
  );
};

export default MealCard;
