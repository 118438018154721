import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateHealthCondition } from '../redux/selectors';
import {
  create_health_conditions,
  update_health_conditions
} from '../redux/reducer';
import { FormComponent } from '@app/pages/facts/styles';
import { Formik, Form } from 'formik';

const AddHealthConditions = ({ onClose, isEdit, content }) => {
  const types = ['Dietary', 'Medical', 'Allergy'];

  const loading = useSelector(CreateHealthCondition);
  const dispatch = useDispatch();
  let initialValues = {
    name: content?.name || '',
    type: content?.type || '',
    description: content?.description || ''
  };

  const handleSubmit = (doc) => {
    if (isEdit) {
      dispatch(update_health_conditions({ id: content?._id, data: doc }));
    } else {
      dispatch(create_health_conditions({ data: doc, onClose }));
    }
  };
  return (
    <FormComponent>
      {isEdit ? <h3>Edit Health Condition</h3> : <h3>Add Health Condition</h3>}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange }) => (
          <Form>
            <div className="form">
              <div className="form_content">
                <label>Name</label>
                <input
                  placeholder="Name"
                  value={values.name}
                  onChange={handleChange}
                  name="name"
                  required
                />
              </div>

              <div className="form_content">
                <label>Type</label>
                <select value={values.type} onChange={handleChange} name="type">
                  <option selected disabled>
                    Select Type
                  </option>
                  {types.map((data, idx) => (
                    <option key={idx} value={data}>
                      {data}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form_content">
                <label>Description</label>
                <textarea
                  placeholder="Description"
                  value={values.description}
                  onChange={handleChange}
                  name="description"
                  required
                  rows={5}
                />
              </div>
            </div>

            {isEdit ? (
              <button type="submit">{loading ? 'Saving...' : 'Save'}</button>
            ) : (
              <button type="submit">{loading ? 'Creating...' : 'Create'}</button>
            )}
          </Form>
        )}
      </Formik>
    </FormComponent>
  );
};

export default AddHealthConditions;
