import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const ProductFormContainer = styled.div`
  width: 60%;
  background: #ffffff;
  border-radius: 7px;
  ${media.tablet`
  width: 60%;
  overflow-y: scroll;
  `}
  ${media.smallDesktopMinimum`
  width: 95%;
  padding: 15px;
  `}
  ${media.tablet`
  width: 90%;
  padding: 15px;

  `}
  ${media.mobile`
  height: 100%;
  width: 95%;
 
  `}
`;

export const AddProductFormDiv = styled.div`
  width: 30%;
  background: #ffffff;
  padding: 20px 20px;
  box-shadow: 0px 0px 13px 1px rgba(63, 63, 68, 0.24),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  border-radius: 22px;
  ${media.tablet`
  width: 60%;
  `}
  ${media.mobile`
  width: 80%;
  `}
`;
export const ProductFormMain = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  ${media.tablet`
  flex-direction: column;
 
  `}
  ${media.mobile`
  flex-direction: column;
  `}
`;
export const ProductFormBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 30px;
  ${media.tablet`
  
  `}
  ${media.mobile`
  gap: 10px;
  flex-direction: column;
  `}
`;

export const ProductFormLeft = styled.div`
  width: 45%;
  ${media.tablet`
  width: 100%;
  `}
  ${media.mobile`
  width: 100%;
  `}
`;
export const ProductFormRight = styled.div`
  width: 45%;
  ${media.tablet`
  width: 100%;
  `}
  ${media.mobile`
  width: 100%;
  `}
`;

export const FormItemContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    max-width: 70px;
  }
  ${media.mobile`
  align-items: start;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top:1px;
  p {
    max-width: 100%;
    margin-bottom:5px;
  }
  `}
`;
export const FormItemCheckContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  ${media.mobile`
 
  margin-top:1px;
  `}
`;
export const PImageUpload = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-top: 20px;
  button {
    margin-left: 30px;
  }
  ${media.smallDesktopMinimum`
  padding-left: 10px;
  margin-top: 20px;
  `}
  ${media.tablet`
  padding-left: 10px;
  margin-top: 20px;
  `}
  ${media.mobile`
  padding-left: 10px;
  margin-top: 20px;
  margin-bottom:40px;
  `}
`;
export const PreviewImgDiv = styled.div`
  width: 78px;
  height: 78px;
  border-radius: 50%;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
  }
`;
export const ActionBtnDiv = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const CheckboxDiv = styled.div`
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    background: red;
    border: 1px solid '#F45';
  }
  input::checked {
    background-color: #f90;
    border: 5px solid '#F45';
  }
`;
