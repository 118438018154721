import React from 'react';
import styles from './styles.module.css';

const Switch = ({ change, status }) => {
  return (
    <div>
      <label className={styles.switch}>
        <input type="checkbox" onChange={change} value={status} checked={status} />
        <span className={styles.slider} />
      </label>
    </div>
  );
};

export default Switch;
