import React, { useState, useEffect } from 'react';
import {
  Header,
  Container,
  FormGrid,
  CreateButton,
  Grid,
  ServingBox,
  SuggestionBox,
  Crumb,
  MealBody,
  ShowButton,
  ProductButtonContainer
} from './style';
import { Formik, Form, FieldArray } from 'formik';
import { getProductCatgories } from '@app/pages/product-categories/redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { getCountries } from 'country-state-picker';
import { MEASUREMENTS } from '../products/filterData';
import { getMealProducts } from '../products/redux/selectors';
import { ProductItemsDiv, ProductOption } from '../products/addmealpack/style';
import { useParams } from 'react-router-dom';
import { getRecipe, getRecipeServings } from '../recipe/redux/selector';
import { get_recipes } from '../recipe/redux/reducer';
import { getCurrencies } from '../currrencies/redux/selectors';
import { get_currencies } from '../currrencies/redux/reducer';
import { create_product, get_meal_products } from '../products/redux/reducer';
import useDebounce from '@app/Hooks/useDebounce';
import { get_product_categories } from '../product-categories/redux/reducer';
import left from '@design-system/Assets/left.png';
import { Link } from 'react-router-dom';
import { Sidemodal } from '@design-system/SideModal/style';
import { FormsModal } from '@app/globalstyles/asgard';
import { FormContainerProducts } from '../products/style';
import { SwitchProductFormTab } from '../products/style';
import { ToggleFormBtnProduct } from '../products/style';
import AddProductForm from '../products/addproduct';

const cook = ['Low Effort', 'Medium Effort', 'Cook time'];
const priceCat = ['Sapa Friendly', 'Meduim Class', 'Gourmet'];

const ConvertMealPage = () => {
  const currencies = useSelector(getCurrencies);
  const countries = getCountries();
  const categories = useSelector(getProductCatgories);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showMeal, setShowMeal] = useState(false);
  const suggestions = useSelector(getMealProducts);
  const serving = useSelector(getRecipeServings);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [search, setSearch] = useState('');
  const params = useParams();
  const recipe = useSelector(getRecipe);
  const dispatch = useDispatch();
  const debouncedTerm = useDebounce(search, 500);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState('');
  const [hide, setHide] = useState(false);
  const [formtab, setFormTab] = useState('product');

  useEffect(() => {
    const result = categories?.find((category) => {
      return category?.name === 'Meal Pack';
    });
    setCategory(result?._id);
  }, [categories]);

  let initialValues = {
    name: recipe.name,
    description: recipe.description,
    country: '',
    cook_time: recipe.prep_time,

    other_ingredients: [
      {
        name: '',
        product: '',
        measurement: '',
        unit: '',
        price: {
          value: '',
          currency: ''
        }
      }
    ],

    base_ingredients: {
      0: {
        product: '',
        name: '',
        measurement: 0,
        servings: {}
      }
    }
  };

  const onSubmit = async (doc) => {
    const element = doc.base_ingredients;
    const baseIngredientsLength = element?.length || 1;
    const base = [];

    for (let i = 0; i < baseIngredientsLength; i++) {
      const servings = Object.keys(element[i].servings);
      servings.forEach((serving) => {
        const newProduct = {
          ...element[i].servings[serving],
          name: element[i].name,
          product: element[i].product
        };
        base.push(newProduct);
      });
    }

    const data = {
      ...doc,
      base_ingredients: base,
      recipe: recipe._id,
      quantity: 1,
      product_category: category,
      recipe_creator: recipe.created_by._id,
      image: recipe.images[0],
      other_images: recipe.images,
      price: {
        value: 1,
        currency: '61ec4753f5c1cf7132e2d2ca'
      }
    };

    if (
      data.other_ingredients.length === 1 &&
      data.other_ingredients[0].name.length < 1
    ) {
      delete data.other_ingredients;
    }

    dispatch(create_product(data));
  };

  const hideOnEscape = (e) => {
    if (e.key === 'Escape') {
      setShowSuggestions(false);
      setShowMeal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', hideOnEscape, true);
  });

  useEffect(() => {
    dispatch(get_recipes(params.id));
    dispatch(get_product_categories());
    dispatch(get_currencies());
  }, [dispatch, params]);

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const handleForm = () => {
    setHide(!hide);
  };

  useEffect(
    () => {
      if (debouncedTerm) {
        dispatch(get_meal_products({ search: debouncedTerm }));
      }
    },
    [debouncedTerm, dispatch] // Only call effect if debounced search term changes
  );

  const handleSearhChange = (idx, text) => {
    setCurrentIndex(idx);
    setSearch(text);
    setShowSuggestions(true);
  };

  const handleTextChange = (idx, text) => {
    setCurrentIndex(idx);
    setSearch(text);
    setShowMeal(true);
  };
  return (
    <>
      <Header>Convert to Meal</Header>
      <Sidemodal isOpen={show} toggle={() => setShow(!show)}>
        <MealBody>
          <div className="ingredients_list">
            <p className="title">Ingredients</p>
            <ul>
              {recipe?.ingredients?.map((data) => (
                <li key={data._id}>
                  {data.food_item} - ({data.measurement}) -{' '}
                  {`${data.servings} servings`}
                </li>
              ))}
            </ul>
          </div>
        </MealBody>
      </Sidemodal>
      {hide && (
        <FormsModal show={hide} onClick={handleForm}>
          <FormContainerProducts onClick={StopBubbling}>
            <SwitchProductFormTab>
              <ToggleFormBtnProduct
                active={formtab === 'product'}
                onClick={() => setFormTab('product')}
              >
                Create Product
              </ToggleFormBtnProduct>
            </SwitchProductFormTab>
            {formtab === 'product' && <AddProductForm closeform={handleForm} />}
          </FormContainerProducts>
        </FormsModal>
      )}

      <ShowButton onClick={() => setShow(!show)}>Ingredients</ShowButton>

      <ProductButtonContainer>
        <button onClick={handleForm}>Create Product</button>
      </ProductButtonContainer>
      <Crumb>
        <Link to={`/recipe/${params.id}`} className="title">
          <img src={left} alt="left_arrow" />
          {recipe.name}
        </Link>
      </Crumb>

      <Container>
        <div className="form_container">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => onSubmit(values)}
          >
            {({
              values: formikValues,
              handleChange,
              setFieldValue,
              handleSubmit
            }) => (
              <Form className="form">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Village Rice"
                  name="name"
                  value={formikValues.name}
                  onChange={handleChange('name')}
                />

                <label>Description</label>
                <input
                  type="text"
                  placeholder="Village Rice"
                  value={formikValues.description}
                  name="description"
                  onChange={handleChange('description')}
                />

                <FormGrid>
                  <div className="form_content">
                    <label>Price Cateory</label>
                    <select
                      name="price_category"
                      onChange={handleChange('price_category')}
                    >
                      <option selected disabled value="">
                        --Select-
                      </option>
                      {priceCat.map((data, index) => (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form_content">
                    <label>Cook Time</label>
                    <select
                      name="cook_time"
                      value={formikValues.cook_time}
                      onChange={handleChange('cook_time')}
                    >
                      <option selected disabled value="">
                        --Select--
                      </option>
                      {cook.map((data, index) => (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      ))}
                    </select>
                  </div>
                </FormGrid>
                <label>Country</label>
                <select
                  name="country"
                  label="Select Country"
                  onChange={handleChange('country')}
                >
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>

                <div className="title">Base Ingredients</div>

                <FieldArray name="base_ingredients">
                  {({ push }) => (
                    <>
                      {formikValues?.base_ingredients ? (
                        <>
                          {Object.keys(formikValues?.base_ingredients)?.map(
                            (item, idx) => (
                              <Grid key={idx}>
                                <div className="form_container">
                                  <label>Food Item</label>
                                  <input
                                    type="text"
                                    name={`item?.${idx}?.${item}`}
                                    placeholder="Search for product"
                                    value={
                                      formikValues?.base_ingredients[item]?.name
                                    }
                                    onChange={(e) => {
                                      let products = formikValues?.base_ingredients;
                                      let product = products[item];
                                      const data = {
                                        ...product,
                                        name: e.target.value
                                      };
                                      products[item] = data;
                                      setFieldValue('base_ingredients', products);
                                      handleSearhChange(item, e.target.value);
                                    }}
                                  />
                                </div>
                                {serving &&
                                  Object?.keys(serving)?.map((value) => (
                                    <ServingBox key={value}>
                                      <div className="form_box">
                                        <select
                                          name="type"
                                          onChange={(e) => {
                                            let products =
                                              formikValues?.base_ingredients;
                                            let product = products[item];
                                            const data = {
                                              ...product,
                                              servings: {
                                                ...product?.servings,
                                                [value]: {
                                                  unit: e.target.value,
                                                  servings: value
                                                }
                                              }
                                            };
                                            products[item] = data;
                                            setFieldValue(
                                              'base_ingredients',
                                              products
                                            );
                                          }}
                                        >
                                          <option disabled>Unit</option>
                                          {MEASUREMENTS.map((weight, id) => (
                                            <option key={id} value={weight}>
                                              {weight}
                                            </option>
                                          ))}
                                        </select>
                                        <input
                                          type="text"
                                          placeholder="0"
                                          onChange={(e) => {
                                            let products =
                                              formikValues?.base_ingredients;
                                            let product = products[item];
                                            const data = {
                                              ...product,
                                              servings: {
                                                ...product?.servings,
                                                [value]: {
                                                  ...product.servings[value],
                                                  measurement: e.target.value
                                                }
                                              }
                                            };
                                            products[item] = data;
                                            setFieldValue(
                                              'base_ingredients',
                                              products
                                            );
                                          }}
                                        />
                                      </div>

                                      <div className="form_box">
                                        <select
                                          name="type"
                                          onChange={(e) => {
                                            let products =
                                              formikValues?.base_ingredients;
                                            let product = products[item];
                                            const data = {
                                              ...product,
                                              servings: {
                                                ...product?.servings,
                                                [value]: {
                                                  ...product?.servings[value],
                                                  price: {
                                                    currency: e.target.value
                                                  }
                                                }
                                              }
                                            };
                                            products[item] = data;
                                            setFieldValue(
                                              'base_ingredients',
                                              products
                                            );
                                          }}
                                        >
                                          <option disabled>Currency</option>
                                          {currencies.map((currency) => (
                                            <option
                                              key={currency._id}
                                              value={currency._id}
                                            >
                                              {currency.short_code} -{' '}
                                              {currency.symbol}
                                            </option>
                                          ))}
                                        </select>
                                        <input
                                          type="number"
                                          placeholder="0"
                                          onChange={(e) => {
                                            let products =
                                              formikValues?.base_ingredients;
                                            let product = products[item];

                                            const data = {
                                              ...product,
                                              servings: {
                                                ...product?.servings,
                                                [value]: {
                                                  ...product?.servings[value],
                                                  price: {
                                                    ...product?.servings[value]
                                                      .price,
                                                    value: e.target.value
                                                  }
                                                }
                                              }
                                            };

                                            products[item] = data;
                                            setFieldValue(
                                              'base_ingredients',
                                              products
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="serving_box">
                                        {`${value} servings`}
                                      </div>
                                    </ServingBox>
                                  ))}
                              </Grid>
                            )
                          )}
                          <div
                            className="add"
                            onClick={() => {
                              push({
                                product: '',
                                name: '',
                                servings: 0,
                                measurement: 0,
                                unit: 'kg'
                              });
                            }}
                          >
                            Add Ingredient
                          </div>
                        </>
                      ) : null}

                      {suggestions?.length > 0 && showSuggestions ? (
                        <ProductItemsDiv>
                          {suggestions.map((item, id) => (
                            <ProductOption
                              key={id}
                              onClick={() => {
                                let products = formikValues?.base_ingredients;
                                const data = {
                                  ...products[currentIndex],
                                  product: item._id,
                                  name: item.name
                                };
                                products[currentIndex] = data;
                                setFieldValue('base_ingredients', products);
                                setSearch(item.name);
                                setShowSuggestions(false);
                              }}
                              className="list is-hoverable"
                            >
                              {item?.name}
                            </ProductOption>
                          ))}
                        </ProductItemsDiv>
                      ) : null}
                    </>
                  )}
                </FieldArray>

                <div className="title">Other Ingredients</div>
                <FieldArray name="other_ingredients">
                  {({ push }) => (
                    <>
                      {formikValues.other_ingredients &&
                        formikValues.other_ingredients.length > 0 &&
                        formikValues.other_ingredients.map((item, idx) => (
                          <Grid>
                            <div className="form_container">
                              <label>Food Item</label>
                              <input
                                type="text"
                                placeholder="Search for product"
                                name={`item.${idx}.${item}`}
                                value={item.name}
                                onChange={(e) => {
                                  let products = formikValues.other_ingredients;
                                  let product = products[idx];
                                  const data = {
                                    ...product,
                                    name: e.target.value
                                  };
                                  products[idx] = data;
                                  setFieldValue('other_ingredients', products);
                                  handleTextChange(idx, e.target.value);
                                }}
                              />
                            </div>
                            <ServingBox>
                              <div className="form_box">
                                <select
                                  name="type"
                                  onChange={(e) => {
                                    let products = formikValues.other_ingredients;
                                    let product = products[idx];
                                    const data = {
                                      ...product,
                                      unit: e.target.value
                                    };

                                    products[idx] = data;
                                    setFieldValue('other_ingredients', products);
                                  }}
                                >
                                  <option disabled>Unit</option>
                                  {MEASUREMENTS.map((weight, id) => (
                                    <option key={id} value={weight}>
                                      {weight}
                                    </option>
                                  ))}
                                </select>
                                <input
                                  type="text"
                                  placeholder="0"
                                  value={item.measurement}
                                  onChange={(e) => {
                                    let products = formikValues.other_ingredients;
                                    let product = products[idx];
                                    const data = {
                                      ...product,
                                      measurement: e.target.value
                                    };
                                    products[idx] = data;
                                    setFieldValue('other_ingredients', products);
                                  }}
                                />
                              </div>
                              <div className="form_box">
                                <select
                                  name="type"
                                  onChange={(e) => {
                                    let products = formikValues.other_ingredients;
                                    let product = products[idx];
                                    const data = {
                                      ...product,
                                      price: {
                                        currency: e.target.value
                                      }
                                    };

                                    products[idx] = data;
                                    setFieldValue('other_ingredients', products);
                                  }}
                                >
                                  <option disabled>Currency</option>
                                  {currencies.map((currency) => (
                                    <option key={currency._id} value={currency._id}>
                                      {currency.short_code} - {currency.symbol}
                                    </option>
                                  ))}
                                </select>
                                <input
                                  type="number"
                                  placeholder="0"
                                  onChange={(e) => {
                                    let products = formikValues.other_ingredients;
                                    let product = products[idx];
                                    const data = {
                                      ...product,
                                      price: {
                                        ...item.price,
                                        value: e.target.value,
                                        currency: item.price.currency
                                      }
                                    };

                                    products[idx] = data;
                                    setFieldValue('other_ingredients', products);
                                  }}
                                />
                              </div>
                            </ServingBox>
                          </Grid>
                        ))}
                      <div
                        className="add"
                        onClick={() => {
                          push({
                            product: '',
                            name: '',
                            measurement: 0,
                            unit: '',
                            price: {
                              value: 1,
                              currency: ''
                            }
                          });
                        }}
                      >
                        Add Ingredient
                      </div>
                      {suggestions?.length > 0 && showMeal ? (
                        <SuggestionBox>
                          {suggestions.map((item, id) => (
                            <ProductOption
                              key={id}
                              onClick={() => {
                                let products = formikValues.other_ingredients;
                                const data = {
                                  product: item._id,
                                  name: item.name
                                };
                                products[currentIndex] = data;
                                setFieldValue('other_ingredients', products);
                                setSearch(item.name);
                                setShowMeal(false);
                              }}
                              className="list is-hoverable"
                            >
                              {item?.name}
                            </ProductOption>
                          ))}
                        </SuggestionBox>
                      ) : null}
                    </>
                  )}
                </FieldArray>

                <div className="image_container">
                  {recipe?.images?.length >= 1 &&
                    recipe?.images?.map((image, id) => (
                      <img src={image} alt="selected" key={id} />
                    ))}
                </div>

                <CreateButton type="submit">Create meal</CreateButton>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default ConvertMealPage;
