import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 30px;

  p {
    font-weight: 600;
    font-size: 25px;
    color: #284242;
  }

  button {
    border: none;
    outline: none;
    background: #549a9a;
    color: #fff;
    height: 45px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const Search = styled.input`
  width: 30%;
  box-sizing: border-box;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  backgroud: none;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px;
  margin-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;

  &:placeholder {
    font-weight: 500;
    font-size: 18px;
    color: #bdbdbd;
  }
`;

export const Dot = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-content: center;

  .dot {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #d9d8da;
  }
`;

export const Menu = styled.div`
  position: absolute;
  z-index: 1;
  background: #fff;
  border-radius: 10px;
  color: #000;
  width: 60px;
  margin-left: -30px;
  margin-top: 10px;
  padding: 10px;
  box-shadow: 0px 0px 6px rgba(43, 41, 47, 0.1);

  .list {
    display: flex;
    flex-direction: column;
    ${'' /* gap: 10px; */}
  }
  p {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
  p:nth-child(3) {
    color: red;
  }
`;

export const Modal = styled.div`
  padding: 20px;

  p {
    font-size: 18px;
    font-weight: 600;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  h4 {
    margin-top: -2px;
  }

  label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  input {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    outline: none;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 8px;
  }

  textarea {
    width: 100%;
    box-sizing: border-box;
    outline: none;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 8px;
  }

  .btn {
    display: grid;
    place-items: center;
  }

  button {
    border: none;
    outline: none;
    background: #549a9a;
    color: #fff;
    height: 45px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 30px;
    width: 100%;
  }
`;

export const Pill = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  glex-direction: row;

  .content {
    border-radius: 20px;
    background: #cfeee1;
    color: #10aa69;
    padding: 5px;
  }
`;
