import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const CouponsFormContainer = styled.div`
  width: 60%;
  background: #ffffff;
  border-radius: 7px;
  ${media.tablet`
  width: 60%;
  overflow-y: scroll;
  `}
  ${media.smallDesktopMinimum`
  width: 95%;
  padding: 15px;
  `}
  ${media.tablet`
  width: 90%;
  padding: 15px;

  `}
  ${media.mobile`
  height: 100%;
  width: 95%;
 
  `}
`;

export const AutoCompDiv = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 25px;
`;
export const AutoCompDivContainer = styled.div`
  max-width: 50%;
  display: flex;
`;

export const AutoFormItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    max-width: 70px;
  }
  ${media.mobile`
  align-items: start;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top:1px;
  p {
    max-width: 100%;
    margin-bottom:5px;
  }
  `}
`;
export const MTextArea = styled.textarea`
  width: 85.5%;
  height: 75px;
  background: #ffffff;
  border: 1px solid #549a9a;
  border-radius: 5px;
  padding: 5px 5px;
  &:focus {
    outline: none;
    border: 1px solid #549a9a;
  }

  ${media.mobile`
  width: 97%;
  `}
`;
export const TextareaDiv = styled.div`
  /* width: 100%; */
  margin-top: -15px;
  display: flex;
  /* background: red; */
  justify-content: space-between;
  /* gap: 0px 30px; */
  padding: 20px 25px;
  p {
    margin-top: 1px;
  }
  ${media.mobile`
flex-direction: column;
padding: 10px 0px;
p {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  `}
`;

export const SelectedIngredientLab = styled.div`
  max-width: 300px;
  height: 30px;
  display: flex;
  font-size: 12px;
  padding: 0px 20px;
  text-transform: capitalize;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #549a9a;
  box-sizing: border-box;
  border-radius: 20px;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
    background: ${Colors.grey};
  }
`;

export const AutocompleteInput = styled.div`
  width: 67%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  background: #ffffff;
  border: 1px solid #549a9a;
  border-radius: 5px;
  input {
    width: 80%;
    height: 55%;
    padding: 5px 10px;
    outline: none;
    border: none;
    flex: 1;
  }
  input:focus {
    outline: none;
    border: none;
  }
  ${media.mobile`
  width: 100%;
  `}
`;

export const IngredInputDiv = styled.div`
  width: 45%;
  padding: 20px 10px;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  /* background: red; */
  justify-content: space-between;
  /* gap: 0px 30px; */

  p {
    margin-top: 1px;
  }
  ${media.mobile`
flex-direction: column;
padding: 10px 0px;
p {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  `}
`;

export const FormHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const PercentBlock = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;

  input {
    border: none;
    outline: none;
  }

  select {
    border-radius: 0;
    width: 95px;
    border: 1px solid #e0e0e0;
    height: 42px;
    border-radius-top-left: 5px;
    border-radius-bottom-left: 5px;
  }
`;
