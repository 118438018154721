import styled from 'styled-components';
import Colors from '@app/globalstyles/utils/colors';

export const GroupBody = styled.div`
  overflow: hidden;
`;

export const GroupContainer = styled.div`
  width: 100%;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  font-size: 24px;
  color: #003232;
  padding-top: 29.5px;
  padding-bottom: 29.5px;
  padding-left: 14.65px;
`;

export const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 50px;
  padding-left: 14.65px;

  .group_title {
    font-weight: 500;
    font-size: 15px;
    color: #bdbdbd;
    margin-right: 5px;
  }

  .group_name {
    font-weight: 500;
    font-size: 15px;
    color: #549a9a;
  }
`;

export const TabGroup = styled.div`
  color: #e0e0e0;
  display: inline-block;
  padding: 7px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  font-weight: 500;
  position: relative;
  margin-top:21px;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    height: 5.5px;
    border-radius: 10px;
    background: #e0e0e0;
    width: 100%;
    left: 1%;
    top: calc(4.8% - 1px);
  }

  .tab {
    margin-top: 10px;
    color: #18191a;
    display: inline;
    justify-content: space-between;
    padding: 7.3px;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: 900;
    color: #e0e0e0;
    vertical-align: bottom;
    margin-right: 20px;
  }

  .active {
    background: none;
    color: #549a9a;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    

    &:after{
      content: " ";
      display: block;
      position: absolute;
      height: 5px;
      background: #549a9a;
      width: 100%;
      border-radius: 10px;
      left: 0;
      top: calc(104% - 2px);
      z-index: 2;
    }

`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow-x: scroll;

  thead {
    background: #f2f2f2;
    width: 100%;
  }

  th {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    color: #4f4f4f;
    padding-left: 12px;
    padding-top: 12.97px;
    padding-bottom: 12.97px;
  }

  td {
    padding-left: 12px;
    padding-top: 12.97px;
    padding-bottom: 12.97px;
    border-bottom: 1px solid #f2f2f2;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }

  .user_info {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .user_img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #fff;
    background: #549a9a;
    margin-right: 10px;
  }

  .email {
    font-weight: 400;
    font-size: 12px;
    color: #828282;
  }
`;

export const Container = styled.div`
  border: 1px solid #f2f2f2;
  margin-top: 30px;
  border-radius: 10px;
  width: 100%;
  position: relative;

  .header_content {
    padding-left: 19.1px;
    padding-right: 19.1px;
    padding-top: 14.5px;
    padding-bottom: 14.5px;
    font-weight: 500;
    font-size: 14px;
    color: #828282;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  .visibility {
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
  }

  .body_content {
    padding-left: 19.1px;
    padding-right: 19.1px;
    padding-top: 20.71px;
    padding-bottom: 14.5px;
  }

  .body_header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .body_title {
    font-weight: 600;
    font-size: 18px;
    color: #4f4f4f;
  }

  .body_sub_title {
    font-weight: 500;
    font-size: 15px;
    color: #828282;
  }

  .button_container {
    display: grid;
    place-items: center;

    button {
      border: 1px solid #549a9a;
      outline: none;
      background: none;
      padding-top: 11px;
      padding-bottom: 11px;
      padding-left: 124.87px;
      padding-right: 124.87px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 15px;
      color: #549a9a;
      margin-bottom: 27.21px;
      marign-top: 14.83px;
      cursor: pointer;

      &:hover {
        background: #549a9a;
        color: #fff;
      }
    }
  }
`;

export const Footer = styled.div`
  border: 2px solid #f2f2f2;
  border-radius: 10px;
  width: auto;
  padding-top: 30.87px;
  padding-bottom: 30.87px;
  padding-left: 19.94px;
  padding-right: 19.94px;
  margin-top: 18.19px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  .footer_title {
    font-weight: 500;
    font-size: 15px;
    color: #828282;
    text-transform: uppercase;
  }

  .footer_sub_title {
    font-weight: 500;
    font-size: 18px;
    color: #333333;
  }
`;

export const GroupDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 364px;
  grid-gap: 10px;
`;

export const PageContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

export const SectionContainer = styled.div``;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
  margin-top: 1em;
  padding: 5px;
`;

export const SectionBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Tabs = styled.ul`
  display: flex;
  border: 2px 2px 0px solid grey;
  padding: 10px;
  cursor: pointer;
`;

export const Tab = styled.li`
  margin-left: 2em;
  list-style: none;
  color: ${(props) => (props.active ? Colors.primaryMain : Colors.blackish)};
  font-weight: ${(props) => (props.active ? '800' : '400')};
`;
