import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_health_conditions,
  get_health_conditions_success,
  get_health_conditions_error,
  create_health_conditions,
  create_health_conditions_error,
  create_health_conditions_success,
  delete_health_conditions,
  delete_health_conditions_error,
  delete_health_conditions_success,
  update_health_conditions,
  update_health_conditions_error,
  update_health_conditions_success
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getHealthConditions({ payload }) {
  try {
    const { page = 1, limit = 10, search } = payload;
    let searchString = ``;
    if (search) {
      searchString = `&search=${search}`;
    }
    const request = yield call(
      api.get,
      `/health_conditions?page=${page}&limit=${limit}${searchString}`
    );
    const res = request.data;
    const response = res.data;
    yield put({
      type: get_health_conditions_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
    yield put({
      type: get_health_conditions_error.type
    });
  }
}

function* createHealthConditions({ payload }) {
  try {
    const { data, onClose } = payload;
    const request = yield call(api.post, `/health_conditions`, data);
    const res = request.data;
    const response = res.data;
    toast.success(res.message);
    yield put({
      type: create_health_conditions_success.type,
      payload: response
    });
    onClose();
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
    yield put({
      type: create_health_conditions_error.type
    });
  }
}

function* deletHealthConditions({ payload }) {
  try {
    const request = yield call(api.delete, `/health_conditions/${payload}`);
    const res = request.data;
    const response = res.data;
    toast.success(res.message);
    yield put({
      type: delete_health_conditions_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
    yield put({
      type: delete_health_conditions_error.type
    });
  }
}

function* updateHealthConditions({ payload }) {
  try {
    const { data, id } = payload;
    const request = yield call(api.patch, `/health_conditions/${id}`, data);
    const res = request.data;
    const response = res.data;
    toast.success(res.message);
    yield put({
      type: update_health_conditions_success.type,
      payload: response
    });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
    yield put({
      type: update_health_conditions_error.type
    });
  }
}

function* HealthConditionsSagas() {
  yield takeEvery(get_health_conditions.type, getHealthConditions);
  yield takeEvery(create_health_conditions.type, createHealthConditions);
  yield takeEvery(delete_health_conditions.type, deletHealthConditions);
  yield takeEvery(update_health_conditions.type, updateHealthConditions);
}

export default HealthConditionsSagas;
