import styled, { css } from 'styled-components/macro';

import media from '@app/globalstyles/utils/media';
import Colors from '@app/globalstyles/utils/colors';

export const MenuContainer = styled.div`
  width: 235px;
  position: fixed;
  top: 0;
  left: 0;
  max-height: 100vh;
  border-radius: 1.93988px;
  display: flex;
  flex-direction: column;
  background: ${Colors.white};
  border: 1px solid #e4e7eb;
  /* display:none; */
  padding-top: 5px;
  /* padding-left: 15px;
  padding-right: 10px; */
  transition: all 0.5s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  /* overflow: auto; */
  ${media.smallDesktopMinimum`
        display: none;
    `}
`;

export const MenuItem = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  width: 92%;
  margin-top: 5px;
  height: 35px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: ${Colors.grey};
  border: none;
  margin-bottom: 18px;
  ${({ active }) =>
    active
      ? css`
          background: ${Colors.activemenubg};
          color: ${Colors.navTextActive};
          font-weight: 900;
          border-radius: 8px;
        `
      : css`
          background: transparent;
          color: #b8b8b8;
        `}

  padding-left: 10px;
  border-radius: 5px;
  cursor: pointer;
`;
export const MenuItemLogout = styled.div`
  display: flex;
  background: rgba(116, 173, 173, 0.51);
  /* justify-items: center; */
  align-items: center;
  width: 100%;
  height: 100px;
  flex-wrap: wrap;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: ${Colors.grey};
  border: none;
  ${({ active }) =>
    active
      ? css`
          background: rgba(116, 173, 173, 0.51);
          color: ${Colors.navTextActive};
          font-weight: 900;
          border-radius: 8px;
        `
      : css`
          background: rgba(116, 173, 173, 0.51);
          color: #b8b8b8;
        `}

  cursor: pointer;
`;
export const MenuText = styled.small`
  font-style: normal;
  font-size: 16px;
  margin-left: 15px;
`;
export const MenuTextLogout = styled.small`
  font-style: normal;
  font-size: 14px;
  line-height: 0px;
  margin-top: -10px;
  margin-left: 0px;
  color: ${({ color }) => (color ? color : '#B8B8B8')};
`;

export const MenuIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 6px;
  margin-left: ${({ marginLeft }) => (marginLeft ? 20 : null)};
`;

export const BigMenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
`;

export const MenuDropdownIcon = styled.img`
  margin-top: 10px;
  width: 10px;
  margin-left: 5px;
`;
export const MenuIconContainerLG = styled.div`
  display: flex;
  margin-left: 30px;
  margin-right: 10px;
`;

export const MenuIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const MobileMenuContainer = styled.div`
  display: none;
  > img {
    cursor: pointer;
    z-index: 7;
  }

  ${media.smallDesktopMinimum`
  /* margin-top: -15px; */
        justify-content:space-between;
        top:0;
        position:fixed;
        display: flex;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
        height: 50px;
        width: 100%;
        padding:0px 0px 0px 20px;
        background-color: #FFF;
        z-index:100;
    `}
  ${media.tablet`
  /* margin-top: -15px; */
        justify-content:space-between;
        top:0;
        position:fixed;
        display: flex;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
        height: 50px;
        width: 100%;
        padding:0px 0px 0px 20px;
        background-color: #FFF;
        z-index:100;
    `}
`;
export const MobileMenuContainerAll = styled.div`
  display: none;
  position: absolute;
  top: 0;

  ${media.smallDesktop`
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: transparent;
        padding: 0 0 0 0px;
    `}
  ${media.tablet`
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: transparent;
        padding: 0 0 0 0px;
    `}
`;
export const UserInfoMenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MenuToggleContainer = styled.div`
  position: fixed;
  left: 15px;
  top: 30px;
  display: ${({ open }) => (open ? 'none' : '')};
  cursor: pointer;
`;

export const ProfileIconMobile = styled.div`
  height: 32px;
  width: 32px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 2px;
  background: rgba(255, 255, 255, 0.22);
  mix-blend-mode: normal;
  border-radius: 24.5px;
  cursor: pointer;
  margin-right: 10px;
`;

export const InnerContainerMenu = styled.div`
  width: calc(100% - 30px);
  padding: 10px 15px;
  overflow-y: scroll;
  flex-direction: column;
  max-height: calc(100vh - 100px);
`;

export const Content = styled.div`
  height: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: ${Colors.grey};
  margin-bottom: 10px;
`;
