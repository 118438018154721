import React from 'react';

const Approved = (props) => {
  return (
    <div>
      <svg
        width={25}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.96 21a9 9 0 1 0 .001-18 9 9 0 0 0 0 18Zm4.77-11.36a1 1 0 0 0-1.537-1.28l-3.598 4.317c-.347.416-.542.647-.697.788l-.006.006-.007-.005c-.168-.127-.383-.339-.766-.722l-1.451-1.451a1 1 0 0 0-1.414 1.414l1.451 1.451.041.041c.326.327.64.641.933.862.327.248.756.48 1.305.456.55-.025.956-.296 1.26-.572.27-.247.555-.588.85-.943l.037-.044L17.73 9.64Z"
          fill="#549A9A"
        />
      </svg>
    </div>
  );
};

export default Approved;
