import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomerDetails from './containers/CustomerDetails';
import { get_customer, get_customer_address } from './redux/reducer';

import { getCustomer, getLoading, getAddress } from './redux/selectors';

import { Tab, Tabs } from './style';

const Customer = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const customer = useSelector(getCustomer);
  const address = useSelector(getAddress);
  const params = useParams();
  const [key, setKey] = useState('overview');

  useEffect(() => {
    dispatch(get_customer(params.id));
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(get_customer_address(params.id));
  }, [dispatch, params]);
  return (
    <div style={{ paddingLeft: 30, marginRight: 30 }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Tabs>
            <Tab active={key === 'overview'} onClick={() => setKey('overview')}>
              Overview
            </Tab>
            <Tab active={key === 'members'} onClick={() => setKey('members')}>
              Members
            </Tab>
            <Tab active={key === 'basket'} onClick={() => setKey('basket')}>
              Basket
            </Tab>
            <Tab
              active={key === 'transactions'}
              onClick={() => setKey('transactions')}
            >
              Transactions
            </Tab>
          </Tabs>
          {key === 'overview' ? (
            <CustomerDetails customer={customer} address={address} />
          ) : null}
        </>
      )}
    </div>
  );
};

export default Customer;
