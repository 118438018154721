import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_product,
  get_product_error,
  get_product_success,
  update_product,
  update_product_error,
  update_product_success
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getProductSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/products/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_product_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_product_error.type
    });
  }
}

function* updateProductSagas({ payload }) {
  const { id, data } = payload;
  try {
    const requestRes = yield call(api.put, `/products/${id}`, data);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: update_product_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: update_product_error.type
    });
  }
}

function* productSagas() {
  yield takeEvery(get_product.type, getProductSagas);
  yield takeEvery(update_product.type, updateProductSagas);
}

export default productSagas;
