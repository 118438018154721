import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_meal_categories,
  get_meal_categories_success,
  get_meal_categories_error,
  create_meal_categories,
  create_meal_categories_success,
  create_meal_categories_error,
  delete_meal_categories,
  delete_meal_categories_success,
  delete_meal_categories_error,
  update_meal_categories,
  update_meal_categories_success,
  update_meal_categories_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';
import { FileUploadToCloud } from '@app/services/fileUploadService';

function* getMealCategories({ payload }) {
  try {
    const { page = 1, limit = 20, search } = payload;
    let searchString = ``;
    if (search) {
      searchString = `&search=${search}`;
    }

    const request = yield call(
      api.get,
      `/meal_categories?page=${page}&limit=${limit}${searchString}`
    );
    const res = request.data;
    const response = res.data;
    yield put({
      type: get_meal_categories_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
    yield put({
      type: get_meal_categories_error.type
    });
  }
}

function* createMealCategories({ payload }) {
  try {
    const { data, onClose } = payload;
    let image = ``;

    if (data.images !== null) {
      const responseImage = yield call(FileUploadToCloud, data.images);
      image = responseImage.secure_url;
    }
    const doc = {
      ...data,
      image: image
    };
    delete doc['images'];
    if (doc.image === ``) {
      delete doc['image'];
    }
    const request = yield call(api.post, `/meal_categories`, doc);
    const res = request.data;
    const response = res.data;
    toast.success(res.message);
    yield put({
      type: create_meal_categories_success.type,
      payload: response
    });
    onClose();
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
    yield put({
      type: create_meal_categories_error.type
    });
  }
}

function* deleteMealCategories({ payload }) {
  try {
    const request = yield call(api.delete, `/meal_categories/${payload}`);
    const res = request.data;
    const response = res.data;
    toast.success(res.message);
    yield put({
      type: delete_meal_categories_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
    yield put({
      type: delete_meal_categories_error.type
    });
  }
}

function* updateMealCatgeories({ payload }) {
  try {
    const { id, data } = payload;
    let image = ``;
    if (typeof data.images !== 'string') {
      const responseImage = yield call(FileUploadToCloud, data.images);
      image = responseImage.secure_url;
    } else {
      image = data.images;
    }
    const doc = {
      ...data,
      image: image
    };
    delete doc['images'];
    const request = yield call(api.patch, `/meal_categories/${id}`, doc);
    const res = request.data;
    const response = res.data;
    toast.success(res.message);
    yield put({
      type: update_meal_categories_success.type,
      payload: response
    });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
    yield put({
      type: update_meal_categories_error.type
    });
  }
}

function* MealCategoriesSagas() {
  yield takeEvery(get_meal_categories.type, getMealCategories);
  yield takeEvery(create_meal_categories.type, createMealCategories);
  yield takeEvery(delete_meal_categories.type, deleteMealCategories);
  yield takeEvery(update_meal_categories.type, updateMealCatgeories);
}

export default MealCategoriesSagas;
