import styled from 'styled-components';

export const Container = styled.main`
  padding: 26px;

  .header {
    display: flex;
    gap: 4px;
    align-items: center;

    .link {
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      color: #605d66;
    }

    p {
      line-height: 0;
      font-size: 24px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 24px;

  .left-flex {
    width: 100%;
    flex: 1;
  }

  .right-flex {
    width: 368px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .edit {
      background: #549a9a;
      border-radius: 10px;
      color: #fff;
      border: none;
      font-size: 16px;
      font-weight: 600;
      padding: 13px;
      cursor: pointer;
      width: 100%;
    }

    .delete {
      border: 1px solid #ee1717;
      outline: none;
      padding: 13px;
      font-size: 14px;
      color: #ee1717;
      font-weight: 600;
      background: none;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background: #ee1717;
        color: white;
      }
    }
  }
`;

export const DetailStyles = styled.div`
  img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    color: #605d66;
  }

  .types {
    border: 0.5px solid #d9d8da;
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
    text-transform: capitalize;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
  }

  li {
    text-transform: capitalize;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .nutrient_list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
  }

  .nutrient {
    border: 1px solid #e3e8e8;
    padding: 10px;
    border-radius: 20px;
    font-size: 14px;
    color: #7c8091;
    font-weight: 400;
  }
`;
