import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import ErrorMessage from '@app/components/forms/errorMessage';
import { CustomInput } from '@app/components/forms/InputGen';
import { FormBox } from '../style';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { getProductLoading, getProductImages } from '../redux/selectors';
import { FileUploadToCloud } from '@app/services/fileUploadService';
import { create_product_group, get_product_images } from '../redux/reducer';
import { getProductCatgories } from '../../product-categories/redux/selectors';
import { get_product_categories } from '../../product-categories/redux/reducer';

const CreateProductGroup = ({ setOpen, open }) => {
  const [productImage, setProductImage] = useState(0);
  const categories = useSelector(getProductCatgories);
  const images = useSelector(getProductImages);
  console.log(images);
  let initialValues = {
    name: '',
    description: '',
    product_category: ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required().label('Name'),
    description: Yup.string().min(3).required().label('Description')
  });
  const loading = useSelector(getProductLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_product_categories());
    dispatch(get_product_images());
  }, [dispatch]);

  const handleSubmit = async (doc) => {
    if (productImage.length <= 0) return toast.error('Please pick a product Image');
    const response = await FileUploadToCloud(productImage);
    let data = {
      ...doc,
      image: response.secure_url
    };
    dispatch(create_product_group(data));
    setOpen(!open);
  };

  const imgRef = useRef();

  const handleOpenImage = () => {
    imgRef.current.click();
  };

  const handlePickImage = (e) => {
    setProductImage(e.target.files[0]);
  };

  return (
    <FormBox>
      <Formik
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
      >
        {({
          handleChange,
          values: formikValues,
          errors,
          setFieldTouched,
          touched
        }) => (
          <Form>
            <label className="title">Name</label>
            <CustomInput
              type="text"
              error={touched.name && errors.name}
              name="name"
              placeholder="name"
              onBlur={() => setFieldTouched('name')}
              onChange={handleChange('name')}
            />
            <ErrorMessage error={errors.name} visbile={touched.name} />

            <label className="title">Description</label>
            <CustomInput
              type="text"
              error={touched.description && errors.description}
              name="description"
              placeholder="Description"
              onBlur={() => setFieldTouched('description')}
              onChange={handleChange('description')}
            />
            <ErrorMessage error={errors.description} visbile={touched.description} />

            <label className="title">Product Category</label>
            <select
              name="product_category"
              onChange={handleChange('product_category')}
            >
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>

            <ErrorMessage
              error={errors.product_category}
              visbile={touched.product_category}
            />

            <div className="image_container" onClick={handleOpenImage}>
              {productImage?.name ? (
                <div className="image_preview">
                  <img src={URL.createObjectURL(productImage)} alt="selected" />
                </div>
              ) : (
                <div className="image_label">Upload Image</div>
              )}
            </div>

            <input
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              ref={imgRef}
              onChange={(e) => handlePickImage(e)}
            />

            <div style={{ display: 'grid', placeItems: 'center' }}>
              <button type="submit" disabled={loading}>
                {loading ? 'Loading' : 'Create'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </FormBox>
  );
};

export default CreateProductGroup;
