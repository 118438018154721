import { createSelector } from '@reduxjs/toolkit';

const productData = (state = {}) => state.product;

export const getProduct = createSelector(
  productData,
  (productData) => productData.data
);

export const getLoading = createSelector(
  productData,
  (productData) => productData.ui.loading
);
