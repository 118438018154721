import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    stats: {
      pageCount: 1
    }
  },
  data: [],
  details: {}
};

export const paymentReducerSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    get_payment_success: (state, { payload }) => {
      state.ui.loading = false;
      const { payments, stats } = payload;
      state.data = payments;
      state.ui.stats = {
        ...stats
      };
    },
    get_payment_error: (state) => {
      state.ui.loading = false;
    },
    get_payment: (state) => {
      state.ui.loading = true;
    },

    get_one_payment_success: (state, { payload }) => {
      state.ui.loading = false;
      state.details = payload;
    },
    get_one_payment_error: (state) => {
      state.ui.loading = false;
    },

    get_one_payment: (state) => {
      state.ui.loading = true;
    },

    create_payment_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data.unshift(payload);
    },
    create_payment_error: (state) => {
      state.ui.loading = false;
    },
    create_payment: (state) => {
      state.ui.loading = true;
    }
  }
});

export const {
  get_payment,
  get_payment_error,
  get_payment_success,
  get_one_payment,
  get_one_payment_error,
  get_one_payment_success,
  create_payment,
  create_payment_error,
  create_payment_success
} = paymentReducerSlice.actions;

export default paymentReducerSlice.reducer;
