import { all } from 'redux-saga/effects';
import authSagas from './auth/redux/sagas';
import currenciesSagas from './pages/currrencies/redux/sagas';
import customerSagas from './pages/customer/redux/sagas';
import customersSagas from './pages/customers/redux/sagas';
import groupSagas from './pages/group/redux/sagas';
import groupsSagas from './pages/groups/redux/sagas';
import dashboardSagas from './pages/home/redux/sagas';
import productCategoriesSagas from './pages/product-categories/redux/sagas';
import productSagas from './pages/product/redux/sagas';
import productsSagas from './pages/products/redux/sagas';
import staffSagas from './pages/partners/redux/sagas';
import paymentSagas from './pages/payments/redux/sagas';
import orderSagas from './pages/international-orders/redux/sagas';
import paidBasketSagas from './pages/orders/redux/sagas';
import couponsSagas from './pages/coupons/redux/sagas';
import RecipeSagas from './pages/recipes/redux/sagas';
import RecipesSagas from './pages/recipe/redux/sagas';
import productGroupsSagas from './pages/product-groups/redux/sagas';
import partnerSagas from './pages/partner/redux/sagas';
import DriveSagas from './pages/akaani-drive/redux/sagas';
import DeliveryPartnersSagas from './pages/deliveryPartner/redux/sagas';
import UnitsSagas from './pages/unit/redux/sagas';
import MealsSagas from './pages/meals/redux/saga';
import MealFactsSagas from './pages/facts/redux/saga';
import MealCategoriesSagas from './pages/mealCategories/redux/saga';
import HealthConditionsSagas from './pages/health/redux/saga';
import TagsSagas from './pages/meal-tags/redux/sagas';

export default function* root() {
  yield all([
    authSagas(),
    dashboardSagas(),
    groupsSagas(),
    customersSagas(),
    productsSagas(),
    currenciesSagas(),
    productCategoriesSagas(),
    productSagas(),
    groupSagas(),
    customerSagas(),
    staffSagas(),
    paymentSagas(),
    orderSagas(),
    paidBasketSagas(),
    couponsSagas(),
    RecipeSagas(),
    RecipesSagas(),
    productGroupsSagas(),
    partnerSagas(),
    DriveSagas(),
    DeliveryPartnersSagas(),
    UnitsSagas(),
    MealsSagas(),
    MealFactsSagas(),
    MealCategoriesSagas(),
    HealthConditionsSagas(),
    TagsSagas()
  ]);
}
