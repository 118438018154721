import React, { useEffect } from 'react';
import { SectionBody, SectionContainer } from '../style';
import { TableRow } from '@design-system/Table';
import { useDispatch, useSelector } from 'react-redux';
import { get_group_basket } from '../redux/reducer';
import { useParams } from 'react-router-dom';
import { getGroupBasket, getPaymentLoading } from '../redux/selectors';
import Link from '@design-system/Link';
import { Table } from '../style';

export const Basket = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const basketProducts = useSelector(getGroupBasket);
  const loading = useSelector(getPaymentLoading);

  useEffect(() => {
    dispatch(get_group_basket(params.id));
  }, [dispatch, params.id]);

  return (
    <SectionContainer>
      <SectionBody style={{ marginTop: '25px' }}>
        <Table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Weight</th>
              <th>Quantity</th>
              <th>Price</th>
              {/* <th>Category</th> */}
              <th>Product Owner</th>
            </tr>
          </thead>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <tbody>
              {basketProducts?.map((basket) => (
                <TableRow key={basket._id}>
                  <td>{basket?.product_data?.product?.name}</td>
                  <td>{basket?.product_data?.weight}kg</td>
                  <td>{basket.quantity}</td>

                  <td>
                    {basket.price?.currency?.symbol} {basket.price?.value}
                  </td>
                  {/* <td></td> */}
                  <td>
                    {basket.visibility === 'private' ? (
                      <Link to={`/users/${basket?.user?._id}`}>
                        {basket?.user?.first_name} {basket?.user?.last_name}
                      </Link>
                    ) : (
                      <Link to={`/groups/${basket?.group?._id}`}>
                        {basket?.group?.name}
                      </Link>
                    )}
                  </td>
                </TableRow>
              ))}
            </tbody>
          )}
        </Table>
      </SectionBody>
    </SectionContainer>
  );
};

export default Basket;
