import StatusLabel from '@design-system/StatusLabel';
import { Text } from '@design-system/Text';
import React, { useState } from 'react';
import moment from 'moment';
import {
  ItemContainer,
  SectionBody,
  SectionContainer,
  SectionHeader
} from '../style';
import { FormsModal } from '@app/globalstyles/asgard';
import ConfirmVerify from './VerifyAccount';

function CustomerDetails({ customer, address }) {
  const [showForm, setShowForm] = useState(false);

  const handleShowVerifyForm = () => {
    setShowForm(!showForm);
  };

  return (
    <SectionContainer>
      <SectionHeader>
        <h2>User Details</h2>
      </SectionHeader>
      <SectionBody>
        <ItemContainer>
          <p>Name</p>
          <Text size={'small'} align="left">
            {customer?.first_name} {customer?.last_name}
          </Text>
        </ItemContainer>
        <ItemContainer>
          <p>Email</p>
          <Text align="left">{customer?.email}</Text>
        </ItemContainer>

        <ItemContainer>
          <p>User Name</p>
          <Text align="left">{customer?.username}</Text>
        </ItemContainer>
      </SectionBody>
      <SectionBody>
        <ItemContainer>
          <p>Phone Number</p>
          <Text size={'small'} align="left">
            {customer?.phone ? customer?.phone : 'Not Provided'}
          </Text>
        </ItemContainer>
        <ItemContainer>
          <p>Joined Date</p>
          <Text align="left">{moment(customer?.created_at).format('LL')}</Text>
        </ItemContainer>
        <ItemContainer>
          <p>Email Verification</p>
          <p>
            {!customer.email_verified ? (
              <StatusLabel bg={'danger'}>Not Verified</StatusLabel>
            ) : (
              <StatusLabel bg={'success'}>Verified</StatusLabel>
            )}
          </p>
        </ItemContainer>
      </SectionBody>

      <SectionBody>
        {address.length >= 0 && (
          <ItemContainer>
            <p>Address Not Provided </p>
          </ItemContainer>
        )}
      </SectionBody>

      {address.map((data) => (
        <SectionBody key={data?._id}>
          <ItemContainer>
            <p>Country </p>
            <Text size={'small'} align="left">
              {data?.country ? data?.country : 'Not Provided'}
            </Text>
          </ItemContainer>
          <ItemContainer>
            <p>State </p>
            <Text size={'small'} align="left">
              {data?.state ? data?.state : 'Not Provided'}
            </Text>
          </ItemContainer>
          <ItemContainer>
            <p>Street Address </p>
            <Text size={'small'} align="left">
              {data?.street_address ? data?.street_address : 'Not Provided'}
            </Text>
          </ItemContainer>
        </SectionBody>
      ))}

      {address?.map((data) => (
        <SectionBody key={data?._id}>
          <ItemContainer>
            <p>City </p>
            <Text size={'small'} align="left">
              {data?.city ? data?.city : 'Not Provided'}
            </Text>
          </ItemContainer>
        </SectionBody>
      ))}

      {showForm && (
        <FormsModal show={showForm}>
          <ConfirmVerify id={customer._id} closeform={handleShowVerifyForm} />
        </FormsModal>
      )}
    </SectionContainer>
  );
}

export default CustomerDetails;
