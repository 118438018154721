import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_tags,
  get_tags_error,
  get_tags_success,
  create_tags,
  create_tags_error,
  create_tags_success,
  delete_tag,
  delete_tag_error,
  delete_tag_success,
  update_tag,
  update_tag_error,
  update_tag_success
} from './reducers';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getTagSagas() {
  try {
    const request = yield call(api.get, `/tags`);
    const res = request.data;
    const response = res.data;
    yield put({
      type: get_tags_success.type,
      payload: response
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_tags_error.type
    });
  }
}

function* createTagsSagas({ payload }) {
  try {
    const { data, onClose } = payload;
    const request = yield call(api.post, `/tags`, data);
    const res = request.data;
    const response = res.data;
    toast.success(res.message);
    yield put({
      type: create_tags_success.type,
      payload: response
    });
    onClose();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    console.log(error);
    yield put({
      type: create_tags_error.type
    });
  }
}

function* deleteTagsSagas({ payload }) {
  try {
    const request = yield call(api.delete, `/tags/${payload}`);
    const requestRes = request.data;
    const response = requestRes.data;
    toast.success(requestRes.message);
    yield put({
      type: delete_tag_success.type,
      payload: response
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    yield put({
      type: delete_tag_error.type
    });
  }
}

function* updateTagsSagas({ payload }) {
  try {
    const { data, id, onClose } = payload;
    const request = yield call(api.put, `/tags/${id}`, data);
    const requestRes = request.data;
    const response = requestRes.data;
    toast.success(requestRes.message);
    yield put({
      type: update_tag_success.type,
      payload: response
    });
    onClose();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    yield put({
      type: update_tag_error.type
    });
  }
}

function* TagsSagas() {
  yield takeEvery(get_tags.type, getTagSagas);
  yield takeEvery(create_tags.type, createTagsSagas);
  yield takeEvery(delete_tag.type, deleteTagsSagas);
  yield takeEvery(update_tag.type, updateTagsSagas);
}

export default TagsSagas;
