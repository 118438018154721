export const filterItems = (array) => {
  let filtered = [];
  if (array?.length) {
    array.forEach((item, index) => {
      filtered[filtered.length] = {
        food_item: item.food_item,
        measurement: item.measurement,
        product_group: item?.product_group?._id
      };
    });
  } else {
    filtered.push({
      food_item: '',
      measurement: '',
      product_group: ''
    });
  }
  return Object.assign({}, filtered);
};

export const filterInstructions = (array) => {
  let filtered = [];
  if (array?.length) {
    array.forEach((item, index) => {
      filtered[filtered.length] = {
        step: item.step,
        time: item.time
      };
    });
  } else {
    filtered.push({
      step: '',
      time: ''
    });
  }
  return filtered;
};

export const filterServings = (servings) => {
  let newServings = [];
  servings.map((serving) => {
    if (serving === 1) {
      newServings.push({ value: 1, label: 'One serving' });
    }
    if (serving === 2) {
      newServings.push({ value: 2, label: 'Two serving' });
    }
    if (serving === 4) {
      newServings.push({ value: 4, label: 'Four serving' });
    }
    if (serving === 8) {
      newServings.push({ value: 8, label: 'Eight serving' });
    }
    return newServings;
  });
  return newServings;
};

export const filterSingleServing = (serving) => {
  switch (serving) {
    case 1:
      return { value: 1, label: 'One serving' };
    case 2:
      return { value: 2, label: 'Two serving' };
    case 4:
      return { value: 4, label: 'Four serving' };
    case 8:
      return { value: 8, label: 'Eight serving' };
    default:
      return { value: 0, label: '' };
  }
};

export const filtertype = (type) => {
  switch (type) {
    case 'Breakfast':
      return { value: 'Breakfast', label: 'Breakfast' };
    case 'Lunch':
      return { value: 'Lunch', label: 'Lunch' };
    case 'Dinner':
      return { value: 'Dinner', label: 'Dinner' };
    case 'Snack':
      return { value: 'Snack', label: 'Snack' };
    case 'Desert':
      return { value: 'Desert', label: 'Desert' };
    case 'Universal':
      return { value: 'Universal', label: 'Universal' };
    default:
      return { value: '', label: '' };
  }
};

export const filtertime = (time) => {
  switch (time) {
    case 'low_effort':
      return { value: 'low_effort', label: 'Low Effort (10 mins - 45 mins)' };
    case 'medium_effort':
      return {
        value: 'medium_effort',
        label: 'Medium Effort (45 mins - 2 hours)'
      };
    case 'high_effort':
      return { value: 'high_effort', label: 'High Effort (> 2 hours)' };
    default:
      return { value: '', label: '' };
  }
};

export const filter_type = (type) => {
  let split_array = type?.split(',');
  let type_array = [];

  split_array?.map((type) => type_array.push({ label: type, value: type }));
  return type_array;
};

export const filter_countries = (country) => {
  let country_array = [];
  country?.map((data) => country_array.push({ label: data, value: data }));
  return country_array;
};

// export const handleImages = (images) => {
//   if (images.length === 1) {
//     return Object.assign({ 1: "", 2: "", 3: "" }, recipe.images);
//   }
//   if (images.length === 2) {
//     return Object.assign({ 2: "", 3: "" }, recipe.images);
//   }
//   if (images.length === 3) {
//     return Object.assign({ 3: "" }, recipe.images);
//   }
//   if (images.length === 4) {
//     return Object.assign({}, recipe.images);
//   }
// };
