import styled, { keyframes } from 'styled-components';

export const SlideIn = keyframes`
from {
    opacity: 0
}
to{
    opacity: 1
}
`;
export const BackDrop = styled.div`
 width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transform:translate(-100%)
  animation-name: ${SlideIn};
  animation-duration: 0.5s;
`;

export const ModalBody = styled.div`
  width: ${({ width }) => (width ? width : 'auto')};
  height: auto;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  z-index: 20;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
`;

export const ModalHeader = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #d9d8da;

  .title {
    font-size: 20px;
    font-weight: 900;
  }

  .cancel {
    display: grid;
    place-items: center;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #d9d8da;
    width: 30px;
    height: 30px;
    color: #605d66;
  }
`;

export const ModalContainer = styled.div`
  padding: 16px;
`;
