import { FieldArray } from 'formik';
import React from 'react';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';

const Ingredients = ({ value, setFieldValue }) => {
  return (
    <div className="ingredients">
      <div style={{ width: '100%' }}>
        <label style={{ fontSize: '16px', fontWeight: 600 }}>Ingredients</label>
        <div>
          <div
            style={{
              color: '#605D66',
              fontSize: '14px',
              fontWeight: 500,
              marginTop: '10px'
            }}
          >
            Food Item
          </div>
          <FieldArray name="ingredients">
            {({ push, remove }) => (
              <>
                {value.ingredients &&
                  value.ingredients.map((item, idx) => (
                    <div className="instructions" key={idx}>
                      <input
                        type="text"
                        name="value"
                        placeholder="E.g Rice"
                        required
                        value={item.value}
                        onChange={(e) => {
                          let infos = value.ingredients;
                          let info = infos[idx];
                          const data = {
                            ...info,
                            value: e.target.value
                          };
                          infos[idx] = data;
                          setFieldValue('ingredients', infos);
                        }}
                      />
                      <div onClick={() => remove(idx)} className="del">
                        <MdDeleteOutline size={20} />
                      </div>

                      <div
                        onClick={() => {
                          push({
                            value: ''
                          });
                        }}
                        className="add"
                      >
                        <IoMdAddCircleOutline size={20} />
                      </div>
                    </div>
                  ))}
              </>
            )}
          </FieldArray>
        </div>
      </div>

      <div style={{ width: '100%' }}>
        <label style={{ fontSize: '16px', fontWeight: 600 }}>Cooking Steps</label>
        <div>
          <div
            style={{
              color: '#605D66',
              fontSize: '14px',
              fontWeight: 500,
              marginTop: '10px'
            }}
          >
            Instructions
          </div>
        </div>

        <FieldArray name="instructions">
          {({ push, remove }) => (
            <>
              {value.instructions &&
                value.instructions.map((item, idx) => (
                  <div className="instructions" key={idx}>
                    <input
                      type="text"
                      name="value"
                      placeholder="E.g Boil the rice"
                      required
                      value={item.value}
                      onChange={(e) => {
                        let infos = value.instructions;
                        let info = infos[idx];
                        const data = {
                          ...info,
                          value: e.target.value
                        };
                        infos[idx] = data;
                        setFieldValue('instructions', infos);
                      }}
                    />
                    <div onClick={() => remove(idx)} className="del">
                      <MdDeleteOutline size={20} />
                    </div>

                    <div
                      onClick={() => {
                        push({
                          value: ''
                        });
                      }}
                      className="add"
                    >
                      <IoMdAddCircleOutline size={20} />
                    </div>
                  </div>
                ))}
            </>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default Ingredients;
