import React, { useState } from 'react';
import Logo from '@design-system/Assets/akaani.png';
import { Link } from 'react-router-dom';
import {
  LoginContainer,
  Background,
  AccessCard,
  LoginBackground,
  TextInput,
  Text,
  Form,
  HrLine,
  Button
} from '@design-system/';
import { RESET_PASSWORD } from '@app/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getLoading } from './redux/selectors';
import { login } from './redux/reducer';
import toast from 'react-hot-toast';

export const Login = () => {
  const [formdata, setFormData] = useState({
    email: '',
    password: ''
  });

  const dispatch = useDispatch();
  const loading = useSelector(getLoading);

  function handleChange(e) {
    setFormData({
      ...formdata,
      [e.target.name]: e.target.value
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const email = formdata.email;
    const akaaniDomain = email.endsWith('@useakaani.com');
    if (akaaniDomain) {
      const password = formdata.password;
      const data = {
        email,
        password
      };
      dispatch(login(data));
    } else {
      toast.error('Not a valid akaani account');
    }
  }

  return (
    <Background>
      <img
        alt="akaani logo"
        src={Logo}
        style={{ position: 'fixed', margin: '4% 0 0 3%' }}
      />
      <LoginContainer>
        <LoginBackground>
          <Text size="large" color="white" align="left">
            {' '}
            Welcome Back Comrade
          </Text>
        </LoginBackground>
        <AccessCard>
          <Text size="large" color="black" align="left" style={{ marginTop: '10%' }}>
            Login
          </Text>
          <Text
            size="small"
            color="#797B80"
            align="left"
            weight="900"
            style={{ marginTop: '-4%' }}
          >
            Log in with your akaani staff account.
          </Text>
          <HrLine color="#494A4D" text="" weight="900" size="1em" />
          <Form>
            <TextInput
              id="email"
              value={formdata.email || ''}
              onChange={handleChange}
              name="email"
              disabled={loading}
              type="email"
              required={true}
              placeholder="Enter your Email"
              style={{ fontSize: '1em' }}
            ></TextInput>
            <TextInput
              id="password"
              type="password"
              disabled={loading}
              value={formdata.password || ''}
              onChange={handleChange}
              name="password"
              required={true}
              placeholder="Enter your Password"
              style={{ fontSize: '1em' }}
            ></TextInput>

            <Link
              style={{
                textDecoration: 'none',
                color: '#7879F1',
                fontWeight: '900',
                marginTop: '3%'
              }}
              to={RESET_PASSWORD}
            >
              Forgot Password?
            </Link>

            <Button
              style={{ marginTop: 50 }}
              text={loading ? 'Loading...' : 'Login'}
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
            />
          </Form>
        </AccessCard>
      </LoginContainer>
    </Background>
  );
};

export default Login;
