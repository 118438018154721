import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_delivery_partners,
  get_delivery_partners_success,
  get_delivery_partners_error,
  delete_delivery_partner,
  delete_delivery_partner_success,
  delete_delivery_partner_error,
  create_delivery_partner,
  create_delivery_partner_success,
  create_delivery_partner_error,
  update_delivery_partner,
  update_delivery_partner_success,
  update_delivery_partner_error
} from './reducers';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getDeliveryPartner({ payload }) {
  try {
    const { page = 1, limit = 10, searchString } = payload;
    let search = ``;
    if (searchString) {
      search = `&search=${searchString}`;
    } else {
      search = ``;
    }
    const request = yield call(
      api.get,
      `/delivery_partners?page=${page}&limit=${limit}${search}`
    );
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: get_delivery_partners_success.type,
      payload: response
    });
  } catch (error) {
    console.log(error);
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_delivery_partners_error.type
    });
  }
}

function* deleteDeliveryPartner({ payload }) {
  try {
    const request = yield call(api.delete, `delivery_partners/${payload}`);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: delete_delivery_partner_success.type,
      payload: response
    });
    toast.success(requestData.message);
  } catch (error) {
    yield put({
      type: delete_delivery_partner_error.type
    });
    toast.error(error ? error.toString() : error?.response?.data?.message);
  }
}

function* createDeliveryPartner({ payload }) {
  try {
    const request = yield call(api.post, `delivery_partners/`, payload);
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: create_delivery_partner_success.type,
      payload: response
    });
    toast.success(requestData.message);
  } catch (error) {
    yield put({
      type: create_delivery_partner_error.type
    });
    toast.error(error ? error.toString() : error?.response?.data?.message);
  }
}

function* updateDeliveryPartner({ payload }) {
  try {
    const request = yield call(
      api.patch,
      `delivery_partners/${payload.id}`,
      payload.data
    );
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: update_delivery_partner_success.type,
      payload: response
    });
    toast.success(requestData.message);
  } catch (error) {
    yield put({
      type: update_delivery_partner_error.type
    });
    console.log(error);
    toast.error(
      error?.response?.data?.status_code === 400
        ? error?.response?.data?.message
        : error.toString()
    );
  }
}

function* DeliveryPartnersSagas() {
  yield takeEvery(get_delivery_partners.type, getDeliveryPartner);
  yield takeEvery(delete_delivery_partner.type, deleteDeliveryPartner);
  yield takeEvery(create_delivery_partner.type, createDeliveryPartner);
  yield takeEvery(update_delivery_partner.type, updateDeliveryPartner);
}

export default DeliveryPartnersSagas;
