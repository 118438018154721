import CloseIcon from '@design-system/Assets/CloseIcon';
import React from 'react';
import { Content } from '../style';
import recipeImg from '@design-system/Assets/recipe.png';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { get_recipe, update_recipe } from '../redux/reducer';
import Approved from '@design-system/Assets/Approved';
import Declined from '@design-system/Assets/Declined';

const CreatorContent = ({ toggle, creator, showForm }) => {
  const dispatch = useDispatch();

  const handleUpdate = (name) => {
    if (name === 'approved') {
      const data = {
        status: 'approved'
      };
      dispatch(update_recipe({ data, id: creator._id }));
      toggle();
      dispatch(get_recipe());
    } else {
      const data = {
        status: 'rejected'
      };
      dispatch(update_recipe({ data, id: creator._id }));
      toggle();
      dispatch(get_recipe());
    }
  };
  return (
    <div>
      <Content>
        <CloseIcon onClick={toggle} className="icon" />

        <img src={recipeImg} alt={recipeImg} />
        {creator.status === 'approved' && (
          <div className="recipe_info">
            <Approved />
            <div className="recipe_info_text">Approved</div>
          </div>
        )}
        {creator.status === 'rejected' && (
          <div className="recipe_info">
            <Declined />
            <div className="recipe_info_text">Declined</div>
          </div>
        )}

        <div className="content_header">Recipe:</div>
        <div className="content_sub">{creator.name}</div>

        <div className="content_header">Creator:</div>
        <div className="content_sub">
          {creator?.created_by?.first_name} {creator?.created_by?.last_name}
        </div>

        <div className="content_header">Date Created</div>
        <div className="content_sub"> {moment(creator.created_at).format('LL')}</div>

        {creator.status === 'approved' ||
          (creator.status === 'rejected' ? null : (
            <>
              <button
                className="filled_button"
                onClick={() => handleUpdate('approved')}
              >
                Approve
              </button>
              <button
                className="outline_button"
                onClick={() => handleUpdate('decline')}
              >
                Decline
              </button>
            </>
          ))}

        <div className="content_header">Create meal</div>
        <div className="line" />
        <button
          onClick={() => showForm(creator)}
          className="meal_button"
          disabled={creator.status === 'approved' ? false : true}
        >
          Convert to meal
        </button>
      </Content>
    </div>
  );
};

export default CreatorContent;
