import React, { useState, useEffect } from 'react';
import { Header, Box } from './style';
import { getUnits, getLoading, getPageStats } from './redux/selectors';
import { get_units, delete_units } from './redux/reducers';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import CreateUnit from './components/createUnit';
import { FormsModal } from '@app/globalstyles/asgard';
import UpdateUnit from './components/updateUnit';
import EmptyDataUi from '@app/components/emptyData';
import Modal from '@app/components/modal';
import useDebounce from '@app/Hooks/useDebounce.js';
import Pagination from '@app/components/pagination';

const Unit = () => {
  const product_units = useSelector(getUnits);
  const loading = useSelector(getLoading);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const [box, setBox] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState('');
  const debouncedTerm = useDebounce(searchString, 500);
  const pageStats = useSelector(getPageStats);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      get_units({
        page: currentPage,
        limit: pageSize,
        searchString: debouncedTerm
      })
    );
  }, [dispatch, currentPage, pageSize, debouncedTerm]);

  const handleShow = (data) => {
    setHide(!hide);
    setBox(data);
  };

  const handleDelete = (id) => {
    dispatch(delete_units(id));
  };

  const getNewUnits = (page, limit) => {
    dispatch(get_units({ page, limit, debouncedTerm }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewUnits(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewUnits(1, pageSize);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  function StopBubbling(e) {
    e.stopPropagation();
  }

  return (
    <div>
      <Header>Units</Header>

      <Box>
        <div className="search_bar">
          <input type="text" placeholder="Search" onChange={handleSearch} />
        </div>
        <button onClick={() => setShow(true)}>Create</button>
      </Box>

      {show && (
        <FormsModal show={show} onClick={() => setShow(!show)}>
          <div onClick={StopBubbling}>
            <CreateUnit
              StopBubbling={StopBubbling}
              hadleShow={() => setShow(false)}
              product_units={product_units}
            />
          </div>
        </FormsModal>
      )}

      {hide && (
        <Modal width="400px" onClose={() => setHide(false)} title="Edit Unit">
          <UpdateUnit units={box} onClose={handleShow} />
        </Modal>
      )}

      {!loading && product_units.length <= 0 ? (
        <EmptyDataUi />
      ) : (
        <Table
          loading={loading}
          product_units={product_units}
          handleShow={handleShow}
          handleDelete={handleDelete}
        />
      )}
      <Pagination
        itemsCount={pageStats.docs}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangePageSize={handlePageSizeChange}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Unit;
