import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTags, loadingTags } from './redux/selectors';
import { delete_tag, get_tags } from './redux/reducers';
import LoadingDataUi from '@app/components/loading';
import TagTable from './components/tag-table';
import { FormsModal } from '@app/globalstyles/asgard';
import AddTag from './components/addTag';

const MealTags = () => {
  const tags = useSelector(getTags);
  const loading = useSelector(loadingTags);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [content, setContent] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(get_tags());
  }, [dispatch]);

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const handleDelete = (doc) => {
    dispatch(delete_tag(doc));
  };

  const handleEdit = (doc) => {
    setContent(doc);
    setIsEdit(!isEdit);
    setTimeout(() => {
      setShow(!show);
    }, 400);
  };

  return (
    <div>
      {show && (
        <FormsModal show={show} onClick={() => setShow(!show)}>
          <div onClick={StopBubbling}>
            <AddTag
              onClose={() => setShow(!show)}
              content={content}
              isEdit={isEdit}
            />
          </div>
        </FormsModal>
      )}
      <div className="flex justify-between items-center p-6">
        <p className="text-[#222222] text-[24px] font-[600]">Meal Tags</p>
        <button
          onClick={() => setShow(!show)}
          className="bg-[#549a9a] text-white rounded-[9px] p-4 text-[16px] font-[600] cursor-pointer"
        >
          Add a tag
        </button>
      </div>

      <div className="p-6 mt-6">
        {loading ? (
          <LoadingDataUi />
        ) : (
          <TagTable
            tags={tags}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        )}
      </div>
    </div>
  );
};

export default MealTags;
