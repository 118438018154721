import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  width: 85px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.bg === 'warning'
      ? '#FEF4D1'
      : props.bg === 'success'
      ? '#D5EDDC'
      : props.bg === 'deleted'
      ? '#ffbd33'
      : '#FCD1D1'};
  color: ${(props) =>
    props.color === 'warning'
      ? '#D1A713'
      : props.bg === 'success'
      ? '#219653'
      : props.color === 'deleted'
      ? '#ff5733'
      : '#EE1717'};
  text-transform: capitalize;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
  text-align: center;
`;

export const Badge = ({ children, ...props }) => {
  return <Content {...props}>{children}</Content>;
};
