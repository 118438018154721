import React from 'react';
import styled from 'styled-components';

const BaseButton = styled.button`
  background-color: #549a9a;
  font-size: 16px;
  border-radius: 10px;
  padding: 5px;
  border: none;
  cursor: pointer;
  color: #fff;
`;

export const SimpleButton = ({ text, onClick, ...props }) => {
  return (
    <BaseButton onClick={onClick} {...props}>
      {text}
    </BaseButton>
  );
};

export default SimpleButton;
