import styled from 'styled-components/macro';
import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';

export const SearchDiv = styled.div`
  width: 35%;
  height: 44px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  background: ${Colors.searchInputBg};
  border-radius: 5px;
  img {
    cursor: pointer;
  }

  ${media.smallDesktopMinimum`
        margin-left: 10px;
        width: 55%;
        border: none;

        img{
            height:40px;
            align-self: flex-end;
        }
    `}
  ${media.tablet`
        margin-left: 10px;
        width: 500px;
        border: none;

        img{
            /* width:40px; */
            height:40px;
            align-self: flex-end;
        }
    `}
    ${media.mobile`
        margin-left: 0px;
        width: 95%;
        border: none;

        img{
            /* width:40px;
            height:20px; */
            align-self: flex-end;
        }
    `}
`;

export const SearchInput = styled.input`
  border: none;
  height: 100%;
  padding: 0px 10px;

  flex-grow: 1;
  background: transparent;
  outline: none;
  border-radius: 12px;

  ${media.tablet`
        width: 85%;
    `}

  ::placeholder {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 150%;
    color: #929aa7;
  }
  &::active {
    border: none;
  }
`;
