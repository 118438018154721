import React from 'react';

const DeleteIcon = (props) => {
  return (
    <div>
      <svg
        width={25}
        height={25}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.715 6.39h-18v3a2 2 0 0 1 2 2v4c0 2.83 0 4.243.879 5.122.878.879 2.292.879 5.12.879h2c2.83 0 4.243 0 5.122-.88.879-.878.879-2.292.879-5.12v-4a2 2 0 0 1 2-2v-3Zm-10.5 5a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5Zm5 0a1 1 0 1 0-2 0v5a1 1 0 0 0 2 0v-5Z"
          fill="#E0E0E0"
        />
        <path
          d="M10.783 3.761c.114-.106.365-.2.714-.267a6.68 6.68 0 0 1 1.218-.103c.44 0 .868.036 1.217.103.35.067.6.16.715.267"
          stroke="#E0E0E0"
          strokeWidth={2}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default DeleteIcon;
