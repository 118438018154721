import media from '@app/globalstyles/utils/media';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import Colors from '@app/globalstyles/utils/colors';

export const CustomInput = styled.input`
  width: 100%;
  height: 42px;
  background: #ffffff;
  outline: none;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  .remove {
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  ${media.mobile`
  width: 100%;
  `}

  ::placeholder {
    color: #797b80;
    padding-left: 3%;
  }

  &:focus {
    outline: none;
    border: 1px solid ${({ error }) => (error ? 'red' : 'rgba(0, 0, 0, 0.33)')};
  }
`;
export const CustomTextArea = styled.textarea`
  width: 100%;
  height: 42px;
  background: #ffffff;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.33);
  box-sizing: border-box;
  border-radius: 3px;
  ${media.mobile`
  width: 100%;
  `}

  ::placeholder {
    color: #797b80;
    padding-left: 3%;
  }

  &:focus {
    outline: none;
    border: 1px solid ${({ error }) => (error ? 'red' : 'rgba(0, 0, 0, 0.33)')};
  }
`;

export const InputControllerDiv = styled.div`
  width: 100%;
  display: flex;
  /* background: green; */

  padding: 0;
  flex-direction: column;
  p {
    margin-top: -12px;
  }
  ${media.mobile`
  width: 100%;
  p {
    margin-left: 1%;
  }
  `}
`;

export const NumberInput = styled(NumberFormat)`
  width: ${({ width }) => (width ? width : '70%')};
  min-height: 35px;
  outline: none;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
`;

export const SelectWeightInput = styled.div`
  position: relative;
  width: 28%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 30px;
  display: flex;
  color: ${Colors.black};
  /* box-sizing: border-box; */
  border-radius: 10px;
  ${media.tablet`
   width:100%;
  `}
  ${media.mobile`
   width:100%;
   margin-left: 0px;
  `}

  select {
    font-size: 14px;
    height: 100%;
    width: 100%;
    padding: 0em 1em 0em 1.5em;
    background: transparent;
    color: rgba(0, 0, 0, 0.53);
    border: none;
    outline: none;
    border-radius: 5px;
    appearance: none;
    option {
      background: #ffffff;
      color: #000;
    }
  }
  div {
    position: absolute;
    right: 4%;
    color: ${Colors.white};

    height: 100%;
    /* width: 2em; */
    pointer-events: none;
    display: flex;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
    }
  }
`;

export const CustomTextAreaInput = styled.textarea`
  width: 100%;
  height: 90px;
  outline: none;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  resize: none;
`;

export const PickImageWhiteButton = styled.button`
  width: 60px;
  height: 60px;
  outline: none;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
`;
