import React from 'react';
import { SectionContainer } from '../style';
import moment from 'moment';
import {
  DetailHeading,
  ProductDetailsContainer,
  ProductGrid,
  ProductGridItem
} from '@design-system/ProductGrid';

export const OtherDetails = ({ product }) => {
  return (
    <SectionContainer>
      <ProductDetailsContainer>
        <ProductGrid>
          <ProductGridItem>
            <DetailHeading>Created By</DetailHeading>
            <p className="detailvalue">
              {product.created_by?.first_name} {product.created_by?.last_name}
            </p>
          </ProductGridItem>
          <ProductGridItem>
            <DetailHeading>Updated By</DetailHeading>
            <p className="detailvalue">
              {product.updated_by?.first_name} {product.updated_by?.last_name}
            </p>
          </ProductGridItem>
          <ProductGridItem>
            <DetailHeading>Date Created</DetailHeading>
            {product.created_at ? (
              <p className="detailvalue">
                {moment(product.created_at).format('DD')}-
                {moment(product.created_at).format('MM')}-
                {moment(product.created_at).format('YYYY')}
              </p>
            ) : null}
          </ProductGridItem>
        </ProductGrid>
      </ProductDetailsContainer>
    </SectionContainer>
  );
};

export default OtherDetails;
