import React, { useEffect, useState } from 'react';
import { Container, Header } from './styles';
import { IoSearch } from 'react-icons/io5';
import { FormsModal } from '@app/globalstyles/asgard';
import AddMealFact from './components/add';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMealFacts,
  getMealFactsLoading,
  getMealFactsPageStats
} from './redux/selector';
import { delete_meal_facts, get_meal_facts } from './redux/reducer';
import FactsTable from './components/table';
import useDebounce from '@app/Hooks/useDebounce';
import LoadingDataUi from '@app/components/loading';
import Pagination from '@app/components/pagination';

const Facts = () => {
  const [show, setShow] = useState(false);
  const facts = useSelector(getMealFacts);
  const loading = useSelector(getMealFactsLoading);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const debouncedTerm = useDebounce(search, 500);
  const pageStats = useSelector(getMealFactsPageStats);
  const [content, setContent] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(
      get_meal_facts({ page: currentPage, limit: limit, search: debouncedTerm })
    );
  }, [dispatch, currentPage, limit, debouncedTerm]);

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const getNewMealFacts = (page, limit) => {
    dispatch(get_meal_facts({ page, limit, debouncedTerm }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewMealFacts(page, limit);
  };
  const handlePageSizeChange = () => {
    setLimit(parseInt(limit));
    setCurrentPage(1);
    getNewMealFacts(1, limit);
  };

  const handleDelete = (doc) => {
    dispatch(delete_meal_facts(doc));
  };

  const handleEdit = (doc) => {
    setContent(doc);
    setIsEdit(!isEdit);
    setTimeout(() => {
      setShow(!show);
    }, 400);
  };

  return (
    <Container>
      {show && (
        <FormsModal show={show} onClick={() => setShow(!show)}>
          <div onClick={StopBubbling}>
            <AddMealFact
              onClose={() => setShow(!show)}
              isEdit={isEdit}
              content={content}
            />
          </div>
        </FormsModal>
      )}
      <Header>
        <p>Meal Facts</p>

        <div className="search_box">
          <IoSearch size={20} color="#64748B" />
          <input
            placeholder="Search for a Meal Fact"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <button
          onClick={() => {
            setContent({});
            setIsEdit(false);
            setTimeout(() => {
              setShow(!show);
            }, 400);
          }}
        >
          Add a Fun Meal Fact
        </button>
      </Header>

      {loading ? (
        <LoadingDataUi />
      ) : (
        <div>
          <FactsTable
            facts={facts}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />

          <Pagination
            itemsCount={pageStats.docs}
            currentPage={currentPage}
            pageSize={limit}
            onChangePageSize={handlePageSizeChange}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </Container>
  );
};

export default Facts;
