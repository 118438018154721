import { AsgardP, Img } from '@app/globalstyles/asgard';
import React from 'react';
import styled from 'styled-components';

const PCardContainer = styled.div`
  width: 175.06px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;
const PCard = styled.div`
  width: 175.06px;
  height: 118.61px;
  border-radius: 5px;
  background: #afafaf;
  img {
    min-width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`;
const PCardFloat = styled.div`
  width: 175.06px;
  height: 118.61px;
  margin-top: -122.61px;
  position: relative;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
`;
const MeasurementLabelDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 61.5px;
  height: 22.93px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  bottom: 4%;
  right: 2%;
  span {
    font-size: 14px;
  }
`;

function SubProductCard({ imgUrl, alt = 'Sub Product', measurement, unit }) {
  return (
    <PCardContainer>
      <PCard>
        <Img src={imgUrl} alt={alt} />
        <PCardFloat>
          <MeasurementLabelDiv>
            <span style={{ color: '#003232' }}>
              {measurement} {unit}
            </span>
          </MeasurementLabelDiv>
        </PCardFloat>
      </PCard>
      <AsgardP mt="3px" color="#4F4F4F">
        {alt}
      </AsgardP>
    </PCardContainer>
  );
}

export default SubProductCard;
