import React from 'react';
import { Formik, Form } from 'formik';
import { FormBox } from '../style';
import { CustomInput } from '@app/components/forms/InputGen';
import * as Yup from 'yup';
import ErrorMessage from '@app/components/forms/errorMessage';
import { create_units } from '../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getCreating } from '../redux/selectors';

const CreateUnit = ({ hadleShow }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getCreating);
  let initialValues = {
    name: ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required().label('Name')
  });

  const handleSubmit = (doc) => {
    dispatch(create_units(doc));
    setTimeout(() => {
      hadleShow();
    }, 500);
  };

  return (
    <div>
      <FormBox>
        <div className="form_title">Add Unit</div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            handleChange,
            errors,
            setFieldTouched,
            values: formikValues,
            touched
          }) => (
            <Form>
              <div className="form_content">
                <label className="title">Name</label>
                <CustomInput
                  type="text"
                  error={touched.name && errors.name}
                  name="name"
                  placeholder="name"
                  onBlur={() => setFieldTouched('name')}
                  onChange={handleChange('name')}
                />
                <ErrorMessage error={errors.name} visbile={touched.name} />

                <div>
                  <button type="submit" disabled={loading}>
                    {loading ? 'Loading' : 'Create'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </FormBox>
    </div>
  );
};

export default CreateUnit;
