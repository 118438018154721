export const FilterData = [
  {
    id: 1,
    title: 'All',
    value: `status=${''}`
  },
  {
    id: 2,
    title: 'Approved',
    value: 'status=approved'
  },
  {
    id: 3,
    title: 'Pending',
    value: 'status=pending'
  },
  {
    id: 4,
    title: 'Declined',
    value: 'status=declined'
  },
  {
    id: 5,
    title: 'Created meal',
    value: 'has_meal=true'
  },
  {
    id: 6,
    title: 'No available meal',
    value: 'has_meal=false'
  }
];
