import React from 'react';
import { Title, Description } from '../style';
import { Badge } from '@design-system/Badge/Badge';
import { useDispatch } from 'react-redux';
import { update_recipes } from '../redux/reducer';

const RecipeTitle = ({ recipe }) => {
  const dispatch = useDispatch();

  const handleUpdate = (name) => {
    if (name === 'approved') {
      const data = {
        status: 'approved'
      };
      dispatch(update_recipes({ data, id: recipe._id }));
    } else {
      const data = {
        status: 'rejected'
      };
      dispatch(update_recipes({ data, id: recipe._id }));
    }
  };
  return (
    <div>
      <Title>
        <div>
          <div className="recipe_title">{recipe.name}</div>
          <div className="recipe_link">
            <p>{recipe.video_url}</p>
          </div>
          <div className="status_box">
            <Badge
              bg={
                recipe.status === 'rejected'
                  ? 'danger'
                  : recipe.status === 'approved'
                  ? 'success'
                  : 'warning'
              }
              color={
                recipe.status === 'rejected'
                  ? 'danger'
                  : recipe.status === 'approved'
                  ? 'success'
                  : 'warning'
              }
            >
              {recipe.status === 'rejected' ? 'Declined' : recipe.status}
            </Badge>
            <p>{recipe.prep_time}</p>
          </div>
        </div>

        <div className="btn_container">
          {recipe.status !== 'approved' ? (
            <>
              <button
                className="filled_button"
                onClick={() => handleUpdate('approved')}
              >
                Approve
              </button>
              <button
                className="outline_button"
                onClick={() => handleUpdate('rejected')}
              >
                Decline
              </button>
            </>
          ) : null}
        </div>
      </Title>
      <Description>{recipe.description}</Description>
    </div>
  );
};

export default RecipeTitle;
