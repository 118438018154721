import React, { useState } from 'react';
import {
  CardContainer,
  Background,
  Card,
  TextInput,
  Text,
  Form,
  Button
} from '@design-system/';
import { useDispatch, useSelector } from 'react-redux';
import { getLoading } from './redux/selectors';
import { request_token } from './redux/reducer';
import toast from 'react-hot-toast';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const [state, setState] = useState({
    email: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const akaaniDomain = state.email.endsWith('@useakaani.com');
    if (akaaniDomain) {
      const data = {
        email: state.email
      };
      dispatch(request_token(data));
    } else {
      toast.error('Email must be an akaani email');
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Background style={{ backgroundColor: 'grey' }}>
      <CardContainer>
        <Card style={{ paddingBottom: 120, paddingTop: 100 }}>
          <Text size="large" color="#043C10" weight="900">
            Reset Password
          </Text>
          <Text size="small">To reset your password, enter your akaani email.</Text>
          <Form>
            <TextInput
              type="text"
              id="email"
              name="email"
              onChange={handleChange}
              value={state.email}
              placeholder="Enter your email"
            ></TextInput>

            <Button
              style={{ marginTop: 30 }}
              text={loading ? 'Loading...' : 'Send'}
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
            />
          </Form>
        </Card>
      </CardContainer>
    </Background>
  );
};

export default ResetPassword;
