import React from 'react';
import TrendsDashboard from './trends';
import DashboardHeader from '@app/components/header';
import KPIs from './containers/stats';

function DashboardPage() {
  return (
    <div>
      <DashboardHeader />
      <KPIs />
      <TrendsDashboard />
    </div>
  );
}

export default DashboardPage;
