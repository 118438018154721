import React, { useEffect } from 'react';
import { SectionBody, SectionContainer } from '../style';
import { TableRow } from '@design-system/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupMembers, getLoadingMembers } from '../redux/selectors';
import { get_group_members } from '../redux/reducer';
import { Button, Div, Img } from '@app/globalstyles/asgard';
import loadingSpinner from '@app/assets/icons/loading.svg';
import Colors from '@app/globalstyles/utils/colors';
import { Table } from '../style';

export const Members = ({ group }) => {
  const dispatch = useDispatch();
  const members = useSelector(getGroupMembers);
  const loading = useSelector(getLoadingMembers);
  const { _id } = group;

  // const { sizes } = group;
  useEffect(() => {
    dispatch(get_group_members(_id));
  }, [dispatch, _id]);

  return (
    <SectionContainer>
      <SectionBody style={{ marginTop: '30px' }}>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Amount Spent</th>
              <th>Total Weight</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              members?.map((member) => (
                <TableRow key={member._id}>
                  <td>
                    <div className="user_info">
                      <div className="user_img">
                        {member?.user_id?.first_name?.slice(0, 1)}{' '}
                        {member?.user_id?.last_name?.slice(0, 1)}
                      </div>
                      <div>
                        {`${member?.user_id?.first_name} ${member?.user_id?.last_name}`}
                        <div className="email">{member?.user_id?.email}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {member?.currency?.symbol}
                    {member?.total_price}
                  </td>
                  <td>{member?.total_weight}kg</td>
                  <td>
                    <Button
                      p="10px 20px"
                      bc={Colors.primaryMain}
                      color={Colors.white}
                      br="5px"
                    >
                      View
                    </Button>
                  </td>
                </TableRow>
              ))}
          </tbody>
        </Table>
      </SectionBody>
      {loading && (
        <Div width="100%" display="flex" justify="center" alignI="center">
          <Img src={loadingSpinner} alt="Loading Spinner" />
        </Div>
      )}
    </SectionContainer>
  );
};

export default Members;
