import styled from 'styled-components/macro';
import media from '@app/globalstyles/utils/media';

export const HeaderContainer = styled.div`
  height: 50px;
  width: 98%;
  display: flex;
  margin-top: -10px;
  padding-bottom: 15px;
  ${media.smallDesktopMinimum`
	margin-top:20px;
	`}
`;
