import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    creating: false,
    stats: {
      pageCount: 1
    }
  },
  tags: []
};

export const tagsReducerSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    get_tags: (state) => {
      state.ui.loading = true;
    },
    get_tags_success: (state, { payload }) => {
      state.ui.loading = false;
      state.tags = payload;
    },
    get_tags_error: (state) => {
      state.ui.loading = false;
    },
    create_tags: (state) => {
      state.ui.creating = true;
    },
    create_tags_success: (state) => {
      state.ui.creating = false;
    },
    create_tags_error: (state) => {
      state.ui.creating = false;
    },
    delete_tag: (state) => {
      state.ui.creating = true;
    },
    delete_tag_success: (state) => {
      state.ui.creating = false;
    },
    delete_tag_error: (state) => {
      state.ui.creating = false;
    },
    update_tag: (state) => {
      state.ui.creating = true;
    },
    update_tag_success: (state) => {
      state.ui.creating = false;
    },
    update_tag_error: (state) => {
      state.ui.creating = false;
    }
  }
});

export const {
  get_tags,
  get_tags_error,
  get_tags_success,
  create_tags,
  create_tags_error,
  create_tags_success,
  delete_tag,
  delete_tag_error,
  delete_tag_success,
  update_tag,
  update_tag_error,
  update_tag_success
} = tagsReducerSlice.actions;

export default tagsReducerSlice.reducer;
