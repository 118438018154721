import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import commaNumber from 'comma-number';
import Colors from '@app/globalstyles/utils/colors';
import { AsgardH, AsgardP } from '@app/globalstyles/asgard';
import media from '@app/globalstyles/utils/media';
import { useDispatch, useSelector } from 'react-redux';
import { getKPIs, getLoading } from '../redux/selectors';
import { get_stats } from '../redux/reducer';
import { getAllProducts } from '@app/pages/products/redux/selectors';
import { get_all_products } from '@app/pages/products/redux/reducer';

export const CardContainer = styled.div`
  display: grid;
  /* place-items: center; */
  grid-template-columns: repeat(4, 23.5%);
  grid-gap: 20px;
  ${media.smallDesktopMinimum`
    grid-template-columns: repeat(2, 48.5%);
    `}
  ${media.tablet`
    grid-template-columns: repeat(4, 20.5%);
    `}
	${media.mobile`
    grid-template-columns: repeat(1, 95%);
    `}
`;
export const CardBody = styled.div`
  height: 116px;
  background: ${Colors.white};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  :hover {
    border: 2px solid ${Colors.blue};
    cursor: pointer;
  }
`;

const KPIs = () => {
  const kpis = useSelector(getKPIs);
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  const allProducts = useSelector(getAllProducts);

  useEffect(() => {
    dispatch(get_stats());
    dispatch(get_all_products());
  }, [dispatch]);

  return (
    <CardContainer>
      <>
        <CardBody>
          <AsgardP tAlign="center" color={Colors.grey}>
            {'All Sellers'}
          </AsgardP>
          <AsgardH fs="18px" tAlign="center" color={Colors.blackish}>
            {loading ? 0 : commaNumber(kpis.all_sellers)}
          </AsgardH>
        </CardBody>

        <CardBody>
          <AsgardP tAlign="center" color={Colors.grey}>
            {'All Products'}
          </AsgardP>
          <AsgardH fs="18px" tAlign="center" color={Colors.blackish}>
            {loading ? 0 : commaNumber(allProducts?.length)}
          </AsgardH>
        </CardBody>

        <CardBody>
          <AsgardP tAlign="center" color={Colors.grey}>
            {'New Users'}
          </AsgardP>
          <AsgardH fs="18px" tAlign="center" color={Colors.blackish}>
            {loading ? 0 : commaNumber(kpis.new_users)}
          </AsgardH>
        </CardBody>

        <CardBody>
          <AsgardP tAlign="center" color={Colors.grey}>
            {'All Users'}
          </AsgardP>
          <AsgardH fs="18px" tAlign="center" color={Colors.blackish}>
            {loading ? 0 : commaNumber(kpis.all_users)}
          </AsgardH>
        </CardBody>
      </>
    </CardContainer>
  );
};

export default KPIs;
