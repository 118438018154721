import React, { PureComponent } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line
} from 'recharts';

const CustomizedDot = (props) => {
  const { cx, cy } = props;

  return (
    <svg
      x={cx - 10}
      y={cy - 10}
      width={25}
      height={25}
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.16"
        cx="14"
        cy="14"
        r="13"
        stroke="#3751FF"
        stroke-width="2"
      />
      <g filter="url(#filter0_d_103_407)">
        <circle cx="14" cy="14" r="7" fill="white" />
        <circle cx="14" cy="14" r="5" stroke="#3751FF" stroke-width="4" />
      </g>
      <defs>
        <filter
          id="filter0_d_103_407"
          x="5"
          y="6"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.215686 0 0 0 0 0.317647 0 0 0 0 1 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_103_407"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_103_407"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
const CustomizedDot2 = (props) => {
  const { cx, cy } = props;

  return (
    <svg
      x={cx - 10}
      y={cy - 10}
      width={25}
      height={25}
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.16"
        cx="10"
        cy="10"
        r="13"
        stroke="#3751FF"
        stroke-width="2"
      />
      <g filter="url(#filter0_d_103_407)">
        <circle cx="14" cy="14" r="7" fill="white" />
        <circle cx="14" cy="14" r="5" stroke="#DFE0EB" stroke-width="4" />
      </g>
      <defs>
        <filter
          id="filter0_d_103_407"
          x="5"
          y="6"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.215686 0 0 0 0 0.317647 0 0 0 0 1 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_103_407"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_103_407"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default class Example extends PureComponent {
  render() {
    if (this.props.dashboard === 'monthlyUser') {
      return (
        <ResponsiveContainer width="98%" aspect={2 / 1}>
          <LineChart
            width={730}
            height={250}
            data={this.props.stat}
            margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
          >
            <XAxis dataKey="name" />
            <YAxis orientation="right" />
            <CartesianGrid
              strokeDasharray="3 3"
              horizontal={true}
              vertical={false}
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="uv"
              stroke="#DFE0EB"
              fillOpacity={1}
              fill="url(#colorUv)"
              strokeWidth={3}
              activeDot={<CustomizedDot2 />}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#3751FF"
              fillOpacity={1}
              strokeWidth={3}
              fill="url(#colorPv)"
              activeDot={<CustomizedDot />}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      );
    } else if (this.props.dashboard === 'monthlyRevenue')
      return (
        <ResponsiveContainer width="98%" aspect={2 / 1}>
          <LineChart
            width={730}
            height={250}
            data={this.props.order}
            margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
          >
            <XAxis dataKey="name" />
            <YAxis orientation="right" />
            <CartesianGrid
              strokeDasharray="3 3"
              horizontal={true}
              vertical={false}
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="uv"
              stroke="#DFE0EB"
              fillOpacity={1}
              fill="url(#colorUv)"
              strokeWidth={3}
              activeDot={<CustomizedDot2 />}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#3751FF"
              fillOpacity={1}
              strokeWidth={3}
              fill="url(#colorPv)"
              activeDot={<CustomizedDot />}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      );
  }
}
