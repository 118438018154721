import React, { useEffect, useState } from 'react';
import { Header, Box } from './style';
import {
  getProductCatgories,
  getLoading,
  getPageStats
} from '../product-categories/redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { get_product_categories } from '../product-categories/redux/reducer';
import { FormsModal } from '@app/globalstyles/asgard';
import AddCategory from './components/AddCategory';
import Table from './Table';
import EditCategory from './components/EditCategory';
import EmptyDataUi from '@app/components/emptyData';
import useDebounce from '@app/Hooks/useDebounce.js';
import Pagination from '@app/components/pagination';

const Categories = () => {
  const categories = useSelector(getProductCatgories);
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  const [hide, setHide] = useState(false);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState('');
  const debouncedTerm = useDebounce(searchString, 500);
  const pageStats = useSelector(getPageStats);

  const hideOnEscape = (e) => {
    if (e.key === 'Escape') {
      setHide(false);
      setShow(false);
    }
  };

  const handleShow = (data) => {
    setShow(!show);
    setCategory(data);
  };

  useEffect(() => {
    document.addEventListener('keydown', hideOnEscape, true);
  });

  useEffect(() => {
    dispatch(
      get_product_categories({
        page: currentPage,
        limit: pageSize,
        searchString: debouncedTerm
      })
    );
  }, [dispatch, currentPage, pageSize, debouncedTerm]);

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const getNewCategories = (page, limit) => {
    dispatch(get_product_categories({ page, limit, debouncedTerm }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewCategories(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewCategories(1, pageSize);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };
  return (
    <div>
      <Header>Product Categories</Header>

      {hide && (
        <FormsModal show={hide} onClick={() => setHide(!hide)}>
          <AddCategory StopBubbling={StopBubbling} onClose={() => setHide(!hide)} />
        </FormsModal>
      )}

      {show && (
        <FormsModal show={show} onClick={() => setShow(!show)}>
          <EditCategory
            StopBubbling={StopBubbling}
            category={category}
            close={() => setShow(!show)}
          />
        </FormsModal>
      )}

      <Box>
        <div className="search_bar">
          <input type="text" placeholder="Search" onChange={handleSearch} />
        </div>
        <button onClick={() => setHide(true)}>Add Category</button>
      </Box>

      {!loading && categories?.length <= 0 ? (
        <EmptyDataUi />
      ) : (
        <Table loading={loading} categories={categories} handleShow={handleShow} />
      )}

      <Pagination
        itemsCount={pageStats.docs}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangePageSize={handlePageSizeChange}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Categories;
