import { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  ActionBtnDiv,
  FormItemContainer,
  OpenImgPickbtn,
  PImageUpload,
  ProductFormBody,
  ProductFormLeft,
  ProductFormMain,
  ProductFormRight
} from '@app/pages/products/addproduct/style';

import { AsgardP, Button, FormCloseBtnContainer } from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';
import Divider from '@app/globalstyles/dividerLine';
import { CustomInput, CustomTextAreaInput } from '@app/components/forms/InputGen';
import SelectInputP from '@app/pages/products/addproduct/select';
import ErrorMessage from '@app/components/forms/errorMessage';
import { FileUploadToCloud } from '@app/services/fileUploadService';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_product_categories } from '@app/pages/product-categories/redux/reducer';
import { getProductCatgories } from '@app/pages/product-categories/redux/selectors';
import { getProduct } from '../redux/selectors';
import { update_product } from '../redux/reducer';
import { EditDescDiv } from '@design-system/ProductGrid';
import { ProductUpdateFormDiv } from '../style';
import { CloseFormButton } from '@app/components/forms/CloseFormButton';

const EditProductDetails = ({ closeform }) => {
  const [productImage, setProductImage] = useState([]);
  const dispatch = useDispatch();
  const categories = useSelector(getProductCatgories);
  const product = useSelector(getProduct);
  let initialValues = {
    name: product.name || '',
    description: product.description || '',
    product_category: product.product_category._id || '',
    quantity: product.quantity || '',
    container_type: product.container_type[0] || '',
    country: product.country || ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Name is required').label('Name'),
    container_type: Yup.string().required().label('Weight'),
    product_category: Yup.string().required().label('Product Category'),
    country: Yup.string().required().label('Country'),
    description: Yup.string().required().label('Description')
  });

  const imgRef = useRef();

  const handleOpenImage = () => {
    imgRef.current.click();
  };

  const handlePickImage = (e) => {
    setProductImage(e.target.files[0]);
  };
  const handleCreateProduct = async (doc) => {
    let response;
    if (productImage.name) {
      response = await FileUploadToCloud(productImage);
    }

    const data = {
      ...doc,
      image: response ? response.secure_url : product.image
    };
    delete data['currency'];
    dispatch(update_product({ id: product._id, data }));
    closeform();
  };

  useEffect(() => {
    dispatch(get_product_categories());
  }, [dispatch]);
  return (
    <ProductUpdateFormDiv>
      {/* <ProductFormContainer> */}
      <div style={{ marginTop: '15px' }}>
        <AsgardP fw="bold" fs="20px" color={Colors.black} tAlign="center">
          Edit Product
        </AsgardP>
        <FormCloseBtnContainer mt="-50px">
          <CloseFormButton onClick={closeform} />
        </FormCloseBtnContainer>
      </div>
      <Divider bg={Colors.primaryMain} />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values) => handleCreateProduct(values)}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values
        }) => (
          <Form onSubmit={handleSubmit}>
            <ProductFormMain>
              <EditDescDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                  Description
                </AsgardP>
                <CustomTextAreaInput
                  name="description"
                  value={values.description}
                  placeholder="Description"
                  onBlur={() => setFieldTouched('description')}
                  onChange={handleChange('description')}
                ></CustomTextAreaInput>
                <ErrorMessage
                  error={errors.description}
                  visible={touched.description}
                />
              </EditDescDiv>
              <ProductFormBody>
                <ProductFormLeft>
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                      Country
                    </AsgardP>
                    <SelectInputP
                      name="country"
                      value={values.country}
                      onBlur={() => setFieldTouched('country')}
                      onChange={handleChange('country')}
                      label="Select Country"
                    >
                      <option value={'Nigeria'}>Nigeria</option>
                      <option value={'United States'}>United States</option>
                      <option value={'Canada'}>Canada</option>
                    </SelectInputP>
                  </FormItemContainer>
                  <ErrorMessage error={errors.country} visible={touched.country} />
                </ProductFormLeft>
                <ProductFormRight>
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                      Container
                    </AsgardP>
                    <SelectInputP
                      name="container_type"
                      value={values.container_type}
                      onBlur={() => setFieldTouched('container_type')}
                      onChange={handleChange('container_type')}
                      label="Select container"
                    >
                      <option value={'ziplocks'}>Ziplocks</option>
                      <option value={'flat poly'}>Flat Poly</option>
                      <option value={'boxes'}>Boxes</option>
                    </SelectInputP>
                  </FormItemContainer>
                  <ErrorMessage
                    error={errors.container_type}
                    visible={touched.container_type}
                  />
                </ProductFormRight>
              </ProductFormBody>
              <EditDescDiv>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    columnGap: '1em'
                  }}
                >
                  <FormItemContainer>
                    <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.black}>
                      Upload Image
                    </AsgardP>
                    <OpenImgPickbtn type="button" onClick={handleOpenImage}>
                      {productImage?.name
                        ? productImage?.name
                        : 'Upload JPEG or PNG'}
                    </OpenImgPickbtn>
                  </FormItemContainer>
                  <div>
                    <FormItemContainer>
                      <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                        Category
                      </AsgardP>
                      <SelectInputP
                        name="type"
                        value={values.product_category}
                        onBlur={() => setFieldTouched('product_category')}
                        onChange={handleChange('product_category')}
                        label="Select Category"
                      >
                        {categories.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                        ))}
                      </SelectInputP>
                    </FormItemContainer>
                    <ErrorMessage
                      error={errors.product_category}
                      visible={touched.product_category}
                    />
                  </div>
                  <div>
                    <FormItemContainer>
                      <AsgardP mt="15px" mb="3px" fw="bold" color={Colors.blackish}>
                        Name
                      </AsgardP>
                      <CustomInput
                        error={touched.name && errors.name}
                        type="text"
                        name="name"
                        value={values.name}
                        placeholder="Name"
                        onBlur={() => setFieldTouched('name')}
                        onChange={handleChange('name')}
                      />
                    </FormItemContainer>
                    <ErrorMessage error={errors.name} visible={touched.name} />
                  </div>
                </div>
              </EditDescDiv>
              <EditDescDiv>
                <PImageUpload>
                  {productImage?.name ? <p>{productImage?.name}</p> : null}
                  {/* {productImage?.name ? (
                    <PreviewImgDiv>
                      <Img
                        width="100%"
                        height="100%"
                        br="50%"
                        src={URL.createObjectURL(productImage)}
                        alt="selected image"
                      />
                    </PreviewImgDiv>
                  ) : (
                    <PreviewImgDiv>
                      <Img
                        width="100%"
                        height="100%"
                        br="50%"
                        src={product.image}
                        alt="selected image"
                      />
                    </PreviewImgDiv>
                  )} */}
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    ref={imgRef}
                    onChange={(e) => handlePickImage(e)}
                  />
                </PImageUpload>
              </EditDescDiv>
              <ActionBtnDiv>
                <Button
                  type="submit"
                  p="10px 60px"
                  bc={Colors.primaryMain}
                  br="5px"
                  mr="15px"
                  color={Colors.white}
                  hbc={Colors.blackish}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </ActionBtnDiv>
            </ProductFormMain>
          </Form>
        )}
      </Formik>
      {/* </ProductFormContainer> */}
    </ProductUpdateFormDiv>
  );
};

export default EditProductDetails;
