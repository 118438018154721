import styled from 'styled-components';
export const AddMealFormContainer = styled.div``;
export const AddMealInputDiv = styled.div`
  width: ${({ w }) => (w ? w : '80%')};
  display: flex;
  align-items: center;
  gap: 2%;
  margin-top: 25px;
`;

export const AddProdInput = styled.input`
  width: ${({ width }) => (width ? `calc(${width} - 10px)` : '100%')};
  padding: 0px 5px;
  outline: none;
  height: 42px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.33);
  box-sizing: border-box;
  border-radius: 10px;
`;
export const AddOrRemoveBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0px;
`;

export const FloaTingLabel = styled.span`
  position: absolute;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  top: 30%;
  left: ${({ left }) => (left ? left : '90%')};
  color: #493e3e;
`;

export const RelativeInputDiv = styled.div`
  position: relative;
  display: flex;
  width: ${({ w }) => (w ? w : '100%')};
`;

export const RelativeInputDiv2 = styled.div`
  position: relative;
  width: ${({ w }) => (w ? w : '100%')};
`;

export const ProductItemsDiv = styled.div`
  height: 200px;
  position: absolute;
  width: ${({ w }) => (w ? w : '40%')};
  background: #ffffff;
  box-shadow: 0px 0px 11px rgba(111, 93, 93, 0.25);
  border-radius: 10px;
  top: 113%;
  padding: 10px;
  overflow-y: scroll;
`;

export const ProductOption = styled.div`
  margin-top: 5px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: lightblue;
  }
`;
