import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { SectionContainer } from '../style';
import { AsgardP, Div, FormsModal } from '@app/globalstyles/asgard';
import {
  GridHeaderSection,
  ProductDetailsContainer
} from '@design-system/ProductGrid';
import { DetailsEditButton } from '@design-system/ProductGrid/DetailsEditButton';
import EditSubProducts from './EditSubProducts';
import SubProductCard from '@design-system/Card/SubProductCard';
import EditProductDetails from './EditProductDetails';

const ProductContentDiv = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ToggleBtnDiv = styled.div`
  display: flex;
  gap: 1.5rem;
`;
const ProDetailToggleBtn = styled.div`
  padding: 0px 0px;
  cursor: pointer;
  margin-bottom: -1px;
  border-bottom: ${({ active }) => (active ? '3px solid #284242' : 'none')};
  h3 {
    color: #284242;
  }
`;

const ProductDetailDivs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

const ValueDiv = styled.div`
  display: flex;
  max-width: 70%;
`;
const TitleDiv = styled.div`
  display: flex;
  min-width: 10%;
`;

export const SubProducts = ({ product }) => {
  const [showForm, setShowForm] = useState(false);
  const [showFormSize, setShowFormSize] = useState(false);
  const [key, setkey] = useState('details');
  const handleShowProductForm = () => {
    setShowForm(!showForm);
  };
  const handleShowProductFormSize = () => {
    setShowFormSize(!showFormSize);
  };
  const { sub_products } = product;

  return (
    <SectionContainer>
      <ProductDetailsContainer>
        <GridHeaderSection>
          <ToggleBtnDiv>
            <ProDetailToggleBtn
              active={key === 'details' ? true : false}
              onClick={() => setkey('details')}
            >
              <h3>Product Description </h3>
            </ProDetailToggleBtn>
            <ProDetailToggleBtn
              active={key === 'content' ? true : false}
              onClick={() => setkey('content')}
            >
              <h3>Product Content</h3>
            </ProDetailToggleBtn>
          </ToggleBtnDiv>
          {key === 'details' ? (
            <DetailsEditButton onClick={handleShowProductFormSize} />
          ) : (
            <DetailsEditButton onClick={handleShowProductForm} />
          )}
        </GridHeaderSection>

        {key === 'details' ? (
          <ProductDetailDivs>
            <Div display="flex" width="100%">
              <TitleDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color="#AFAFAF">
                  Description
                </AsgardP>
              </TitleDiv>
              <ValueDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color="#4C515A">
                  {product?.description}
                </AsgardP>
              </ValueDiv>
            </Div>
            <Div display="flex" width="100%">
              <TitleDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color="#AFAFAF">
                  Country
                </AsgardP>
              </TitleDiv>
              <ValueDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color="#4C515A">
                  {product?.country}
                </AsgardP>
              </ValueDiv>
            </Div>
            <Div display="flex" width="100%">
              <TitleDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color="#AFAFAF">
                  Container
                </AsgardP>
              </TitleDiv>
              <ValueDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color="#4C515A">
                  {product?.container_type?.length > 0
                    ? product?.container_type[0]
                    : 'Not Provided'}
                </AsgardP>
              </ValueDiv>
            </Div>
            <Div display="flex" width="100%">
              <TitleDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color="#AFAFAF">
                  Discounted
                </AsgardP>
              </TitleDiv>
              <ValueDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color="#4C515A">
                  {product.discount > 0 ? 'YES' : 'NO'}
                </AsgardP>
              </ValueDiv>
            </Div>
            <Div display="flex" width="100%">
              <TitleDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color="#AFAFAF">
                  Discount
                </AsgardP>
              </TitleDiv>
              <ValueDiv>
                <AsgardP mt="15px" mb="3px" fw="bold" color="#4C515A">
                  {product.discount}
                </AsgardP>
              </ValueDiv>
            </Div>
          </ProductDetailDivs>
        ) : null}
        {key === 'content' ? (
          <ProductContentDiv>
            {sub_products?.map((product) => (
              <SubProductCard
                key={product.id}
                imgUrl={`${product.product_id?.image}`}
                alt={product?.name}
                measurement={product?.measurement}
                unit={product?.unit}
              />
            ))}

            {sub_products?.length <= 0 && (
              <Div>
                <p>Product Content Data Not Available </p>
              </Div>
            )}
          </ProductContentDiv>
        ) : null}
        {showForm && (
          <FormsModal show={showForm}>
            <EditSubProducts product={product} closeform={handleShowProductForm} />
          </FormsModal>
        )}
        {showFormSize && (
          <FormsModal show={showFormSize}>
            <EditProductDetails
              product={product}
              closeform={handleShowProductFormSize}
            />
          </FormsModal>
        )}
      </ProductDetailsContainer>
    </SectionContainer>
  );
};

export default SubProducts;
