import { createSelector } from '@reduxjs/toolkit';

const couponsData = (state = {}) => state?.coupons;

export const getCoupons = createSelector(
  couponsData,
  (couponsData) => couponsData.data
);

export const getLoading = createSelector(
  couponsData,
  (couponsData) => couponsData.ui.loading
);
