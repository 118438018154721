import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_recipes_success,
  get_recipes_error,
  get_recipes,
  update_recipes_success,
  update_recipes_error,
  update_recipes,
  update_recipe_success,
  update_recipe_error,
  update_recipe,
  get_product_bundle,
  get_product_bundle_success,
  get_product_bundle_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getRecipesSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/recipes/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_recipes_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: get_recipes_error
    });
  }
}

function* updateRecipesStatusSagas({ payload }) {
  const { data, id } = payload;
  try {
    const requestRes = yield call(api.post, `/recipes/status/${id}`, data);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: update_recipes_success.type,
      payload: response
    });
    toast.success('Recipe updated successfully!!');
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: update_recipes_error.type
    });
  }
}

function* updateRecipeSagas({ payload }) {
  const { data, id } = payload;
  try {
    const requestRes = yield call(api.put, `/recipes/${id}`, data);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: update_recipe_success.type,
      payload: response
    });
    toast.success('Recipe updated successfully!!');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: update_recipe_error.type
    });
  }
}

function* getProductBundle({ payload }) {
  try {
    const { page, limit, searchString } = payload;
    const search = searchString ? `&search=${searchString}` : '';
    const request = yield call(
      api.get,
      `/products?page=${page}&limit=${limit}${search}`
    );
    const requestData = request.data;
    const response = requestData.data;
    yield put({
      type: get_product_bundle_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: get_product_bundle_error.type
    });
  }
}

function* RecipesSagas() {
  yield takeEvery(get_recipes, getRecipesSagas);
  yield takeEvery(update_recipes.type, updateRecipesStatusSagas);
  yield takeEvery(update_recipe.type, updateRecipeSagas);
  yield takeEvery(get_product_bundle.type, getProductBundle);
}

export default RecipesSagas;
