import React from 'react';
import media from '@app/globalstyles/utils/media';
import styled from 'styled-components';

export const CustomSelect = styled.select`
  width: 72%;
  height: 37px;
  background: #ffffff;
  border: 1px solid #549a9a;
  border-radius: 5px;
  ${media.mobile`
  width: 100%;
  `}

  option {
    color: #797b80;
    padding-left: 3%;
  }

  &:focus {
    outline: none;
    border: 1px solid #549a9a;
  }
`;
function SelectInputP({ name, label, children, ...rest }) {
  return (
    <CustomSelect name={name} {...rest}>
      <option value="">Select</option>
      {children}
    </CustomSelect>
  );
}

export default SelectInputP;
