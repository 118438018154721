import React from 'react';
import { InputControllerDiv } from './InputGen';

function ErrorMessage({ error, visible }) {
  if (!visible || !error) return null;

  return (
    <InputControllerDiv>
      <p style={{ fontSize: '11px', color: 'red', width: '100%', marginTop: '5px' }}>
        {error}
      </p>
    </InputControllerDiv>
  );
}

export default ErrorMessage;
