import { Img } from '@app/globalstyles/asgard';
import React from 'react';
import styled from 'styled-components';
import trashCan from '@app/assets/icons/trashCanWhite.svg';

const PreviewCardContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 10px;
`;
const PreviewCard = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background: #afafaf;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;
const PCardFloat = styled.div`
  width: 60px;
  height: 60px;
  margin-top: -66px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.55);
`;
const DeleteDiv = styled.div`
  position: absolute;
`;

export const ImagesScrollDiv = styled.div`
  display: flex;
  padding: 3px 0px;
  gap: 12px;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-top: 0px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

function ImagePreviewcard({ imgUrl, alt = 'product Image', measurement, unit }) {
  return (
    <PreviewCardContainer>
      <PreviewCard>
        <Img src={imgUrl} alt={alt} />
        <PCardFloat>
          <DeleteDiv>
            <Img src={trashCan} alt={alt} />
          </DeleteDiv>
        </PCardFloat>
      </PreviewCard>
    </PreviewCardContainer>
  );
}

export default ImagePreviewcard;
