import TableFeatures from '@app/components/tablefeatures';
import React, { useEffect, useState } from 'react';
import { CustomersContainer, SearchBar, FilterBox } from './style';
import { getCoupons, getLoading } from './redux/selectors';
import CouponTable from './table';
import { FormsModal } from '@app/globalstyles/asgard';
import AddProductForm from './addcoupon';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@app/components/pagination';
import { get_coupons } from './redux/reducer';
import { CouponsFilterData } from './CouponsFilterData';
import { Img } from '@app/globalstyles/asgard';
import ArrowTriangleDown from '@assets/icons/filter_downarrow.svg';
import {
  FilterDiv,
  FilterOption,
  FilterSelectDiv,
  SortFilterSelect
} from '../products/style';
import SearchIcon from '@design-system/Assets/Search';

function Coupons() {
  const loading = useSelector(getLoading);
  const couponsFromReducer = useSelector(getCoupons);
  const [selectedfilter, setSelectedFilter] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [coupons, setCoupons] = useState([]);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const searchString = e.target.value.toLowerCase();
    if (!searchString) {
      setCoupons(couponsFromReducer);
    } else {
      const result = couponsFromReducer.filter((coupon) =>
        coupon.coupon_name.toLowerCase().includes(searchString)
      );
      setCoupons(result);
      setCurrentPage(1);
    }
  };
  const getNewCoupons = () => {
    dispatch(get_coupons());
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewCoupons(page, pageSize);
  };
  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(size));
    setCurrentPage(1);
    getNewCoupons(1, pageSize);
  };

  const handleSelectFilter = (e) => {
    setSelectedFilter(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    setCoupons(couponsFromReducer);
  }, [couponsFromReducer]);

  const handleShowProductForm = () => {
    setShowForm(!showForm);
  };
  return (
    <div>
      <CustomersContainer>
        <TableFeatures
          searchFiled={false}
          title="Coupon"
          createBtnLabel={'Create Coupon'}
          addbtn={true}
          onCreateBtn={handleShowProductForm}
        />
        {showForm && (
          <FormsModal show={showForm}>
            <AddProductForm closeform={handleShowProductForm} />
          </FormsModal>
        )}
        <FilterBox>
          <SearchBar>
            <SearchIcon />
            <input type="text" placeholder="Search" onChange={handleChange} />
          </SearchBar>

          <FilterDiv>
            <FilterSelectDiv>
              <SortFilterSelect>
                <select onChange={handleSelectFilter}>
                  {CouponsFilterData.map((filter) => (
                    <FilterOption
                      key={filter.id}
                      active={selectedfilter === filter.value}
                      value={filter.value}
                    >
                      {filter.title}
                    </FilterOption>
                  ))}
                </select>
                <div>
                  <Img src={ArrowTriangleDown} alt="select" />
                </div>
              </SortFilterSelect>

              <SortFilterSelect>
                <select onChange={handleSelectFilter}>
                  {CouponsFilterData.map((filter) => (
                    <FilterOption
                      key={filter.id}
                      active={selectedfilter === filter.value}
                      value={filter.value}
                    >
                      {filter.title}
                    </FilterOption>
                  ))}
                </select>
                <div>
                  <Img src={ArrowTriangleDown} alt="select" />
                </div>
              </SortFilterSelect>
            </FilterSelectDiv>
          </FilterDiv>
        </FilterBox>

        <CouponTable
          coupons={coupons}
          currentPage={currentPage}
          pageSize={pageSize}
        />
        {!loading && (
          <Pagination
            onChangePageSize={handlePageSizeChange}
            // itemsCount={pageStats.docs}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </CustomersContainer>
    </div>
  );
}

export default Coupons;
