import React from 'react';

const Declined = (props) => {
  return (
    <div>
      <svg
        width={25}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.98 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM8.273 16.707a1 1 0 0 1 0-1.414L11.566 12 8.273 8.707a1 1 0 0 1 1.415-1.414l3.292 3.293 3.293-3.293a1 1 0 1 1 1.415 1.414L14.395 12l3.293 3.293a1 1 0 0 1-1.415 1.414l-3.293-3.293-3.292 3.293a1 1 0 0 1-1.415 0Z"
          fill="#EB5757"
        />
      </svg>
    </div>
  );
};

export default Declined;
