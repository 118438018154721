import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

const StatusLabel = styled(Badge)`
  width: 80px;
  padding: 0.5em;
  text-align: center;
  background: ${(props) =>
    props.bg === 'warning'
      ? 'orange'
      : props.bg === 'success'
      ? 'rgba(51, 184, 144, 0.75)'
      : ' rgba(235, 166, 30, 0.75)'};
  border-radius: 4px;
  font-size: 1em;
  color: ${(props) =>
    props.bg === 'warning'
      ? 'white'
      : props.bg === 'success'
      ? '#FFFFFF'
      : '#FFFFFF'};
  text-transform: uppercase;
  cursor: pointer;
`;

export default StatusLabel;
