import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BaseLine = styled.h2`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  font-weight: ${(props) => (props.weight ? props.weight : '500')};
  font-size: ${(props) => (props.size ? props.size : '')};
  margin-top: 10%;
  color: ${(props) => (props.color ? props.color : '#549a9a')};
`;

const BaseText = styled.span`
  background-color: #fff;
  padding: 0 10px;
`;

export const HrLine = ({ text, ...props }) => {
  return (
    <BaseLine {...props}>
      <BaseText>{text}</BaseText>
    </BaseLine>
  );
};

HrLine.propTypes = {
  text: PropTypes.string.isRequired
};
