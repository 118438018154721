import React from 'react';
import LoadingDataUi from '@app/components/loading';
import moment from 'moment';
import { TableBox } from '../style';

const Table = ({ loading, categories, handleShow }) => {
  return (
    <div>
      {loading ? (
        <LoadingDataUi />
      ) : (
        <TableBox>
          <table>
            <thead className="table_head">
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="table_body">
              {categories?.map((data) => (
                <tr key={data._id}>
                  <td>{data.name}</td>
                  <td>{data.description}</td>
                  <td>{moment(data.created_at).format('LL')}</td>
                  <td>
                    <button onClick={() => handleShow(data)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableBox>
      )}
    </div>
  );
};

export default Table;
