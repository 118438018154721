import { createSelector } from '@reduxjs/toolkit';

const health = (state = {}) => state.health;

export const getHealthConditions = createSelector(health, (health) => health.health);

export const getHealthConditionsLoading = createSelector(
  health,
  (health) => health.ui.loading
);

export const getHealthConditionsPageStats = createSelector(
  health,
  (health) => health.ui.stats
);

export const CreateHealthCondition = createSelector(
  health,
  (health) => health.ui.creating
);
