import styled from 'styled-components';

export const Header = styled.div`
  box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.07);
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #003232;
  padding-top: 29.5px;
  padding-bottom: 29.5px;
  padding-left: 14.65px;
`;

export const Container = styled.div`
  padding-left: 14.65px;
  padding-right: 14.65px;
  padding-top: 31.5px;
  overflow-x: hidden;
  padding-bottom: 100px;

  .title {
    font-weight: 500;
    font-size: 15px;
    color: #549a9a;
    text-decoration: none;

    > img {
      margin-right: 9px;
    }
  }

  .recipe_video {
    border-radius: 10px;
    margin-top: 22px;
    margin-bottom: 44px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  .btn_container {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .recipe_title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    text-transform: capitalize;
  }

  .recipe_link p {
    font-weight: 500;
    font-size: 14px;
    line-height: 1%;
    color: #4f4f4f;
  }

  .status_box {
    display: flex;
    align-items: center;
    align_content: center;
  }

  .status_box p {
    font-weight: 500;
    font-size: 15px;
    color: #828282;
    margin-left: 10px;
  }

  .filled_button {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 4px;
    background: #549a9a;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;

    &:disabled {
      background: #acd1d1;
    }
  }

  .outline_button {
    width: 100%;
    height: 50px;
    margin-left: 9px;
    border: 1px solid #549a9a;
    outline: none;
    border-radius: 4px;
    background: none;
    font-weight: 600;
    font-size: 18px;
    color: #549a9a;
    cursor: pointer;
  }

  .meal_button {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 4px;
    background: #549a9a;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;

    &:disabled {
      background: #acd1d1;
    }
  }
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 15px;
  color: #4f4f4f;
  border-top: 1px solid #f2f2f2;
  padding-top: 12.53px;
  margin-top: 18.5px;
`;

export const RecipeIngriedents = styled.div`
  .title {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    margin-bottom: 7.5px;
  }

  .list_box {
    padding-top: 10.75px;
    border-top: 1px solid #f2f2f2;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding-top: 10.75px;
  }

  ul li {
    font-size: 15px;
    font-weight: 400;
    color: #4f4f4f;
    margin-bottom: 10px;
  }

  .image_box {
    display: flex;
    align-items: center;
    align-content: center;
    border-top: 1px solid #f2f2f2;
    padding-top: 18.89px;

    > img {
      width: 166.07px;
      height: 91.35px;
      border-radius: 5px;
      object-fit: cover;
      margin-right: 16.81px;
    }
  }
  .edit_button {
    width: 100%;
    height: 50px;
    margin-right: 9px;
    border: 1px solid #549a9a;
    outline: none;
    border-radius: 4px;
    background: none;
    font-weight: 600;
    font-size: 18px;
    color: #549a9a;
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  padding: 10px;

  .header {
    color: #4f4f4f;
    font-size: 20px;
    font-weight: 700;
  }
`;

export const EditModalBody = styled.div`
  padding: 10px 30px;
  // height: 70vh;
  // overflow-y: scroll
  .header {
    color: #4f4f4f;
    font-size: 20px;
    font-weight: 700;
  }
`;

export const ApprovalModal = styled.div`
  background: #fff;
  ${'' /* height: 50vh; */}
  padding: 10px;
  border-radius: 10px;
  width: 350px;

  select {
    width: 100%;
    margin-top: 24px;
  }

  .btn_container {
    display: flex;
    gap: 10px;
    align-content: center;
    align-items: center;
  }

  .filled_button {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 4px;
    background: #549a9a;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;

    &:disabled {
      background: #acd1d1;
    }
  }

  .outline_button {
    width: 100%;
    height: 50px;
    margin-left: 9px;
    border: 1px solid #549a9a;
    outline: none;
    border-radius: 4px;
    background: none;
    font-weight: 600;
    font-size: 18px;
    color: #549a9a;
    cursor: pointer;
  }
`;

export const FormBody = styled.div`
  padding-top: 20px;
  height: 70vh;
  overflow-y: scroll;
  label {
    color: #4f4f4f;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .form_content {
      display: flex;
      flex-direction: column;

      > input {
        border: 1px solid #e0e0e0;
        outline: none;
        height: 44px;
        margin-bottom: 10px;
        padding-left: 14px;
        padding-right: 14px;
      }

      > select {
        border: 1px solid #e0e0e0;
        outline: none;
        height: 47px;
        margin-bottom: 10px;
        border-radius: 0px;
      }
    }
  }

  .form_content {
    display: flex;
    flex-direction: column;

    > input {
      border: 1px solid #e0e0e0;
      outline: none;
      height: 44px;
      margin-bottom: 10px;
      padding-left: 14px;
      padding-right: 14px;
    }

    > select {
      border: 1px solid #e0e0e0;
      outline: none;
      height: 47px;
      margin-bottom: 10px;
      border-radius: 0px;
    }
  }

  .psw_container {
    display: flex;
    align-items: center;
    align-content: center;
    padding-left: 14px;
    padding-right: 14px;
    height: 44px;
    border: 1px solid #e0e0e0;

    .psw {
      border: none;
      box-sizing: border-box;
      outline: none;
      height: 42px;
      width: 100%;
    }
  }

  textarea {
    border: 1px solid #e0e0e0;
    outline: none;
    padding-left: 14px;
    padding-right: 14px;
    font-weight: 400;
    font-size: 18px;
    color: #828282;
  }

  button {
    background: #549a9a;
    height: 44px;
    border: none;
    outline: none;
    width: 200px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;

    &:disabled {
      background: #d4e7e7;
    }
  }

  .cancel {
    background: #fff;
    height: 44px;
    border: 1px solid #549a9a;
    outline: none;
    width: 200px;
    color: #549a9a;
    font-weight: 700;
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;
    margin-right: 9px;

    &:disabled {
      background: #d4e7e7;
    }
  }

  .segment {
    display: flex;
    align-items: center;
    align-content: center;
    height: 44px;
    border: 1px solid #e0e0e0;

    > .select {
      border-right: 1px solid #e0e0e0;
      border-bottom: none;
      border-top: none;
      border-left: none;

      border-radius: 0px;

      outline: none;
      margin-bottom: 0px;
      height: 43px;
    }

    > input {
      border: none;
      outline: none;
      height: 42px;
      width: 100%;
    }
  }
`;

export const BundleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;

  button {
    background: #549a9a;
    height: 45px;
    border: none;
    outline: none;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;

    &:disabled {
      background: #d4e7e7;
    }
  }
`;
