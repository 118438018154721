import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormComponent } from '@app/pages/facts/styles';
import { CreatingMealCategories } from '../redux/selector';
import { create_meal_categories, update_meal_categories } from '../redux/reducer';

const AddMealCategory = ({ onClose, isEdit, content }) => {
  const imgRef = useRef();
  const [image, setImage] = useState(null);
  const loading = useSelector(CreatingMealCategories);
  const dispatch = useDispatch();

  useEffect(() => {
    if (content?.image) {
      setImage(content?.image);
    }
  }, [content]);

  let initialValues = {
    name: content?.name || '',
    description: content?.description || ''
  };

  const pickImage = () => {
    imgRef.current.click();
  };

  const handleSelectImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (doc) => {
    let data = {
      ...doc,
      images: image
    };

    if (isEdit) {
      dispatch(update_meal_categories({ id: content?._id, data }));
    } else {
      dispatch(create_meal_categories({ data, onClose }));
    }
  };

  return (
    <FormComponent>
      <input
        type="file"
        accept=".png, .jpg, .jpeg"
        className="image"
        ref={imgRef}
        onChange={handleSelectImage}
      />
      {isEdit ? <h3>Edit Meal Category</h3> : <h3>Add Meal Category</h3>}

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange }) => (
          <Form>
            <div className="form">
              <div className="form_content">
                <label>Name</label>
                <input
                  placeholder="Name"
                  value={values.name}
                  onChange={handleChange}
                  name="name"
                  required
                />
              </div>

              <div className="form_content">
                <label>Description</label>
                <textarea
                  placeholder="Description"
                  value={values.description}
                  onChange={handleChange}
                  name="description"
                  required
                  rows={5}
                />
              </div>

              {image?.name ? (
                <div onClick={pickImage}>
                  <img src={URL.createObjectURL(image)} alt="selected" />
                </div>
              ) : image ? (
                <div onClick={pickImage}>
                  <img src={image} alt="selected" />
                </div>
              ) : (
                <div className="image_box" onClick={pickImage}>
                  <div>
                    <h4>Choose a file to upload</h4>
                  </div>
                </div>
              )}
            </div>

            {isEdit ? (
              <button type="submit">{loading ? 'Saving...' : 'Save'}</button>
            ) : (
              <button type="submit">{loading ? 'Creating...' : 'Create'}</button>
            )}
          </Form>
        )}
      </Formik>
    </FormComponent>
  );
};

export default AddMealCategory;
