import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { AsgardP, Button, Div, Img } from '@app/globalstyles/asgard';
import Colors from '@app/globalstyles/utils/colors';
import {
  ActionBtnDiv,
  ProductFormMain
} from '../../../components/forms/addproduct/style';
import { AutoCompDiv, IngredInputDiv, CustomerFormContainer } from './style';
import { addIcon } from '../../../components/re-usedicons';
import Divider from '@app/globalstyles/dividerLine';
import { CustomInput } from '../../../components/forms/InputGen';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getLoading } from '../redux/selectors';
import { create_staff } from '../redux/reducer';
import { getCountries, getCountry, getStates } from 'country-state-picker';
import SelectInputP from './select';
import ErrorMessage from '@app/components/forms/errorMessage';

function AddStaff({ closeform }) {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const [country, setCountry] = useState('');
  const [states, setStates] = useState([]);
  const countries = getCountries();

  const handleCountry = (e) => {
    const tempCountry = getCountry(e.target.value);
    const states = getStates(e.target.value);
    setCountry(tempCountry);
    setStates(states);
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required'),
    country: Yup.string().required('Country is required'),
    phone: Yup.string().required('Phone is required'),
    state: Yup.string().required('State is required'),
    role: Yup.string().required('Role is required'),
    city: Yup.string().required('City is required')
  });

  let initialValuesDb = {
    first_name: '',
    email: '',
    last_name: '',
    phone: '',
    country: '',
    city: '',
    role: '',
    state: ''
  };

  const handleCreateStaff = async (doc) => {
    doc.country = country.name;
    dispatch(create_staff(doc));
    closeform();
  };

  return (
    <CustomerFormContainer>
      <Div width="100%" display="flex" justify="center">
        <AsgardP fw="600" color={Colors.blackish} tAlign="center">
          Add Staff
        </AsgardP>
      </Div>
      <Divider bg={Colors.primaryMain} />
      <Formik
        initialValues={initialValuesDb}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values) => handleCreateStaff(values)}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          setFieldValue
        }) => (
          <Form onSubmit={handleSubmit}>
            <ProductFormMain>
              <AutoCompDiv>
                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>First Name</AsgardP>
                  <CustomInput
                    error={touched.first_name && errors.first_name}
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    onBlur={() => setFieldTouched('first_name')}
                    onChange={handleChange}
                  />
                  <br />
                </IngredInputDiv>
                <ErrorMessage
                  error={errors.first_name}
                  visible={touched.first_name}
                />

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Last Name</AsgardP>
                  <CustomInput
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    onBlur={() => setFieldTouched('last_name')}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    error={errors.last_name}
                    visible={touched.last_name}
                  />
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Country</AsgardP>
                  <SelectInputP
                    name="country"
                    onBlur={() => setFieldTouched('country')}
                    onChange={(e) => {
                      setFieldValue('country', e.target.value);
                      handleCountry(e);
                    }}
                    label="Select Country"
                  >
                    {countries.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </SelectInputP>
                  <ErrorMessage error={errors.country} visible={touched.country} />
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>State</AsgardP>
                  <SelectInputP
                    name="state"
                    onBlur={() => setFieldTouched('state')}
                    onChange={handleChange}
                    label="Select State"
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </SelectInputP>
                  <ErrorMessage error={errors.state} visible={touched.state} />
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Email</AsgardP>
                  <CustomInput
                    type="email"
                    name="email"
                    placeholder="email"
                    onBlur={() => setFieldTouched('email')}
                    onChange={handleChange}
                  />
                  <ErrorMessage error={errors.email} visible={touched.email} />
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>City</AsgardP>
                  <CustomInput
                    type="text"
                    name="city"
                    placeholder="City"
                    onBlur={() => setFieldTouched('city')}
                    onChange={handleChange}
                  />
                  <ErrorMessage error={errors.city} visible={touched.city} />
                </IngredInputDiv>

                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Phone</AsgardP>
                  <CustomInput
                    type="phone"
                    name="phone"
                    placeholder="Enter Phone"
                    onBlur={() => setFieldTouched('phone')}
                    onChange={handleChange}
                  />
                  <ErrorMessage error={errors.phone} visible={touched.phone} />
                </IngredInputDiv>
                <IngredInputDiv>
                  <AsgardP color={Colors.blackish}>Role</AsgardP>
                  <SelectInputP
                    name="role"
                    onBlur={() => setFieldTouched('role')}
                    onChange={handleChange}
                    label="Select Role"
                  >
                    <option value={'admin'}>Admin</option>
                    <option value={'staff'}>Staff</option>
                  </SelectInputP>
                  <ErrorMessage error={errors.role} visible={touched.role} />
                </IngredInputDiv>
              </AutoCompDiv>
              <ActionBtnDiv>
                <Button
                  type="submit"
                  p="10px 30px"
                  bc={Colors.primaryMain}
                  br="5px"
                  mr="15px"
                  color={Colors.white}
                  onClick={handleSubmit}
                  hbc={Colors.blackish}
                >
                  <Img src={addIcon} alt="product-btn" /> Create
                </Button>
                <Button
                  type="button"
                  disabled={loading}
                  onClick={closeform}
                  p="10px 30px"
                  bc={Colors.red}
                  br="5px"
                  color={Colors.white}
                  hbc={Colors.blackish}
                >
                  Cancel
                </Button>
              </ActionBtnDiv>
            </ProductFormMain>
          </Form>
        )}
      </Formik>
    </CustomerFormContainer>
  );
}

export default AddStaff;
