import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const OrderHeader = styled.div`
  width: 100%;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  padding-top: 29.5px;
  padding-bottom: 29.5px;
  padding-left: 14.65px;
  font-weight: 600;
  font-size: 24px;
  color: #003232;
`;

export const SearchFeatures = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-top: 31.21px;
  padding-bottom: 20px;
  padding-left: 14.65px;
  padding-right: 14.65px;
  width: auto;

  .search_container {
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    // padding-top: 12px;
    // padding-bottom: 12px;
    padding-left: 23px;
    width: 587px;
  }

  input {
    width: 578px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: none;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 18px;
  }

  input::placeholder {
    font-weight: 500;
    font-size: 18px;
    color: #bdbdbd;
  }

  .filter {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .filter_text {
    font-weight: 500;
    font-size: 15px;
    color: #4f4f4f;
    margin-right: 11px;
  }

  .filter_content {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #549a9a;
    font-size: 12px;
    font-weight: 500;
    background: #edfafa;
    border: 1px solid #549a9a;
    width: 185px;
    padding-left: 19px;
    padding-right: 22px;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
  }

  .filter_box {
    position: absolute;
    width: 228px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-top: 120px;
    margin-left: 71px;
    display: block;
  }

  .hide {
    display: none;
  }

  .filter_box_content {
    font-weight: 500;
    font-size: 12px;
    color: #828282;

    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;

    &:hover {
      background: #edfafa;
      color: #549a9a;
    }
  }

  .active {
    font-weight: 500;
    font-size: 12px;
    background: #edfafa;
    color: #549a9a;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;
  }
`;

export const FilterBox = styled.div`
  margin-top: 30px;
  padding-left: 14.65px;

  .filter_query {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .filter_query_text {
    font-weight: 500;
    font-size: 15px;
    color: #4f4f4f;
    margin-right: 11px;
  }

  .filter_query_content {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #549a9a;
    font-size: 12px;
    font-weight: 500;
    background: #edfafa;
    border: 1px solid #549a9a;
    width: 185px;
    padding-left: 19px;
    padding-right: 22px;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
  }

  .filter_query_box {
    position: absolute;
    width: 228px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-top: 150px;
    margin-left: 105px;
    display: block;
  }

  .hide {
    display: none;
  }

  .filter_box_content {
    font-weight: 500;
    font-size: 12px;
    color: #828282;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;

    &:hover {
      background: #edfafa;
      color: #549a9a;
    }
  }

  .active {
    font-weight: 500;
    font-size: 12px;
    background: #edfafa;
    color: #549a9a;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;
  }
`;

export const PriceBox = styled.div`
  .filter_query {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .filter_query_text {
    font-weight: 500;
    font-size: 15px;
    color: #4f4f4f;
    margin-right: 11px;
  }

  .filter_query_content {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #e0e0e0;
    font-size: 12px;
    font-weight: 500;
    background: #fff;
    border: 1px solid #f2f2f2;
    width: 105px;
    padding: 9px;
    height: 30px;
  }

  .filter_query_box {
    position: absolute;
    width: 133px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-top: 270px;
    margin-left: 1px;
    display: block;
  }

  .hide {
    display: none;
  }

  .filter_box_content {
    font-weight: 500;
    font-size: 12px;
    color: #828282;
    padding-top: 6.5px;
    padding-bottom: 10.5px;
    padding-left: 19px;
    cursor: pointer;

    &:hover {
      background: #edfafa;
      color: #549a9a;
    }
  }

  .active {
    font-weight: 500;
    font-size: 12px;
    background: #edfafa;
    color: #549a9a;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;
  }
`;

export const TrackingBox = styled.div`
  .filter_query {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .filter_query_text {
    font-weight: 500;
    font-size: 15px;
    color: #4f4f4f;
    margin-right: 11px;
  }

  .filter_query_content {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #e0e0e0;
    font-size: 12px;
    font-weight: 500;
    background: #fff;
    border: 1px solid #f2f2f2;
    width: 105px;
    padding: 9px;
    height: 30px;
  }

  .filter_query_box {
    position: absolute;
    width: 123px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-top: 180px;
    margin-left: 1px;
    display: block;
  }

  .hide {
    display: none;
  }

  .filter_box_content {
    font-weight: 500;
    font-size: 12px;
    color: #828282;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;

    &:hover {
      background: #edfafa;
      color: #549a9a;
    }
  }

  .active {
    font-weight: 500;
    font-size: 12px;
    background: #edfafa;
    color: #549a9a;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;
  }
`;

export const ShipmentBox = styled.div`
  .filter_query {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .filter_query_text {
    font-weight: 500;
    font-size: 15px;
    color: #4f4f4f;
    margin-right: 11px;
  }

  .filter_query_content {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #e0e0e0;
    font-size: 12px;
    font-weight: 500;
    background: #fff;
    border: 1px solid #f2f2f2;
    width: 105px;
    padding: 9px;
    height: 30px;
  }

  .filter_query_box {
    position: absolute;
    width: 123px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-top: 180px;
    margin-left: 1px;
    display: block;
  }

  .hide {
    display: none;
  }

  .filter_box_content {
    font-weight: 500;
    font-size: 12px;
    color: #828282;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;

    &:hover {
      background: #edfafa;
      color: #549a9a;
    }
  }

  .active {
    font-weight: 500;
    font-size: 12px;
    background: #edfafa;
    color: #549a9a;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    padding-left: 19px;
    cursor: pointer;
  }
`;

export const FilterDateBox = styled.div`
  margin-top: 15px;
  padding-left: 14.65px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  .filter_start_date {
    border: 1px solid #f2f2f2;
    padding-left: 15px;
    width: 150px;
    cursor: pointer;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14%;
      color: #828282;
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
      color: #4f4f4f;
      line-height: 2%;
    }
  }

  button {
    background: #549a9a;
    border-radius: 5px;
    color: #fff;
    border: none;
    outline: none;
    width: 214px;
    height: 45px;
    margin-left: 11px;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
  }
`;

export const GroupContainer = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  padding-top: 40px;
  /* font-size: 9px; */
  border-radius: 8px;
  margin-bottom: 30px;
  ${media.mobile`
  width: 99.8%;
   margin-left: -10px;
   margin-bottom: 0px;
  `}
`;
export const PaidStatuslable = styled.div`
  width: 80px;
  padding: 0.5em;
  text-align: center;
  background: ${(props) =>
    props.bg === 'warning'
      ? 'orange'
      : props.bg === 'success'
      ? 'rgba(51, 184, 144, 0.75)'
      : ' #C13535'};
  border-radius: 4px;
  font-size: 1em;
  color: ${(props) =>
    props.bg === 'warning'
      ? 'white'
      : props.bg === 'success'
      ? '#FFFFFF'
      : '#FFFFFF'};
  text-transform: uppercase;
`;
