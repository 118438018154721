import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_products,
  get_products_error,
  get_products_success,
  get_all_products_success,
  get_all_products_error,
  get_all_products,
  create_product,
  create_product_error,
  create_product_success,
  get_meal_products_success,
  get_meal_products_error,
  get_meal_products
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getAllProductsSagas() {
  try {
    const requestRes = yield call(api.get, `/products?&limit=2000`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_all_products_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_all_products_error.type
    });
  }
}

function* getProductsSagas({
  payload: { page = 1, limit = 10, selectedfilter, searchString }
}) {
  try {
    const search = searchString ? `&search=${searchString}` : ``;
    const filter = selectedfilter ? `${selectedfilter}` : ``;

    const requestRes = yield call(
      api.get,
      `/products?${filter}&page=${page}&limit=${limit}${search}`
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_products_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_products_error.type
    });
  }
}
function* getMealProductsSagas({ payload }) {
  const { search } = payload;
  try {
    const requestRes = yield call(api.get, `/products/search?search=${search}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_meal_products_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_meal_products_error.type
    });
  }
}

function* createProductSagas({ payload }) {
  try {
    const requestRes = yield call(api.post, '/products', payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: create_product_success.type,
      payload: response
    });
    toast.success('Product created successfully');
    window.location.href = `/products/${response._id}`;
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: create_product_error.type
    });
  }
}

function* productsSagas() {
  yield takeEvery(get_products.type, getProductsSagas);
  yield takeEvery(get_meal_products.type, getMealProductsSagas);
  yield takeEvery(create_product.type, createProductSagas);
  yield takeEvery(get_all_products.type, getAllProductsSagas);
}

export default productsSagas;
