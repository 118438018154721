import * as React from 'react';

const UploadImage = (props) => (
  <svg
    width={133}
    height={133}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={66.289} cy={66.131} r={65.936} fill="#F2F2F2" />
    <path
      d="m66.29 51.547 1.767-1.768-1.768-1.768-1.768 1.768 1.768 1.768Zm-2.5 25a2.5 2.5 0 1 0 5 0h-5Zm16.767-14.268-12.5-12.5-3.536 3.536 12.5 12.5 3.536-3.536ZM64.52 49.78l-12.5 12.5 3.536 3.536 12.5-12.5-3.536-3.536Zm-.732 1.768v25h5v-25h-5Z"
      fill="#549A9A"
    />
    <path
      d="M53.79 84.88h25"
      stroke="#549A9A"
      strokeWidth={5}
      strokeLinecap="round"
    />
  </svg>
);

export default UploadImage;
