import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const TrendsContainer = styled.div`
  width: 97.9%;
  background: red;
  height: 53%;
  margin-bottom: 60px;
  display: flex;
  margin-top: 25px;
  background: ${Colors.white};
  border: 1px solid #dfe0eb;
  flex-wrap: wrap;
  padding: 0px;
  /* box-sizing: border-box; */
  border-radius: 8px;
  ${media.smallDesktopMinimum`
         height: 45%;
         flex-wrap: wrap;
         /* background:red; */
    `}
  ${media.tablet`
         height: 90%;
		 width: 97%;
         flex-wrap: wrap;
    `}
	${media.mobile`
         height: 60%;
		 width: 100%;
		 margin-left:-12px;
         flex-wrap: wrap;
    `}
`;
export const RevenueBody = styled.div`
  width: calc(63% - 20px);
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0px 10px;
  border-right: 1px solid #dfe0eb;
  ${media.smallDesktopMinimum`
         width: 100%;
         
    `}
  ${media.tablet`
         width: 100%;
         height: 60%;
         
    `}
	${media.mobile`
         width: 100%;
         height: 20%;
         
    `}
`;
export const SubRevenueBody = styled.div`
  width: 34.8%;
  height: 50%;
  background: #fff;
  /* padding-left: 2%; */
  box-sizing: border-box;
  ${media.smallDesktopMinimum`
		width: 100%;
		 height: 50%;
    `}
  ${media.tablet`
         width: 100%;
		 height: 80%;
         
    `}
	${media.mobile`
         width: 100%;
		 height: 50%;
         
    `}
`;
