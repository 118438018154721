import React, { useState } from 'react';
import { SectionBody, SectionContainer } from '../style';
import { Div, FormsModal } from '@app/globalstyles/asgard';
import AddProductSize from './AddProductSize';
import CommaNumber from 'comma-number';
import { SimpleButton } from '@design-system/SimpleButton';
import {
  GridHeaderSection,
  ProductDetailsContainer
} from '@design-system/ProductGrid';
import { DetailsEditButton } from '@design-system/ProductGrid/DetailsEditButton';
import EditSizes from './EditSizes';

export const Sizes = ({ product }) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const handleShowProductForm = () => {
    setShowForm(!showForm);
  };
  const handleShowProductFormEdithSizes = () => {
    setShowEditForm(!showEditForm);
  };
  const { sizes } = product;
  return (
    <SectionContainer>
      <ProductDetailsContainer>
        <GridHeaderSection>
          <h3>Available Sizes</h3>
          <Div display="flex" alignI="center">
            <SimpleButton text={'Add New'} onClick={handleShowProductForm} />

            <DetailsEditButton ml="5px" onClick={handleShowProductFormEdithSizes} />
          </Div>
        </GridHeaderSection>

        <SectionBody>
          <ul
            style={{
              display: 'flex',
              gap: '30px',
              color: '#4C515A',
              marginLeft: '-20px'
            }}
          >
            {sizes?.map((size) => (
              <li key={size._id}>
                <div style={{ display: 'flex' }}>
                  <p>{size.value}kg Pack</p>
                  <p> - </p>
                  <p>
                    {size.price?.currency?.symbol}
                    {CommaNumber(size.price?.value)}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </SectionBody>
        {showForm && (
          <FormsModal show={showForm}>
            <AddProductSize product={product} closeform={handleShowProductForm} />
          </FormsModal>
        )}
        {showEditForm && (
          <FormsModal show={showEditForm}>
            <EditSizes
              product={product}
              closeform={handleShowProductFormEdithSizes}
            />
          </FormsModal>
        )}
      </ProductDetailsContainer>
    </SectionContainer>
  );
};

export default Sizes;
