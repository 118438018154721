import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false
  },
  data: []
};

export const currenciesReducerSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    get_currencies_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    get_currencies_error: (state) => {
      state.ui.loading = false;
    },
    get_currencies: (state) => {
      state.ui.loading = true;
    },
    create_currency_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data.unshift(payload);
    },
    create_currency_error: (state) => {
      state.ui.loading = false;
    },
    create_currency: (state) => {
      state.ui.loading = true;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_currencies,
  get_currencies_error,
  get_currencies_success,
  create_currency,
  create_currency_error,
  create_currency_success
} = currenciesReducerSlice.actions;

export default currenciesReducerSlice.reducer;
