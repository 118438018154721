import styled from 'styled-components';

export const InputLabel = styled.label`
  margin-top: 4%;
  color: #003232;
  font-size: 18px;

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1400px) {
    font-size: 20px;
  }
`;
