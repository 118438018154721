import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    stats: {
      pageCount: 1
    }
  },
  data: [],
  details: {}
};

export const ordersReducerSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    get_orders_success: (state, { payload }) => {
      state.ui.loading = false;
      const { orderHistory, stats } = payload;
      state.data = orderHistory;
      state.ui.stats = {
        ...stats
      };
    },
    get_orders_error: (state) => {
      state.ui.loading = false;
    },
    get_orders: (state) => {
      state.ui.loading = true;
    },
    get_one_order_success: (state, { payload }) => {
      state.ui.loading = false;
      state.details = payload;
    },
    get_one_order_error: (state) => {
      state.ui.loading = false;
    },
    get_one_order: (state) => {
      state.ui.loading = true;
    }
  }
});

export const {
  get_one_order,
  get_one_order_error,
  get_one_order_success,
  get_orders,
  get_orders_error,
  get_orders_success
} = ordersReducerSlice.actions;

export default ordersReducerSlice.reducer;
