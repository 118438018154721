import { put, takeEvery, takeLatest, call, select } from '@redux-saga/core/effects';
import {
  get_group,
  get_group_error,
  get_group_success,
  update_group,
  update_group_error,
  update_group_success,
  get_group_basket,
  get_group_basket_error,
  get_group_basket_success,
  get_group_members,
  get_group_members_error,
  get_group_members_success,
  mark_basket_product_as_paid,
  mark_basket_product_as_paid_error,
  mark_basket_product_as_paid_success,
  get_group_transactions,
  get_group_transactions_failure,
  get_group_transactions_success,
  update_tracking,
  update_tracking_success,
  update_tracking_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';
import { getGroup } from './selectors';

function* getGroupBasketSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/group_basket/group/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_group_basket_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_group_basket_error.type
    });
  }
}

function* getGroupSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/baskets/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_group_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_group_error.type
    });
  }
}

function* updateGroupSagas({ payload }) {
  try {
    const requestRes = yield call(api.put, `/baskets/${payload.id}`, payload.data);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: update_group_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: update_group_error.type
    });
  }
}

function* markProductAsPaidSagas({ payload }) {
  try {
    const requestRes = yield call(api.put, `/group_basket/${payload}/mark_paid`);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: mark_basket_product_as_paid_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: mark_basket_product_as_paid_error.type
    });
  }
}

function* getGroupMembersSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/group_members/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_group_members_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_group_members_error.type
    });
  }
}

function* getGroupTransactionsSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/payments/byGroup/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_group_transactions_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_group_transactions_failure.type
    });
  }
}

function* updateTrackingSaga({ payload }) {
  try {
    const group = yield select(getGroup);
    const requestRes = yield call(api.patch, `/baskets/${group._id}`, payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: update_tracking_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: update_tracking_error.type
    });
  }
}

function* groupSagas() {
  yield takeEvery(get_group.type, getGroupSagas);
  yield takeEvery(update_group.type, updateGroupSagas);
  yield takeEvery(get_group_basket.type, getGroupBasketSagas);
  yield takeEvery(get_group_members.type, getGroupMembersSagas);
  yield takeLatest(mark_basket_product_as_paid.type, markProductAsPaidSagas);
  yield takeLatest(get_group_transactions.type, getGroupTransactionsSagas);
  yield takeLatest(update_tracking.type, updateTrackingSaga);
}

export default groupSagas;
