import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const BTableBody = styled.table`
  width: 100%;
  border-collapse: collapse;
  width: 100%;

  thead {
    background: #f0f0f0;
  }

  .search_box {
    height: 30px;
    outline: none;
    border: 1px solid #f2f2f2;
    padding: 9px;

    &placeholder {
      color: #e0e0e0;
    }
  }

  td {
    text-align: left;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: normal;
    color: ${Colors.tableText};
    /* white-space: nowrap; */
  }
  th {
    padding: 20px 20px;
    text-align: left;
    text-transform: capitalize;
    color: #4c515a;
    font-weight: 500;
    font-size: 15px;
  }
  td {
    color: ${Colors.muted};
    font-size: 14px;
  }
  tr:last-child(even) {
    border-bottom: 1px solid #dfe0eb;
  }
  tr:hover() {
    background: #3751ff;
    opacity: 0.04;
    cursor: pointer;
  }

  .coupon_code {
    background: #f2f2f2;
    font-size: 12px;
    font-weight: bold;
    padding: 3px;
    width: 100%;
    text-align: center;
  }

  .tracking {
    border: 1px solid #549a9a;
    border-radius: 3px;
    width: 100%;
    text-align: center;
    padding: 6px;
    font-weight: 500;
    font-size: 12px;
    color: #549a9a;
  }
`;

export const BTextHeader = styled.th`
  text-align: left;
  padding: 8px;
  border-bottom: 2px solid red;
  td {
    text-align: left;
    padding: 8px;
    /* border-bottom:2px solid red;  */
    color: ${Colors.grey};
  }
`;

export const TableRow = styled.tr`
  &:hover {
    background: rgba(55, 81, 255, 0.04);
    cursor: pointer;
  }
`;

export const GroupsTableContent = styled.div`
  width: 100%;
  ${media.mobile`
  overflow-x: scroll;
  `}
`;
