import styled from 'styled-components';

export const Header = styled.div`
  border-bottom: 1px solid #f0f0f0;
  padding-top: 30px;
  padding-bottom: 31px;
  padding-left: 27px;
  font-weight: 600;
  font-size: 25px;
  color: #284242;
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 31px;
  padding-left: 27px;
  padding-right: 39px;

  .search_bar {
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    // padding-top: 12px;
    // padding-bottom: 12px;
    padding-left: 23px;
    width: 587px;
  }

  input {
    width: 578px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: none;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 18px;
  }

  input::placeholder {
    font-weight: 500;
    font-size: 18px;
    color: #bdbdbd;
  }

  button {
    border: none;
    outline: none;
    background: #549a9a;
    color: #fff;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    // margin-right: 27px;
  }
`;

export const FormBox = styled.div`
  background: #fff;
  width: 345px;
  border-radius: 10px;
  padding: 10px;

  .header {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .form_content: {
    display: flex;
    flex-direction: column;
  }

  .form_title {
    text-align: center;
    margin-bottom: 24px;
    font-sixe: 16px;
    font-weight: 700;
  }

  .title {
    margin-top: 8px;
  }

  input {
    width: 100%;
    height: 42px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  select {
    width: 100%;
    height: 42px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  button {
    border: none;
    outline: none;
    background: #549a9a;
    color: #fff;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    margin-top: 24px;
    // margin-right: 27px;
  }

  .image_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }

  .image_label {
    border: 1px dashed #bdbdbd;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
  }

  .image_preview {
    composes: imageLabel;
    border: none;

    img {
      width: 100%;
      height: 5rem;
      object-fit: cover;
      border-radius: 10px;
    }
  }
`;

export const TableBox = styled.div`
  margin-top: 5px;
  margin-left: 27px;
  margin-right: 39px;

  table {
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
  }

  .table_head {
    background: #f2f2f2;
  }

  .table_head th {
    font-weight: 600;
    font-size: 16px;
    color: #4c515a;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 19px;
    text-align: left;
  }

  .table_body {
    border-bottom: 1px solid #f2f2f2;
  }

  .table_body td {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 19px;
  }

  button {
    border: 1px solid #549a9a;
    outline: none;
    background: #fff;
    color: #549a9a;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
  }

  .delete_btn {
    border: none;
    outline: none;
    background: red;
    color: #fff;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 31px;
    padding-left: 32px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
  }
`;
