import { useEffect } from 'react';
import { AsgardP, Button, Div, Img } from '@app/globalstyles/asgard';
import { BTableBody, FBContent, TableRow } from './style';
import elipse from '@assets/icons/vert_elipses.svg';
import { useDispatch, useSelector } from 'react-redux';
import StatusBadge from '@design-system/StatusBadge';
import { get_staff } from '../redux/reducer';
import Link from '@design-system/Link';
import { CUSTOMERS } from '@app/pages/ROUTECONSTS';
import { getLoading } from '../redux/selectors';
import LoadingDataUi from '@app/components/loading';
import EmptyDataUi from '@app/components/emptyData';

const CustomerTable = ({ staff }) => {
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_staff());
  }, [dispatch]);
  return (
    <div>
      <FBContent>
        {loading ? (
          <LoadingDataUi />
        ) : (
          <BTableBody>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Location</th>
              <th>Role</th>
              <th></th>
            </tr>
            {staff.map((staff, i) => (
              <TableRow key={staff._id}>
                <td colSpan="1">
                  <Div display="flex">
                    <Link to={`${CUSTOMERS}/${staff.user._id}`}>
                      <AsgardP ml="10px">
                        {staff.user.first_name} {staff.user.last_name}
                      </AsgardP>
                    </Link>
                  </Div>
                </td>
                <td>{staff.user.email}</td>
                <td>
                  {staff.city}, {staff.country}
                </td>
                <td>
                  <StatusBadge bg="success">{staff.role}</StatusBadge>
                </td>
                <td>
                  <Div display="flex">
                    <Link to={`${CUSTOMERS}/${staff.user._id}`}>
                      <Button
                        h="20px"
                        ml="10px"
                        p="2px 5px"
                        bc="transparent"
                        br="3px"
                      >
                        <Img src={elipse} alt="indicator" />
                      </Button>
                    </Link>
                  </Div>
                </td>
              </TableRow>
            ))}
          </BTableBody>
        )}
      </FBContent>
      {!loading && staff.length <= 0 && <EmptyDataUi />}
    </div>
  );
};

export default CustomerTable;
