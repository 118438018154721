import React, { useEffect } from 'react';
import {
  ItemContainer,
  SectionBody,
  SectionContainer,
  SectionHeader,
  ProductContainer
} from './style';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get_one_order } from '../redux/reducer';
import { getSingleOrder, getLoading } from '../redux/selectors';
import { Text } from '@design-system/Text';
import moment from 'moment';
import Link from '@design-system/Link';
import { AsgardP } from '@app/globalstyles/asgard';

const OrderPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const order = useSelector(getSingleOrder);
  const params = useParams();

  useEffect(() => {
    dispatch(get_one_order(params.id));
  }, [dispatch, params]);

  return (
    <div style={{ paddingLeft: 30, marginRight: 30 }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <SectionContainer>
          <Link to="/international-orders">
            <AsgardP>Back</AsgardP>
          </Link>
          <SectionHeader>
            <h2>Order Details</h2>
          </SectionHeader>

          <SectionBody>
            <ItemContainer>
              <p>Created at</p>
              <Text size="small" align="left">
                {moment(order.created_at).format('LL')}
              </Text>
            </ItemContainer>

            <ItemContainer>
              <p>Date Paid</p>
              <Text size="small" align="left">
                {moment(order.date_paid).format('LL')}
              </Text>
            </ItemContainer>

            <ItemContainer>
              <p>Price</p>
              <Text size="small" align="left">
                {order?.amount_paid?.currency?.symbol} {order?.amount_paid?.value}
              </Text>
            </ItemContainer>
          </SectionBody>

          <h2>Products</h2>
          <ProductContainer>
            {order?.products?.map((product, i) => (
              <div key={(product, i)} className="card">
                <img src={product?.product_data?.product?.image} alt="basket" />
                <Text size="small" align="left">
                  {product?.product_data?.product?.name}
                </Text>
                <Text size="small" align="left">
                  {product?.price.currency?.symbol} {product?.price?.value}
                </Text>
                <div className="footer">
                  <h5>quantity: {product?.quantity}</h5>
                  <h5>weight: {product?.product_data?.weight}</h5>
                </div>
              </div>
            ))}
          </ProductContainer>
        </SectionContainer>
      )}
    </div>
  );
};

export default OrderPage;
