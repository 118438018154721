import styled from 'styled-components';

export const Base = styled.div`
  color: #000;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  display: grid;
  top: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

export const Sidemodal = styled.div`
  color: #000;
  position: fixed;
  z-index: 500;
  width: 30%;
  height: 100vh;
  background: #fff;
  display: grid;
  box-shadow: 2px 0px 4px rgba(158, 158, 158, 0.4);
  top: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .box {
    padding: 20px;
  }
`;
