import React, { useState, useEffect } from 'react';
import { Header } from '../partner/style';
import { useSelector, useDispatch } from 'react-redux';
import SearchContent from '../partner/components/searchContent';
import Producttable from '../partner/table/Producttable';
import { getProducts, getLoading, getPageStats } from '../products/redux/selectors';
import { get_products } from '../products/redux/reducer';
import Pagination from '@app/components/pagination';
import EmptyDataUi from '@app/components/emptyData';

const PartnerProducts = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const [products, setProducts] = useState([]);
  const product = useSelector(getProducts);
  const [filter, setFilter] = useState('');
  const [searchString, setSearchString] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageStats = useSelector(getPageStats);
  const [active, setActive] = useState('All');
  const [drop, setDrop] = useState(false);

  useEffect(() => {
    setProducts(product);
  }, [product, filter]);

  const handleChange = (e) => {
    let search = e.target.value.toLowerCase();
    setSearchString(search);
  };

  const handleFilter = (data) => {
    setActive(data.title);
    setFilter(data.value);
    onClose();
  };

  const onClose = () => {
    setDrop(!drop);
  };

  const getNewProducts = (page, limit) => {
    dispatch(get_products({ page, limit, selectedfilter: filter }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewProducts(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewProducts(1, pageSize);
  };

  useEffect(() => {
    dispatch(
      get_products({
        limit: pageSize,
        page: currentPage,
        selectedfilter: filter,
        searchString
      })
    );
  }, [dispatch, filter, currentPage, pageSize, searchString]);

  return (
    <div>
      <Header>
        <p className="header_text">Partners Products</p>
      </Header>
      <SearchContent
        handleChange={handleChange}
        active={active}
        showFilter={false}
        handleFilter={handleFilter}
        drop={drop}
        onClose={onClose}
        showApproval={true}
      />
      {!loading && products.length <= 0 ? (
        <EmptyDataUi />
      ) : (
        <Producttable loading={loading} partners={products} />
      )}

      {!loading && (
        <Pagination
          onChangePageSize={handlePageSizeChange}
          itemsCount={pageStats.docs}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default PartnerProducts;
