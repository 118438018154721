import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LOGIN } from '@app/routes';
import { useSelector } from 'react-redux';
import { getUser } from '@app/auth/redux/selectors';

export default function ProtectedRoute({ path, component }) {
  const user = useSelector(getUser);
  const token = localStorage.getItem('x-access-token');
  if (Object.keys(user) && token) {
    return <Route path={path} component={component} />;
  } else {
    localStorage.removeItem('x-access-token');
    return <Redirect to={LOGIN} />;
  }
}
