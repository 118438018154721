import React from 'react';
import styled from 'styled-components';

const Stepper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  .outer {
    width: 100%;
    height: 10px;
    background: #d4e7e7;
    border-radius: 5px;
  }

  .inner {
    width: 50%;
    height: 10px;
    background: #549a9a;
    border-radius: 5px;
  }

  .completed {
    width: 100%;
    height: 10px;
    background: #549a9a;
    border-radius: 5px;
  }
`;

const Steps = ({ step }) => {
  return (
    <Stepper>
      <div className="outer">
        {step === 1 ? <div className="completed" /> : <div className="inner" />}
      </div>
    </Stepper>
  );
};

export default Steps;
