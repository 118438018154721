import React from 'react';
import LoadingDataUi from '@app/components/loading';
import { Link } from 'react-router-dom';
import { BTableBody } from '@app/pages/products/table/style';
import { Badge } from '@design-system/Badge/Badge';
const Table = ({ loading, partners }) => {
  return (
    <div>
      {loading ? (
        <LoadingDataUi />
      ) : (
        <BTableBody>
          <table style={{ width: '100%' }}>
            <thead className="table_head">
              <tr>
                <th>Partner Name</th>
                <th>Business Name</th>
                <th>Products</th>
                <th>Phone Number</th>
                <th>State</th>
                <th>Type</th>
                <th>Verification Status</th>
              </tr>
            </thead>
            <tbody className="table_body">
              {partners.map((data) => (
                <tr key={data._id}>
                  <td>
                    <Link
                      to={`/partners/${data._id}`}
                      style={{
                        textDecoration: 'none',
                        color: '#549a9a',
                        fontWeight: '500'
                      }}
                    >
                      {`${data.user.first_name} ${data.user.last_name}`}
                    </Link>
                  </td>

                  <td>
                    <Link
                      to={`/partners/products/${data._id}`}
                      style={{
                        textDecoration: 'none',
                        color: '#549a9a',
                        fontWeight: '500'
                      }}
                    >
                      {`${data.business_name}`}
                    </Link>
                  </td>
                  <td>{data.products}</td>
                  <td>{data.phone}</td>
                  <td>{data.state}</td>
                  <td style={{ textTransform: 'capitalize' }}>{data.type}</td>
                  <td>
                    {data?.account_verified ? (
                      <Badge bg="success">Verified</Badge>
                    ) : (
                      <Badge bg="danger">Not Verified</Badge>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </BTableBody>
      )}
    </div>
  );
};

export default Table;
