import { createSelector } from '@reduxjs/toolkit';

const recipeData = (state = {}) => state.recipe;

export const getRecipes = createSelector(
  recipeData,
  (recipeData) => recipeData.data
);

export const getPageStats = createSelector(
  recipeData,
  (recipeData) => recipeData.ui.stats
);

export const getLoading = createSelector(
  recipeData,
  (recipeData) => recipeData.ui.loading
);

export const getProductGroup = createSelector(
  recipeData,
  (recipeData) => recipeData.product_group
);

export const getProductLoading = createSelector(
  recipeData,
  (recipeData) => recipeData.ui.product_loading
);

export const getProductImages = createSelector(
  recipeData,
  (recipeData) => recipeData.product_images
);

export const getUploadingRecipes = createSelector(
  recipeData,
  (recipeData) => recipeData.create_recipe_data
);

export const getStep = createSelector(recipeData, (recipeData) => recipeData.step);
export const getMode = createSelector(
  recipeData,
  (recipeData) => recipeData.editMode
);
export const getId = createSelector(recipeData, (recipeData) => recipeData.recipeId);
export const getImageIndex = createSelector(
  recipeData,
  (recipeData) => recipeData.imageIndex
);
export const getEditSuccess = createSelector(
  recipeData,
  (recipeData) => recipeData.edit_success
);
