import React from 'react';

const CloseIcon = (props) => {
  return (
    <div>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.9141 6.77734L6.91406 18.7773"
          stroke="#BDBDBD"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.91406 6.77734L18.9141 18.7773"
          stroke="#BDBDBD"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default CloseIcon;
