import { createSelector } from '@reduxjs/toolkit';

const driveData = (state = {}) => state.drive;

export const getImages = createSelector(driveData, (driveData) => driveData.data);

export const getLoading = createSelector(
  driveData,
  (driveData) => driveData.ui.loading
);

export const getPageStats = createSelector(
  driveData,
  (driveData) => driveData.ui.stats
);

export const getCreateLoading = createSelector(
  driveData,
  (driveData) => driveData.ui.createLoading
);
