import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    verifyingAccount: false
  },
  data: [],
  address: []
};

export const customerReducerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    get_customer_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    get_customer_error: (state) => {
      state.ui.loading = false;
    },

    get_customer: (state) => {
      state.ui.loading = true;
    },

    mark_customer_as_verified: (state) => {
      state.ui.verifyingAccount = true;
    },
    mark_customer_as_verified_success: (state, { payload }) => {
      state.ui.verifyingAccount = false;
      state.data = payload;
    },
    mark_customer_as_verified_error: (state) => {
      state.ui.verifyingAccount = false;
    },
    get_customer_address: (state) => {
      state.ui.loading = true;
    },
    get_customer_address_success: (state, { payload }) => {
      state.ui.loading = false;
      state.address = payload;
    },
    get_customer_address_error: (state) => {
      state.ui.loading = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_customer,
  get_customer_error,
  get_customer_success,
  mark_customer_as_verified,
  mark_customer_as_verified_error,
  mark_customer_as_verified_success,
  get_customer_address,
  get_customer_address_success,
  get_customer_address_error
} = customerReducerSlice.actions;

export default customerReducerSlice.reducer;
