import React, { useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import { FormBox } from '../style';
import { CustomInput } from '@app/components/forms/InputGen';
import * as Yup from 'yup';
import ErrorMessage from '@app/components/forms/errorMessage';
import { FileUploadToCloud } from '@app/services/fileUploadService';
import { create_product_category } from '@app/pages/product-categories/redux/reducer';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { getCategoryLoading } from '@app/pages/product-categories/redux/selectors';

const AddCategory = ({ StopBubbling, onClose }) => {
  const [productImage, setProductImage] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector(getCategoryLoading);
  let initialValues = {
    name: '',
    description: ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required().label('Name'),
    description: Yup.string().min(3).required().label('Description')
  });

  const handleSubmit = async (doc) => {
    if (productImage.length <= 0)
      return toast.error('Please pick a product category Image');
    const response = await FileUploadToCloud(productImage);
    let data = {
      ...doc,
      image: response.secure_url
    };
    dispatch(create_product_category(data));
    setTimeout(() => {
      onClose();
    }, 500);
  };
  const imgRef = useRef();
  const handleOpenImage = () => {
    imgRef.current.click();
  };

  const handlePickImage = (e) => {
    setProductImage(e.target.files[0]);
  };
  return (
    <div>
      <FormBox onClick={StopBubbling}>
        <div className="form_title">Add Category</div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            handleChange,
            handleSubmit,
            errors,
            setFieldTouched,
            values: formikValues,
            touched
          }) => (
            <Form>
              <div className="form_content">
                <label className="title">Name</label>
                <CustomInput
                  type="text"
                  error={touched.name && errors.name}
                  name="name"
                  placeholder="name"
                  onBlur={() => setFieldTouched('name')}
                  onChange={handleChange('name')}
                />
                <ErrorMessage error={errors.name} visbile={touched.name} />

                <label className="title">Description</label>
                <CustomInput
                  type="text"
                  error={touched.description && errors.description}
                  name="description"
                  placeholder="Description"
                  onBlur={() => setFieldTouched('description')}
                  onChange={handleChange('description')}
                />
                <ErrorMessage
                  error={errors.description}
                  visbile={touched.description}
                />
                <div className="image_container">
                  {productImage?.name ? (
                    <div className="image_preview">
                      <img src={URL.createObjectURL(productImage)} alt="selected" />
                    </div>
                  ) : (
                    <div className="image_label" onClick={handleOpenImage}>
                      Upload Image
                    </div>
                  )}
                </div>

                <input
                  style={{ display: 'none' }}
                  type="file"
                  accept="image/*"
                  ref={imgRef}
                  onChange={(e) => handlePickImage(e)}
                />
                <div>
                  <button type="submit" disabled={loading}>
                    {loading ? 'Loading' : 'Submit'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </FormBox>
    </div>
  );
};

export default AddCategory;
