import React from 'react';
import { Formik, Form } from 'formik';
import styles from './styles.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { add_payment_term } from '../redux/reducer';
import { getCreating } from '../redux/selector';

const PaymentTerm = ({ id, onClose }) => {
  const loading = useSelector(getCreating);
  const dispatch = useDispatch();
  let data = {
    pre_payment: '',
    note: ''
  };

  const handleSubmit = (docs) => {
    let data = {
      partner: id,
      payment_terms: {
        ...docs
      }
    };
    dispatch(add_payment_term({ data, close: onClose }));
  };
  return (
    <div>
      <Formik initialValues={data} onSubmit={handleSubmit}>
        {({ values, handleChange }) => (
          <Form>
            <div className={styles.label}>Pre Payment (between 1 and 100)</div>
            <input
              type="number"
              className={styles.payment_input}
              min="1"
              name="pre_payment"
              max="100"
              placeholder="1 or 100"
              value={values.pre_payment}
              onChange={handleChange}
            />

            <div className={styles.label}>Note</div>
            <textarea
              rows="5"
              name="note"
              value={values.note}
              onChange={handleChange}
              placeholder="Please leave a note..."
              style={{
                width: '100%',
                boxSizing: 'border-box',
                borderRadius: '5px',
                border: '0.5px solid #d9d8da',
                padding: '5px',
                fontSize: '16px'
              }}
            />

            <button
              type="submit"
              className={styles.comment_button}
              disabled={
                values?.pre_payment === '' && values.pre_payment === ''
                  ? true
                  : false
              }
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentTerm;
