import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  BigMenuItemsContainer,
  InnerContainerMenu,
  MenuContainer,
  Content,
  MenuText,
  MenuToggleContainer,
  UserInfoMenuContainer
} from './style';
import BigMenu from './bigMenu';
import { AsgardNavLink, Div, Img } from '@app/globalstyles/asgard';
import akaaniLogo from '@assets/icons/logo.svg';
import logout from '@assets/icons/logout.svg';
import { menuData } from './menuData';
import LogoutMenu from './logout';
import { AccordionContainer, AccordionContent } from '@app/components/accordion';

export default function Menu() {
  const history = useHistory();

  const [navStatus, setNavStatus] = useState(true);
  const [active, setActive] = useState('');

  const handleClick = (name) => {
    setActive(name === active ? null : name);
  };

  const handleOpenBigSideNav = (event) => {
    setNavStatus(true);
  };

  return (
    <Div p="10px 15px">
      <MenuToggleContainer
        onClick={handleOpenBigSideNav}
        open={navStatus}
      ></MenuToggleContainer>
      <MenuContainer open={navStatus}>
        <InnerContainerMenu>
          <Div mb="29px" mt="10px">
            <Img src={akaaniLogo} alt="Akaani" />
          </Div>
          <UserInfoMenuContainer></UserInfoMenuContainer>
          <BigMenuItemsContainer>
            {menuData?.map((menu, i) => {
              let isActive = active === menu.text;
              return (
                <div>
                  {menu.link ? (
                    <BigMenu
                      link={menu.link}
                      text={menu.text}
                      key={i}
                      icon={
                        history?.location?.pathname.startsWith(menu.link)
                          ? menu.activeIcon
                          : menu.icon
                      }
                    />
                  ) : (
                    <AccordionContainer>
                      <AccordionContent
                        onClick={() => handleClick(menu.text)}
                        itemName={menu.text}
                        content={menu}
                        itemContent={
                          <>
                            {menu.content.map((item) => (
                              <AsgardNavLink to={item.link}>
                                <Content>
                                  <Div display="flex" justify="space-between">
                                    <MenuText>{item.name}</MenuText>
                                  </Div>
                                </Content>
                              </AsgardNavLink>
                            ))}
                          </>
                        }
                        isActive={isActive}
                      />
                    </AccordionContainer>
                  )}
                </div>
              );
            })}
          </BigMenuItemsContainer>
        </InnerContainerMenu>
        <LogoutMenu
          textColor="rgba(77, 77, 77, 0.7)"
          link={'/logout'}
          text="Logout"
          icon={history?.location?.pathname === '/logout' ? logout : logout}
        />
      </MenuContainer>
    </Div>
  );
}
