import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: #252733;
    font-size: 19px;
    font-weight: 700;
  }

  .search_box {
    background: #f2f2f2;
    border-radius: 12px;
    width: 354px;
    display: flex;
    padding: 14px;
    gap: 16px;

    input {
      background: transparent;
      border: none;
      outline: none;
      width: 100%;
    }
  }

  button {
    background: #549a9a;
    border: none;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
  }
`;

export const FormComponent = styled.div`
  background: #fff;
  width: 589px;
  padding: 32px 40px;

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: #222222;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
  }

  .form_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  label {
    color: #828282;
    font-size: 16px;
    font-weight: 500;
  }

  input {
    border: 1px solid #eeeeee;
    padding: 14px 16px;
    border-radius: 8px;
    &:focus {
      border: 1px solid #549a9a;
    }
  }

  textarea {
    border: 1px solid #eeeeee;
    padding: 14px 16px;
    border-radius: 8px;
  }

  .image {
    display: none;
  }

  .image_box {
    border: 1px dashed #eeeeee;
    height: 200px;
    display: grid;
    place-items: center;
    border-radius: 20px;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }

  button {
    margin-top: 24px;
    width: 100%;
    background: #549a9a;
    border: none;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  ${'' /* overflow-x: auto; */}
  margin-top: 40px;

  table {
    width: 100%;
    border-collapse: collapse;

    tr:hover() {
      background: #3751ff;
      opacity: 0.04;
      cursor: pointer;
    }
  }

  th {
    padding-bottom: 12px;
    font-size: 14px;
    color: #9fa2b4;
    font-weight: 700;
    border-bottom: 1.5px solid #dfe0eb;
    text-align: left;
    padding-left: 20px;
  }

  .narrow_column {
    width: 372px;
  }

  img {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    object-fit: cover;
  }

  tr {
    &:hover {
      background: rgba(55, 81, 255, 0.04);
      cursor: pointer;
    }
  }

  td {
    border-bottom: 1px solid #dfe0eb;
    padding-top: 26px;
    padding-bottom: 26px;
    white-space: normal;
    text-align: left;
    font-size: 14px;
    color: #252733;
    font-weight: 600;
    padding-left: 20px;
  }
`;
