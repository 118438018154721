import styled from 'styled-components/macro';

export const TableFeaturesContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  justify-content: space-between;
  padding: 5px 20px;
`;

export const PageTitleHeading = styled.h4`
  color: #284242;
  font-size: 1.5em;
  font-weight: 900;
`;
