import React from 'react';
import { EditModalBody, FormBody } from '../style';
import ReviewRecipe from '@app/pages/recipes/createRecipe/ReviewRecipe';

const EditRecipe = ({ recipes, updateRecipe }) => {
  return (
    <EditModalBody>
      <div className="header">Edit Recipe</div>
      <FormBody>
        <ReviewRecipe />
      </FormBody>
    </EditModalBody>
  );
};

export default EditRecipe;
