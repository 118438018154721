import React from 'react';
import commaNumber from 'comma-number';
import { Card } from '../style';

const OrderCard = ({ order, currency, id }) => {
  return (
    <Card>
      <div className="order_box" key={order.id}>
        <img src={order.product.image} className="image" alt="order" />
        <div className="order_box_container">
          <div>
            <div className="name">{order?.product?.name}</div>
          </div>
          <div>
            <div className="price">
              {currency}
              {commaNumber(order?.price?.value)}
            </div>
            <div className="quantity">Qty:{order?.product?.quantity}</div>
            <div className="quantity">
              Size: {order?.size} {order?.product?.unit}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OrderCard;
