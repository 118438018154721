import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_product_categories,
  get_product_categories_error,
  get_product_categories_success,
  create_product_category,
  create_product_category_error,
  create_product_category_success,
  edit_product_category,
  edit_product_category_success,
  edit_product_category_error
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getProductCategoriesSagas({ payload }) {
  try {
    const { page = 1, limit = 100, searchString } = payload;
    let search = ``;
    if (searchString) {
      search = `&search=${searchString}`;
    } else {
      search = ``;
    }
    const requestRes = yield call(
      api.get,
      `/product_categories?page=${page}&limit=${limit}${search}`
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_product_categories_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error.response.data.message);
    yield put({
      type: get_product_categories_error.type
    });
  }
}

function* createProductCategorySagas({ payload }) {
  try {
    const requestRes = yield call(api.post, '/product_categories', payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: create_product_category_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: create_product_category_error.type
    });
  }
}

function* updateCategorySagas({ payload }) {
  try {
    const { id, data, close } = payload;
    const requestRes = yield call(api.put, `/product_categories/${id}`, data);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: edit_product_category_success.type,
      payload: response
    });
    close();
    // setTimeout(() => {
    //   window.location.href = '/product-categories';
    // }, 1000);
  } catch (error) {
    toast.error(error.response);
    yield put({
      type: edit_product_category_error.type
    });
  }
}

function* productCategoriesSagas() {
  yield takeEvery(get_product_categories.type, getProductCategoriesSagas);
  yield takeEvery(create_product_category.type, createProductCategorySagas);
  yield takeEvery(edit_product_category.type, updateCategorySagas);
}

export default productCategoriesSagas;
