import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const CouponFormMain = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  ${media.tablet`
  flex-direction: column;
 
  `}
  ${media.mobile`
  flex-direction: column;
  `}
`;

export const ActionBtnDiv = styled.div`
  width: 95%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const CheckboxDiv = styled.div`
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    background: red;
    border: 1px solid '#F45';
  }
  input::checked {
    background-color: #f90;
    border: 5px solid '#F45';
  }
`;
