import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const DashboardContainer = styled.div`
  background-color: white;
  height: 100%;
  left: 0;
  right: 0;
  position: fixed;
  overflow-y: scroll;
  /* overflow-x: hidden; */
  display: flex;
  flex-wrap: wrap;
  ${media.tablet`
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    `}

  ${media.mobile`
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    `}
`;

export const DashboardBody = styled.div`
  margin-left: 238px;
  width: calc(100% - 238px);
  display: flex;
  flex-direction: column;

  border-radius: 8px;
  padding-top: 0;
  margin-top: 0;
  min-height: calc(100vh - 50px);

  ${media.smallDesktopMinimum`
        width: 100%;
        padding: 10px;
        margin-top: 50px;
        margin-left: 0;
        margin-right:0;
    `}
  ${media.tablet`
        width: 100%;
        padding: 10px;
        margin-top: 50px;
        margin-left: 0;
        margin-right:0;
    `}
	${media.mobile`
        width: 100%;
        overflow-y: scroll;
    `} 
	${media.smallMobile`
        width: 100%;
    `}
`;
