import React from 'react';
import { SearchBox } from '@app/pages/recipes/style';

const Search = ({ handleFilter }) => {
  return (
    <SearchBox>
      <div className="search_bar">
        <input type="text" placeholder="Search" onChange={handleFilter} />
      </div>
    </SearchBox>
  );
};

export default Search;
