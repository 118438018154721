import React, { useState } from 'react';
import RevenueStats from './revenuestats';
import RevenueAnalytics from './revenuestats/analytics';
import { RevenueBody, SubRevenueBody, TrendsContainer } from './style';

function TrendsDashboard() {
  const [currency, setCurrency] = useState('NGN');

  return (
    <TrendsContainer>
      <RevenueBody>
        <RevenueStats
          currency={currency}
          onUSD={() => setCurrency('USD')}
          onNGN={() => setCurrency('NGN')}
        />
      </RevenueBody>
      <SubRevenueBody>
        <RevenueAnalytics currency={currency} />
      </SubRevenueBody>
    </TrendsContainer>
  );
}

export default TrendsDashboard;
