import React from 'react';
import styled from 'styled-components/macro';

const DividerLine = styled.div`
  background: ${({ bg }) => (bg ? bg : '#dfe0eb')};
  width: 100%;
  height: 1px;
  margin-top: ${({ mt }) => (mt ? mt : null)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
`;

function Divider({ bg, mb, mt }) {
  return <DividerLine bg={bg} mb={mb} mt={mt} />;
}

export default Divider;
