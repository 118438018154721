import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const BTableBody = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #f0f0f0;

  width: 100%;

  ${media.mobile`
  /* width: 150vw; */
  td {
    white-space: nowrap;
  }
  `}
  thead {
    border-bottom: 1px solid #dfe0eb;
  }
  td {
    text-align: left;
    padding: 15px 0px;
    font-size: 14px;
    /* font-weight: 600; */
    color: ${Colors.black};
    border-bottom: 1px solid #dfe0eb;
    flex: 0 0 400px;
    /* white-space: nowrap; */
  }
  th {
    padding: 20px 10px;
    text-align: left;
    text-transform: capitalize;
    color: #656565;
    font-weight: bold;
    font-size: 14px;
  }
  td {
    padding: 10px 10px;
    color: ${Colors.black};
    font-size: 14px;
    /* white-space: nowrap; */
  }
  tr:last-child(even) {
    border-bottom: 1px solid #f0f0f0;
  }
  tr:hover {
    background: rgba(55, 81, 255, 0.04);
    cursor: pointer;
  }
`;

export const BTextHeader = styled.th`
  text-align: left;
  padding: 8px;
  border-bottom: 21px solid #f0f0f0;
  td {
    text-align: left;
    padding: 8px;
    white-space: nowrap;
    /* border-bottom:2px solid red;  */
    color: ${Colors.black};
  }
`;
export const TableRow = styled.tr`
  &:hover {
    background: rgba(55, 81, 255, 0.04);
    cursor: pointer;
  }
`;
export const BudgetTableContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
export const ProductsTableContent = styled.div`
  width: 100%;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.05),
    0px 1px 3px rgba(63, 63, 68, 0.15);
  ${media.mobile`
  overflow-x: scroll;
  `}
`;
