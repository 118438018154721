import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { FormBody } from '../style';
import { getCountries, getCountry } from 'country-state-picker';
import SelectInputP from '@app/pages/products/addproduct/select';

const PersonalInfo = ({ data, next }) => {
  const countries = getCountries();
  const [country, setCountry] = useState('');
  const [code, setCode] = useState('');

  const handleCountry = (e) => {
    const tempCountry = getCountry(e.target.value);
    setCountry(tempCountry);
    setCode(e.target.value);
  };

  const handleSubmit = (values) => {
    next({ ...values, country: country.name, code });
  };

  const type = [
    {
      id: 1,
      name: 'Shopper',
      value: 'shopper'
    },
    {
      id: 3,
      name: 'Shop',
      value: 'shop'
    },
    {
      id: 4,
      name: 'Cook',
      value: 'cook'
    }
  ];

  return (
    <FormBody>
      <Formik initialValues={data} onSubmit={handleSubmit}>
        {({ values, handleChange, errors, touched, handleBlur, setFieldValue }) => (
          <Form>
            <div className="grid">
              <div className="form_content">
                <label>First Name</label>
                <input
                  placeholder="John"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_content">
                <label>Last Name</label>
                <input
                  placeholder="Doe"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="grid">
              <div className="form_content">
                <label>Email Address </label>
                <input
                  placeholder="JohnDoe@gmail.com"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_content">
                <label>Country </label>
                <select
                  value={country?.name}
                  name="country"
                  onChange={(e) => {
                    setFieldValue('country', e.target.value);
                    handleCountry(e);
                  }}
                >
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="grid">
              <div className="form_content">
                <label>Phone Number</label>
                <input
                  placeholder=""
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_content">
                <label>Type</label>
                <SelectInputP onChange={handleChange} name="type">
                  <option selected disabled>
                    Select Type
                  </option>
                  {type.map((type) => (
                    <option key={type.id} value={type.value}>
                      {type.name}
                    </option>
                  ))}
                </SelectInputP>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button type="submit">Continue</button>
            </div>
          </Form>
        )}
      </Formik>
    </FormBody>
  );
};

export default PersonalInfo;
