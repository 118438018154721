import { Link } from 'react-router-dom';
import styled from 'styled-components';

const AsgardLink = styled(Link)`
  text-decoration: none;
  color: ${({ color }) => (color ? color : '#549a9a')};
  font-weight: 900;
  font-size: 1em;
  margin-top: 3%;
`;

export default AsgardLink;
