import React from 'react';
import { ModalBody, ModalHeader, BackDrop, ModalContainer } from './style';

const Modal = ({ children, ...props }) => {
  return (
    <BackDrop>
      <ModalBody width={props.width}>
        <ModalHeader>
          <div className="title">{props.title}</div>
          <div className="cancel" onClick={props.onClose}>
            X
          </div>
        </ModalHeader>
        <ModalContainer>{children}</ModalContainer>
      </ModalBody>
    </BackDrop>
  );
};

export default Modal;
