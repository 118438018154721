import styled from 'styled-components';

export const Container = styled.div`
  display: grid;

  @media screen and (max-width: 992px) {
    display: block;
    width: 100%;
  }
`;

export const SignupContainer = styled(Container)`
  grid-template-columns: 40% 60%;
`;

export const LoginContainer = styled(Container)`
  grid-template-columns: 40% 60%;
  width: 53%;
  margin: auto;

  @media screen and (min-width: 993px) and (max-width: 1024px) {
    width: 80%;
    margin: auto;
  }

  @media screen and (max-width: 992px) {
    display: block;
    width: 90%;
    margin: auto;
  }
`;

export const GroupDetailsContainer = styled(Container)`
  grid-template-columns: 70% 30%;
`;
