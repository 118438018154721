import Colors from '@app/globalstyles/utils/colors';
import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const ProductsContainer = styled.div`
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  margin-bottom: 30px;
  ${media.mobile`
  width: 99.8%;
   margin-left: -10px;
   margin-bottom: 0px;
  `}
`;

export const FilterDiv = styled.div`
  width: calc(100% - 50px);
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0px 20px;
  padding-top: 10px;

  .save_btn {
    margin-top: -20px;
    height: 34px;
    cursor: pointer;
    width: 142px;
    border: none;
    outline: none;
    background: #549a9a;
    border-radius: 5px;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
  }
`;

export const SortFilterSelect = styled.div`
  position: relative;
  width: 142px;
  height: 34px;
  background: #ffffff;
  border: 1px solid rgba(185, 185, 185, 0.15);
  box-sizing: border-box;

  margin-top: 0px;
  margin-bottom: 30px;
  display: flex;
  color: ${Colors.black};
  /* box-sizing: border-box; */
  border-radius: 5px;
  ${media.tablet`
   width:100%;
  `}
  ${media.mobile`
   width:100%;
   margin-left: 0px;
  `}

  select {
    font-size: 14px;
    height: 100%;
    width: 100%;
    padding: 0em 1em 0em 1.5em;
    background: transparent;
    color: rgba(0, 0, 0, 0.53);
    border: none;
    outline: none;
    border-radius: 5px;
    appearance: none;
  }
  div {
    position: absolute;
    right: 2%;
    color: ${Colors.white};
    background: rgba(255, 255, 255, 0.85);

    height: 100%;
    width: 2em;
    pointer-events: none;
    display: flex;
    align-items: center;
  }
`;

export const FilterOption = styled.option`
  padding: 5px 0px;
  color: ${({ active }) => (active ? '#284242;' : Colors.black)};
  outline: none;
  background: ${({ active }) => (active ? '#B8D5D5' : 'transparent')};
  border: none;
  border-radius: 5px;
  :hover {
    background: #b8d5d5;
  }
`;

export const FilterSelectDiv = styled.div`
  display: flex;
  gap: 1rem;
`;
export const ProductsMainDiv = styled.div`
  width: 100%;
  min-height: 90vh;
`;

export const FormContainerProducts = styled.div`
  width: 50%;
  min-height: calc(80% - 30px);
  padding: 15px 5px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  position: relative;
`;

export const SwitchProductFormTab = styled.div`
  width: 60%;
  margin: 0 auto;
  padding: 0px 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e5e5e5;
  border-radius: 7px;

  .cancel {
    display: flex;
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
`;
export const ToggleFormBtnProduct = styled.button`
  width: 49%;
  height: 80%;
  outline: none;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  border: none;
  background: ${({ active }) => (active ? '#ffffff' : 'transparent')};
  border-radius: 7px;
  cursor: pointer;
`;
