import { AsgardP } from '@app/globalstyles/asgard';
import { BTableBody, GroupsTableContent, TableRow } from './style';
import { useEffect } from 'react';
import CommaNumber from 'comma-number';
import { useDispatch } from 'react-redux';
import EmptyDataUi from '@app/components/emptyData';
import { useSelector } from 'react-redux';
import { getLoading } from '../redux/selectors';
import { get_baskets } from '../redux/reducer';
import moment from 'moment';
// import Link from '@design-system/Link';
import LoadingDataUi from '@app/components/loading';
// import PriceQuery from '../components/PriceQuery';
// import ShipmentQuery from '../components/ShipmentQuery';
import { Link } from 'react-router-dom';

const GroupsTable = ({
  groups,
  pageSize,
  currentPage,
  handleChange,
  shipmentFilter,
  cycle,
  endDate,
  startDate,
  filterDate,
  priceRange,
  lowPrice,
  highPrice,
  getSearch
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);

  moment.monthsShort('-MMM-');
  useEffect(() => {
    dispatch(
      get_baskets({
        limit: pageSize,
        page: currentPage,
        cycle,
        filterDate,
        startDate,
        endDate,
        getSearch,
        low_price: lowPrice,
        high_price: highPrice
      })
    );
  }, [
    dispatch,
    currentPage,
    pageSize,
    cycle,
    startDate,
    endDate,
    filterDate,
    lowPrice,
    highPrice,
    getSearch
  ]);

  return (
    <div>
      {loading ? (
        <LoadingDataUi />
      ) : (
        <GroupsTableContent>
          <BTableBody>
            <thead>
              <tr>
                <th>Order No</th>
                <th>Customer</th>
                <th>Amount</th>
                <th>Partner</th>
                <th>Delivery Fee</th>
                <th>Status</th>
                <th>Date paid</th>
              </tr>
            </thead>
            <tbody>
              {/* <td>
                <input
                  type="text"
                  className="search_box"
                  onChange={handleChange}
                  placeholder="Search basket name"
                />
              </td> */}
              {/* <td></td>
              <td></td>
              <td>
                <PriceQuery priceRange={priceRange} />
              </td>
              <td></td>
              <td></td>
              <td>
                <ShipmentQuery shipmentFilter={shipmentFilter} />
              </td> */}

              <>
                {groups?.map((group) => (
                  <TableRow key={group._id}>
                    <td>
                      <Link
                        to={`/basket-orders/${group?._id}`}
                        style={{
                          textDecoration: 'none',
                          color: '#549a9a',
                          fontWeight: '500'
                        }}
                      >
                        {group?.order_no}
                      </Link>
                    </td>
                    <td>
                      {`${group?.created_by.first_name} ${group?.created_by.last_name}`}
                    </td>
                    <td>
                      <AsgardP>
                        {group.amount_paid.currency?.symbol}
                        {CommaNumber(group.amount_paid.value)}
                      </AsgardP>
                    </td>
                    <td>{group?.partner.business_name}</td>
                    <td>
                      {' '}
                      {group.amount_paid.currency?.symbol}
                      {CommaNumber(group.delivery_fee)}
                      {CommaNumber(group.service_fee)}
                    </td>
                    <td>
                      <div className="tracking">{group.current_tracking}</div>
                    </td>
                    <td>{moment(group.date_paid).format('LL')}</td>
                    {/* <td>{group.shipment_circle}</td> */}
                  </TableRow>
                ))}
              </>
            </tbody>
          </BTableBody>
        </GroupsTableContent>
      )}

      {!loading && groups.length <= 0 && <EmptyDataUi />}
    </div>
  );
};

export default GroupsTable;
