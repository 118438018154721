import React, { useState, useEffect } from 'react';
import { Header, Search } from './style.js';
import { useSelector, useDispatch } from 'react-redux';
import { getLoading, getDeliveryPartners, getPageStats } from './redux/selectors.js';
import { delete_delivery_partner, get_delivery_partners } from './redux/reducers.js';
import EmptyDataUi from '@app/components/emptyData/index.js';
import Table from './table/index.js';
import Pagination from '@app/components/pagination/index.jsx';
import useDebounce from '@app/Hooks/useDebounce.js';
import { FormsModal } from '@app/globalstyles/asgard/index.js';
import { FormContainerProducts } from '../products/style.js';
import AddDeliveryPartner from './components/addDelivery/index.js';
import ViewDelivery from './components/viewdelivery/index.js';

const DeliveryPartner = () => {
  const delivery_partners = useSelector(getDeliveryPartners);
  const loading = useSelector(getLoading);
  const pageStats = useSelector(getPageStats);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState('');
  const debouncedTerm = useDebounce(searchString, 500);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const [mode, setMode] = useState('add');
  const [content, setContent] = useState({});

  useEffect(() => {
    dispatch(
      get_delivery_partners({
        page: currentPage,
        limit: pageSize,
        searchString: debouncedTerm
      })
    );
  }, [dispatch, currentPage, pageSize, debouncedTerm]);

  const getNewPartner = (page, limit) => {
    dispatch(get_delivery_partners({ page, limit, debouncedTerm }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewPartner(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewPartner(1, pageSize);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  const handleDelete = (idx) => {
    dispatch(delete_delivery_partner(idx));
  };

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const open = () => {
    setShow(true);
    setMode('add');
    setContent({});
  };

  const handleEdit = (doc) => {
    setShow(true);
    setMode('edit');
    setContent(doc);
  };

  const view = (doc) => {
    setHide(true);
    setContent(doc);
  };

  return (
    <div>
      {show && (
        <FormsModal show={show} onClick={() => setShow(false)}>
          <FormContainerProducts onClick={StopBubbling}>
            <AddDeliveryPartner mode={mode} content={content} />
          </FormContainerProducts>
        </FormsModal>
      )}

      {hide && (
        <FormsModal show={hide} onClick={() => setHide(false)}>
          <FormContainerProducts onClick={StopBubbling}>
            <ViewDelivery content={content} />
          </FormContainerProducts>
        </FormsModal>
      )}
      <Header>
        <p>Delivery Partners</p>
        <button onClick={open}>Add Delivery Partner</button>
      </Header>
      <Search placeholder="Search delivery partner..." onChange={handleSearch} />
      {!loading && delivery_partners?.length <= 0 ? (
        <EmptyDataUi />
      ) : (
        <Table
          loading={loading}
          partners={delivery_partners}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          openView={view}
        />
      )}
      <Pagination
        itemsCount={pageStats.docs}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangePageSize={handlePageSizeChange}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default DeliveryPartner;
