import styled from 'styled-components';

export const Card = styled.div`
  background-color: #fff;
  padding: 10% 10% 45% 10%;
  box-shadow: 0px 0px 50px 20px rgba(84, 154, 154, 0.2);
  border-radius: 10px;
`;

//Card for the Signup and Login Card
export const AccessCard = styled(Card)`
  padding: 8% 12% 30% 12%;
  border-radius: 0;

  @media screen and (max-width: 1024px) {
    padding: 6% 10% 10% 10%;
    border-radius: 0;
  }
`;
