import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_staff,
  get_staff_error,
  get_staff_success,
  create_staff,
  create_staff_error,
  create_staff_success
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getStaffSagas() {
  try {
    const requestRes = yield call(api.get, '/staff');
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_staff_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_staff_error.type
    });
  }
}

function* createStaffSagas({ payload }) {
  try {
    const requestRes = yield call(api.post, '/staff', payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: create_staff_success.type,
      payload: response
    });
    toast.success('Staff created successfully');
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: create_staff_error.type
    });
  }
}

function* staffSagas() {
  yield takeEvery(get_staff.type, getStaffSagas);
  yield takeEvery(create_staff.type, createStaffSagas);
}

export default staffSagas;
