import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { FormComponent } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { CreatingMealFacts } from '../redux/selector';
import { create_meal_facts, update_meal_facts } from '../redux/reducer';

const AddMealFact = ({ onClose, isEdit, content }) => {
  const imgRef = useRef();
  const [image, setImage] = useState(null);
  const loading = useSelector(CreatingMealFacts);
  const dispatch = useDispatch();

  let initialValues = {
    title: content?.title || '',
    body: content?.body || ''
  };

  const pickImage = () => {
    imgRef.current.click();
  };

  const handleSelectImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (doc) => {
    let data = {
      ...doc,
      images: image
    };

    if (isEdit) {
      dispatch(update_meal_facts({ id: content?._id, data }));
    } else {
      dispatch(create_meal_facts({ data: data, onClose }));
    }
  };

  useEffect(() => {
    if (content?.image) {
      setImage(content?.image);
    }
  }, [content]);
  return (
    <FormComponent>
      <input
        type="file"
        accept=".png, .jpg, .jpeg"
        className="image"
        ref={imgRef}
        onChange={handleSelectImage}
      />
      {isEdit ? <h3>Edit Fun Meal Fact</h3> : <h3>Add Fun Meal Fact</h3>}

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange }) => (
          <Form>
            <div className="form">
              <div className="form_content">
                <label>Title</label>
                <input
                  placeholder="Title"
                  value={values.title}
                  onChange={handleChange}
                  name="title"
                  required
                />
              </div>

              <div className="form_content">
                <label>Body</label>
                <textarea
                  placeholder="Body"
                  value={values.body}
                  onChange={handleChange}
                  name="body"
                  required
                  rows={5}
                />
              </div>

              {image?.name ? (
                <div onClick={pickImage}>
                  <img src={URL.createObjectURL(image)} alt="selected" />
                </div>
              ) : image ? (
                <div onClick={pickImage}>
                  <img src={image} alt="selected" />
                </div>
              ) : (
                <div className="image_box" onClick={pickImage}>
                  <div>
                    <h4>Choose a file to upload</h4>
                  </div>
                </div>
              )}
            </div>

            {isEdit ? (
              <button type="submit">{loading ? 'Saving...' : 'Save'}</button>
            ) : (
              <button type="submit">{loading ? 'Creating...' : 'Create'}</button>
            )}
          </Form>
        )}
      </Formik>
    </FormComponent>
  );
};

export default AddMealFact;
