import React, { useEffect, useState } from 'react';
import Ingriedents from './components/Ingriedents';
import RecipeTitle from './components/RecipeTitle';
import { Header, Container } from './style';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLoading, getProductBundle, getRecipe } from './redux/selector';
import { get_product_bundle, get_recipes } from './redux/reducer';
import LoadingDataUi from '@app/components/loading';
import left from '@design-system/Assets/left.png';
import { Link } from 'react-router-dom';
import { FormsModal } from '@app/globalstyles/asgard';
import CreateProduct from '../recipes/components/createProduct';
import { getEditSuccess } from '../recipes/redux/selectors';

const RecipePage = () => {
  const loading = useSelector(getLoading);
  const recipe = useSelector(getRecipe);
  const params = useParams();
  const edited = useSelector(getEditSuccess);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState({});
  const product_bundle = useSelector(getProductBundle);
  const page = 1;
  const limit = 1000;
  const searchString = 'bundle';
  const [countries, setCountries] = useState([]);
  const [bundles, setBundles] = useState([]);

  const showForm = (data) => {
    setOpen(true);
    setContent(data);
  };

  useEffect(() => {
    if (Object.keys(recipe).length >= 1) {
      const countries = recipe?.countries?.map((data) => {
        return {
          value: data,
          label: data
        };
      });

      const bundles = recipe?.product_bundles?.map((data) => {
        return {
          value: data._id,
          label: data.name
        };
      });

      setCountries(countries);
      setBundles(bundles);
    }
  }, [recipe]);

  const closeForm = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(get_product_bundle({ page, limit, searchString }));
  }, [dispatch, page, limit, searchString]);

  useEffect(() => {
    dispatch(get_recipes(params.id));
  }, [dispatch, params, edited]);

  return (
    <div style={{ overflowX: 'hidden' }}>
      {open ? (
        <FormsModal show={open}>
          <CreateProduct
            showForm={showForm}
            creator={content}
            closeForm={closeForm}
          />
        </FormsModal>
      ) : null}
      {loading ? (
        <LoadingDataUi />
      ) : (
        <div>
          <Header>Recipes</Header>
          <Container>
            <div>
              <Link to="/recipe" className="title">
                <img src={left} alt="left_arrow" />
                {recipe.name}
              </Link>
            </div>
            <video
              className="recipe_video"
              width="100%"
              height="495"
              controls
              src={recipe.video}
            >
              <source type="video/.mp4"></source>
            </video>
            <RecipeTitle recipe={recipe} showForm={showForm} />
            <Ingriedents
              recipe={recipe}
              bundle={product_bundle}
              countries={countries}
              bundles={bundles}
            />
          </Container>
        </div>
      )}
    </div>
  );
};

export default RecipePage;
