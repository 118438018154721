import React, { useEffect, useState } from 'react';
import { GroupContainer, OrderHeader } from './style';
import GroupsTable from './table';
import { getGroups, getLoading, getPageStats } from './redux/selectors';
import Pagination from '@app/components/pagination';
import { useDispatch, useSelector } from 'react-redux';
import Search from './components/Search';
import { get_baskets } from './redux/reducer';
import moment from 'moment';
import FilterQuery from './components/FilterQuery';

function BasketOrders() {
  const groupsFromReducer = useSelector(getGroups);
  const loading = useSelector(getLoading);
  const [cycle, setCycle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const pageStats = useSelector(getPageStats);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [getSearch, setGetSearch] = useState('');
  const [groups, setGroups] = useState([]);
  const [range, setRange] = useState([
    {
      startDate: '',
      endDate: '',
      key: 'selection'
    }
  ]);
  const [open, setOpen] = useState(false);
  const [highPrice, setHighPrice] = useState('');
  const [lowPrice, setLowPrice] = useState('');

  const priceRange = (price) => {
    setLowPrice(price.low_price);
    setHighPrice(price.high_price);
  };

  const handleSearchFilters = () => {
    const startDate = moment(range[0].startDate).format('MM-DD-YYYY');
    const endDate = moment(range[0].endDate).format('MM-DD-YYYY');
    if (filterDate) {
      setStartDate(startDate);
      setEndDate(endDate);
    } else {
      setStartDate('');
      setEndDate('');
    }
  };

  const handleSearch = (e) => {
    setGetSearch(e.target.value.toLowerCase());
  };

  const onSearch = () => {
    handleSearchFilters();
  };

  const handleChange = (e) => {
    const searchString = e.target.value.toLowerCase();
    if (!searchString) {
      setGroups(groupsFromReducer);
    } else {
      const result = groupsFromReducer.filter((group) =>
        group.name.toLowerCase().includes(searchString)
      );
      setGroups(result);
      setCurrentPage(1);
    }
  };

  const getNewGroups = (
    page,
    limit,
    cycle,
    startDate,
    endDate,
    filterDate,
    lowPrice,
    highPrice,
    getSearch
  ) => {
    dispatch(
      get_baskets({
        page,
        limit,
        cycle,
        startDate,
        endDate,
        filterDate,
        getSearch,
        low_price: lowPrice,
        high_price: highPrice
      })
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewGroups(page, pageSize);
  };
  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewGroups(1, pageSize);
  };

  const shipmentFilter = (e) => {
    setCycle(e.value);
  };

  useEffect(() => {
    setGroups(groupsFromReducer);
  }, [
    groupsFromReducer,
    cycle,
    startDate,
    endDate,
    filterDate,
    lowPrice,
    highPrice,
    getSearch
  ]);
  return (
    <div>
      <OrderHeader>Orders</OrderHeader>
      <Search handleFilter={handleSearch} />
      <FilterQuery
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        onSearch={onSearch}
        range={range}
        setRange={setRange}
        open={open}
        setOpen={setOpen}
      />

      <GroupContainer>
        <GroupsTable
          lowPrice={lowPrice}
          highPrice={highPrice}
          filterDate={filterDate}
          endDate={endDate}
          startDate={startDate}
          priceRange={priceRange}
          handleChange={handleChange}
          shipmentFilter={shipmentFilter}
          groups={groups}
          currentPage={currentPage}
          pageSize={pageSize}
          cycle={cycle}
          getSearch={getSearch}
        />
        {!loading && (
          <Pagination
            onChangePageSize={handlePageSizeChange}
            itemsCount={pageStats.docs}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </GroupContainer>
    </div>
  );
}

export default BasketOrders;
