import React from 'react';
import { FormContainer } from '../style';
import UploadImage from './UploadImage';

const SelectFile = ({ next }) => {
  const editVideo = (videoFile) => {
    next({ video: videoFile });
  };

  const startUpload = async () => {
    try {
      let cloudinaryOptions = {
        cloud_name: process.env.REACT_APP_CLOUDINARY_NAME,
        upload_preset: process.env.REACT_APP_CLOUDINARY_FOLDER
      };

      return await window.cloudinary.openUploadWidget(
        cloudinaryOptions,
        async (error, result) => {
          if (error || !result) {
            console.log('upload not successful', error);
            return;
          }
          if (!error && result && result.event === 'success') {
            editVideo(result.info.secure_url);
          }
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleUpload = () => {
    startUpload();
  };

  return (
    <FormContainer>
      <div className="upload_container">
        <UploadImage />
        <h4>Reels Upload</h4>
        <p>Upload an MP4 video file</p>
        <label className="select_file_button" onClick={handleUpload}>
          Select File
        </label>
      </div>
    </FormContainer>
  );
};

export default SelectFile;
