import React from 'react';
import { Button, Img } from '@app/globalstyles/asgard';
import closeIcon from '@app/assets/icons/Close_round.svg';

export const CloseFormButton = ({ onClick }) => {
  return (
    <Button w="50px" bc="transparent" h="30px" br="50%" mt="5px" onClick={onClick}>
      <Img width="25px" height="25px" src={closeIcon} alt="Close Form" />
    </Button>
  );
};
