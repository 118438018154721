import React from 'react';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import styles from './styles.module.css';
import { getCreating } from '../redux/selectors';
import { update_units } from '../redux/reducers';

const UpdateUnit = ({ units, onClose }) => {
  const loading = useSelector(getCreating);
  const dispatch = useDispatch();
  let data = {
    name: units?.name
  };

  const submit = (doc) => {
    dispatch(update_units({ id: units?._id, data: doc }));
    setTimeout(() => {
      onClose();
    }, 1500);
  };

  return (
    <Formik initialValues={data} onSubmit={submit}>
      {({ handleChange, values }) => (
        <Form className={styles.container}>
          <div className={styles.label}>Name</div>
          <input
            type="text"
            placeholder="name"
            name="name"
            value={values.name}
            onChange={handleChange}
          />

          <button type="submit">{loading ? 'Saving...' : 'Save Changes'}</button>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateUnit;
