import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const PaymentsContainer = styled.div`
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  margin-bottom: 30px;
  ${media.mobile`
  width: 99.8%;
   margin-left: -10px;
   margin-bottom: 0px;
  `}
`;
