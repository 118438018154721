import React, { useState } from 'react';
import LoadingDataUi from '@app/components/loading';
import { BTableBody } from '@app/pages/products/table/style';
import { Badge } from '@design-system/Badge/Badge';
import { Dot, Menu, Pill } from '../style';

const Table = ({ loading, partners, handleDelete, handleEdit, openView }) => {
  const [show, setShow] = useState(-1);

  const toggleMenu = (index) => {
    setShow(show === index ? -1 : index);
  };

  return (
    <div>
      {' '}
      {loading ? (
        <LoadingDataUi />
      ) : (
        <BTableBody>
          <table style={{ width: '100%' }}>
            <thead className="table_head">
              <tr>
                <th>Name</th>
                <th> Location</th>
                <th>Interstate</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="table_body">
              {partners.map((data, i) => (
                <tr key={data._id}>
                  <td>{data.name}</td>
                  <td>
                    <Pill>
                      {data?.location?.split(',').map((data, i) => (
                        <div className="content" key={i}>
                          {data}
                        </div>
                      ))}
                    </Pill>
                  </td>

                  <td>
                    {data?.interstate ? (
                      <Badge bg="success">Yes</Badge>
                    ) : (
                      <Badge bg="danger">No</Badge>
                    )}
                  </td>
                  <td>
                    <Dot onClick={() => toggleMenu(i)}>
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                    </Dot>
                    {show === i && (
                      <Menu>
                        <div className="list">
                          <p
                            onClick={() => {
                              openView(data);
                              setShow(-1);
                            }}
                          >
                            View
                          </p>
                          <p
                            onClick={() => {
                              handleEdit(data);
                              setShow(-1);
                            }}
                          >
                            Edit
                          </p>
                          <p onClick={() => handleDelete(data._id)}>Delete</p>
                        </div>
                      </Menu>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </BTableBody>
      )}
    </div>
  );
};

export default Table;
