import styled from 'styled-components/macro';
import { Link, NavLink } from 'react-router-dom';
import { SlideIn } from '@app/components/UI/Modal/style';

export const AsgardLink = styled(Link)`
  text-decoration: none;
  text-align: center;
  color: ${({ color }) => (color ? color : '#549a9a')};
`;
export const AsgardNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ color }) => (color ? color : '#549a9a')};
`;
export const Div = styled.div`
  width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  max-width: ${({ mw }) => (mw ? mw : null)};
  margin-top: ${({ mt }) => (mt ? mt : null)};
  margin-left: ${({ ml }) => (ml ? ml : null)};
  margin-right: ${({ mr }) => (mr ? mr : null)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
  padding: ${({ padding }) => (padding ? padding : null)};
  display: ${({ display }) => display || null};
  flex-direction: ${({ flexDirection }) => flexDirection || null};
  flex-wrap: ${({ wrap }) => wrap || null};
  align-items: ${({ alignI }) => alignI || null};
  align-self: ${({ align }) => (align ? align : null)};
  justify-content: ${({ justify }) => (justify ? justify : null)};
  background-color: ${({ bc }) => (bc ? bc : null)};
  background-image: ${({ image }) => (image ? `url(${image})` : null)};
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : null)};
  box-sizing: ${({ boxSizing }) => (boxSizing ? boxSizing : null)};
  background-size: cover;
  cursor: ${({ cursor }) => (cursor ? cursor : null)};
  background-repeat: no-repeat;
  border: ${({ border }) => (border ? border : null)};
  border-radius: ${({ br }) => (br ? br : null)};
  position: ${({ position }) => (position ? position : null)};
  top: ${({ top }) => (top ? top : null)};
  left: ${({ left }) => (left ? left : null)};
  right: ${({ right }) => (right ? right : null)};
  bottom: ${({ bottom }) => (bottom ? bottom : null)};
`;

export const Img = styled.img`
  width: ${({ w }) => (w ? w : null)};
  height: ${({ h }) => (h ? h : null)};
  margin: ${({ m }) => (m ? m : null)};
  padding: ${({ p }) => (p ? p : null)};
  margin-top: ${({ mt }) => (mt ? mt : null)};
  margin-left: ${({ ml }) => (ml ? ml : null)};
  margin-right: ${({ mr }) => (mr ? mr : null)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
  border-radius: ${({ br }) => (br ? br : null)};
  align-self: ${({ align }) => (align ? align : null)};
  opacity: ${({ op }) => (op ? op : null)};
  cursor: ${({ cursor }) => (cursor ? cursor : null)};
`;

export const Button = styled.button`
  background-color: ${({ bc }) => (bc ? bc : null)};
  width: ${({ w }) => (w ? w : null)};
  height: ${({ h }) => (h ? h : null)};
  padding: ${({ p }) => (p ? p : null)};
  border: ${({ border }) => (border ? `1px solid ${border}` : `none`)};
  border-bottom: ${({ borderBot }) => (borderBot ? `3px solid ${borderBot}` : null)};
  border-radius: ${({ br }) => (br ? br : null)};
  outline: none;
  font-weight: ${({ fw }) => (fw ? fw : null)};
  font-size: ${({ fs }) => (fs ? fs : null)};
  display: ${({ display }) => display};
  align-items: ${({ alignI }) => alignI || null};
  cursor: pointer;
  box-shadow: ${({ bs }) => (bs ? bs : null)};
  box-sizing: ${({ bSizing }) => (bSizing ? bSizing : null)};
  color: ${({ color }) => (color ? color : null)};
  margin: ${({ m }) => (m ? m : null)};
  margin-top: ${({ mt }) => (mt ? mt : null)};
  margin-left: ${({ ml }) => (ml ? ml : null)};
  margin-right: ${({ mr }) => (mr ? mr : null)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
  &:hover {
    background-color: ${({ hbc }) => (hbc ? hbc : null)};
    color: ${({ hcolor }) => (hcolor ? hcolor : null)};
    cursor: pointer;
  }
`;

export const AsgardInput = styled.input`
  width: ${({ w }) => (w ? w : null)};
  height: ${({ h }) => (h ? h : null)};
  font-weight: ${({ fw }) => (fw ? fw : null)};
  color: ${({ color }) => (color ? color : null)};
  background: ${({ bg }) => (bg ? bg : null)};
  display: ${({ display }) => display};
  margin: ${({ m }) => (m ? m : null)};
  outline: ${({ o }) => (o ? o : null)};
  border: ${({ border }) => (border ? `1px solid ${border}` : `none`)};
  padding: ${({ p }) => (p ? p : null)};
  text-align: ${({ tAlign }) => (tAlign ? tAlign : null)};
  letter-spacing: ${({ tSpacing }) => (tSpacing ? tSpacing : null)};
`;
export const AsgardTextArea = styled.textarea`
  width: ${({ w }) => (w ? w : null)};
  height: ${({ h }) => (h ? h : null)};
  font-weight: ${({ fw }) => (fw ? fw : null)};
  color: ${({ color }) => (color ? color : null)};
  background: ${({ bg }) => (bg ? bg : null)};
  display: ${({ display }) => display};
  margin: ${({ m }) => (m ? m : null)};
  outline: ${({ o }) => (o ? o : null)};
  border: ${({ border }) => (border ? `1px solid ${border}` : `none`)};
  padding: ${({ p }) => (p ? p : null)};
  text-align: ${({ tAlign }) => (tAlign ? tAlign : null)};
  letter-spacing: ${({ tSpacing }) => (tSpacing ? tSpacing : null)};
`;

export const AsgardP = styled.p`
  font-weight: ${({ fw }) => (fw ? fw : null)};
  color: ${({ color }) => (color ? color : null)};
  background: ${({ bg }) => (bg ? bg : null)};
  display: ${({ display }) => display};
  margin: ${({ m }) => (m ? m : null)};
  margin-left: ${({ ml }) => (ml ? ml : null)};
  margin-right: ${({ mr }) => (mr ? mr : null)};
  margin-top: ${({ mt }) => (mt ? mt : null)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
  text-transform: ${({ tr }) => (tr ? tr : null)};
  font-size: ${({ fs }) => (fs ? fs : null)};
  padding: ${({ p }) => (p ? p : null)};
  text-align: ${({ tAlign }) => (tAlign ? tAlign : null)};
  text-transform: ${({ tf }) => (tf ? tf : null)};
  cursor: ${({ cursor }) => (cursor ? cursor : null)};
  opacity: ${({ op }) => (op ? op : null)};
`;
export const AsgardH = styled.h4`
  font-weight: ${({ fw }) => (fw ? fw : null)};
  color: ${({ color }) => (color ? color : null)};
  background: ${({ bg }) => (bg ? bg : null)};
  display: ${({ display }) => display};
  margin: ${({ m }) => (m ? m : null)};
  margin-left: ${({ ml }) => (ml ? ml : null)};
  margin-right: ${({ mr }) => (mr ? mr : null)};
  margin-top: ${({ mt }) => (mt ? mt : null)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
  text-transform: ${({ tr }) => (tr ? tr : null)};
  font-size: ${({ fs }) => (fs ? fs : null)};
  padding: ${({ p }) => (p ? p : null)};
  text-align: ${({ tAlign }) => (tAlign ? tAlign : null)};
  opacity: ${({ op }) => (op ? op : null)};
`;
export const HeightSpacer = styled.div`
  height: ${({ h }) => h || null};
  width: ${({ w }) => w || null};
`;

export const FormsModal = styled.div`
  /* height: 100%; */
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  align-items: center;
  background: ${({ show }) => (show ? 'rgba(0, 0, 0, 0.4)' : '')};
  position: fixed;
  transition: all 0.5s ease-out;
  transform: ${({ show }) => (show ? `translateY(0)` : `translateY(-20px)`)};
  animation-name: ${SlideIn};
  animation-duration: 0.5s;
  z-index: ${({ show }) => (show ? 105 : 0)};
`;

export const FormCloseBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  /* background: #f90; */
  margin-top: ${({ mt }) => (mt ? mt : '-50px')};
  margin-bottom: ${({ mb }) => (mb ? mb : '15px')};
`;

export const CreateFormCloseBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  /*  background: #f90; */
  margin-top: ${({ mt }) => (mt ? mt : '-2px')};
  margin-bottom: ${({ mb }) => (mb ? mb : '10px')};
  right: 20px;
`;
