import { createSelector } from '@reduxjs/toolkit';

const mealCategories = (state = {}) => state.meal_categories;

export const getMealCategories = createSelector(
  mealCategories,
  (mealCategories) => mealCategories.meal_categories
);

export const getMealCategoriesLoading = createSelector(
  mealCategories,
  (mealCategories) => mealCategories.ui.loading
);

export const getMealCategoriesPageStats = createSelector(
  mealCategories,
  (mealCategories) => mealCategories.ui.stats
);

export const CreatingMealCategories = createSelector(
  mealCategories,
  (mealCategories) => mealCategories.ui.creating
);
