import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_meal_facts,
  get_meal_facts_success,
  get_meal_facts_error,
  create_meal_facts,
  create_meal_facts_success,
  create_meal_facts_error,
  delete_meal_facts,
  delete_meal_facts_success,
  delete_meal_facts_error,
  update_meal_facts,
  update_meal_facts_success,
  update_meal_facts_error
} from './reducer';

import api from '@service/DataService';
import toast from 'react-hot-toast';
import { FileUploadToCloud } from '@app/services/fileUploadService';

function* getMealFactsSagas({ payload }) {
  try {
    const { page = 1, limit = 10, search } = payload;

    let searchString = ``;
    if (search) {
      searchString = `&search=${search}`;
    }

    const request = yield call(
      api.get,
      `/meal_facts?page=${page}&limit=${limit}${searchString}`
    );
    const requestRes = request.data;
    const response = requestRes.data;

    yield put({
      type: get_meal_facts_success.type,
      payload: response
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_meal_facts_error.type
    });
  }
}

function* createMealFact({ payload }) {
  try {
    const { data, onClose } = payload;
    let image = ``;
    if (data.images !== null) {
      const responseImage = yield call(FileUploadToCloud, data.images);
      image = responseImage.secure_url;
    }

    const doc = {
      ...data,
      image: image
    };
    delete doc['images'];
    if (doc.image === ``) {
      delete doc['image'];
    }
    const request = yield call(api.post, `/meal_facts`, doc);
    const requestRes = request.data;
    const response = requestRes.data;
    toast.success(requestRes.message);
    yield put({
      type: create_meal_facts_success.type,
      payload: response
    });

    onClose();
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
    yield put({
      type: create_meal_facts_error.type
    });
  }
}

function* deleteMealFact({ payload }) {
  try {
    const request = yield call(api.delete, `/meal_facts/${payload}`);
    const res = request.data;
    toast.success(res.message);
    const response = res.data;
    yield put({
      type: delete_meal_facts_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(
      error?.data?.response?.message || error?.message || error?.response?.data
    );
    yield put({
      type: delete_meal_facts_error.type
    });
  }
}

function* updateMealFact({ payload }) {
  try {
    const { id, data } = payload;
    let image = ``;
    if (typeof data.images !== 'string') {
      const responseImage = yield call(FileUploadToCloud, data.images);
      image = responseImage.secure_url;
    } else {
      image = data.images;
    }
    const doc = {
      ...data,
      image: image
    };
    delete doc['images'];
    const request = yield call(api.patch, `/meal_facts/${id}`, doc);
    const res = request.data;
    const response = res.data;
    toast.success(res.message);
    yield put({
      type: update_meal_facts_success.type,
      payload: response
    });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: update_meal_facts_error.type
    });
  }
}

function* MealFactsSagas() {
  yield takeEvery(get_meal_facts.type, getMealFactsSagas);
  yield takeEvery(create_meal_facts.type, createMealFact);
  yield takeEvery(delete_meal_facts.type, deleteMealFact);
  yield takeEvery(update_meal_facts.type, updateMealFact);
}

export default MealFactsSagas;
