import { FieldArray } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';

const BoxStyle = styled.div`
  input {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 14px 12px;
    box-sizing: border-box;
    color: #828282;
    font-size: 15px;
    font-weight: 500;

    &::placeholder {
      color: #e0e0e0;
      font-weight: 500;
      font-size: 15px;
    }

    &:focus {
      outline: none;
      border: 1px solid #549a9a;
    }
  }
`;

const HealthCondition = ({ value, setFieldValue }) => {
  return (
    <BoxStyle>
      <FieldArray name="incompatible_medical_conditions">
        {({ push, remove }) => (
          <>
            {value.incompatible_medical_conditions &&
              value.incompatible_medical_conditions.map((item, idx) => (
                <div
                  key={idx}
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center'
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Health Condition"
                    value={item.idx}
                    onChange={(e) => {
                      let infos = value.incompatible_medical_conditions;
                      let info = infos[idx];
                      let data = {
                        ...info,
                        idx: e.target.value
                      };
                      infos[idx] = data;
                      setFieldValue('incompatible_medical_conditions', infos);
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center'
                    }}
                  >
                    {value.incompatible_medical_conditions.length >= 2 && (
                      <div onClick={() => remove(idx)}>
                        <MdDeleteOutline size={20} className="del" />
                      </div>
                    )}

                    <div
                      onClick={() => {
                        push({
                          idx: ''
                        });
                      }}
                      className="add"
                    >
                      <IoMdAddCircleOutline size={20} />
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </FieldArray>
    </BoxStyle>
  );
};

export default HealthCondition;
