import { AsgardP } from '@app/globalstyles/asgard';
import { BTableBody, GroupsTableContent, TableRow } from './style';
import { useEffect, useState } from 'react';
import CommaNumber from 'comma-number';
import { useDispatch } from 'react-redux';
import EmptyDataUi from '@app/components/emptyData';
import { useSelector } from 'react-redux';
import { getLoading } from '../redux/selectors';
import { get_groups } from '../redux/reducer';
import moment from 'moment';
import Modal from '@app/components/modal';
// import Link from '@design-system/Link';
import LoadingDataUi from '@app/components/loading';
import { PaidStatuslable } from '../style';
import BasketDetails from '../components/BasketDetails';

const GroupsTable = ({
  groups,
  pageSize,
  currentPage,
  selectedfilter,
  searchString
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const [visible, setVisible] = useState(false);
  const [basket, setBasket] = useState({});

  useEffect(() => {
    dispatch(
      get_groups({
        limit: pageSize,
        page: currentPage,
        selectedfilter,
        searchString
      })
    );
  }, [dispatch, selectedfilter, currentPage, pageSize, searchString]);

  return (
    <div>
      <GroupsTableContent>
        {loading ? (
          <LoadingDataUi />
        ) : (
          <BTableBody>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Partner</th>
                <th>Price</th>
                <th>Shipment Circle</th>
                <th>Date Created</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <tbody>
              {groups?.map((group) => (
                <TableRow
                  key={group._id}
                  onClick={() => {
                    setVisible(true);
                    setBasket(group);
                  }}
                >
                  {/* <td style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                    <Link to={`/groups/${group._id}`}>
                      <AsgardP>{group.name}</AsgardP>
                    </Link>
                  </td>
                  <td>{group.country}</td> */}
                  <td>
                    {group?.creator?.first_name} {group?.creator?.last_name}
                  </td>
                  <td>
                    <AsgardP>{group?.partner?.business_name}</AsgardP>
                  </td>
                  <td>
                    <AsgardP>
                      {group.currency?.short_code}
                      {CommaNumber(group.total_price)}
                    </AsgardP>
                  </td>
                  <td>{group.shipment_circle}</td>
                  <td>{moment(group.created_at).format('LL')}</td>
                  <td>
                    {group.paid ? (
                      <PaidStatuslable bg="success">PAID</PaidStatuslable>
                    ) : (
                      <PaidStatuslable bg="red">NOT PAID</PaidStatuslable>
                    )}
                  </td>
                </TableRow>
              ))}
            </tbody>
          </BTableBody>
        )}
      </GroupsTableContent>
      {!loading && groups.length <= 0 && <EmptyDataUi />}
      {visible && (
        <Modal
          width={'700px'}
          onClose={() => setVisible(false)}
          title={'Basket Details'}
        >
          <BasketDetails key={basket?._id} basket={basket} />
        </Modal>
      )}
    </div>
  );
};

export default GroupsTable;
