import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const CustomersContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  margin-bottom: 30px;
  ${media.mobile`
  width: 99.8%;
   margin-left: -10px;
   margin-bottom: 0px;
  `}
`;

export const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const SearchBar = styled.div`
  display: flex;
  border: 1px solid #f4f4f4;
  align-content: center;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  width: 423.5px;
  margin-left: 31px;

  input {
    border: none;
    outline: none;

    ::placeholder {
      color: #64748b;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;
