import React from 'react';
import { DetailStyles } from '../style';

const Details = ({ meal }) => {
  return (
    <DetailStyles>
      {meal?.image && <img src={meal?.image} alt={meal?.name} />}

      <div className="my-6">
        <p>
          Calorie Per Serving: <span>{meal?.calorie_per_serving}</span>
        </p>
        <p>
          Total Calorie: <span>{meal?.total_calories}</span>
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '24px'
        }}
      >
        <div>
          <div style={{ display: 'flex', gap: '10px' }}>
            {meal?.types?.map((data, idx) => (
              <div key={idx} className="types">
                {data}
              </div>
            ))}
          </div>

          <p className="mt-6 mb-2">Tags</p>
          <div className="flex item-center gap-[10px]">
            {meal?.incompatible_medical_conditions?.map((data, idx) => (
              <div className="types" key={idx}>
                {data}
              </div>
            ))}
          </div>
        </div>

        <p>Vegan: {meal?.vegan ? 'Yes' : 'No'}</p>
      </div>

      <div className="nutrient_list">
        {meal?.nutrients?.map((data, idx) => (
          <div key={idx} className="nutrient">
            <span style={{ textTransform: 'capitalize' }}>{data.name} </span>
            {data.value}
          </div>
        ))}
      </div>

      <p className="description">{meal?.description}</p>

      <div className="my-6">
        <p className="title mb-4">Portion Per Serving</p>
        <ul className="space-y-4 list-disc">
          {meal?.portion_per_serving?.map((data, idx) => (
            <li key={idx}>{data}</li>
          ))}
        </ul>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div className="title mb-4">Ingredients</div>
        <ul>
          {meal?.ingredients?.map((data, idx) => (
            <li key={idx} className="list-disc">
              {data}
            </li>
          ))}
        </ul>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div className="title mb-4">Instructions</div>
        <ul>
          {meal?.instructions?.map((data, idx) => (
            <li ley={idx} className="list-disc">
              {data}
            </li>
          ))}
        </ul>
      </div>

      {meal?.categories?.length >= 1 && (
        <div style={{ marginTop: '24px' }}>
          <div className="title">Category</div>
          <ul>
            {meal?.categories?.map((data, idx) => (
              <li ley={idx}>{data.name}</li>
            ))}
          </ul>
        </div>
      )}

      {meal?.health_conditions?.length >= 1 && (
        <div style={{ marginTop: '24px' }}>
          <div className="title">Health Conditions</div>
          <ul>
            {meal?.health_conditions?.map((data, idx) => (
              <li ley={idx}>{data.name}</li>
            ))}
          </ul>
        </div>
      )}
    </DetailStyles>
  );
};

export default Details;
