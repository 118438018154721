import React, { useEffect, useState, useRef } from 'react';
import { FormBody, ModalBody, ImageContainer } from '../style';
import { FormContainer } from '../../recipes/style';
import UploadImage from '../../recipes/createRecipe/UploadImage';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrencies } from '@app/pages/currrencies/redux/selectors';
import { get_currencies } from '@app/pages/currrencies/redux/reducer';
import { getProductGroups } from '@app/pages/product-groups/redux/selectors';
import { get_product_groups } from '@app/pages/product-groups/redux/reducers';
import add from '@app/assets/images/Add_ring.png';
import toast from 'react-hot-toast';
import { add_partner_product } from '../redux/reducer';
import { MEASUREMENTS } from '@app/pages/products/filterData';
import { getCountries } from 'country-state-picker';
import { getCreating } from '../redux/selector';

const AddProduct = ({ id }) => {
  const dispatch = useDispatch();
  const currencies = useSelector(getCurrencies);
  const groups = useSelector(getProductGroups);
  const countries = getCountries();
  const loading = useSelector(getCreating);
  const inputRef = useRef();
  const [link, setLink] = useState('');

  const [images, setImages] = useState({
    0: '',
    1: '',
    2: '',
    3: ''
  });
  const [preview, setPreview] = useState({
    0: '',
    1: '',
    2: '',
    3: ''
  });

  const handleImage = (e, key) => {
    const file = e.target.files?.[0];
    if (file && file.type.substring(0, 5) === 'image') {
      setImages((prev) => {
        let init = { ...prev };
        init[key] = file;
        return init;
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setLink(url);
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const previewfile = (image, key) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview((prev) => {
        let init = { ...prev };
        init[key] = reader.result;
        return init;
      });
    };
    reader.readAsDataURL(image);
  };

  useEffect(() => {
    if (typeof images[0] !== 'string') {
      previewfile(images[0], '0');
    }

    if (typeof images[1] !== 'string') {
      previewfile(images[1], '1');
    }

    if (typeof images[2] !== 'string') {
      previewfile(images[2], '2');
    }

    if (typeof images[3] !== 'string') {
      previewfile(images[3], '3');
    }
  }, [images]);

  let data = {
    name: '',
    description: '',
    product_group: '',
    price: '',
    currency: '',
    unit: ''
  };

  const handleSubmit = async (doc) => {
    const img = Object.values(images).filter((img) => img !== '');
    if (img.length <= 0) return toast.error('Please add an Image!');
    const data = {
      ...doc,
      owner: id,
      price: {
        value: doc.price,
        currency: doc.currency
      },
      image: img,
      video: link
    };
    dispatch(add_partner_product(data));
  };

  useEffect(() => {
    dispatch(get_currencies());
    dispatch(get_product_groups());
  }, [dispatch]);
  return (
    <div style={{ height: '500px', overflowY: 'scroll' }}>
      <ModalBody>
        <div className="header">Add Product</div>

        <FormBody>
          <Formik initialValues={data} onSubmit={(values) => handleSubmit(values)}>
            {({ values, handleChange }) => (
              <Form>
                <div className="form_content" style={{ marginTop: '10px' }}>
                  <label>Product Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                  />
                </div>

                <div className="grid">
                  <div className="form_content">
                    <label>Price</label>
                    <div className="segment">
                      <select
                        name="currency"
                        onChange={handleChange}
                        className="select"
                      >
                        {currencies.map((currency) => (
                          <option key={currency._id} value={currency._id}>
                            {currency.short_code} - {currency.symbol}
                          </option>
                        ))}
                      </select>
                      <input type="number" name="price" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="form_content">
                    <label>Product Group</label>
                    <select name="product_group" onChange={handleChange}>
                      {groups.map(({ name, _id }) => (
                        <option key={_id} value={_id}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="grid">
                  <div className="form_content">
                    <label>Measurement</label>
                    <div className="segment">
                      <select name="unit" onChange={handleChange} className="select">
                        {MEASUREMENTS.map((it, idx) => (
                          <option key={idx + it} value={it}>
                            {it}
                          </option>
                        ))}
                      </select>

                      <input type="number" name="weight" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="form_content">
                    <label>Country</label>
                    <select name="country" onChange={handleChange}>
                      {countries.map(({ name, code }) => (
                        <option key={code} value={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form_content" style={{ marginTop: '10px' }}>
                  <label>Product Description</label>
                  <textarea
                    rows="9"
                    cols="50"
                    name="description"
                    onChange={handleChange}
                  />
                </div>

                <ImageContainer>
                  {Object.keys(images).map((image, index) => (
                    <div>
                      <input
                        accept=".png, .jpg, .jpeg"
                        type="file"
                        id={image}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          handleImage(event, image);
                        }}
                      />
                      <div>
                        {preview[index].length === 0 ? (
                          <label className="image_label" htmlFor={image}>
                            <img src={add} alt="add" />
                          </label>
                        ) : (
                          <label className="image_preview" htmlFor={image}>
                            <img src={preview[index]} alt="add" />
                          </label>
                        )}
                      </div>
                    </div>
                  ))}
                </ImageContainer>
                <div>
                  <FormContainer>
                    <div className="upload_container">
                      <input
                        ref={inputRef}
                        className=""
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept=".mov,.mp4"
                      />
                      {!link && (
                        <div>
                          <UploadImage />
                          <label
                            className="select_file_button"
                            onClick={() => {
                              handleChoose();
                            }}
                          >
                            <p>Attach a Video</p>
                          </label>
                        </div>
                      )}
                      {link && (
                        <video width="450" height="150" controls src={link} />
                      )}
                    </div>
                  </FormContainer>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button type="submit" disabled={loading}>
                    {loading ? 'Creating' : 'Create Product'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </FormBody>
      </ModalBody>
    </div>
  );
};

export default AddProduct;
