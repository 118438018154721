import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    stats: {
      pageCount: 1
    }
  },
  data: []
};

export const couponsReducerSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    get_coupons_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    get_coupons_error: (state) => {
      state.ui.loading = false;
    },
    get_coupons: (state) => {
      state.ui.loading = true;
    },
    create_coupon_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data.unshift(payload);
    },
    create_coupon_error: (state) => {
      state.ui.loading = false;
    },
    create_coupon: (state) => {
      state.ui.loading = true;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_coupons,
  get_coupons_error,
  get_coupons_success,
  create_coupon,
  create_coupon_success,
  create_coupon_error
} = couponsReducerSlice.actions;

export default couponsReducerSlice.reducer;
