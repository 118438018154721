import React, { useEffect } from 'react';
import { SectionBody, SectionContainer } from '../style';
import { TableRow } from '@design-system/Table';
import { useDispatch, useSelector } from 'react-redux';
import { get_group_transactions } from '../redux/reducer';
import { useParams } from 'react-router-dom';
import { getGroupTransactions, getPaymentLoading } from '../redux/selectors';
import Link from '@design-system/Link';
import moment from 'moment';
import { Table } from '../style';

export const Transactions = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const basketTransactions = useSelector(getGroupTransactions);
  const loading = useSelector(getPaymentLoading);

  useEffect(() => {
    dispatch(get_group_transactions(params.id));
  }, [dispatch, params.id]);

  return (
    <SectionContainer>
      <SectionBody style={{ marginTop: '30px' }}>
        <Table>
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Payment Date</th>
              <th>Delivery Date</th>
              <th>Paid by</th>
            </tr>
          </thead>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <tbody>
              {basketTransactions.map((trn) => (
                <>
                  {trn.map((trn) => (
                    <TableRow key={trn._id}>
                      <td>{trn?.transaction_id}</td>
                      <td>
                        {' '}
                        {trn.price?.currency?.symbol} {trn.price?.value}
                      </td>
                      <td>{moment(trn.created_at).format('LL')}</td>
                      <td>{moment(trn.group.shipment_date).format('LL')}</td>
                      <td>
                        <Link to={`/users/${trn?.created_by?._id}`}>
                          {trn?.created_by?.first_name} {trn?.created_by?.last_name}
                        </Link>
                      </td>
                    </TableRow>
                  ))}
                </>
              ))}
            </tbody>
          )}
        </Table>
      </SectionBody>
    </SectionContainer>
  );
};

export default Transactions;
