import media from '@app/globalstyles/utils/media';
import styled from 'styled-components/macro';

export const GroupContainer = styled.div`
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 30px;
  ${media.mobile`
  width: 99.8%;
   margin-left: -10px;
   margin-bottom: 0px;
  `}
`;
export const PaidStatuslable = styled.div`
  width: 80px;
  padding: 0.5em;
  text-align: center;
  background: ${(props) =>
    props.bg === 'warning'
      ? 'orange'
      : props.bg === 'success'
      ? 'rgba(51, 184, 144, 0.75)'
      : ' #C13535'};
  border-radius: 4px;
  font-size: 1em;
  color: ${(props) =>
    props.bg === 'warning'
      ? 'white'
      : props.bg === 'success'
      ? '#FFFFFF'
      : '#FFFFFF'};
  text-transform: uppercase;
`;

export const BasketDetailsHeader = styled.div`
  width: 100%;
  display: flex;
  // justify-content: space-between;
  // background:rgba(51, 184, 144, 0.75);
  margin: 10px 0;
`;

export const HeaderTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #a8a8a8;
  margin: 0;
  margin-right: 10px;
`;

export const HeaderText = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  margin: 0;
`;

export const ProductDetailsContainer = styled.div`
  width: 100%;
  height: 300px;
  margin: 10px 0;
  overflow-y: scroll;
`;

export const ProductDetailRow = styled.div`
  display: flex;
`;

export const ProductDetailText = styled.p`
  width: 45%;
  margin-top: 0;
`;

export const ProductQuantityText = styled.p`
  width: 27%;
  margin-top: 0;
`;

export const ProductPriceText = styled.p`
  width: 27%;
  margin-top: 0;
`;

export const ProductDetailColumn = styled.p`
  width: 27%;
  color: #000000;
`;
export const ProductColumn = styled.p`
  color: #000000;
  width: 45%;
`;
