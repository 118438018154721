import { useEffect } from 'react';
import { AsgardP, Button, Div, Img } from '@app/globalstyles/asgard';
import { BTableBody, FBContent, TableRow } from './style';
import elipse from '@assets/icons/vert_elipses.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getLoading } from '../redux/selectors';
import { get_customers } from '../redux/reducer';
import Link from '@design-system/Link';
import { CUSTOMERS } from '@app/pages/ROUTECONSTS';
import LoadingDataUi from '@app/components/loading';
import EmptyDataUi from '@app/components/emptyData';

const CustomerTable = ({ customers, pageSize, currentPage, searchString }) => {
  // const customers = useSelector(getCustomers);
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_customers({ limit: pageSize, page: currentPage, searchString }));
  }, [dispatch, currentPage, pageSize, searchString]);

  return (
    <div>
      <FBContent>
        {loading ? (
          <LoadingDataUi />
        ) : (
          <BTableBody>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
            </tr>
            {customers.map((customer, i) => (
              <TableRow key={customer._id}>
                <td colSpan="1">
                  <Div display="flex">
                    <Link to={`${CUSTOMERS}/${customer._id}`}>
                      <AsgardP ml="10px">
                        {customer.first_name} {customer.last_name}
                      </AsgardP>
                    </Link>
                  </Div>
                </td>
                <td>{customer.email}</td>
                <td>{customer.phone}</td>

                <td>
                  <Div display="flex">
                    <Link to={`${CUSTOMERS}/${customer._id}`}>
                      <Button
                        h="20px"
                        ml="10px"
                        p="2px 5px"
                        bc="transparent"
                        br="3px"
                      >
                        <Img src={elipse} alt="indicator" />
                      </Button>
                    </Link>
                  </Div>
                </td>
              </TableRow>
            ))}
          </BTableBody>
        )}
      </FBContent>
      {!loading && customers.length <= 0 && <EmptyDataUi />}
    </div>
  );
};

export default CustomerTable;
