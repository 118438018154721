import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_product_groups,
  get_product_groups_success,
  get_product_groups_error,
  create_product_group,
  create_product_group_succes,
  create_product_group_error,
  delete_product_group,
  delete_product_group_success,
  delete_product_group_error,
  update_product_group,
  update_product_group_success,
  update_product_group_error,
  get_one_product_group,
  get_one_product_group_success,
  get_one_product_group_error
} from './reducers';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getProductGroupsSagas({ payload }) {
  try {
    const { page = 1, limit = 100, searchString } = payload;
    let search = ``;
    if (searchString) {
      search = `&search=${searchString}`;
    } else {
      search = ``;
    }
    const requestRes = yield call(
      api.get,
      `/product_groups?page=${page}&limit=${limit}${search}`
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_product_groups_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: get_product_groups_error.type
    });
    toast.error(error ? error.toString() : error.response.data.message);
  }
}

function* createProductGroupsSagas({ payload }) {
  try {
    const requestRes = yield call(api.post, '/product_groups', payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: create_product_group_succes.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: create_product_group_error.type
    });
    toast.error(error ? error.toString() : error.response.data.message);
  }
}

function* deleteProductGroupsSagas({ payload }) {
  try {
    const requestRes = yield call(api.delete, `/product_groups/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: delete_product_group_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: delete_product_group_error.type
    });
    toast.error(error ? error.toString() : error.response.data.message);
  }
}

function* updateProductGroupsSagas({ payload }) {
  try {
    const { id, data } = payload;
    const requestRes = yield call(api.put, `/product_groups/${id}`, data);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(responseData.message);
    yield put({
      type: update_product_group_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: update_product_group_error.type
    });
    toast.error(error ? error.toString() : error.response.data.message);
  }
}

function* getOneProductGroupsSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/product_groups/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_one_product_group_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: get_one_product_group_error.type
    });
    toast.error(error ? error.toString() : error.response.data.message);
  }
}

function* productGroupsSagas() {
  yield takeEvery(get_product_groups.type, getProductGroupsSagas);
  yield takeEvery(create_product_group.type, createProductGroupsSagas);
  yield takeEvery(delete_product_group.type, deleteProductGroupsSagas);
  yield takeEvery(update_product_group.type, updateProductGroupsSagas);
  yield takeEvery(get_one_product_group.type, getOneProductGroupsSagas);
}

export default productGroupsSagas;
