import React, { useState } from 'react';
import { Container, Footer } from '../style';
import { FormsModal } from '@app/globalstyles/asgard';
import EditShippingDetails from './EditShippingDetails';
import { SimpleButton } from '@design-system/SimpleButton';
import { useDispatch } from 'react-redux';
import { update_group } from '../redux/reducer';
import moment from 'moment';
import Switch from '@design-system/Switch/Switch';

export const ProductDetails = ({ group }) => {
  const [showForm, setShowForm] = useState(false);
  const handleShowGroupDetailsForm = () => {
    setShowForm(!showForm);
  };

  const dispatch = useDispatch();

  const toggleApprovalStatus = () => {
    const payload = {
      id: group._id,
      data: { approved: !group.approved }
    };
    dispatch(update_group(payload));
  };

  const toggleHiddenStatus = () => {
    const payload = {
      id: group._id,
      data: { hidden: !group.hidden }
    };
    dispatch(update_group(payload));
  };

  return (
    <div>
      <Container>
        {showForm && (
          <FormsModal show={showForm}>
            <EditShippingDetails
              group={group}
              closeform={handleShowGroupDetailsForm}
            />
          </FormsModal>
        )}
        <div className="header_content">
          Group Details
          <div className="visibility">
            Visibility Status
            <div style={{ marginLeft: '10px' }}>
              <Switch change={toggleHiddenStatus} status={group.hidden} />
            </div>
          </div>
        </div>

        <div className="body_content">
          <div className="body_header">
            <div>
              <div className="body_title">Group Code</div>
              <div className="body_sub_title">{group.group_code}</div>
            </div>

            <div>
              <div className="body_title">Group Name</div>
              <div className="body_sub_title">{group.name}</div>
            </div>

            <div>
              <div className="body_title">Total Weight</div>
              <div className="body_sub_title">{group.group_code}</div>
            </div>
          </div>

          <div style={{ marginTop: '16.68px' }}>
            <div className="body_title">Description</div>
            <div className="body_sub_title">{group.description}</div>
          </div>

          <div style={{ marginTop: '16.68px' }}>
            <div className="body_title">Location</div>
            <div className="body_sub_title">
              {group.city}, {group.country}
            </div>
          </div>

          <div style={{ marginTop: '16.68px' }}>
            <div className="body_title">Next Shipment Date</div>
            <div className="body_sub_title">
              {moment(group.shipment_date).format('LL')}
            </div>
          </div>

          <div style={{ marginTop: '16.68px' }}>
            <div className="body_title">Delivery Cycle</div>
            <div className="body_sub_title">{group.shipment_circle}</div>
          </div>
          <div style={{ marginTop: '16.68px' }}>
            <div className="body_title">Approval Status</div>
            {!group.approved && (
              <SimpleButton onClick={toggleApprovalStatus} text={'Change'} />
            )}
            <div className="body_sub_title">
              {group.approved ? 'Approved' : 'Not Approved'}
            </div>
          </div>

          <div className="button_container">
            <button onClick={handleShowGroupDetailsForm}>Edit Details</button>
          </div>
        </div>
      </Container>

      <Footer>
        <div>
          <div className="footer_title">Created by</div>
          <div className="footer_sub_title">
            {group.creator?.first_name} {group.creator?.last_name}
          </div>
        </div>

        <div>
          <div className="footer_title">Email</div>
          <div className="footer_sub_title">{group.creator?.email}</div>
        </div>

        <div>
          <div className="footer_title">date created</div>
          <div className="footer_sub_title">
            {' '}
            {moment(group.created_at).format('LL')}
          </div>
        </div>
      </Footer>
    </div>
  );
};

export default ProductDetails;
