import { createSelector } from '@reduxjs/toolkit';

const recipeData = (state = {}) => state.recipes;

export const getRecipe = createSelector(recipeData, (recipeData) => recipeData.data);

export const getLoading = createSelector(
  recipeData,
  (recipeData) => recipeData.ui.loading
);

export const getRecipeServings = createSelector(getRecipe, (recipe) => {
  const servings = recipe?.ingredients?.reduce((prev, current) => {
    prev[current.servings] = 1;
    return prev;
  }, {});

  return servings;
});

export const getProductBundle = createSelector(
  recipeData,
  (recipeData) => recipeData.product_bundle
);

export const getCreating = createSelector(recipeData, (data) => data.ui.creating);
export const getUpdating = createSelector(recipeData, (data) => data.ui.creating);
