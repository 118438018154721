import { Img } from '@app/globalstyles/asgard';
import React from 'react';
import editbutton from '@app/assets/icons/edit_button.svg';
import styled from 'styled-components';

const EditBun = styled.button`
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-left: ${({ ml }) => (ml ? ml : null)};
`;
export function DetailsEditButton({ ml = '', onClick }) {
  return (
    <EditBun onClick={onClick} ml={ml}>
      <Img src={editbutton} alt="Edit" />
    </EditBun>
  );
}
