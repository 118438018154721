import React, { useState, useEffect } from 'react';
import { VIewPage, Flex } from '../style';
import left from '@design-system/Assets/left.png';
import { Link } from 'react-router-dom';
import { FormsModal } from '@app/globalstyles/asgard';
import { FormContainerProducts } from '../../products/style';
import EditPersonal from '../components/EditPersonal';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  getLoading,
  getPartner,
  getCreating,
  getPartnerPaymentTerm,
  getPartnerBank,
  getDeliveryLocation
} from '../redux/selector';
import {
  get_partner,
  delete_partner,
  update_partner,
  get_payment_term,
  get_bank_detail,
  get_locations,
  verify_partner
} from '../redux/reducer';
import LoadingDataUi from '@app/components/loading';
import commaNumber from 'comma-number';
import Modal from '@app/components/modal';
import PaymentTerm from '../components/paymentTerm';
import { Badge } from '@design-system/Badge/Badge';
import UpdatePaymentTerm from '../components/updatePaymentTerm';
import styles from '../styles.module.css';
import peep from '@app/assets/images/peep.png';

const ViewPartner = () => {
  const [hide, setHide] = useState(false);
  const [update, setUpdate] = useState(false);
  const partner = useSelector(getPartner);
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  const params = useParams();
  const creating = useSelector(getCreating);
  const [show, setShow] = useState(false);
  const payment_term = useSelector(getPartnerPaymentTerm);
  const [open, setOpen] = useState(false);
  const bank = useSelector(getPartnerBank);
  const location = useSelector(getDeliveryLocation);

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const handleDelete = () => {
    dispatch(delete_partner(params.id));
  };

  const updatePartner = (data) => {
    dispatch(
      update_partner({
        id: params.id,
        data
      })
    );
  };

  useEffect(() => {
    dispatch(get_partner(params.id));
    dispatch(get_payment_term({ id: params.id }));
    dispatch(get_locations(params.id));
    dispatch(get_bank_detail(params.id));
  }, [dispatch, params]);

  const OpenSesame = () => {
    setHide(true);
  };

  const verifyPartner = () => {
    dispatch(verify_partner(partner?.user?._id));
  };

  return (
    <VIewPage>
      {hide && (
        <FormsModal show={hide} onClick={() => setHide(false)}>
          <FormContainerProducts onClick={StopBubbling}>
            <EditPersonal
              personal={partner}
              updatePartner={updatePartner}
              loading={creating}
              update={update}
              setUpdate={setUpdate}
            />
          </FormContainerProducts>
        </FormsModal>
      )}

      {show && (
        <Modal
          width={'400px'}
          onClose={() => setShow(false)}
          title="Add Payment Term"
        >
          <PaymentTerm id={partner._id} onClose={() => setShow(false)} />
        </Modal>
      )}

      {open && (
        <Modal
          width={'400px'}
          onClose={() => setOpen(false)}
          title="Update Payment Term"
        >
          <UpdatePaymentTerm
            id={partner._id}
            payment={payment_term}
            onClose={() => setOpen(false)}
          />
        </Modal>
      )}
      {loading ? (
        <LoadingDataUi />
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link to="/partners/overview" className="link">
                Partners
              </Link>
              <img src={left} alt="left_arrow" className="left_arrow" />
              <div className="title">Partner Details</div>
            </div>
            <Flex>
              <button className="tp_btn" onClick={handleDelete}>
                Delete Partner
              </button>
              {partner?.account_verified === false && (
                <button className="vp_btn" onClick={verifyPartner}>
                  Verfiy Partner
                </button>
              )}
            </Flex>
          </div>

          <div className={styles.container}>
            <div>
              <div className={styles.card}>
                <div className={styles.profile}>
                  <img
                    src={partner?.user?.photo_url ? partner?.user?.photo_url : peep}
                    alt="user"
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignContent: 'baseline',
                      width: '100%'
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <div className={styles.name}>
                        {`${partner?.user?.first_name} ${partner?.user?.last_name}` ||
                          null}
                      </div>
                      <div className={styles.email}>{partner?.short_bio} </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          alignContent: 'center',
                          marginBottom: '5px',
                          marginTop: '5px'
                        }}
                      >
                        <div className={styles.email}>{partner?.user?.email} </div>
                        <div style={{ color: '#d9d8da' }}> | </div>
                        <div className={styles.email}>{partner?.phone} </div>
                      </div>

                      {partner?.account_verified ? (
                        <Badge bg="success">Verified</Badge>
                      ) : (
                        <Badge bg="danger">Not Verified</Badge>
                      )}
                    </div>

                    <div
                      onClick={OpenSesame}
                      style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#549a9a',
                        cursor: 'pointer'
                      }}
                    >
                      Edit
                    </div>
                  </div>
                </div>
                {/* end of profile class */}

                <div className={styles.others}>
                  <div>
                    <div className={styles.location}>{partner?.type} </div>
                    <div className={styles.location_t}>type </div>
                  </div>
                  <div>
                    <div className={styles.location}>{partner?.business_name} </div>
                    <div className={styles.location_t}>business name </div>
                  </div>
                  <div>
                    <div className={styles.location}>{partner?.phone} </div>
                    <div className={styles.location_t}>business number </div>
                  </div>

                  <div>
                    <div className={styles.location}>{partner?.street_address} </div>
                    <div className={styles.location_t}>street address </div>
                  </div>
                  <div>
                    <div className={styles.location}>
                      {' '}
                      {partner?.currency?.symbol}
                      {commaNumber(partner?.delivery_fee?.value)}{' '}
                    </div>
                    <div className={styles.location_t}>delivery fee </div>
                  </div>

                  <div>
                    <div className={styles.location}>{partner?.country} </div>
                    <div className={styles.location_t}>country </div>
                  </div>

                  <div>
                    <div className={styles.location}>{partner?.state} </div>
                    <div className={styles.location_t}>state </div>
                  </div>

                  <div>
                    <div className={styles.location}>{partner?.city} </div>
                    <div className={styles.location_t}>city </div>
                  </div>
                  <div>
                    <div className={styles.location}>
                      {moment(partner?.created_at).format('LL')}{' '}
                    </div>
                    <div className={styles.location_t}>Date Created </div>
                  </div>
                </div>
              </div>

              {/* bank details */}

              {bank !== null && (
                <div className={styles.card}>
                  <div className={styles.label}>Bank details </div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(3, 1fr)',
                      gap: '24px',
                      marginTop: '24px'
                    }}
                  >
                    <div>
                      <div className={styles.location}>{bank?.bank_name} </div>
                      <div className={styles.location_t}>bank name </div>
                    </div>

                    <div>
                      <div className={styles.location}>{bank?.account_name} </div>
                      <div className={styles.location_t}>account name </div>
                    </div>

                    <div>
                      <div className={styles.location}>{bank?.account_number} </div>
                      <div className={styles.location_t}>account number </div>
                    </div>
                  </div>
                </div>
              )}

              {/* end */}

              {/* delivery location card */}
              {location?.length >= 1 && (
                <div className={styles.card}>
                  <div className={styles.label}>Delivery Locations </div>
                  {location?.map((data) => (
                    <div className={styles.location_card} key={data?._id}>
                      <div>
                        <div className={styles.location}>{data.location} </div>
                        <div className={styles.location_t}>location </div>
                      </div>
                      <div>
                        <div className={styles.location}>{data.eta} </div>
                        <div className={styles.location_t}>delivery time </div>
                      </div>
                      <div>
                        <div className={styles.location}>
                          {`${data.price_car.currency.symbol}${commaNumber(
                            data.price_car.value
                          )}`}{' '}
                        </div>
                        <div className={styles.location_t}>price by car </div>
                      </div>

                      <div>
                        <div className={styles.location}>
                          {`${data.price_bike.currency.symbol}${commaNumber(
                            data.price_bike.value
                          )}`}{' '}
                        </div>
                        <div className={styles.location_t}>price by bike </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* end  */}

              {/* documents */}
              {partner?.proof_of_address && partner?.government_id ? (
                <div className={styles.card}>
                  <div className={styles.label}>Documents </div>
                  <div className={styles.image_box}>
                    <div>
                      <div
                        className={styles.location_t}
                        style={{ marginBottom: '10px' }}
                      >
                        Proof of address
                      </div>
                      <a
                        href={partner?.proof_of_address}
                        target={'_blank'}
                        rel="noreferrer noopener"
                        style={{ textDecoration: 'none' }}
                      >
                        <iframe
                          src={partner?.proof_of_address}
                          style={{
                            cursor: 'pointer',
                            overflow: 'hidden',
                            border: 'none'
                          }}
                          title="Proof Of Address"
                        />
                        <div className={styles.location_t}>View Document </div>
                      </a>
                    </div>
                    <div>
                      <div
                        className={styles.location_t}
                        style={{ marginBottom: '10px' }}
                      >
                        Government ID
                      </div>

                      <a
                        href={partner?.government_id}
                        target={'_blank'}
                        rel="noreferrer noopener"
                        style={{ textDecoration: 'none' }}
                      >
                        <iframe
                          src={partner?.government_id}
                          style={{ cursor: 'pointer', borer: 'none' }}
                          title="Government ID"
                        />
                        <div className={styles.location_t}>View Document</div>
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* end */}
            </div>

            {/* right card */}
            <div>
              <div className={styles.card}>
                <div className={styles.label}>Payment Term</div>

                {Object?.keys(payment_term || {})?.length >= 1 ? (
                  <>
                    <div className={styles.sub}>Percentage</div>
                    <div className={styles.sub_text}>
                      {payment_term?.pre_payment}%
                    </div>
                    <div className={styles.line} />
                    {payment_term.note && (
                      <>
                        <div className={styles.sub}>Note</div>
                        <div className={styles.sub_text}>{payment_term?.note}</div>
                      </>
                    )}
                    <button className={styles.pay} onClick={() => setOpen(true)}>
                      Update Payment term
                    </button>
                  </>
                ) : (
                  <div className={styles.center}>
                    <div className={styles.center_label}>No Payment term yet </div>
                    <button className={styles.pay} onClick={() => setShow(true)}>
                      Add Payment term
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </VIewPage>
  );
};

export default ViewPartner;
