import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false
  },
  data: []
};

export const staffReducerSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    get_staff_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload;
    },
    get_staff_error: (state) => {
      state.ui.loading = false;
    },
    get_staff: (state) => {
      state.ui.loading = true;
    },

    create_staff_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data.unshift(payload);
    },
    create_staff_error: (state) => {
      state.ui.loading = false;
    },
    create_staff: (state) => {
      state.ui.loading = true;
    }
  }
});

export const {
  get_staff,
  get_staff_error,
  get_staff_success,
  create_staff,
  create_staff_error,
  create_staff_success
} = staffReducerSlice.actions;

export default staffReducerSlice.reducer;
