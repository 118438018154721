import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_payment,
  get_payment_success,
  get_payment_error,
  get_one_payment,
  get_one_payment_success,
  get_one_payment_error,
  create_payment,
  create_payment_error,
  create_payment_success
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getPaymentSagas({ payload }) {
  try {
    const { limit = 10, page = 1, searchString } = payload;
    let search = ``;
    if (searchString) {
      search = `search=${searchString}`;
    }
    const requestRes = yield call(
      api.get,
      `/payments?page=${page}&limit=${limit}&${search}`
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_payment_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_payment_error.type
    });
  }
}

function* getSinglePaymentSagas({ payload }) {
  try {
    const requestRes = yield call(api.get, `/payments/${payload}`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_one_payment_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.message);
    yield put({
      type: get_one_payment_error.type
    });
  }
}

function* createPaymentSagas({ payload }) {
  try {
    const requestRes = yield call(api.post, '/payments', payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      tyep: create_payment_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error.response.data.mesaage);
    yield put({
      type: create_payment_error.type
    });
  }
}

function* paymentSagas() {
  yield takeEvery(get_payment.type, getPaymentSagas);
  yield takeEvery(get_one_payment.type, getSinglePaymentSagas);
  yield takeEvery(create_payment.type, createPaymentSagas);
}

export default paymentSagas;
