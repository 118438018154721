import styled from 'styled-components';
import img99 from './icons/99-01.png';
import img11 from './icons/11-01.png';
import img44 from './icons/44-01.png';

export const CardContainer = styled.div`
  padding: 7.5%;
  width: 30%;
  margin: auto;
  background: url(${img99}) top right no-repeat, url(${img11}) top left no-repeat,
    url(${img44}) bottom right no-repeat;

  @media screen and (max-width: 992px) {
    width: 85%;
    background: none;
  }
  @media screen and (min-width: 1024px) and (max-width: 1400px) {
    width: 70%;
    padding: 10%;
  }
`;

export const EmailSentCardContainer = styled(CardContainer)`
  width: 50%;
  @media screen and (max-width: 992px) {
    width: 85%;
    background: none;
  }
  @media screen and (min-width: 1024px) and (max-width: 1400px) {
    width: 70%;
    background: none;
  }
`;
