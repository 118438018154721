import React from 'react';
import { TableBox } from '../style';
import moment from 'moment';
import { Badge } from '@design-system/Badge/Badge';
import LoadingDataUi from '@app/components/loading';
import { Link } from 'react-router-dom';

export const Table = ({ showModal, recipe, loading }) => {
  return (
    <div>
      {loading ? (
        <LoadingDataUi />
      ) : (
        <TableBox>
          <table>
            <thead className="table_head">
              <tr>
                <th>Recipe</th>
                <th>Creator</th>
                <th>Date Created</th>
                <th>Approval Status</th>
              </tr>
            </thead>
            <tbody className="table_body">
              {recipe.map((recipe) => (
                <tr key={recipe._id}>
                  <td>
                    <Link to={`/recipe/${recipe._id}`} className="recipe_name">
                      {recipe.name}
                    </Link>
                  </td>
                  <td className="creator_name">
                    {recipe?.created_by?.first_name} {recipe?.created_by?.last_name}
                  </td>
                  <td className="date_created">
                    {moment(recipe?.created_at)?.format('LL')}
                  </td>
                  <td>
                    <Badge
                      bg={
                        recipe.status === 'rejected'
                          ? 'danger'
                          : recipe.status === 'approved'
                          ? 'success'
                          : 'warning'
                      }
                      color={
                        recipe.status === 'rejected'
                          ? 'danger'
                          : recipe.status === 'approved'
                          ? 'success'
                          : 'warning'
                      }
                    >
                      {recipe.status === 'rejected' ? 'Declined' : recipe.status}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableBox>
      )}
    </div>
  );
};
