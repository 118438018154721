import React from 'react';
import { AsgardP, Button, Div } from '@app/globalstyles/asgard';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import next from '@assets/icons/right.svg';
// import prev from '@assets/icons/left.svg';
import { PagesNav, TableFooter } from './style';
import Colors from '@app/globalstyles/utils/colors';
// import Colors from '@app/globalstyles/utils/colors';
// import styled from 'styled-components';

// const SelectPageLimit = styled.select`
//   outline: none;
//   border: none;
//   font-size: 16px;
//   color: ${Colors.grey};
// `;

function Pagination({
  itemsCount,
  pageSize,
  currentPage,
  onPageChange
  // onChangePageSize
}) {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  // if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);

  // const startCount = currentPage === 1 ? 1 : (currentPage - 1) * pageSize + 1;
  // const endCount = Math.min(startCount + pageSize - 1, itemsCount);
  return (
    <TableFooter>
      <PagesNav>
        <AsgardP>Page</AsgardP>{' '}
        <AsgardP ml="5px" fw="bold">
          {currentPage}{' '}
        </AsgardP>
        <AsgardP ml="5px">of</AsgardP>{' '}
        <AsgardP ml="5px" fw="900">
          {pages.length}
        </AsgardP>
      </PagesNav>
      <PagesNav>
        {/* <AsgardP mr="10px" color={Colors.grey}>
          Rows per page:
        </AsgardP>
        <SelectPageLimit onChange={(e) => onChangePageSize(e.target.value)}>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={70}>70</option>
          <option value={100}>100</option>
        </SelectPageLimit>
        <Div ml="10px" mr="10px">
          <AsgardP color={Colors.grey}>
            {startCount} - {endCount} of {itemsCount}
          </AsgardP>
        </Div> */}
        <Div display="flex" mt="15px" mb="15px">
          {pages?.map((page, index) => (
            <Button
              key={index}
              display="flex"
              ml="5px"
              p="0px 15px"
              bc={page === currentPage ? '#F5F5F5' : 'transparent'}
              br="7px"
              cursor="pointer"
              onClick={() => onPageChange(page)}
            >
              <AsgardP
                fw={page === currentPage ? 'bold' : 'transparent'}
                color={Colors.blackish}
              >
                {page}
              </AsgardP>
            </Button>
          ))}
        </Div>
      </PagesNav>
    </TableFooter>
  );
}

Pagination.propTypes = {
  itemsCount: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
  // onChangePageSize: PropTypes.func.isRequired
};

export default Pagination;
