import React, { useState, useEffect } from 'react';
import { VIewPage, Card, Flex, ProductBox } from '../style';
import left from '@design-system/Assets/left.png';
import { Link } from 'react-router-dom';
import { FormsModal } from '@app/globalstyles/asgard';
import { FormContainerProducts } from '../../products/style';
import AddProduct from '../addProduct';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getLoading,
  getPartner,
  getPageStats,
  getPartnerProduct,
  getCreating
} from '../redux/selector';
import {
  get_partner,
  approve_product,
  get_partner_products
} from '../redux/reducer';
import LoadingDataUi from '@app/components/loading';
import commaNumber from 'comma-number';
import EmptyDataUi from '@app/components/emptyData';
import { getProductGroups } from '@app/pages/product-groups/redux/selectors';
import { get_product_groups } from '@app/pages/product-groups/redux/reducers';
import Pagination from '@app/components/pagination';
import CommentsBox from '../components/commentsBox';
import Modal from '@app/components/modal';
import { Badge } from '@design-system/Badge/Badge';
import useDebounce from '@app/Hooks/useDebounce';
import Switch from '@design-system/Switch/Switch';
import { edit_partner_products } from '../redux/reducer';

const ViewProducts = () => {
  const [show, setShow] = useState(false);
  const partner = useSelector(getPartner);
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  const params = useParams();
  const group = useSelector(getProductGroups);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const pageStats = useSelector(getPageStats);
  const products = useSelector(getPartnerProduct);
  const [filter, setFilter] = useState('');
  const [groupFilter, setGroupFilter] = useState('');
  const load = useSelector(getCreating);
  const [visible, setVisible] = useState(false);
  const [partnerId, setPartnerId] = useState({});
  const [search, setSearch] = useState('');
  const debouncedTerm = useDebounce(search, 500);
  const [checked, setChecked] = useState('');

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleFilterGroup = (e) => {
    setGroupFilter(e.target.value);
  };

  const getNewProducts = (page, limit) => {
    dispatch(
      get_partner_products({
        page,
        limit,
        status: filter,
        product_group: groupFilter,
        id: params.id,
        searchTerm: debouncedTerm
      })
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewProducts(page, pageSize);
  };

  const handleChange = (doc, id, comment) => {
    let data = {
      status: doc,
      comment: comment?.comment
    };

    dispatch(approve_product({ data, id }));
    setVisible(false);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewProducts(1, pageSize);
  };

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const handleComment = (doc) => {
    setVisible(true);
    setPartnerId(doc);
  };

  useEffect(() => {
    dispatch(
      get_partner_products({
        id: params.id,
        status: filter,
        product_group: groupFilter,
        page: currentPage,
        limit: pageSize,
        searchTerm: debouncedTerm
      })
    );
  }, [dispatch, params, groupFilter, filter, currentPage, pageSize, debouncedTerm]);

  useEffect(() => {
    dispatch(get_product_groups({ page: 1, limit: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(get_partner(params.id));
  }, [dispatch, params]);

  const handleCheck = (e, id) => {
    if (e.target.checked === true) {
      setChecked(id);
      let data = {
        is_bundle: true
      };

      dispatch(edit_partner_products({ data, id }));
    } else {
      setChecked('');
      let data = {
        is_bundle: false
      };

      dispatch(edit_partner_products({ data, id }));
    }
  };

  return (
    <VIewPage>
      {visible && (
        <Modal width={'352px'} onClose={() => setVisible(false)} title={'Comment'}>
          <CommentsBox handleChange={handleChange} id={partnerId?._id} />
        </Modal>
      )}

      {show && (
        <FormsModal show={show} onClick={() => setShow(false)}>
          <FormContainerProducts onClick={StopBubbling}>
            <AddProduct id={params.id} />
          </FormContainerProducts>
        </FormsModal>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/partners/products" className="link">
            Partners Products
          </Link>
          <img src={left} alt="left_arrow" className="left_arrow" />
          <div className="title">{partner.business_name} - </div>
          <p className="link">{partner.type}</p>
        </div>
        <Link to={`/partners/${partner?._id}`}>
          <button className="vp_btn">Partner Details</button>
        </Link>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center'
        }}
      >
        <Flex>
          <div>
            <p> Group:</p>
            <select onChange={handleFilterGroup} value={groupFilter}>
              <option selected disabled value="">
                --Select--
              </option>
              {group.map((data) => (
                <option value={data._id} key={data._id}>
                  {data.name}
                </option>
              ))}
            </select>
          </div>

          <div style={{ marginLeft: '20px' }}>
            <p>Approval Status:</p>
            <select onChange={handleFilter} value={filter}>
              <option disabled selected>
                --Select--
              </option>
              <option value="">All</option>
              <option value="status=approved">Approved</option>
              <option value="status=declined">Unapproved</option>
              <option value="status=pending">Pending</option>
              <option value="deleted=true">Deleted</option>
              <option value="in_stock=true">In-Stock</option>
              <option value="in_stock=false">Out-of-Stock</option>
            </select>
          </div>
        </Flex>

        <input
          type="text"
          placeholder="Search by product name..."
          onChange={(e) => setSearch(e.target.value)}
          style={{
            border: '1px solid #d9d8da ',
            borderRadius: '5px',
            maxWidth: '100%',
            height: '45px',
            fontSize: '16px',
            width: '350px',
            boxSizing: 'border-box',
            marginTop: '30px',
            padding: '10px'
          }}
        />
      </div>

      {loading ? (
        <LoadingDataUi />
      ) : (
        <>
          {/* products */}
          <ProductBox>
            <div className="header">
              Products
              <button className="add" onClick={() => setShow(true)}>
                Add Product
              </button>
            </div>
            {products?.length <= 0 && <EmptyDataUi />}
            <div className="body">
              {products?.map((data) => (
                <Card key={data._id}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginBottom: '16px'
                    }}
                  >
                    {data?.status === 'approved' ? (
                      <Badge bg="success"> approved</Badge>
                    ) : data?.status === 'declined' ? (
                      <Badge bg="danger"> declined</Badge>
                    ) : data?.deleted === true ? (
                      <Badge bg="deleted" color="deleted">
                        Deleted
                      </Badge>
                    ) : (
                      <Badge bg="warning" color="warning">
                        pending
                      </Badge>
                    )}
                  </div>
                  <Link to={`/partners/products/edit/${data?._id}`}>
                    <div className="img_overlay"> View </div>
                  </Link>
                  <img src={data?.image} alt="products" />
                  <div className="title_text">{data?.name?.slice(0, 25)}...</div>
                  <div className="price">
                    {data?.price?.currency?.symbol}
                    {commaNumber(data?.price?.value)}
                  </div>
                  {data.status === 'declined' && (
                    <div className="comment">comment: {data.comment}</div>
                  )}
                  <div className="btn_container">
                    <button
                      onClick={() => handleChange('approved', data._id)}
                      disabled={data?.status === 'approved'}
                    >
                      {load ? 'Loading...' : ' Approve'}
                    </button>

                    <button className="decline" onClick={() => handleComment(data)}>
                      {load ? 'Loading...' : ' Decline'}
                    </button>
                  </div>

                  <div className="bundle">
                    Bundle
                    {data?.is_bundle}
                    <Switch
                      change={(e) => handleCheck(e, data._id)}
                      status={checked === data._id || data.is_bundle}
                    />
                  </div>
                </Card>
              ))}
            </div>
          </ProductBox>

          <Pagination
            onChangePageSize={handlePageSizeChange}
            itemsCount={pageStats.docs}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </VIewPage>
  );
};

export default ViewProducts;
