import { createSelector } from '@reduxjs/toolkit';

const productsData = (state = {}) => state.international_products;

export const getProducts = createSelector(
  productsData,
  (productsData) => productsData.data
);

export const getAllProducts = createSelector(
  productsData,
  (productsData) => productsData.allProducts
);
export const getMealProducts = createSelector(
  productsData,
  (productsData) => productsData.mealPackProducts
);

export const getLoading = createSelector(
  productsData,
  (productsData) => productsData.ui.loading
);
export const getLoadingMealProducts = createSelector(
  productsData,
  (productsData) => productsData.ui.loadingMealProducts
);

export const getPageStats = createSelector(
  productsData,
  (productsData) => productsData.ui.stats
);

export const getLoadingCreateMeal = createSelector(
  productsData,
  (productsData) => productsData.ui.loadingCreateMeal
);
