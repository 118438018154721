import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false
  },
  kpis: {
    new_users: 0,
    all_users: 0,
    active_groups: 0,
    all_groups: 0,
    monthly_payments: 0,
    all_payments: 0,
    monthly_value: [],
    all_value: [],
    user_statistics: [],
    user_stats: [],
    order_stats: [],
    all_sellers: 0
  }
};

export const dashboardReducerSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    get_users_stats_success: (state, { payload }) => {
      state.ui.loading = false;
      state.kpis = {
        ...state.kpis,
        new_users: payload.month,
        all_users: payload.total
      };
    },
    get_users_stats_error: (state) => {
      state.ui.loading = false;
    },
    get_users_stats: (state) => {
      state.ui.loading = true;
    },
    get_groups_stats_success: (state, { payload }) => {
      state.ui.loading = false;
      state.kpis = {
        ...state.kpis,
        active_groups: payload.active,
        all_groups: payload.total
      };
    },
    get_groups_stats_error: (state) => {
      state.ui.loading = false;
    },
    get_groups_stats: (state) => {
      state.ui.loading = true;
    },
    get_stats: (state) => {
      state.ui.loading = true;
    },
    get_payment_stats_success: (state, { payload }) => {
      state.kpis = {
        ...state.kpis,
        monthly_payments: payload.month,
        all_payments: payload.total,
        all_value: payload.all_value,
        monthly_value: payload.monthly_value
      };
      state.ui.loading = false;
    },
    get_payment_stats_error: (state) => {
      state.ui.loading = false;
    },
    get_user_statistics: (state) => {
      state.ui.loading = false;
    },
    get_user_statistics_success: (state, { payload }) => {
      state.ui.loading = false;
      state.user_statistics = payload;
    },
    get_user_statistics_error: (state) => {
      state.ui.loading = false;
    },
    get_order_stat: (state) => {
      state.ui.loading = false;
    },
    get_order_stat_success: (state, { payload }) => {
      state.ui.loading = false;
      state.order_stats = payload;
    },
    get_order_stat_error: (state) => {
      state.ui.loading = false;
    },
    get_all_sellers: (state) => {
      state.ui.loading = true;
    },
    get_all_sellers_success: (state, { payload }) => {
      state.ui.loading = false;
      const { partners } = payload;
      state.kpis = {
        ...state.kpis,
        all_sellers: partners.length
      };
      state.all_sellers = partners;
    },
    get_all_sellers_error: (state) => {
      state.ui.loading = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_users_stats,
  get_users_stats_error,
  get_users_stats_success,
  get_groups_stats,
  get_groups_stats_error,
  get_groups_stats_success,
  get_stats,
  get_payment_stats_error,
  get_payment_stats_success,
  get_user_statistics,
  get_user_statistics_success,
  get_user_statistics_error,
  get_order_stat,
  get_order_stat_success,
  get_order_stat_error,
  get_all_sellers,
  get_all_sellers_success,
  get_all_sellers_error
} = dashboardReducerSlice.actions;

export default dashboardReducerSlice.reducer;
