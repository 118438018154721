import React, { useState, useEffect } from 'react';
import { BundleContainer, RecipeIngriedents } from '../style';
import { update_recipe } from '../redux/reducer';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EditRecipe from './EditRecipe';
import { FormsModal } from '@app/globalstyles/asgard';
import { FormContainerProducts } from '../../products/style';
import {
  filterInstructions,
  filterItems,
  filtertime,
  filter_countries,
  filter_type
} from '../utils';
import {
  update_id,
  update_mode,
  upload_recipe_data
} from '@app/pages/recipes/redux/reducer';
import ProductBundle from './productBundle';
import Select from 'react-select';
import { getCountries } from 'country-state-picker';
import { Formik, Form } from 'formik';
import { getCreating } from '../redux/selector';

const Ingriedents = ({ recipe, bundle, bundles, countries }) => {
  const [hide, setHide] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const singleRecipeObject = recipe && {
    name: recipe?.name,
    description: recipe?.description,
    video: recipe?.video,
    video_url: recipe?.video_url,
    ingredients: filterItems(recipe?.ingredients),
    instructions: filterInstructions(recipe?.instructions),
    images: recipe?.images,
    other_ingredients: filterItems(recipe?.other_ingredients),
    prep_time: filtertime(recipe?.prep_time),
    type: filter_type(recipe?.type),
    countries: filter_countries(recipe?.countries)
  };

  const [country, setCountry] = useState([]);
  const loading = useSelector(getCreating);

  let data = {
    countries: countries || ''
  };

  useEffect(() => {
    const countries = getCountries();
    const result = countries.map((data) => {
      return {
        value: data.name,
        label: data.name
      };
    });
    setCountry(result);
  }, []);

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const handleEdit = () => {
    setHide(true);
    dispatch(upload_recipe_data(singleRecipeObject));
    dispatch(update_mode(true));
    dispatch(update_id(recipe?._id));
  };

  const updateRecipe = (data) => {
    dispatch(
      update_recipe({
        id: params.id,
        data
      })
    );
  };

  const updateCountries = (doc) => {
    const values = doc.countries.map((data) => data.value);
    let data = {
      countries: values
    };
    dispatch(update_recipe({ id: params.id, data }));
  };

  return (
    <RecipeIngriedents>
      {hide && (
        <FormsModal show={hide} onClick={() => setHide(false)}>
          <FormContainerProducts onClick={StopBubbling}>
            <EditRecipe recipes={recipe} updateRecipe={updateRecipe} />
          </FormContainerProducts>
        </FormsModal>
      )}
      <button className="edit_button" onClick={handleEdit}>
        Edit
      </button>
      <p className="title">Ingredients</p>

      <div className="list_box">
        <ul>
          {recipe?.ingredients?.map((data) => (
            <li key={data._id}>
              {data.food_item} - {`${data.measurement} kg`} -{' '}
              {data?.product_group?.name}
            </li>
          ))}
        </ul>
      </div>

      <p className="title">Cooking Steps</p>
      <div className="list_box">
        <ul>
          {recipe?.instructions?.map((data) => (
            <li key={data._id}>
              {data.step} - <strong>{data.time} mins</strong>{' '}
            </li>
          ))}
        </ul>
      </div>

      <div className="grid">
        <ProductBundle bundles={bundle} id={params.id} pBundles={bundles} />

        <div>
          <p className="title">Countries</p>
          <Formik
            initialValues={data}
            onSubmit={updateCountries}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <BundleContainer>
                  <Select
                    value={values.countries}
                    options={country}
                    isMulti
                    name="countries"
                    closeMenuOnSelect={false}
                    isClearable={true}
                    onChange={(value) => setFieldValue('countries', value)}
                  />

                  <button className="item" type="submit" disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                </BundleContainer>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <p className="title">Images</p>

      <div className="image_box">
        {recipe?.images?.map((image, index) => (
          <img src={image} key={index} alt="recipes" />
        ))}
      </div>
    </RecipeIngriedents>
  );
};

export default Ingriedents;
