import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    creating: false,
    stats: {
      pageCount: 1
    }
  },
  delivery: []
};

export const deliveryReducerSlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    get_delivery_partners: (state) => {
      state.ui.loading = true;
    },
    get_delivery_partners_success: (state, { payload }) => {
      state.ui.loading = false;
      const { partners, stats } = payload;
      state.delivery = partners;
      state.ui.stats = stats;
    },
    get_delivery_partners_error: (state) => {
      state.ui.loading = false;
    },
    delete_delivery_partner: (state) => {
      state.ui.loading = true;
    },
    delete_delivery_partner_success: (state, { payload }) => {
      state.ui.loading = false;
      state.delivery = state.delivery.filter((data) => data._id !== payload._id);
    },
    delete_delivery_partner_error: (state) => {
      state.ui.loading = false;
    },
    create_delivery_partner: (state) => {
      state.ui.creating = true;
    },
    create_delivery_partner_success: (state, { payload }) => {
      state.ui.creating = false;
      state.delivery.unshift(payload);
    },
    create_delivery_partner_error: (state) => {
      state.ui.creating = false;
    },
    update_delivery_partner: (state) => {
      state.ui.creating = true;
    },
    update_delivery_partner_success: (state, { payload }) => {
      state.ui.creating = false;
      const previousData = state.delivery;
      const index = previousData.findIndex((data) => data._id === payload._id);
      previousData[index] = payload;
      state.delivery = previousData;
    },
    update_delivery_partner_error: (state) => {
      state.ui.creating = false;
    }
  }
});

export const {
  get_delivery_partners,
  get_delivery_partners_success,
  get_delivery_partners_error,
  delete_delivery_partner,
  delete_delivery_partner_success,
  delete_delivery_partner_error,
  create_delivery_partner,
  create_delivery_partner_success,
  create_delivery_partner_error,
  update_delivery_partner,
  update_delivery_partner_success,
  update_delivery_partner_error
} = deliveryReducerSlice.actions;

export default deliveryReducerSlice.reducer;
