import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    creating: false,
    stats: {
      pageCount: 1
    }
  },
  facts: []
};

export const mealFactsReducerSlice = createSlice({
  name: 'facts',
  initialState,
  reducers: {
    get_meal_facts: (state) => {
      state.ui.loading = true;
    },
    get_meal_facts_success: (state, { payload }) => {
      state.ui.loading = false;
      state.facts = payload.facts;
      state.ui.stats = {
        limit: payload.limit,
        docs: payload.docs,
        page: payload.page
      };
    },
    get_meal_facts_error: (state) => {
      state.ui.loading = false;
    },
    create_meal_facts: (state) => {
      state.ui.creating = true;
    },
    create_meal_facts_success: (state, { payload }) => {
      state.ui.creating = false;
      state.facts.unshift(payload);
    },
    create_meal_facts_error: (state) => {
      state.ui.creating = false;
    },
    delete_meal_facts: (state) => {
      state.ui.creating = true;
    },
    delete_meal_facts_success: (state, { payload }) => {
      state.ui.creating = false;
      const data = state.facts.filter((data) => data._id !== payload._id);
      state.facts = data;
    },
    delete_meal_facts_error: (state) => {
      state.ui.creating = false;
    },
    update_meal_facts: (state) => {
      state.ui.creating = true;
    },
    update_meal_facts_success: (state) => {
      state.ui.creating = false;
    },
    update_meal_facts_error: (state) => {
      state.ui.creating = false;
    }
  }
});

export const {
  get_meal_facts,
  get_meal_facts_success,
  get_meal_facts_error,
  create_meal_facts,
  create_meal_facts_success,
  create_meal_facts_error,
  delete_meal_facts,
  delete_meal_facts_success,
  delete_meal_facts_error,
  update_meal_facts,
  update_meal_facts_success,
  update_meal_facts_error
} = mealFactsReducerSlice.actions;

export default mealFactsReducerSlice.reducer;
