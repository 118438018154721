import * as React from 'react';

const SearchIcon = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={11.1} cy={11.88} r={7} stroke="#64748B" strokeWidth={2} />
    <path
      d="m20.1 20.88-3-3"
      stroke="#64748B"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default SearchIcon;
