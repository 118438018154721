export const BasketFilterData = [
  {
    id: 1,
    title: 'Status',
    value: ''
  },

  {
    id: 2,
    title: 'Paid',
    value: 'paid=true'
  },

  {
    id: 3,
    title: 'Unpaid',
    value: 'paid=false'
  }
];
