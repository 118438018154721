import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    creating: false,
    stats: {
      pageCount: 1
    }
  },

  meal_categories: []
};

export const mealCategoriesReducerSlice = createSlice({
  name: 'meal_categories',
  initialState,
  reducers: {
    get_meal_categories: (state) => {
      state.ui.loading = true;
    },
    get_meal_categories_success: (state, { payload }) => {
      state.ui.loading = false;
      const { categories, stats } = payload;
      state.meal_categories = categories;
      state.ui.stats = {
        ...stats
      };
    },
    get_meal_categories_error: (state) => {
      state.ui.loading = false;
    },
    create_meal_categories: (state) => {
      state.ui.creating = true;
    },
    create_meal_categories_success: (state, { payload }) => {
      state.ui.creating = false;
      state.meal_categories.unshift(payload);
    },
    create_meal_categories_error: (state) => {
      state.ui.creating = false;
    },
    delete_meal_categories: (state) => {
      state.ui.creating = true;
    },
    delete_meal_categories_success: (state, { payload }) => {
      state.ui.creating = false;
      const res = state.meal_categories.filter((data) => data._id !== payload._id);
      state.meal_categories = res;
    },
    delete_meal_categories_error: (state) => {
      state.ui.creating = false;
    },
    update_meal_categories: (state) => {
      state.ui.creating = true;
    },
    update_meal_categories_success: (state) => {
      state.ui.creating = false;
    },
    update_meal_categories_error: (state) => {
      state.ui.creating = false;
    }
  }
});

export const {
  get_meal_categories,
  get_meal_categories_success,
  get_meal_categories_error,
  create_meal_categories,
  create_meal_categories_success,
  create_meal_categories_error,
  delete_meal_categories,
  delete_meal_categories_success,
  delete_meal_categories_error,
  update_meal_categories,
  update_meal_categories_success,
  update_meal_categories_error
} = mealCategoriesReducerSlice.actions;

export default mealCategoriesReducerSlice.reducer;
