import { createSelector } from '@reduxjs/toolkit';

const paymentData = (state = {}) => state.payment;

export const getPayment = createSelector(
  paymentData,
  (paymentData) => paymentData.data
);

export const getSinglePayment = createSelector(paymentData, (data) => data.details);

export const getLoading = createSelector(
  paymentData,
  (paymentData) => paymentData.ui.loading
);

export const getPageStats = createSelector(
  paymentData,
  (paymentData) => paymentData.ui.stats
);
