import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FormBody } from '../style';
import { getStates } from 'country-state-picker';
import { getCurrencies } from '@app/pages/currrencies/redux/selectors';
import { get_currencies } from '@app/pages/currrencies/redux/reducer';
import { useSelector, useDispatch } from 'react-redux';
import SelectInputP from '@app/pages/products/addproduct/select';

const Business = ({ data, next, prev, loading }) => {
  const [states, setStates] = useState([]);
  const currencies = useSelector(getCurrencies);
  const dispatch = useDispatch();

  useEffect(() => {
    const states = getStates(data?.code);
    setStates(states);
  }, [data]);

  const handleSubmit = (values) => {
    next(values, true);
  };

  useEffect(() => {
    dispatch(get_currencies());
  }, [dispatch]);
  return (
    <FormBody>
      <Formik initialValues={data} onSubmit={handleSubmit}>
        {({ values, handleChange }) => (
          <Form>
            <div className="grid">
              <div className="form_content">
                <label>Business Name</label>
                <input
                  placeholder="John Doe"
                  name="business_name"
                  value={values.business_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_content">
                <label>Business Number</label>
                <input
                  placeholder="+234"
                  name="business_number"
                  value={values.business_number}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="grid">
              <div className="form_content">
                <label>Street Address </label>
                <input
                  placeholder="JohnDoe"
                  name="street_address"
                  value={values.street_address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_content">
                <label>City </label>
                <input
                  placeholder="JohnDoe"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="grid">
              <div className="form_content">
                <label>State </label>
                <select name="state" onChange={handleChange}>
                  {states?.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form_content">
                <label>Zip Code </label>
                <input
                  placeholder="JohnDoe"
                  name="zip_code"
                  value={values.zip_code}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="grid">
              <div className="form_content">
                <label>Currency Type</label>
                <SelectInputP onChange={handleChange} name="currency">
                  <option selected disabled>
                    Select Type
                  </option>
                  {currencies.map((currency) => (
                    <option key={currency._id} value={currency._id}>
                      {currency.short_code} - {currency.symbol}
                    </option>
                  ))}
                </SelectInputP>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                <button onClick={prev} className="cancel">
                  Back
                </button>
                <button type="submit" disbaled={loading}>
                  {loading ? 'Submitting' : 'Submit'}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </FormBody>
  );
};

export default Business;
