import styled from 'styled-components';
import media from '@app/globalstyles/utils/media';

export const PageContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

export const SectionContainer = styled.div``;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionBody = styled.div`
  ${media.mobile`
display: flex;
  flex-direction: column;
`}
  display: flex;
  justify-content: space-between;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const BtnContainer = styled.div`
  width: 100%;
  height: 40px;
  bottom: 0px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

export const ProductContainer = styled.div`
  ${media.mobile`
display: grid;
grid-template-columns: repeat(1, 1fr);
grid-gap: 10px;
`}
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  img {
    width: 100%;
    height: 100px;
    border-radius: 12px;
  }

  .card {
    box-shadow: 2px 4px 8px rgba(194, 210, 253, 0.25);
    border-radius: 12px;
    padding: 10px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
`;
