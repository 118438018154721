import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ItemContainer,
  SectionBody,
  SectionContainer,
  SectionHeader
} from './style';
import { Text } from '@design-system/Text';
import { get_one_payment } from '../payments/redux/reducer';
import { getLoading, getSinglePayment } from '../payments/redux/selectors';
import moment from 'moment';

const PaymentPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const payment = useSelector(getSinglePayment);
  const params = useParams();

  useEffect(() => {
    dispatch(get_one_payment(params.id));
  }, [dispatch, params]);
  return (
    <div style={{ paddingLeft: 30, marginRight: 30 }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <SectionContainer>
          <SectionHeader>
            <h2>Payment Details</h2>
          </SectionHeader>
          <SectionBody>
            <ItemContainer>
              <p>Created at</p>
              <Text size="small" align="left">
                {moment(payment.created_at).format('LL')}
              </Text>
            </ItemContainer>
            <ItemContainer>
              <p>Price</p>
              <Text size="small" align="left">
                {payment?.price?.currency?.symbol} {payment?.price?.value}
              </Text>
            </ItemContainer>
          </SectionBody>

          <SectionBody>
            <ItemContainer>
              <p>Channel</p>
              <Text size="small" align="left">
                {payment.channel}
              </Text>
            </ItemContainer>

            <ItemContainer>
              <p>Last Update date</p>
              <Text size="small" align="left">
                {moment(payment.updated_at).format('LL')}
              </Text>
            </ItemContainer>
          </SectionBody>
        </SectionContainer>
      )}
    </div>
  );
};

export default PaymentPage;
