import { createSelector } from '@reduxjs/toolkit';

const partnerData = (state = {}) => state.partner;

export const getPartners = createSelector(partnerData, (data) => data.partner);

export const getLoading = createSelector(partnerData, (data) => data.ui.loading);

export const getPartner = createSelector(partnerData, (data) => data.single_partner);

export const getCreating = createSelector(partnerData, (data) => data.ui.creating);

export const getPartnerProduct = createSelector(
  partnerData,
  (data) => data.products
);

export const getPartnerLocation = createSelector(
  partnerData,
  (data) => data.partner_location
);

export const getPageStats = createSelector(partnerData, (data) => data.ui.stats);

export const getEditSuccess = createSelector(
  partnerData,
  (partnerData) => partnerData.ui.edit
);

export const getPartnerPaymentTerm = createSelector(
  partnerData,
  (data) => data.term
);

export const getPartnerConfig = createSelector(partnerData, (data) => data.config);

export const getPartnerBank = createSelector(partnerData, (data) => data.bank);

export const getDeliveryLocation = createSelector(
  partnerData,
  (data) => data.locations
);

export const getExportData = createSelector(
  partnerData,
  (partnerData) => partnerData.export
);
