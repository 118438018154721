import { put, takeEvery, call } from '@redux-saga/core/effects';
import {
  get_recipe_success,
  get_recipe_error,
  get_recipe,
  add_meal_success,
  add_meal_error,
  add_meal,
  update_recipe_success,
  update_recipe_error,
  update_recipe,
  get_product_group,
  get_product_group_success,
  get_product_group_error,
  create_product_group,
  create_product_group_success,
  create_product_group_error,
  create_recipe,
  create_recipe_success,
  create_recipe_error,
  get_product_images,
  get_product_images_success,
  get_product_images_error,
  edit_recipe,
  edit_recipe_success
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';

function* getRecipeSagas({ payload }) {
  try {
    const { page = 1, limit = 20, selectedfilter, searchString } = payload;
    let search = ``;
    let filter = ``;
    if (searchString) {
      search = `&search=${searchString}`;
    }
    if (selectedfilter) {
      filter = `&${selectedfilter}`;
    }
    const requestRes = yield call(
      api.get,
      `/recipes?&page=${page}&limit=${limit}${search}${filter}`
    );

    const responseData = requestRes.data;
    const response = responseData.data;

    yield put({
      type: get_recipe_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_recipe_error.type
    });
  }
}

function* getProductImages() {
  try {
    const requestRes = yield call(api.get, `/product_images`);
    const responseData = requestRes.data;
    const response = responseData.data;
    console.log(response);
    yield put({
      type: get_product_images_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_product_images_error.type
    });
  }
}

function* addMealSagas({ payload }) {
  try {
    const requestRes = yield call(api.post, `/meal`, payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: add_meal_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: add_meal_error.type
    });
  }
}

function* updateRecipeSagas({ payload }) {
  const { data, id } = payload;
  try {
    const requestRes = yield call(api.post, `/recipes/status/${id}`, data);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: update_recipe_success.type,
      payload: response
    });
    toast.success('Recipe updated successfully!!');
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: update_recipe_error.type
    });
  }
}

function* getProductGroupSaga(payload) {
  try {
    const { page = 1, limit = 1000 } = payload;
    const request = yield call(
      api.get,
      `/product_groups?page=${page}&limit=${limit}`
    );
    const requestRes = request.data;
    const response = requestRes.data;
    yield put({
      type: get_product_group_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: get_product_group_error.type
    });
  }
}

function* createProductGroupSagas({ payload }) {
  try {
    const requestRes = yield call(api.post, `/product_groups`, payload);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(response.message);
    yield put({
      type: create_product_group_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error ? error.toString() : error?.response?.data?.message);
    yield put({
      type: create_product_group_error.type
    });
  }
}

function* editRecipeSaga({ payload }) {
  try {
    let final_array = [];
    let final_countries = [];
    payload.data.type.map((type) => final_array.push(type.value));
    payload.data.countries.map((type) => final_countries.push(type.value));
    let final_string = final_array.join(',');
    const data = {
      ...payload?.data,
      type: final_string,
      prep_time: payload?.data?.prep_time?.value,
      countries: final_countries
    };
    const requestRes = yield call(api.put, `/recipes/${payload?.id}`, data);
    const responseData = requestRes.data;
    const response = responseData.data;
    const closeBtn = document.getElementById('closed');
    closeBtn?.click();
    toast.success(responseData.message);
    setTimeout(() => {
      window.history.pushState({}, '', '/recipe');
    }, 1000);
    yield put({
      type: edit_recipe_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(
      error?.response?.status === 400
        ? "Please ensure instruction time has 'mins','secs' or 'hours' "
        : error?.response?.data?.message
        ? error?.response?.data?.message
        : error?.toString()
    );
    yield put({
      type: create_recipe_error.type,
      payload: error
    });
  }
}

function* createRecipeSaga({ payload }) {
  try {
    let final_array = [];
    let final_countries = [];
    payload.type.map((type) => final_array.push(type.value));
    payload.countries.map((country) => final_countries.push(country.value));
    let final_string = final_array.join(',');
    const data = {
      ...payload,
      type: final_string,
      prep_time: payload?.prep_time?.value,
      countries: final_countries,
      video_url: payload.video
    };
    const requestRes = yield call(api.post, `/recipes`, data);
    const responseData = requestRes.data;
    const response = responseData.data;
    const closeBtn = document.getElementById('close');
    closeBtn?.click();
    toast.success(responseData.message);
    yield put({
      type: create_recipe_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: create_recipe_error.type,
      payload: error
    });
  }
}

function* RecipeSagas() {
  yield takeEvery(get_recipe.type, getRecipeSagas);
  yield takeEvery(add_meal.type, addMealSagas);
  yield takeEvery(update_recipe.type, updateRecipeSagas);
  yield takeEvery(get_product_group.type, getProductGroupSaga);
  yield takeEvery(create_product_group.type, createProductGroupSagas);
  yield takeEvery(create_recipe.type, createRecipeSaga);
  yield takeEvery(edit_recipe.type, editRecipeSaga);
  yield takeEvery(get_product_images.type, getProductImages);
}

export default RecipeSagas;
