import React from 'react';

const ExpandIcon = (props) => {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m12.016 15.617-.707.707.707.707.707-.707-.707-.707ZM5.31 10.324l6 6 1.414-1.414-6-6-1.414 1.414Zm7.414 6 6-6L17.31 8.91l-6 6 1.414 1.414Z"
        fill={props.color || '#549A9A'}
      />
    </svg>
  );
};

export default ExpandIcon;
